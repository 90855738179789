/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';
export const DMMono_300Light =
  'http://fonts.gstatic.com/s/dmmono/v1/aFTR7PB1QTsUX8KYvrGyIYSnbKX9Rlk.ttf';
export const DMMono_300Light_Italic =
  'http://fonts.gstatic.com/s/dmmono/v1/aFTT7PB1QTsUX8KYth-orYataIf4VllXuA.ttf';
export const DMMono_400Regular =
  'http://fonts.gstatic.com/s/dmmono/v1/aFTU7PB1QTsUX8KYhh2aBYyMcKw.ttf';
export const DMMono_400Regular_Italic =
  'http://fonts.gstatic.com/s/dmmono/v1/aFTW7PB1QTsUX8KYth-QAa6JYKzkXw.ttf';
export const DMMono_500Medium =
  'http://fonts.gstatic.com/s/dmmono/v1/aFTR7PB1QTsUX8KYvumzIYSnbKX9Rlk.ttf';
export const DMMono_500Medium_Italic =
  'http://fonts.gstatic.com/s/dmmono/v1/aFTT7PB1QTsUX8KYth-o9YetaIf4VllXuA.ttf';
export const BalsamiqSans_400Regular =
  'http://fonts.gstatic.com/s/balsamiqsans/v1/P5sEzZiAbNrN8SB3lQQX7Pnc8dkdIYdNHzs.ttf';
export const BalsamiqSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/balsamiqsans/v1/P5sazZiAbNrN8SB3lQQX7PncwdsXJaVIDzvcXA.ttf';
export const BalsamiqSans_700Bold =
  'http://fonts.gstatic.com/s/balsamiqsans/v1/P5sZzZiAbNrN8SB3lQQX7PncyWUyBY9mAzLFRQI.ttf';
export const BalsamiqSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/balsamiqsans/v1/P5sfzZiAbNrN8SB3lQQX7PncwdsvmYpsBxDAVQI4aA.ttf';
export const ComicNeue_300Light =
  'http://fonts.gstatic.com/s/comicneue/v1/4UaErEJDsxBrF37olUeD_wHLwpteLwtHJlc.ttf';
export const ComicNeue_300Light_Italic =
  'http://fonts.gstatic.com/s/comicneue/v1/4UaarEJDsxBrF37olUeD96_RTplUKylCNlcw_Q.ttf';
export const ComicNeue_400Regular =
  'http://fonts.gstatic.com/s/comicneue/v1/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf';
export const ComicNeue_400Regular_Italic =
  'http://fonts.gstatic.com/s/comicneue/v1/4UaFrEJDsxBrF37olUeD96_p4rFwIwJePw.ttf';
export const ComicNeue_700Bold =
  'http://fonts.gstatic.com/s/comicneue/v1/4UaErEJDsxBrF37olUeD_xHMwpteLwtHJlc.ttf';
export const ComicNeue_700Bold_Italic =
  'http://fonts.gstatic.com/s/comicneue/v1/4UaarEJDsxBrF37olUeD96_RXp5UKylCNlcw_Q.ttf';
export const Caladea_400Regular =
  'http://fonts.gstatic.com/s/caladea/v1/kJEzBugZ7AAjhybUjR93-9IztOc.ttf';
export const Caladea_400Regular_Italic =
  'http://fonts.gstatic.com/s/caladea/v1/kJExBugZ7AAjhybUvR19__A2pOdvDA.ttf';
export const Caladea_700Bold =
  'http://fonts.gstatic.com/s/caladea/v1/kJE2BugZ7AAjhybUtaNY39oYqO52FZ0.ttf';
export const Caladea_700Bold_Italic =
  'http://fonts.gstatic.com/s/caladea/v1/kJE0BugZ7AAjhybUvR1FQ98SrMxzBZ2lDA.ttf';
export const Jost_100Thin =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGwvxWOh7JuKBE7w.ttf';
export const Jost_200ExtraLight =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGQv1WOh7JuKBE7w.ttf';
export const Jost_300Light =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGnP1WOh7JuKBE7w.ttf';
export const Jost_400Regular =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGwv1WOh7JuKBE7w.ttf';
export const Jost_500Medium =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtG8P1WOh7JuKBE7w.ttf';
export const Jost_600SemiBold =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGHPpWOh7JuKBE7w.ttf';
export const Jost_700Bold =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGJfpWOh7JuKBE7w.ttf';
export const Jost_800ExtraBold =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGQvpWOh7JuKBE7w.ttf';
export const Jost_900Black =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mxrZh9NHHnUBkLNPUtGa_pWOh7JuKBE7w.ttf';
export const Jost_100Thin_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGwvxWOh7JuKBE7w.ttf';
export const Jost_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGQv1WOh7JuKBE7w.ttf';
export const Jost_300Light_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGnP1WOh7JuKBE7w.ttf';
export const Jost_400Regular_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGwv1WOh7JuKBE7w.ttf';
export const Jost_500Medium_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtG8P1WOh7JuKBE7w.ttf';
export const Jost_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGHPpWOh7JuKBE7w.ttf';
export const Jost_700Bold_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGJfpWOh7JuKBE7w.ttf';
export const Jost_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGQvpWOh7JuKBE7w.ttf';
export const Jost_900Black_Italic =
  'http://fonts.gstatic.com/s/jost/v1/92z8tBhPNqw79Ij1C9z1vBQv7mzrWR9NHHnUBkLNPUtGa_pWOh7JuKBE7w.ttf';
export const Oxanium_200ExtraLight =
  'http://fonts.gstatic.com/s/oxanium/v1/RrQVboN_4yJ0JmiMc63l9Lhqa48pA8w.ttf';
export const Oxanium_300Light =
  'http://fonts.gstatic.com/s/oxanium/v1/RrQVboN_4yJ0JmiMc8nm9Lhqa48pA8w.ttf';
export const Oxanium_400Regular =
  'http://fonts.gstatic.com/s/oxanium/v1/RrQQboN_4yJ0JmiMS2XO0LBBd4Y.ttf';
export const Oxanium_500Medium =
  'http://fonts.gstatic.com/s/oxanium/v1/RrQVboN_4yJ0JmiMc5Hn9Lhqa48pA8w.ttf';
export const Oxanium_600SemiBold =
  'http://fonts.gstatic.com/s/oxanium/v1/RrQVboN_4yJ0JmiMc73g9Lhqa48pA8w.ttf';
export const Oxanium_700Bold =
  'http://fonts.gstatic.com/s/oxanium/v1/RrQVboN_4yJ0JmiMc9nh9Lhqa48pA8w.ttf';
export const Oxanium_800ExtraBold =
  'http://fonts.gstatic.com/s/oxanium/v1/RrQVboN_4yJ0JmiMc8Xi9Lhqa48pA8w.ttf';
export const Spartan_100Thin =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrGFuG6OChXtf4qS.ttf';
export const Spartan_200ExtraLight =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrEFuW6OChXtf4qS.ttf';
export const Spartan_300Light =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrHbuW6OChXtf4qS.ttf';
export const Spartan_400Regular =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrGFuW6OChXtf4qS.ttf';
export const Spartan_500Medium =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrG3uW6OChXtf4qS.ttf';
export const Spartan_600SemiBold =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrFbvm6OChXtf4qS.ttf';
export const Spartan_700Bold =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrFivm6OChXtf4qS.ttf';
export const Spartan_800ExtraBold =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrEFvm6OChXtf4qS.ttf';
export const Spartan_900Black =
  'http://fonts.gstatic.com/s/spartan/v1/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrEsvm6OChXtf4qS.ttf';
export const Inter_100Thin =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf';
export const Inter_200ExtraLight =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf';
export const Inter_300Light =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf';
export const Inter_400Regular =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf';
export const Inter_500Medium =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf';
export const Inter_600SemiBold =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf';
export const Inter_700Bold =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf';
export const Inter_800ExtraBold =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf';
export const Inter_900Black =
  'http://fonts.gstatic.com/s/inter/v1/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf';
export const Sen_400Regular = 'http://fonts.gstatic.com/s/sen/v1/6xKjdSxYI9_Hm_-MImrpLQ.ttf';
export const Sen_700Bold = 'http://fonts.gstatic.com/s/sen/v1/6xKudSxYI9__J9CoKkH1JHUQSQ.ttf';
export const Sen_800ExtraBold = 'http://fonts.gstatic.com/s/sen/v1/6xKudSxYI9__O9OoKkH1JHUQSQ.ttf';
export const Bellota_300Light =
  'http://fonts.gstatic.com/s/bellota/v1/MwQzbhXl3_qEpiwAID55kGMViblPtXs.ttf';
export const Bellota_300Light_Italic =
  'http://fonts.gstatic.com/s/bellota/v1/MwQxbhXl3_qEpiwAKJBjHGEfjZtKpXulTQ.ttf';
export const Bellota_400Regular =
  'http://fonts.gstatic.com/s/bellota/v1/MwQ2bhXl3_qEpiwAGJJRtGs-lbA.ttf';
export const Bellota_400Regular_Italic =
  'http://fonts.gstatic.com/s/bellota/v1/MwQ0bhXl3_qEpiwAKJBbsEk7hbBWrA.ttf';
export const Bellota_700Bold =
  'http://fonts.gstatic.com/s/bellota/v1/MwQzbhXl3_qEpiwAIC5-kGMViblPtXs.ttf';
export const Bellota_700Bold_Italic =
  'http://fonts.gstatic.com/s/bellota/v1/MwQxbhXl3_qEpiwAKJBjDGYfjZtKpXulTQ.ttf';
export const BellotaText_300Light =
  'http://fonts.gstatic.com/s/bellotatext/v1/0FlMVP2VnlWS4f3-UE9hHXM5VfsqfQXwQy6yxg.ttf';
export const BellotaText_300Light_Italic =
  'http://fonts.gstatic.com/s/bellotatext/v1/0FlOVP2VnlWS4f3-UE9hHXMx--Gmfw_0YSuixmYK.ttf';
export const BellotaText_400Regular =
  'http://fonts.gstatic.com/s/bellotatext/v1/0FlTVP2VnlWS4f3-UE9hHXMB-dMOdS7sSg.ttf';
export const BellotaText_400Regular_Italic =
  'http://fonts.gstatic.com/s/bellotatext/v1/0FlNVP2VnlWS4f3-UE9hHXMx-9kKVyv8Sjer.ttf';
export const BellotaText_700Bold =
  'http://fonts.gstatic.com/s/bellotatext/v1/0FlMVP2VnlWS4f3-UE9hHXM5RfwqfQXwQy6yxg.ttf';
export const BellotaText_700Bold_Italic =
  'http://fonts.gstatic.com/s/bellotatext/v1/0FlOVP2VnlWS4f3-UE9hHXMx--G2eA_0YSuixmYK.ttf';
export const Gotu_400Regular = 'http://fonts.gstatic.com/s/gotu/v1/o-0FIpksx3QOlH0Lioh6-hU.ttf';
export const CourierPrime_400Regular =
  'http://fonts.gstatic.com/s/courierprime/v1/u-450q2lgwslOqpF_6gQ8kELWwZjW-_-tvg.ttf';
export const CourierPrime_400Regular_Italic =
  'http://fonts.gstatic.com/s/courierprime/v1/u-4n0q2lgwslOqpF_6gQ8kELawRpX837pvjxPA.ttf';
export const CourierPrime_700Bold =
  'http://fonts.gstatic.com/s/courierprime/v1/u-4k0q2lgwslOqpF_6gQ8kELY7pMf-fVqvHoJXw.ttf';
export const CourierPrime_700Bold_Italic =
  'http://fonts.gstatic.com/s/courierprime/v1/u-4i0q2lgwslOqpF_6gQ8kELawRR4-LfrtPtNXyeAg.ttf';
export const Girassol_400Regular =
  'http://fonts.gstatic.com/s/girassol/v1/JTUUjIo_-DK48laaNC9Nz2pJzxbi.ttf';
export const InriaSans_300Light =
  'http://fonts.gstatic.com/s/inriasans/v1/ptRPTiqXYfZMCOiVj9kQ3ELaDQtFqeY3fX4.ttf';
export const InriaSans_300Light_Italic =
  'http://fonts.gstatic.com/s/inriasans/v1/ptRRTiqXYfZMCOiVj9kQ1OzAgQlPrcQybX4pQA.ttf';
export const InriaSans_400Regular =
  'http://fonts.gstatic.com/s/inriasans/v1/ptRMTiqXYfZMCOiVj9kQ5O7yKQNute8.ttf';
export const InriaSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/inriasans/v1/ptROTiqXYfZMCOiVj9kQ1Oz4LSFrpe8uZA.ttf';
export const InriaSans_700Bold =
  'http://fonts.gstatic.com/s/inriasans/v1/ptRPTiqXYfZMCOiVj9kQ3FLdDQtFqeY3fX4.ttf';
export const InriaSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/inriasans/v1/ptRRTiqXYfZMCOiVj9kQ1OzAkQ5PrcQybX4pQA.ttf';
export const InriaSerif_300Light =
  'http://fonts.gstatic.com/s/inriaserif/v1/fC14PYxPY3rXxEndZJAzN3wAVQjFhFyta3xN.ttf';
export const InriaSerif_300Light_Italic =
  'http://fonts.gstatic.com/s/inriaserif/v1/fC16PYxPY3rXxEndZJAzN3SuT4THjliPbmxN0_E.ttf';
export const InriaSerif_400Regular =
  'http://fonts.gstatic.com/s/inriaserif/v1/fC1lPYxPY3rXxEndZJAzN0SsfSzNr0Ck.ttf';
export const InriaSerif_400Regular_Italic =
  'http://fonts.gstatic.com/s/inriaserif/v1/fC1nPYxPY3rXxEndZJAzN3SudyjvqlCkcmU.ttf';
export const InriaSerif_700Bold =
  'http://fonts.gstatic.com/s/inriaserif/v1/fC14PYxPY3rXxEndZJAzN3wQUgjFhFyta3xN.ttf';
export const InriaSerif_700Bold_Italic =
  'http://fonts.gstatic.com/s/inriaserif/v1/fC16PYxPY3rXxEndZJAzN3SuT5TAjliPbmxN0_E.ttf';
export const Gelasio_400Regular =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf9MaFfvUQxTTqSxCmrYGkHgIs.ttf';
export const Gelasio_400Regular_Italic =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf_MaFfvUQxTTqS9CuhZEsCkIt9QQ.ttf';
export const Gelasio_500Medium =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf4MaFfvUQxTTqS_N2CRGEsnIJkWL4.ttf';
export const Gelasio_500Medium_Italic =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf6MaFfvUQxTTqS9CuZkGImmKBhSL7Y1Q.ttf';
export const Gelasio_600SemiBold =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf4MaFfvUQxTTqS_PGFRGEsnIJkWL4.ttf';
export const Gelasio_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf6MaFfvUQxTTqS9CuZvGUmmKBhSL7Y1Q.ttf';
export const Gelasio_700Bold =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf4MaFfvUQxTTqS_JWERGEsnIJkWL4.ttf';
export const Gelasio_700Bold_Italic =
  'http://fonts.gstatic.com/s/gelasio/v1/cIf6MaFfvUQxTTqS9CuZ2GQmmKBhSL7Y1Q.ttf';
export const Gupter_400Regular =
  'http://fonts.gstatic.com/s/gupter/v1/2-cm9JNmxJqPO1QUYZa_Wu_lpA.ttf';
export const Gupter_500Medium =
  'http://fonts.gstatic.com/s/gupter/v1/2-cl9JNmxJqPO1Qslb-bUsT5rZhaZg.ttf';
export const Gupter_700Bold =
  'http://fonts.gstatic.com/s/gupter/v1/2-cl9JNmxJqPO1Qs3bmbUsT5rZhaZg.ttf';
export const Alata_400Regular = 'http://fonts.gstatic.com/s/alata/v1/PbytFmztEwbIofe6xKcRQEOX.ttf';
export const OdibeeSans_400Regular =
  'http://fonts.gstatic.com/s/odibeesans/v1/neIPzCSooYAho6WvjeToRYkyepH9qGsf.ttf';
export const Alatsi_400Regular =
  'http://fonts.gstatic.com/s/alatsi/v1/TK3iWkUJAxQ2nLNGHjUHte5fKg.ttf';
export const ViaodaLibre_400Regular =
  'http://fonts.gstatic.com/s/viaodalibre/v2/vEFW2_lWCgoR6OKuRz9kcRVJb2IY2tOHXg.ttf';
export const Calistoga_400Regular =
  'http://fonts.gstatic.com/s/calistoga/v1/6NUU8F2OJg6MeR7l4e0vtMYAwdRZfw.ttf';
export const IbarraRealNova_400Regular =
  'http://fonts.gstatic.com/s/ibarrarealnova/v2/sZlfdQiA-DBIDCcaWtQzL4BZHoiDoHxSENxuLuE.ttf';
export const IbarraRealNova_400Regular_Italic =
  'http://fonts.gstatic.com/s/ibarrarealnova/v2/sZlZdQiA-DBIDCcaWtQzL4BZHoiDkH5YFP5rPuF6EA.ttf';
export const IbarraRealNova_600SemiBold =
  'http://fonts.gstatic.com/s/ibarrarealnova/v2/sZlYdQiA-DBIDCcaWtQzL4BZHoiDmKR8NNRFMuhjCXY.ttf';
export const IbarraRealNova_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/ibarrarealnova/v2/sZladQiA-DBIDCcaWtQzL4BZHoiDkH5gzNBPNspmGXawpg.ttf';
export const IbarraRealNova_700Bold =
  'http://fonts.gstatic.com/s/ibarrarealnova/v2/sZlYdQiA-DBIDCcaWtQzL4BZHoiDmMB9NNRFMuhjCXY.ttf';
export const IbarraRealNova_700Bold_Italic =
  'http://fonts.gstatic.com/s/ibarrarealnova/v2/sZladQiA-DBIDCcaWtQzL4BZHoiDkH5gqNFPNspmGXawpg.ttf';
export const BebasNeue_400Regular =
  'http://fonts.gstatic.com/s/bebasneue/v1/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf';
export const Baskervville_400Regular =
  'http://fonts.gstatic.com/s/baskervville/v1/YA9Ur0yU4l_XOrogbkun3kQgt5OohvbJ9A.ttf';
export const Baskervville_400Regular_Italic =
  'http://fonts.gstatic.com/s/baskervville/v1/YA9Kr0yU4l_XOrogbkun3kQQtZmspPPZ9Mlt.ttf';
export const Manrope_200ExtraLight =
  'http://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F87jxeN7B.ttf';
export const Manrope_300Light =
  'http://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F87jxeN7B.ttf';
export const Manrope_400Regular =
  'http://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf';
export const Manrope_500Medium =
  'http://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F87jxeN7B.ttf';
export const Manrope_600SemiBold =
  'http://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F87jxeN7B.ttf';
export const Manrope_700Bold =
  'http://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F87jxeN7B.ttf';
export const Manrope_800ExtraBold =
  'http://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf';
export const Tomorrow_100Thin =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLgrETNbFtZCeGqgR2xe2XiKMiokE4.ttf';
export const Tomorrow_100Thin_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLirETNbFtZCeGqgRXXQwHoLOqtgE5h0A.ttf';
export const Tomorrow_200ExtraLight =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLhrETNbFtZCeGqgR0dWkXIBsShiVd4.ttf';
export const Tomorrow_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLjrETNbFtZCeGqgRXXQ63JDMCDjEd4yVY.ttf';
export const Tomorrow_300Light =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLhrETNbFtZCeGqgR15WUXIBsShiVd4.ttf';
export const Tomorrow_300Light_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLjrETNbFtZCeGqgRXXQ8nKDMCDjEd4yVY.ttf';
export const Tomorrow_400Regular =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLmrETNbFtZCeGqgSXVcWHALdio.ttf';
export const Tomorrow_400Regular_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLgrETNbFtZCeGqgRXXe2XiKMiokE4.ttf';
export const Tomorrow_500Medium =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLhrETNbFtZCeGqgR0hWEXIBsShiVd4.ttf';
export const Tomorrow_500Medium_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLjrETNbFtZCeGqgRXXQ5HLDMCDjEd4yVY.ttf';
export const Tomorrow_600SemiBold =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLhrETNbFtZCeGqgR0NX0XIBsShiVd4.ttf';
export const Tomorrow_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLjrETNbFtZCeGqgRXXQ73MDMCDjEd4yVY.ttf';
export const Tomorrow_700Bold =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLhrETNbFtZCeGqgR1pXkXIBsShiVd4.ttf';
export const Tomorrow_700Bold_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLjrETNbFtZCeGqgRXXQ9nNDMCDjEd4yVY.ttf';
export const Tomorrow_800ExtraBold =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLhrETNbFtZCeGqgR11XUXIBsShiVd4.ttf';
export const Tomorrow_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLjrETNbFtZCeGqgRXXQ8XODMCDjEd4yVY.ttf';
export const Tomorrow_900Black =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLhrETNbFtZCeGqgR1RXEXIBsShiVd4.ttf';
export const Tomorrow_900Black_Italic =
  'http://fonts.gstatic.com/s/tomorrow/v2/WBLjrETNbFtZCeGqgRXXQ-HPDMCDjEd4yVY.ttf';
export const KulimPark_200ExtraLight =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdN49secq3hflz1Uu3IwjJYNwa5aZbUvGjU.ttf';
export const KulimPark_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdNm9secq3hflz1Uu3IwhFwUKa9QYZcqCjVVUA.ttf';
export const KulimPark_300Light =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdN49secq3hflz1Uu3IwjPIOwa5aZbUvGjU.ttf';
export const KulimPark_300Light_Italic =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdNm9secq3hflz1Uu3IwhFwUTaxQYZcqCjVVUA.ttf';
export const KulimPark_400Regular =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdN79secq3hflz1Uu3IwtF4m5aZxebw.ttf';
export const KulimPark_400Regular_Italic =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdN59secq3hflz1Uu3IwhFws4YR0abw2Aw.ttf';
export const KulimPark_600SemiBold =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdN49secq3hflz1Uu3IwjIYIwa5aZbUvGjU.ttf';
export const KulimPark_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdNm9secq3hflz1Uu3IwhFwUOapQYZcqCjVVUA.ttf';
export const KulimPark_700Bold =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdN49secq3hflz1Uu3IwjOIJwa5aZbUvGjU.ttf';
export const KulimPark_700Bold_Italic =
  'http://fonts.gstatic.com/s/kulimpark/v1/fdNm9secq3hflz1Uu3IwhFwUXatQYZcqCjVVUA.ttf';
export const SulphurPoint_300Light =
  'http://fonts.gstatic.com/s/sulphurpoint/v1/RLpkK5vv8KaycDcazWFPBj2afVU6n6kFUHPIFaU.ttf';
export const SulphurPoint_400Regular =
  'http://fonts.gstatic.com/s/sulphurpoint/v1/RLp5K5vv8KaycDcazWFPBj2aRfkSu6EuTHo.ttf';
export const SulphurPoint_700Bold =
  'http://fonts.gstatic.com/s/sulphurpoint/v1/RLpkK5vv8KaycDcazWFPBj2afUU9n6kFUHPIFaU.ttf';
export const BigShouldersDisplay_100Thin =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1xPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1Ur9DrDJYM2lAZ.ttf';
export const BigShouldersDisplay_300Light =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UZ_LLJrgA00kAdA.ttf';
export const BigShouldersDisplay_400Regular =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1_PZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1sy9rvLpMc2g.ttf';
export const BigShouldersDisplay_500Medium =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UP_PLJrgA00kAdA.ttf';
export const BigShouldersDisplay_600SemiBold =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UE_TLJrgA00kAdA.ttf';
export const BigShouldersDisplay_700Bold =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1Ud_XLJrgA00kAdA.ttf';
export const BigShouldersDisplay_800ExtraBold =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1Ua_bLJrgA00kAdA.ttf';
export const BigShouldersDisplay_900Black =
  'http://fonts.gstatic.com/s/bigshouldersdisplay/v1/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UT_fLJrgA00kAdA.ttf';
export const BigShouldersText_100Thin =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xzezRtP9G3CGPIf49hxc8P0eytUxBU-IZ_YscCdXQB.ttf';
export const BigShouldersText_300Light =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xyezRtP9G3CGPIf49hxc8P0eytUxBUMKRfSOkOfG0Y3A.ttf';
export const BigShouldersText_400Regular =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xxezRtP9G3CGPIf49hxc8P0eytUxBsnIx7QMISdQ.ttf';
export const BigShouldersText_500Medium =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xyezRtP9G3CGPIf49hxc8P0eytUxBUaKVfSOkOfG0Y3A.ttf';
export const BigShouldersText_600SemiBold =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xyezRtP9G3CGPIf49hxc8P0eytUxBURKJfSOkOfG0Y3A.ttf';
export const BigShouldersText_700Bold =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xyezRtP9G3CGPIf49hxc8P0eytUxBUIKNfSOkOfG0Y3A.ttf';
export const BigShouldersText_800ExtraBold =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xyezRtP9G3CGPIf49hxc8P0eytUxBUPKBfSOkOfG0Y3A.ttf';
export const BigShouldersText_900Black =
  'http://fonts.gstatic.com/s/bigshoulderstext/v1/55xyezRtP9G3CGPIf49hxc8P0eytUxBUGKFfSOkOfG0Y3A.ttf';
export const Jomolhari_400Regular =
  'http://fonts.gstatic.com/s/jomolhari/v1/EvONzA1M1Iw_CBd2hsQCF1IZKq5INg.ttf';
export const TurretRoad_200ExtraLight =
  'http://fonts.gstatic.com/s/turretroad/v1/pxidypMgpcBFjE84Zv-fE0ONEdeLYk1Mq3ap.ttf';
export const TurretRoad_300Light =
  'http://fonts.gstatic.com/s/turretroad/v1/pxidypMgpcBFjE84Zv-fE0PpEteLYk1Mq3ap.ttf';
export const TurretRoad_400Regular =
  'http://fonts.gstatic.com/s/turretroad/v1/pxiAypMgpcBFjE84Zv-fE3tFOvODSVFF.ttf';
export const TurretRoad_500Medium =
  'http://fonts.gstatic.com/s/turretroad/v1/pxidypMgpcBFjE84Zv-fE0OxE9eLYk1Mq3ap.ttf';
export const TurretRoad_700Bold =
  'http://fonts.gstatic.com/s/turretroad/v1/pxidypMgpcBFjE84Zv-fE0P5FdeLYk1Mq3ap.ttf';
export const TurretRoad_800ExtraBold =
  'http://fonts.gstatic.com/s/turretroad/v1/pxidypMgpcBFjE84Zv-fE0PlFteLYk1Mq3ap.ttf';
export const Mansalva_400Regular =
  'http://fonts.gstatic.com/s/mansalva/v1/aWB4m0aacbtDfvq5NJllI47vdyBg.ttf';
export const BeVietnam_100Thin =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVxdDflz-iPfoPuIC2iKsUn7W1hK2czPg.ttf';
export const BeVietnam_100Thin_Italic =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVvdDflz-iPfoPuIC2iIqMfiWdlCWIjPi5p.ttf';
export const BeVietnam_300Light =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVwdDflz-iPfoPuIC2iKg0FzUdPJ24qJzc.ttf';
export const BeVietnam_300Light_Italic =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVudDflz-iPfoPuIC2iIqMfQUVFI0wvNzdwXQ.ttf';
export const BeVietnam_400Regular =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVzdDflz-iPfoPuIC2iEqEt6U9kO2c.ttf';
export const BeVietnam_400Regular_Italic =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVxdDflz-iPfoPuIC2iIqMn7W1hK2czPg.ttf';
export const BeVietnam_500Medium =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVwdDflz-iPfoPuIC2iKlUEzUdPJ24qJzc.ttf';
export const BeVietnam_500Medium_Italic =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVudDflz-iPfoPuIC2iIqMfGURFI0wvNzdwXQ.ttf';
export const BeVietnam_600SemiBold =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVwdDflz-iPfoPuIC2iKnkDzUdPJ24qJzc.ttf';
export const BeVietnam_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVudDflz-iPfoPuIC2iIqMfNUNFI0wvNzdwXQ.ttf';
export const BeVietnam_700Bold =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVwdDflz-iPfoPuIC2iKh0CzUdPJ24qJzc.ttf';
export const BeVietnam_700Bold_Italic =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVudDflz-iPfoPuIC2iIqMfUUJFI0wvNzdwXQ.ttf';
export const BeVietnam_800ExtraBold =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVwdDflz-iPfoPuIC2iKgEBzUdPJ24qJzc.ttf';
export const BeVietnam_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/bevietnam/v1/FBVudDflz-iPfoPuIC2iIqMfTUFFI0wvNzdwXQ.ttf';
export const LexendDeca_400Regular =
  'http://fonts.gstatic.com/s/lexenddeca/v1/K2F1fZFYk-dHSE0UPPuwQ6qgLS76ZHOM.ttf';
export const LexendExa_400Regular =
  'http://fonts.gstatic.com/s/lexendexa/v1/UMBXrPdOoHOnxExyjdBeWirXArM58BY.ttf';
export const LexendGiga_400Regular =
  'http://fonts.gstatic.com/s/lexendgiga/v1/PlI5Fl67Mah5Y8yMHE7lkVxEt8CwfGaD.ttf';
export const LexendMega_400Regular =
  'http://fonts.gstatic.com/s/lexendmega/v1/qFdA35aBi5JtHD41zSTFEv7K6BsAikI7.ttf';
export const LexendPeta_400Regular =
  'http://fonts.gstatic.com/s/lexendpeta/v1/BXRvvFPGjeLPh0kCfI4OkE_1c8Tf1IW3.ttf';
export const LexendTera_400Regular =
  'http://fonts.gstatic.com/s/lexendtera/v1/RrQUbo98_jt_IXnBPwCWtZhARYMgGtWA.ttf';
export const LexendZetta_400Regular =
  'http://fonts.gstatic.com/s/lexendzetta/v1/ll87K2KYXje7CdOFnEWcU8soliQejRR7AQ.ttf';
export const Farro_300Light =
  'http://fonts.gstatic.com/s/farro/v1/i7dJIFl3byGNHa3hNJ6-WkJUQUq7.ttf';
export const Farro_400Regular = 'http://fonts.gstatic.com/s/farro/v1/i7dEIFl3byGNHZVNHLq2cV5d.ttf';
export const Farro_500Medium =
  'http://fonts.gstatic.com/s/farro/v1/i7dJIFl3byGNHa25NZ6-WkJUQUq7.ttf';
export const Farro_700Bold = 'http://fonts.gstatic.com/s/farro/v1/i7dJIFl3byGNHa3xM56-WkJUQUq7.ttf';
export const Lacquer_400Regular =
  'http://fonts.gstatic.com/s/lacquer/v2/EYqzma1QwqpG4_BBB7-AXhttQ5I.ttf';
export const Blinker_100Thin =
  'http://fonts.gstatic.com/s/blinker/v3/cIf_MaFatEE-VTaP_E2hZEsCkIt9QQ.ttf';
export const Blinker_200ExtraLight =
  'http://fonts.gstatic.com/s/blinker/v3/cIf4MaFatEE-VTaP_OGARGEsnIJkWL4.ttf';
export const Blinker_300Light =
  'http://fonts.gstatic.com/s/blinker/v3/cIf4MaFatEE-VTaP_IWDRGEsnIJkWL4.ttf';
export const Blinker_400Regular =
  'http://fonts.gstatic.com/s/blinker/v3/cIf9MaFatEE-VTaPxCmrYGkHgIs.ttf';
export const Blinker_600SemiBold =
  'http://fonts.gstatic.com/s/blinker/v3/cIf4MaFatEE-VTaP_PGFRGEsnIJkWL4.ttf';
export const Blinker_700Bold =
  'http://fonts.gstatic.com/s/blinker/v3/cIf4MaFatEE-VTaP_JWERGEsnIJkWL4.ttf';
export const Blinker_800ExtraBold =
  'http://fonts.gstatic.com/s/blinker/v3/cIf4MaFatEE-VTaP_ImHRGEsnIJkWL4.ttf';
export const Blinker_900Black =
  'http://fonts.gstatic.com/s/blinker/v3/cIf4MaFatEE-VTaP_K2GRGEsnIJkWL4.ttf';
export const Livvic_100Thin =
  'http://fonts.gstatic.com/s/livvic/v3/rnCr-x1S2hzjrlffC-M-mHnOSOuk.ttf';
export const Livvic_100Thin_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCt-x1S2hzjrlfXbdtakn3sTfukQHs.ttf';
export const Livvic_200ExtraLight =
  'http://fonts.gstatic.com/s/livvic/v3/rnCq-x1S2hzjrlffp8IeslfCQfK9WQ.ttf';
export const Livvic_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCs-x1S2hzjrlfXbdv2s13GY_etWWIJ.ttf';
export const Livvic_300Light =
  'http://fonts.gstatic.com/s/livvic/v3/rnCq-x1S2hzjrlffw8EeslfCQfK9WQ.ttf';
export const Livvic_300Light_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCs-x1S2hzjrlfXbduSsF3GY_etWWIJ.ttf';
export const Livvic_400Regular =
  'http://fonts.gstatic.com/s/livvic/v3/rnCp-x1S2hzjrlfnb-k6unzeSA.ttf';
export const Livvic_400Regular_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCr-x1S2hzjrlfXbeM-mHnOSOuk.ttf';
export const Livvic_500Medium =
  'http://fonts.gstatic.com/s/livvic/v3/rnCq-x1S2hzjrlffm8AeslfCQfK9WQ.ttf';
export const Livvic_500Medium_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCs-x1S2hzjrlfXbdvKsV3GY_etWWIJ.ttf';
export const Livvic_600SemiBold =
  'http://fonts.gstatic.com/s/livvic/v3/rnCq-x1S2hzjrlfft8ceslfCQfK9WQ.ttf';
export const Livvic_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCs-x1S2hzjrlfXbdvmtl3GY_etWWIJ.ttf';
export const Livvic_700Bold =
  'http://fonts.gstatic.com/s/livvic/v3/rnCq-x1S2hzjrlff08YeslfCQfK9WQ.ttf';
export const Livvic_700Bold_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCs-x1S2hzjrlfXbduCt13GY_etWWIJ.ttf';
export const Livvic_900Black =
  'http://fonts.gstatic.com/s/livvic/v3/rnCq-x1S2hzjrlff68QeslfCQfK9WQ.ttf';
export const Livvic_900Black_Italic =
  'http://fonts.gstatic.com/s/livvic/v3/rnCs-x1S2hzjrlfXbdu6tV3GY_etWWIJ.ttf';
export const DarkerGrotesque_300Light =
  'http://fonts.gstatic.com/s/darkergrotesque/v1/U9MA6cuh-mLQlC4BKCtayOfARkSVoxr2AW8hTOsXsX0.ttf';
export const DarkerGrotesque_400Regular =
  'http://fonts.gstatic.com/s/darkergrotesque/v1/U9MH6cuh-mLQlC4BKCtayOfARkSVm7beJWcKUOI.ttf';
export const DarkerGrotesque_500Medium =
  'http://fonts.gstatic.com/s/darkergrotesque/v1/U9MA6cuh-mLQlC4BKCtayOfARkSVo0L3AW8hTOsXsX0.ttf';
export const DarkerGrotesque_600SemiBold =
  'http://fonts.gstatic.com/s/darkergrotesque/v1/U9MA6cuh-mLQlC4BKCtayOfARkSVo27wAW8hTOsXsX0.ttf';
export const DarkerGrotesque_700Bold =
  'http://fonts.gstatic.com/s/darkergrotesque/v1/U9MA6cuh-mLQlC4BKCtayOfARkSVowrxAW8hTOsXsX0.ttf';
export const DarkerGrotesque_800ExtraBold =
  'http://fonts.gstatic.com/s/darkergrotesque/v1/U9MA6cuh-mLQlC4BKCtayOfARkSVoxbyAW8hTOsXsX0.ttf';
export const DarkerGrotesque_900Black =
  'http://fonts.gstatic.com/s/darkergrotesque/v1/U9MA6cuh-mLQlC4BKCtayOfARkSVozLzAW8hTOsXsX0.ttf';
export const SairaStencilOne_400Regular =
  'http://fonts.gstatic.com/s/sairastencilone/v1/SLXSc03I6HkvZGJ1GvvipLoYSTEL9AsMawif2YQ2.ttf';
export const Bahianita_400Regular =
  'http://fonts.gstatic.com/s/bahianita/v2/yYLr0hTb3vuqqsBUgxWtxTvV2NJPcA.ttf';
export const Barriecito_400Regular =
  'http://fonts.gstatic.com/s/barriecito/v2/WWXXlj-CbBOSLY2QTuY_KdUiYwTO0MU.ttf';
export const DMSans_400Regular =
  'http://fonts.gstatic.com/s/dmsans/v4/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf';
export const DMSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/dmsans/v4/rP2Fp2ywxg089UriCZaIGDWCBl0O8Q.ttf';
export const DMSans_500Medium =
  'http://fonts.gstatic.com/s/dmsans/v4/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf';
export const DMSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/dmsans/v4/rP2Ap2ywxg089UriCZaw7BymDnYS-Cjk6Q.ttf';
export const DMSans_700Bold =
  'http://fonts.gstatic.com/s/dmsans/v4/rP2Cp2ywxg089UriASitOB-sClQX6Cg.ttf';
export const DMSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/dmsans/v4/rP2Ap2ywxg089UriCZawpBqmDnYS-Cjk6Q.ttf';
export const DMSerifDisplay_400Regular =
  'http://fonts.gstatic.com/s/dmserifdisplay/v4/-nFnOHM81r4j6k0gjAW3mujVU2B2K_d709jy92k.ttf';
export const DMSerifDisplay_400Regular_Italic =
  'http://fonts.gstatic.com/s/dmserifdisplay/v4/-nFhOHM81r4j6k0gjAW3mujVU2B2G_Vx1_r352np3Q.ttf';
export const DMSerifText_400Regular =
  'http://fonts.gstatic.com/s/dmseriftext/v4/rnCu-xZa_krGokauCeNq1wWyafOPXHIJErY.ttf';
export const DMSerifText_400Regular_Italic =
  'http://fonts.gstatic.com/s/dmseriftext/v4/rnCw-xZa_krGokauCeNq1wWyWfGFWFAMArZKqQ.ttf';
export const Gayathri_100Thin =
  'http://fonts.gstatic.com/s/gayathri/v1/MCoWzAb429DbBilWLLhc-pvSA_gA2W8.ttf';
export const Gayathri_400Regular =
  'http://fonts.gstatic.com/s/gayathri/v1/MCoQzAb429DbBilWLIA48J_wBugA.ttf';
export const Gayathri_700Bold =
  'http://fonts.gstatic.com/s/gayathri/v1/MCoXzAb429DbBilWLLiE37v4LfQJwHbn.ttf';
export const PublicSans_100Thin =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpi5ww0pX189fg.ttf';
export const PublicSans_200ExtraLight =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulpm5ww0pX189fg.ttf';
export const PublicSans_300Light =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuSJm5ww0pX189fg.ttf';
export const PublicSans_400Regular =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpm5ww0pX189fg.ttf';
export const PublicSans_500Medium =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuJJm5ww0pX189fg.ttf';
export const PublicSans_600SemiBold =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuyJ65ww0pX189fg.ttf';
export const PublicSans_700Bold =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymu8Z65ww0pX189fg.ttf';
export const PublicSans_800ExtraBold =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulp65ww0pX189fg.ttf';
export const PublicSans_900Black =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuv565ww0pX189fg.ttf';
export const PublicSans_100Thin_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgQctfVotfj7j.ttf';
export const PublicSans_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRgActfVotfj7j.ttf';
export const PublicSans_300Light_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673toPgActfVotfj7j.ttf';
export const PublicSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgActfVotfj7j.ttf';
export const PublicSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpjgActfVotfj7j.ttf';
export const PublicSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tqPhwctfVotfj7j.ttf';
export const PublicSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tq2hwctfVotfj7j.ttf';
export const PublicSans_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRhwctfVotfj7j.ttf';
export const PublicSans_900Black_Italic =
  'http://fonts.gstatic.com/s/publicsans/v3/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tr4hwctfVotfj7j.ttf';
export const Almarai_300Light =
  'http://fonts.gstatic.com/s/almarai/v2/tssoApxBaigK_hnnS_anhnicoq72sXg.ttf';
export const Almarai_400Regular =
  'http://fonts.gstatic.com/s/almarai/v2/tsstApxBaigK_hnnc1qPonC3vqc.ttf';
export const Almarai_700Bold =
  'http://fonts.gstatic.com/s/almarai/v2/tssoApxBaigK_hnnS-aghnicoq72sXg.ttf';
export const Almarai_800ExtraBold =
  'http://fonts.gstatic.com/s/almarai/v2/tssoApxBaigK_hnnS_qjhnicoq72sXg.ttf';
export const BethEllen_400Regular =
  'http://fonts.gstatic.com/s/bethellen/v1/WwkbxPW2BE-3rb_JNT-qEIAiVNo5xNY.ttf';
export const Vibes_400Regular = 'http://fonts.gstatic.com/s/vibes/v1/QdVYSTsmIB6tmbd3HpbsuBlh.ttf';
export const RedHatDisplay_400Regular =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIQ7wUr0m80wwYf0QCXZzYzUoTQ-jSgZYvdCQ.ttf';
export const RedHatDisplay_400Regular_Italic =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIS7wUr0m80wwYf0QCXZzYzUoTg-D6kR47NCV5Z.ttf';
export const RedHatDisplay_500Medium =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIV7wUr0m80wwYf0QCXZzYzUoToDh2EbaDBAEdAbw.ttf';
export const RedHatDisplay_500Medium_Italic =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIX7wUr0m80wwYf0QCXZzYzUoTg-AZQbqrFIkJQb7zU.ttf';
export const RedHatDisplay_700Bold =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIV7wUr0m80wwYf0QCXZzYzUoToRhuEbaDBAEdAbw.ttf';
export const RedHatDisplay_700Bold_Italic =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIX7wUr0m80wwYf0QCXZzYzUoTg-AYYaKrFIkJQb7zU.ttf';
export const RedHatDisplay_900Black =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIV7wUr0m80wwYf0QCXZzYzUoTofhmEbaDBAEdAbw.ttf';
export const RedHatDisplay_900Black_Italic =
  'http://fonts.gstatic.com/s/redhatdisplay/v3/8vIX7wUr0m80wwYf0QCXZzYzUoTg-AYgaqrFIkJQb7zU.ttf';
export const RedHatText_400Regular =
  'http://fonts.gstatic.com/s/redhattext/v2/RrQXbohi_ic6B3yVSzGBrMxgb60sE8yZPA.ttf';
export const RedHatText_400Regular_Italic =
  'http://fonts.gstatic.com/s/redhattext/v2/RrQJbohi_ic6B3yVSzGBrMxQbacoMcmJPECN.ttf';
export const RedHatText_500Medium =
  'http://fonts.gstatic.com/s/redhattext/v2/RrQIbohi_ic6B3yVSzGBrMxYm4QIG-eFNVmULg.ttf';
export const RedHatText_500Medium_Italic =
  'http://fonts.gstatic.com/s/redhattext/v2/RrQKbohi_ic6B3yVSzGBrMxQbZ_cGO2BF1yELmgy.ttf';
export const RedHatText_700Bold =
  'http://fonts.gstatic.com/s/redhattext/v2/RrQIbohi_ic6B3yVSzGBrMxY04IIG-eFNVmULg.ttf';
export const RedHatText_700Bold_Italic =
  'http://fonts.gstatic.com/s/redhattext/v2/RrQKbohi_ic6B3yVSzGBrMxQbZ-UHu2BF1yELmgy.ttf';
export const FiraCode_300Light =
  'http://fonts.gstatic.com/s/firacode/v8/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_GNsFVfxN87gsj0.ttf';
export const FiraCode_400Regular =
  'http://fonts.gstatic.com/s/firacode/v8/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sFVfxN87gsj0.ttf';
export const FiraCode_500Medium =
  'http://fonts.gstatic.com/s/firacode/v8/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_A9sFVfxN87gsj0.ttf';
export const FiraCode_600SemiBold =
  'http://fonts.gstatic.com/s/firacode/v8/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_ONrFVfxN87gsj0.ttf';
export const FiraCode_700Bold =
  'http://fonts.gstatic.com/s/firacode/v8/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_NprFVfxN87gsj0.ttf';
export const LiuJianMaoCao_400Regular =
  'http://fonts.gstatic.com/s/liujianmaocao/v5/845DNN84HJrccNonurqXILGpvCOoferVKGWsUo8.ttf';
export const LongCang_400Regular =
  'http://fonts.gstatic.com/s/longcang/v5/LYjAdGP8kkgoTec8zkRgrXArXN7HWQ.ttf';
export const MaShanZheng_400Regular =
  'http://fonts.gstatic.com/s/mashanzheng/v5/NaPecZTRCLxvwo41b4gvzkXaRMTsDIRSfr0.ttf';
export const ZhiMangXing_400Regular =
  'http://fonts.gstatic.com/s/zhimangxing/v5/f0Xw0ey79sErYFtWQ9a2rq-g0actfektIJ0.ttf';
export const NotoSansHK_100Thin =
  'http://fonts.gstatic.com/s/notosanshk/v5/nKKO-GM_FYFRJvXzVXaAPe9ZUHp1MOv2ObB7.otf';
export const NotoSansHK_300Light =
  'http://fonts.gstatic.com/s/notosanshk/v5/nKKP-GM_FYFRJvXzVXaAPe9ZmFhTHMX6MKliqQ.otf';
export const NotoSansHK_400Regular =
  'http://fonts.gstatic.com/s/notosanshk/v5/nKKQ-GM_FYFRJvXzVXaAPe9hMnB3Eu7mOQ.otf';
export const NotoSansHK_500Medium =
  'http://fonts.gstatic.com/s/notosanshk/v5/nKKP-GM_FYFRJvXzVXaAPe9ZwFlTHMX6MKliqQ.otf';
export const NotoSansHK_700Bold =
  'http://fonts.gstatic.com/s/notosanshk/v5/nKKP-GM_FYFRJvXzVXaAPe9ZiF9THMX6MKliqQ.otf';
export const NotoSansHK_900Black =
  'http://fonts.gstatic.com/s/notosanshk/v5/nKKP-GM_FYFRJvXzVXaAPe9ZsF1THMX6MKliqQ.otf';
export const Aleo_300Light = 'http://fonts.gstatic.com/s/aleo/v3/c4mg1nF8G8_syKbr9DVDno985KM.ttf';
export const Aleo_300Light_Italic =
  'http://fonts.gstatic.com/s/aleo/v3/c4mi1nF8G8_swAjxeDdJmq159KOnWA.ttf';
export const Aleo_400Regular = 'http://fonts.gstatic.com/s/aleo/v3/c4mv1nF8G8_s8ArD0D1ogoY.ttf';
export const Aleo_400Regular_Italic =
  'http://fonts.gstatic.com/s/aleo/v3/c4mh1nF8G8_swAjJ1B9tkoZl_Q.ttf';
export const Aleo_700Bold = 'http://fonts.gstatic.com/s/aleo/v3/c4mg1nF8G8_syLbs9DVDno985KM.ttf';
export const Aleo_700Bold_Italic =
  'http://fonts.gstatic.com/s/aleo/v3/c4mi1nF8G8_swAjxaDBJmq159KOnWA.ttf';
export const B612_400Regular = 'http://fonts.gstatic.com/s/b612/v4/3JnySDDxiSz32jm4GDigUXw.ttf';
export const B612_400Regular_Italic =
  'http://fonts.gstatic.com/s/b612/v4/3Jn8SDDxiSz36juyHBqlQXwdVw.ttf';
export const B612_700Bold = 'http://fonts.gstatic.com/s/b612/v4/3Jn9SDDxiSz34oWXPDCLTXUETuE.ttf';
export const B612_700Bold_Italic =
  'http://fonts.gstatic.com/s/b612/v4/3Jn_SDDxiSz36juKoDWBSVcBXuFb0Q.ttf';
export const B612Mono_400Regular =
  'http://fonts.gstatic.com/s/b612mono/v4/kmK_Zq85QVWbN1eW6lJl1wTcquRTtg.ttf';
export const B612Mono_400Regular_Italic =
  'http://fonts.gstatic.com/s/b612mono/v4/kmK5Zq85QVWbN1eW6lJV1Q7YiOFDtqtf.ttf';
export const B612Mono_700Bold =
  'http://fonts.gstatic.com/s/b612mono/v4/kmK6Zq85QVWbN1eW6lJdayv4os9Pv7JGSg.ttf';
export const B612Mono_700Bold_Italic =
  'http://fonts.gstatic.com/s/b612mono/v4/kmKkZq85QVWbN1eW6lJV1TZkp8VLnbdWSg4x.ttf';
export const Charm_400Regular = 'http://fonts.gstatic.com/s/charm/v4/7cHmv4oii5K0MeYvIe804WIo.ttf';
export const Charm_700Bold = 'http://fonts.gstatic.com/s/charm/v4/7cHrv4oii5K0Md6TDss8yn4hnCci.ttf';
export const MajorMonoDisplay_400Regular =
  'http://fonts.gstatic.com/s/majormonodisplay/v4/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRhLCn2QIndPww.ttf';
export const ZCOOLKuaiLe_400Regular =
  'http://fonts.gstatic.com/s/zcoolkuaile/v5/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf';
export const ZCOOLQingKeHuangYou_400Regular =
  'http://fonts.gstatic.com/s/zcoolqingkehuangyou/v5/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mRIi_28c8bHWA.ttf';
export const ZCOOLXiaoWei_400Regular =
  'http://fonts.gstatic.com/s/zcoolxiaowei/v5/i7dMIFFrTRywPpUVX9_RJyM1YFKQHwyVd3U.ttf';
export const Staatliches_400Regular =
  'http://fonts.gstatic.com/s/staatliches/v3/HI_OiY8KO6hCsQSoAPmtMbectJG9O9PS.ttf';
export const Literata_400Regular =
  'http://fonts.gstatic.com/s/literata/v15/or38Q6P12-iJxAIgLa78DkTtAoDhk0oVpaLVa5RXzC1KOw.ttf';
export const Literata_500Medium =
  'http://fonts.gstatic.com/s/literata/v15/or38Q6P12-iJxAIgLa78DkTtAoDhk0oVl6LVa5RXzC1KOw.ttf';
export const Literata_600SemiBold =
  'http://fonts.gstatic.com/s/literata/v15/or38Q6P12-iJxAIgLa78DkTtAoDhk0oVe6XVa5RXzC1KOw.ttf';
export const Literata_700Bold =
  'http://fonts.gstatic.com/s/literata/v15/or38Q6P12-iJxAIgLa78DkTtAoDhk0oVQqXVa5RXzC1KOw.ttf';
export const Literata_400Regular_Italic =
  'http://fonts.gstatic.com/s/literata/v15/or3yQ6P12-iJxAIgLYT1PLs1a-t7PU0AbeE9KJ5T7ihaO_CS.ttf';
export const Literata_500Medium_Italic =
  'http://fonts.gstatic.com/s/literata/v15/or3yQ6P12-iJxAIgLYT1PLs1a-t7PU0AbeEPKJ5T7ihaO_CS.ttf';
export const Literata_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/literata/v15/or3yQ6P12-iJxAIgLYT1PLs1a-t7PU0AbeHjL55T7ihaO_CS.ttf';
export const Literata_700Bold_Italic =
  'http://fonts.gstatic.com/s/literata/v15/or3yQ6P12-iJxAIgLYT1PLs1a-t7PU0AbeHaL55T7ihaO_CS.ttf';
export const CrimsonPro_200ExtraLight =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTm18OJE_VNWoyQ.ttf';
export const CrimsonPro_300Light =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZkG18OJE_VNWoyQ.ttf';
export const CrimsonPro_400Regular =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm18OJE_VNWoyQ.ttf';
export const CrimsonPro_500Medium =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZ_G18OJE_VNWoyQ.ttf';
export const CrimsonPro_600SemiBold =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZEGp8OJE_VNWoyQ.ttf';
export const CrimsonPro_700Bold =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZKWp8OJE_VNWoyQ.ttf';
export const CrimsonPro_800ExtraBold =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTmp8OJE_VNWoyQ.ttf';
export const CrimsonPro_900Black =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZZ2p8OJE_VNWoyQ.ttf';
export const CrimsonPro_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4Ue5s7dtC4yZNE.ttf';
export const CrimsonPro_300Light_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi7Ke5s7dtC4yZNE.ttf';
export const CrimsonPro_400Regular_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6Ue5s7dtC4yZNE.ttf';
export const CrimsonPro_500Medium_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6me5s7dtC4yZNE.ttf';
export const CrimsonPro_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5KfJs7dtC4yZNE.ttf';
export const CrimsonPro_700Bold_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5zfJs7dtC4yZNE.ttf';
export const CrimsonPro_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4UfJs7dtC4yZNE.ttf';
export const CrimsonPro_900Black_Italic =
  'http://fonts.gstatic.com/s/crimsonpro/v12/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi49fJs7dtC4yZNE.ttf';
export const NotoSerifTC_200ExtraLight =
  'http://fonts.gstatic.com/s/notoseriftc/v7/XLY9IZb5bJNDGYxLBibeHZ0Bvr8vbX9GTsoOAX4.otf';
export const NotoSerifTC_300Light =
  'http://fonts.gstatic.com/s/notoseriftc/v7/XLY9IZb5bJNDGYxLBibeHZ0BvtssbX9GTsoOAX4.otf';
export const NotoSerifTC_400Regular =
  'http://fonts.gstatic.com/s/notoseriftc/v7/XLYgIZb5bJNDGYxLBibeHZ0BhnEESXFtUsM.otf';
export const NotoSerifTC_500Medium =
  'http://fonts.gstatic.com/s/notoseriftc/v7/XLY9IZb5bJNDGYxLBibeHZ0BvoMtbX9GTsoOAX4.otf';
export const NotoSerifTC_600SemiBold =
  'http://fonts.gstatic.com/s/notoseriftc/v7/XLY9IZb5bJNDGYxLBibeHZ0Bvq8qbX9GTsoOAX4.otf';
export const NotoSerifTC_700Bold =
  'http://fonts.gstatic.com/s/notoseriftc/v7/XLY9IZb5bJNDGYxLBibeHZ0BvssrbX9GTsoOAX4.otf';
export const NotoSerifTC_900Black =
  'http://fonts.gstatic.com/s/notoseriftc/v7/XLY9IZb5bJNDGYxLBibeHZ0BvvMpbX9GTsoOAX4.otf';
export const NotoSerifSC_200ExtraLight =
  'http://fonts.gstatic.com/s/notoserifsc/v7/H4c8BXePl9DZ0Xe7gG9cyOj7mm63SzZBEtERe7U.otf';
export const NotoSerifSC_300Light =
  'http://fonts.gstatic.com/s/notoserifsc/v7/H4c8BXePl9DZ0Xe7gG9cyOj7mgq0SzZBEtERe7U.otf';
export const NotoSerifSC_400Regular =
  'http://fonts.gstatic.com/s/notoserifsc/v7/H4chBXePl9DZ0Xe7gG9cyOj7oqCcbzhqDtg.otf';
export const NotoSerifSC_500Medium =
  'http://fonts.gstatic.com/s/notoserifsc/v7/H4c8BXePl9DZ0Xe7gG9cyOj7mlK1SzZBEtERe7U.otf';
export const NotoSerifSC_600SemiBold =
  'http://fonts.gstatic.com/s/notoserifsc/v7/H4c8BXePl9DZ0Xe7gG9cyOj7mn6ySzZBEtERe7U.otf';
export const NotoSerifSC_700Bold =
  'http://fonts.gstatic.com/s/notoserifsc/v7/H4c8BXePl9DZ0Xe7gG9cyOj7mhqzSzZBEtERe7U.otf';
export const NotoSerifSC_900Black =
  'http://fonts.gstatic.com/s/notoserifsc/v7/H4c8BXePl9DZ0Xe7gG9cyOj7miKxSzZBEtERe7U.otf';
export const Manjari_100Thin =
  'http://fonts.gstatic.com/s/manjari/v2/k3kSo8UPMOBO2w1UdbroK2vFIaOV8A.ttf';
export const Manjari_400Regular =
  'http://fonts.gstatic.com/s/manjari/v2/k3kQo8UPMOBO2w1UTd7iL0nAMaM.ttf';
export const Manjari_700Bold =
  'http://fonts.gstatic.com/s/manjari/v2/k3kVo8UPMOBO2w1UdWLNC0HrLaqM6Q4.ttf';
export const NotoSansSC_100Thin =
  'http://fonts.gstatic.com/s/notosanssc/v11/k3kJo84MPvpLmixcA63oeALZTYKL2wv287Sb.otf';
export const NotoSansSC_300Light =
  'http://fonts.gstatic.com/s/notosanssc/v11/k3kIo84MPvpLmixcA63oeALZhaCt9yX6-q2CGg.otf';
export const NotoSansSC_400Regular =
  'http://fonts.gstatic.com/s/notosanssc/v11/k3kXo84MPvpLmixcA63oeALhL4iJ-Q7m8w.otf';
export const NotoSansSC_500Medium =
  'http://fonts.gstatic.com/s/notosanssc/v11/k3kIo84MPvpLmixcA63oeALZ3aGt9yX6-q2CGg.otf';
export const NotoSansSC_700Bold =
  'http://fonts.gstatic.com/s/notosanssc/v11/k3kIo84MPvpLmixcA63oeALZlaet9yX6-q2CGg.otf';
export const NotoSansSC_900Black =
  'http://fonts.gstatic.com/s/notosanssc/v11/k3kIo84MPvpLmixcA63oeALZraWt9yX6-q2CGg.otf';
export const NotoSansTC_100Thin =
  'http://fonts.gstatic.com/s/notosanstc/v10/-nFlOG829Oofr2wohFbTp9i9WyEJIfNZ1sjy.otf';
export const NotoSansTC_300Light =
  'http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9kwMvDd1V39Hr7g.otf';
export const NotoSansTC_400Regular =
  'http://fonts.gstatic.com/s/notosanstc/v10/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf';
export const NotoSansTC_500Medium =
  'http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9ywIvDd1V39Hr7g.otf';
export const NotoSansTC_700Bold =
  'http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9gwQvDd1V39Hr7g.otf';
export const NotoSansTC_900Black =
  'http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9uwYvDd1V39Hr7g.otf';
export const HeptaSlab_100Thin =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5jfbY5B0NBkz.ttf';
export const HeptaSlab_200ExtraLight =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV5zfbY5B0NBkz.ttf';
export const HeptaSlab_300Light =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvlL5zfbY5B0NBkz.ttf';
export const HeptaSlab_400Regular =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5zfbY5B0NBkz.ttf';
export const HeptaSlab_500Medium =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvkn5zfbY5B0NBkz.ttf';
export const HeptaSlab_600SemiBold =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvnL4DfbY5B0NBkz.ttf';
export const HeptaSlab_700Bold =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvny4DfbY5B0NBkz.ttf';
export const HeptaSlab_800ExtraBold =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV4DfbY5B0NBkz.ttf';
export const HeptaSlab_900Black =
  'http://fonts.gstatic.com/s/heptaslab/v7/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvm84DfbY5B0NBkz.ttf';
export const Grenze_100Thin =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZRFGb7hR12BxqPm2IjuAkalnmd.ttf';
export const Grenze_100Thin_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZXFGb7hR12BxqH_VpHsg04k2md0kI.ttf';
export const Grenze_200ExtraLight =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZQFGb7hR12BxqPN0MDkicWn2CEyw.ttf';
export const Grenze_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZWFGb7hR12BxqH_Vrrky0SvWWUy1uW.ttf';
export const Grenze_300Light =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZQFGb7hR12BxqPU0ADkicWn2CEyw.ttf';
export const Grenze_300Light_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZWFGb7hR12BxqH_VqPkC0SvWWUy1uW.ttf';
export const Grenze_400Regular =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZTFGb7hR12Bxq3_2gnmgwKlg.ttf';
export const Grenze_400Regular_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZRFGb7hR12BxqH_WIjuAkalnmd.ttf';
export const Grenze_500Medium =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZQFGb7hR12BxqPC0EDkicWn2CEyw.ttf';
export const Grenze_500Medium_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZWFGb7hR12BxqH_VrXkS0SvWWUy1uW.ttf';
export const Grenze_600SemiBold =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZQFGb7hR12BxqPJ0YDkicWn2CEyw.ttf';
export const Grenze_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZWFGb7hR12BxqH_Vr7li0SvWWUy1uW.ttf';
export const Grenze_700Bold =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZQFGb7hR12BxqPQ0cDkicWn2CEyw.ttf';
export const Grenze_700Bold_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZWFGb7hR12BxqH_Vqfly0SvWWUy1uW.ttf';
export const Grenze_800ExtraBold =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZQFGb7hR12BxqPX0QDkicWn2CEyw.ttf';
export const Grenze_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZWFGb7hR12BxqH_VqDlC0SvWWUy1uW.ttf';
export const Grenze_900Black =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZQFGb7hR12BxqPe0UDkicWn2CEyw.ttf';
export const Grenze_900Black_Italic =
  'http://fonts.gstatic.com/s/grenze/v1/O4ZWFGb7hR12BxqH_VqnlS0SvWWUy1uW.ttf';
export const BaiJamjuree_200ExtraLight =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIqapSCOBt_aeQQ7ftydoa0kePuk5A1-yiSgA.ttf';
export const BaiJamjuree_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIoapSCOBt_aeQQ7ftydoa8W_oGkpox2S2CgOva.ttf';
export const BaiJamjuree_300Light =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIqapSCOBt_aeQQ7ftydoa09eDuk5A1-yiSgA.ttf';
export const BaiJamjuree_300Light_Italic =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIoapSCOBt_aeQQ7ftydoa8W_pikZox2S2CgOva.ttf';
export const BaiJamjuree_400Regular =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDI1apSCOBt_aeQQ7ftydoaMWcjKm7sp8g.ttf';
export const BaiJamjuree_400Regular_Italic =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIrapSCOBt_aeQQ7ftydoa8W8LOub458jGL.ttf';
export const BaiJamjuree_500Medium =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIqapSCOBt_aeQQ7ftydoa0reHuk5A1-yiSgA.ttf';
export const BaiJamjuree_500Medium_Italic =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIoapSCOBt_aeQQ7ftydoa8W_o6kJox2S2CgOva.ttf';
export const BaiJamjuree_600SemiBold =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIqapSCOBt_aeQQ7ftydoa0gebuk5A1-yiSgA.ttf';
export const BaiJamjuree_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIoapSCOBt_aeQQ7ftydoa8W_oWl5ox2S2CgOva.ttf';
export const BaiJamjuree_700Bold =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIqapSCOBt_aeQQ7ftydoa05efuk5A1-yiSgA.ttf';
export const BaiJamjuree_700Bold_Italic =
  'http://fonts.gstatic.com/s/baijamjuree/v3/LDIoapSCOBt_aeQQ7ftydoa8W_pylpox2S2CgOva.ttf';
export const ChakraPetch_300Light =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIflMapbsEk7TDLdtEz1BwkeNIhFQJXE3AY00g.ttf';
export const ChakraPetch_300Light_Italic =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIfnMapbsEk7TDLdtEz1BwkWmpLJQp_A_gMk0izH.ttf';
export const ChakraPetch_400Regular =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIf6MapbsEk7TDLdtEz1BwkmmKBhSL7Y1Q.ttf';
export const ChakraPetch_400Regular_Italic =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIfkMapbsEk7TDLdtEz1BwkWmqplarvI1R8t.ttf';
export const ChakraPetch_500Medium =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIflMapbsEk7TDLdtEz1BwkebIlFQJXE3AY00g.ttf';
export const ChakraPetch_500Medium_Italic =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIfnMapbsEk7TDLdtEz1BwkWmpKRQ5_A_gMk0izH.ttf';
export const ChakraPetch_600SemiBold =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIflMapbsEk7TDLdtEz1BwkeQI5FQJXE3AY00g.ttf';
export const ChakraPetch_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIfnMapbsEk7TDLdtEz1BwkWmpK9RJ_A_gMk0izH.ttf';
export const ChakraPetch_700Bold =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIflMapbsEk7TDLdtEz1BwkeJI9FQJXE3AY00g.ttf';
export const ChakraPetch_700Bold_Italic =
  'http://fonts.gstatic.com/s/chakrapetch/v3/cIfnMapbsEk7TDLdtEz1BwkWmpLZRZ_A_gMk0izH.ttf';
export const Charmonman_400Regular =
  'http://fonts.gstatic.com/s/charmonman/v3/MjQDmiR3vP_nuxDv47jiWJGovLdh6OE.ttf';
export const Charmonman_700Bold =
  'http://fonts.gstatic.com/s/charmonman/v3/MjQAmiR3vP_nuxDv47jiYC2HmL9K9OhmGnY.ttf';
export const Fahkwang_200ExtraLight =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa26Uj3zpmBOgbNpOJHmZlRFipxkwjx.ttf';
export const Fahkwang_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa06Uj3zpmBOgbNpOqNgHFQHC5Tlhjxdw4.ttf';
export const Fahkwang_300Light =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa26Uj3zpmBOgbNpOIjmplRFipxkwjx.ttf';
export const Fahkwang_300Light_Italic =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa06Uj3zpmBOgbNpOqNgBVTHC5Tlhjxdw4.ttf';
export const Fahkwang_400Regular =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noax6Uj3zpmBOgbNpNqPsr1ZPTZ4.ttf';
export const Fahkwang_400Regular_Italic =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa36Uj3zpmBOgbNpOqNuLl7OCZ4ihE.ttf';
export const Fahkwang_500Medium =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa26Uj3zpmBOgbNpOJ7m5lRFipxkwjx.ttf';
export const Fahkwang_500Medium_Italic =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa06Uj3zpmBOgbNpOqNgE1SHC5Tlhjxdw4.ttf';
export const Fahkwang_600SemiBold =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa26Uj3zpmBOgbNpOJXnJlRFipxkwjx.ttf';
export const Fahkwang_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa06Uj3zpmBOgbNpOqNgGFVHC5Tlhjxdw4.ttf';
export const Fahkwang_700Bold =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa26Uj3zpmBOgbNpOIznZlRFipxkwjx.ttf';
export const Fahkwang_700Bold_Italic =
  'http://fonts.gstatic.com/s/fahkwang/v3/Noa06Uj3zpmBOgbNpOqNgAVUHC5Tlhjxdw4.ttf';
export const K2D_100Thin = 'http://fonts.gstatic.com/s/k2d/v3/J7aRnpF2V0ErE6UpvrIw74NL.ttf';
export const K2D_100Thin_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7afnpF2V0EjdZ1NtLYS6pNLAjk.ttf';
export const K2D_200ExtraLight = 'http://fonts.gstatic.com/s/k2d/v3/J7aenpF2V0Erv4QJlJw85ppSGw.ttf';
export const K2D_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7acnpF2V0EjdZ3hlZY4xJ9CGyAa.ttf';
export const K2D_300Light = 'http://fonts.gstatic.com/s/k2d/v3/J7aenpF2V0Er24cJlJw85ppSGw.ttf';
export const K2D_300Light_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7acnpF2V0EjdZ2FlpY4xJ9CGyAa.ttf';
export const K2D_400Regular = 'http://fonts.gstatic.com/s/k2d/v3/J7aTnpF2V0ETd68tnLcg7w.ttf';
export const K2D_400Regular_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7aRnpF2V0EjdaUpvrIw74NL.ttf';
export const K2D_500Medium = 'http://fonts.gstatic.com/s/k2d/v3/J7aenpF2V0Erg4YJlJw85ppSGw.ttf';
export const K2D_500Medium_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7acnpF2V0EjdZ3dl5Y4xJ9CGyAa.ttf';
export const K2D_600SemiBold = 'http://fonts.gstatic.com/s/k2d/v3/J7aenpF2V0Err4EJlJw85ppSGw.ttf';
export const K2D_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7acnpF2V0EjdZ3xkJY4xJ9CGyAa.ttf';
export const K2D_700Bold = 'http://fonts.gstatic.com/s/k2d/v3/J7aenpF2V0Ery4AJlJw85ppSGw.ttf';
export const K2D_700Bold_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7acnpF2V0EjdZ2VkZY4xJ9CGyAa.ttf';
export const K2D_800ExtraBold = 'http://fonts.gstatic.com/s/k2d/v3/J7aenpF2V0Er14MJlJw85ppSGw.ttf';
export const K2D_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/k2d/v3/J7acnpF2V0EjdZ2JkpY4xJ9CGyAa.ttf';
export const KoHo_200ExtraLight =
  'http://fonts.gstatic.com/s/koho/v3/K2FxfZ5fmddNPuE1WJ75JoKhHys.ttf';
export const KoHo_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/koho/v3/K2FzfZ5fmddNNisssJ_zIqCkDyvqZA.ttf';
export const KoHo_300Light = 'http://fonts.gstatic.com/s/koho/v3/K2FxfZ5fmddNPoU2WJ75JoKhHys.ttf';
export const KoHo_300Light_Italic =
  'http://fonts.gstatic.com/s/koho/v3/K2FzfZ5fmddNNiss1JzzIqCkDyvqZA.ttf';
export const KoHo_400Regular = 'http://fonts.gstatic.com/s/koho/v3/K2F-fZ5fmddNBikefJbSOos.ttf';
export const KoHo_400Regular_Italic =
  'http://fonts.gstatic.com/s/koho/v3/K2FwfZ5fmddNNisUeLTXKou4Bg.ttf';
export const KoHo_500Medium = 'http://fonts.gstatic.com/s/koho/v3/K2FxfZ5fmddNPt03WJ75JoKhHys.ttf';
export const KoHo_500Medium_Italic =
  'http://fonts.gstatic.com/s/koho/v3/K2FzfZ5fmddNNissjJ3zIqCkDyvqZA.ttf';
export const KoHo_600SemiBold =
  'http://fonts.gstatic.com/s/koho/v3/K2FxfZ5fmddNPvEwWJ75JoKhHys.ttf';
export const KoHo_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/koho/v3/K2FzfZ5fmddNNissoJrzIqCkDyvqZA.ttf';
export const KoHo_700Bold = 'http://fonts.gstatic.com/s/koho/v3/K2FxfZ5fmddNPpUxWJ75JoKhHys.ttf';
export const KoHo_700Bold_Italic =
  'http://fonts.gstatic.com/s/koho/v3/K2FzfZ5fmddNNissxJvzIqCkDyvqZA.ttf';
export const Kodchasan_200ExtraLight =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cX0aUPOAJv9sG4I-DJeR1Cggeqo3eMeoA.ttf';
export const Kodchasan_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cXqaUPOAJv9sG4I-DJWjUlIgOCs_-YOoIgN.ttf';
export const Kodchasan_300Light =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cX0aUPOAJv9sG4I-DJeI1Oggeqo3eMeoA.ttf';
export const Kodchasan_300Light_Italic =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cXqaUPOAJv9sG4I-DJWjUksg-Cs_-YOoIgN.ttf';
export const Kodchasan_400Regular =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cXxaUPOAJv9sG4I-DJmj3uEicG01A.ttf';
export const Kodchasan_400Regular_Italic =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cX3aUPOAJv9sG4I-DJWjXGAq8Sk1PoH.ttf';
export const Kodchasan_500Medium =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cX0aUPOAJv9sG4I-DJee1Kggeqo3eMeoA.ttf';
export const Kodchasan_500Medium_Italic =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cXqaUPOAJv9sG4I-DJWjUl0guCs_-YOoIgN.ttf';
export const Kodchasan_600SemiBold =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cX0aUPOAJv9sG4I-DJeV1Wggeqo3eMeoA.ttf';
export const Kodchasan_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cXqaUPOAJv9sG4I-DJWjUlYheCs_-YOoIgN.ttf';
export const Kodchasan_700Bold =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cX0aUPOAJv9sG4I-DJeM1Sggeqo3eMeoA.ttf';
export const Kodchasan_700Bold_Italic =
  'http://fonts.gstatic.com/s/kodchasan/v3/1cXqaUPOAJv9sG4I-DJWjUk8hOCs_-YOoIgN.ttf';
export const Krub_200ExtraLight =
  'http://fonts.gstatic.com/s/krub/v3/sZlEdRyC6CRYZo47KLF4R6gWaf8.ttf';
export const Krub_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/krub/v3/sZlGdRyC6CRYbkQiwLByQ4oTef_6gQ.ttf';
export const Krub_300Light = 'http://fonts.gstatic.com/s/krub/v3/sZlEdRyC6CRYZuo4KLF4R6gWaf8.ttf';
export const Krub_300Light_Italic =
  'http://fonts.gstatic.com/s/krub/v3/sZlGdRyC6CRYbkQipLNyQ4oTef_6gQ.ttf';
export const Krub_400Regular = 'http://fonts.gstatic.com/s/krub/v3/sZlLdRyC6CRYXkYQDLlTW6E.ttf';
export const Krub_400Regular_Italic =
  'http://fonts.gstatic.com/s/krub/v3/sZlFdRyC6CRYbkQaCJtWS6EPcA.ttf';
export const Krub_500Medium = 'http://fonts.gstatic.com/s/krub/v3/sZlEdRyC6CRYZrI5KLF4R6gWaf8.ttf';
export const Krub_500Medium_Italic =
  'http://fonts.gstatic.com/s/krub/v3/sZlGdRyC6CRYbkQi_LJyQ4oTef_6gQ.ttf';
export const Krub_600SemiBold =
  'http://fonts.gstatic.com/s/krub/v3/sZlEdRyC6CRYZp4-KLF4R6gWaf8.ttf';
export const Krub_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/krub/v3/sZlGdRyC6CRYbkQi0LVyQ4oTef_6gQ.ttf';
export const Krub_700Bold = 'http://fonts.gstatic.com/s/krub/v3/sZlEdRyC6CRYZvo_KLF4R6gWaf8.ttf';
export const Krub_700Bold_Italic =
  'http://fonts.gstatic.com/s/krub/v3/sZlGdRyC6CRYbkQitLRyQ4oTef_6gQ.ttf';
export const Mali_200ExtraLight =
  'http://fonts.gstatic.com/s/mali/v3/N0bV2SRONuN4QOLlKlRaJdbWgdY.ttf';
export const Mali_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/mali/v3/N0bX2SRONuN4SCj8wlVQIfTTkdbJYA.ttf';
export const Mali_300Light = 'http://fonts.gstatic.com/s/mali/v3/N0bV2SRONuN4QIbmKlRaJdbWgdY.ttf';
export const Mali_300Light_Italic =
  'http://fonts.gstatic.com/s/mali/v3/N0bX2SRONuN4SCj8plZQIfTTkdbJYA.ttf';
export const Mali_400Regular = 'http://fonts.gstatic.com/s/mali/v3/N0ba2SRONuN4eCrODlxxOd8.ttf';
export const Mali_400Regular_Italic =
  'http://fonts.gstatic.com/s/mali/v3/N0bU2SRONuN4SCjECn50Kd_PmA.ttf';
export const Mali_500Medium = 'http://fonts.gstatic.com/s/mali/v3/N0bV2SRONuN4QN7nKlRaJdbWgdY.ttf';
export const Mali_500Medium_Italic =
  'http://fonts.gstatic.com/s/mali/v3/N0bX2SRONuN4SCj8_ldQIfTTkdbJYA.ttf';
export const Mali_600SemiBold =
  'http://fonts.gstatic.com/s/mali/v3/N0bV2SRONuN4QPLgKlRaJdbWgdY.ttf';
export const Mali_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/mali/v3/N0bX2SRONuN4SCj80lBQIfTTkdbJYA.ttf';
export const Mali_700Bold = 'http://fonts.gstatic.com/s/mali/v3/N0bV2SRONuN4QJbhKlRaJdbWgdY.ttf';
export const Mali_700Bold_Italic =
  'http://fonts.gstatic.com/s/mali/v3/N0bX2SRONuN4SCj8tlFQIfTTkdbJYA.ttf';
export const Niramit_200ExtraLight =
  'http://fonts.gstatic.com/s/niramit/v4/I_urMpWdvgLdNxVLVXx7tiiEr5_BdZ8.ttf';
export const Niramit_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/niramit/v4/I_upMpWdvgLdNxVLXbZiXimOq73EZZ_f6w.ttf';
export const Niramit_300Light =
  'http://fonts.gstatic.com/s/niramit/v4/I_urMpWdvgLdNxVLVRh4tiiEr5_BdZ8.ttf';
export const Niramit_300Light_Italic =
  'http://fonts.gstatic.com/s/niramit/v4/I_upMpWdvgLdNxVLXbZiOiqOq73EZZ_f6w.ttf';
export const Niramit_400Regular =
  'http://fonts.gstatic.com/s/niramit/v4/I_uuMpWdvgLdNxVLbbRQkiCvs5Y.ttf';
export const Niramit_400Regular_Italic =
  'http://fonts.gstatic.com/s/niramit/v4/I_usMpWdvgLdNxVLXbZalgKqo5bYbA.ttf';
export const Niramit_500Medium =
  'http://fonts.gstatic.com/s/niramit/v4/I_urMpWdvgLdNxVLVUB5tiiEr5_BdZ8.ttf';
export const Niramit_500Medium_Italic =
  'http://fonts.gstatic.com/s/niramit/v4/I_upMpWdvgLdNxVLXbZiYiuOq73EZZ_f6w.ttf';
export const Niramit_600SemiBold =
  'http://fonts.gstatic.com/s/niramit/v4/I_urMpWdvgLdNxVLVWx-tiiEr5_BdZ8.ttf';
export const Niramit_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/niramit/v4/I_upMpWdvgLdNxVLXbZiTiyOq73EZZ_f6w.ttf';
export const Niramit_700Bold =
  'http://fonts.gstatic.com/s/niramit/v4/I_urMpWdvgLdNxVLVQh_tiiEr5_BdZ8.ttf';
export const Niramit_700Bold_Italic =
  'http://fonts.gstatic.com/s/niramit/v4/I_upMpWdvgLdNxVLXbZiKi2Oq73EZZ_f6w.ttf';
export const Srisakdi_400Regular =
  'http://fonts.gstatic.com/s/srisakdi/v3/yMJRMIlvdpDbkB0A-jq8fSx5i814.ttf';
export const Srisakdi_700Bold =
  'http://fonts.gstatic.com/s/srisakdi/v3/yMJWMIlvdpDbkB0A-gIAUghxoNFxW0Hz.ttf';
export const Thasadith_400Regular =
  'http://fonts.gstatic.com/s/thasadith/v3/mtG44_1TIqPYrd_f5R1YsEkU0CWuFw.ttf';
export const Thasadith_400Regular_Italic =
  'http://fonts.gstatic.com/s/thasadith/v3/mtG-4_1TIqPYrd_f5R1oskMQ8iC-F1ZE.ttf';
export const Thasadith_700Bold =
  'http://fonts.gstatic.com/s/thasadith/v3/mtG94_1TIqPYrd_f5R1gDGYw2A6yHk9d8w.ttf';
export const Thasadith_700Bold_Italic =
  'http://fonts.gstatic.com/s/thasadith/v3/mtGj4_1TIqPYrd_f5R1osnus3QS2PEpN8zxA.ttf';
export const NotoSerifKR_200ExtraLight =
  'http://fonts.gstatic.com/s/notoserifkr/v6/3JnmSDn90Gmq2mr3blnHaTZXTihC8O1ZNH1ahck.otf';
export const NotoSerifKR_300Light =
  'http://fonts.gstatic.com/s/notoserifkr/v6/3JnmSDn90Gmq2mr3blnHaTZXTkxB8O1ZNH1ahck.otf';
export const NotoSerifKR_400Regular =
  'http://fonts.gstatic.com/s/notoserifkr/v6/3Jn7SDn90Gmq2mr3blnHaTZXduZp1ONyKHQ.otf';
export const NotoSerifKR_500Medium =
  'http://fonts.gstatic.com/s/notoserifkr/v6/3JnmSDn90Gmq2mr3blnHaTZXThRA8O1ZNH1ahck.otf';
export const NotoSerifKR_600SemiBold =
  'http://fonts.gstatic.com/s/notoserifkr/v6/3JnmSDn90Gmq2mr3blnHaTZXTjhH8O1ZNH1ahck.otf';
export const NotoSerifKR_700Bold =
  'http://fonts.gstatic.com/s/notoserifkr/v6/3JnmSDn90Gmq2mr3blnHaTZXTlxG8O1ZNH1ahck.otf';
export const NotoSerifKR_900Black =
  'http://fonts.gstatic.com/s/notoserifkr/v6/3JnmSDn90Gmq2mr3blnHaTZXTmRE8O1ZNH1ahck.otf';
export const NotoSerifJP_200ExtraLight =
  'http://fonts.gstatic.com/s/notoserifjp/v7/xn77YHs72GKoTvER4Gn3b5eMZBaPRkgfU8fEwb0.otf';
export const NotoSerifJP_300Light =
  'http://fonts.gstatic.com/s/notoserifjp/v7/xn77YHs72GKoTvER4Gn3b5eMZHKMRkgfU8fEwb0.otf';
export const NotoSerifJP_400Regular =
  'http://fonts.gstatic.com/s/notoserifjp/v7/xn7mYHs72GKoTvER4Gn3b5eMXNikYkY0T84.otf';
export const NotoSerifJP_500Medium =
  'http://fonts.gstatic.com/s/notoserifjp/v7/xn77YHs72GKoTvER4Gn3b5eMZCqNRkgfU8fEwb0.otf';
export const NotoSerifJP_600SemiBold =
  'http://fonts.gstatic.com/s/notoserifjp/v7/xn77YHs72GKoTvER4Gn3b5eMZAaKRkgfU8fEwb0.otf';
export const NotoSerifJP_700Bold =
  'http://fonts.gstatic.com/s/notoserifjp/v7/xn77YHs72GKoTvER4Gn3b5eMZGKLRkgfU8fEwb0.otf';
export const NotoSerifJP_900Black =
  'http://fonts.gstatic.com/s/notoserifjp/v7/xn77YHs72GKoTvER4Gn3b5eMZFqJRkgfU8fEwb0.otf';
export const Solway_300Light =
  'http://fonts.gstatic.com/s/solway/v2/AMOTz46Cs2uTAOCuLlgZms0QW3mqyg.ttf';
export const Solway_400Regular =
  'http://fonts.gstatic.com/s/solway/v2/AMOQz46Cs2uTAOCWgnA9kuYMUg.ttf';
export const Solway_500Medium =
  'http://fonts.gstatic.com/s/solway/v2/AMOTz46Cs2uTAOCudlkZms0QW3mqyg.ttf';
export const Solway_700Bold =
  'http://fonts.gstatic.com/s/solway/v2/AMOTz46Cs2uTAOCuPl8Zms0QW3mqyg.ttf';
export const Solway_800ExtraBold =
  'http://fonts.gstatic.com/s/solway/v2/AMOTz46Cs2uTAOCuIlwZms0QW3mqyg.ttf';
export const Notable_400Regular =
  'http://fonts.gstatic.com/s/notable/v4/gNMEW3N_SIqx-WX9-HMoFIez5MI.ttf';
export const MarkaziText_400Regular =
  'http://fonts.gstatic.com/s/markazitext/v11/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQT4MlBekmJLo.ttf';
export const MarkaziText_500Medium =
  'http://fonts.gstatic.com/s/markazitext/v11/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtcaQT4MlBekmJLo.ttf';
export const MarkaziText_600SemiBold =
  'http://fonts.gstatic.com/s/markazitext/v11/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtSqXT4MlBekmJLo.ttf';
export const MarkaziText_700Bold =
  'http://fonts.gstatic.com/s/markazitext/v11/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtROXT4MlBekmJLo.ttf';
export const SawarabiMincho_400Regular =
  'http://fonts.gstatic.com/s/sawarabimincho/v10/8QIRdiDaitzr7brc8ahpxt6GcIJTLahP46UDUw.ttf';
export const SawarabiGothic_400Regular =
  'http://fonts.gstatic.com/s/sawarabigothic/v8/x3d4ckfVaqqa-BEj-I9mE65u3k3NBSk3E2YljQ.ttf';
export const MPLUSRounded1c_100Thin =
  'http://fonts.gstatic.com/s/mplusrounded1c/v10/VdGCAYIAV6gnpUpoWwNkYvrugw9RuM3ixLsg6-av1x0.ttf';
export const MPLUSRounded1c_300Light =
  'http://fonts.gstatic.com/s/mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0q5psKxeqmzgRK.ttf';
export const MPLUSRounded1c_400Regular =
  'http://fonts.gstatic.com/s/mplusrounded1c/v10/VdGEAYIAV6gnpUpoWwNkYvrugw9RuPWGzr8C7vav.ttf';
export const MPLUSRounded1c_500Medium =
  'http://fonts.gstatic.com/s/mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM1y55sKxeqmzgRK.ttf';
export const MPLUSRounded1c_700Bold =
  'http://fonts.gstatic.com/s/mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM064ZsKxeqmzgRK.ttf';
export const MPLUSRounded1c_800ExtraBold =
  'http://fonts.gstatic.com/s/mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0m4psKxeqmzgRK.ttf';
export const MPLUSRounded1c_900Black =
  'http://fonts.gstatic.com/s/mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0C45sKxeqmzgRK.ttf';
export const Tajawal_200ExtraLight =
  'http://fonts.gstatic.com/s/tajawal/v3/Iurf6YBj_oCad4k1l_6gLrZjiLlJ-G0.ttf';
export const Tajawal_300Light =
  'http://fonts.gstatic.com/s/tajawal/v3/Iurf6YBj_oCad4k1l5qjLrZjiLlJ-G0.ttf';
export const Tajawal_400Regular =
  'http://fonts.gstatic.com/s/tajawal/v3/Iura6YBj_oCad4k1rzaLCr5IlLA.ttf';
export const Tajawal_500Medium =
  'http://fonts.gstatic.com/s/tajawal/v3/Iurf6YBj_oCad4k1l8KiLrZjiLlJ-G0.ttf';
export const Tajawal_700Bold =
  'http://fonts.gstatic.com/s/tajawal/v3/Iurf6YBj_oCad4k1l4qkLrZjiLlJ-G0.ttf';
export const Tajawal_800ExtraBold =
  'http://fonts.gstatic.com/s/tajawal/v3/Iurf6YBj_oCad4k1l5anLrZjiLlJ-G0.ttf';
export const Tajawal_900Black =
  'http://fonts.gstatic.com/s/tajawal/v3/Iurf6YBj_oCad4k1l7KmLrZjiLlJ-G0.ttf';
export const EastSeaDokdo_400Regular =
  'http://fonts.gstatic.com/s/eastseadokdo/v8/xfuo0Wn2V2_KanASqXSZp22m05_aGavYS18y.ttf';
export const IBMPlexMono_100Thin =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6pfjptAgt5VM-kVkqdyU8n3kwq0n1hj-sNFQ.ttf';
export const IBMPlexMono_100Thin_Italic =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6rfjptAgt5VM-kVkqdyU8n1ioStndlre4dFcFh.ttf';
export const IBMPlexMono_200ExtraLight =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6qfjptAgt5VM-kVkqdyU8n3uAL8ldPg-IUDNg.ttf';
export const IBMPlexMono_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6sfjptAgt5VM-kVkqdyU8n1ioSGlZFh8ARHNh4zg.ttf';
export const IBMPlexMono_300Light =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6qfjptAgt5VM-kVkqdyU8n3oQI8ldPg-IUDNg.ttf';
export const IBMPlexMono_300Light_Italic =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6sfjptAgt5VM-kVkqdyU8n1ioSflVFh8ARHNh4zg.ttf';
export const IBMPlexMono_400Regular =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F63fjptAgt5VM-kVkqdyU8n5igg1l9kn-s.ttf';
export const IBMPlexMono_400Regular_Italic =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6pfjptAgt5VM-kVkqdyU8n1ioq0n1hj-sNFQ.ttf';
export const IBMPlexMono_500Medium =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6qfjptAgt5VM-kVkqdyU8n3twJ8ldPg-IUDNg.ttf';
export const IBMPlexMono_500Medium_Italic =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6sfjptAgt5VM-kVkqdyU8n1ioSJlRFh8ARHNh4zg.ttf';
export const IBMPlexMono_600SemiBold =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6qfjptAgt5VM-kVkqdyU8n3vAO8ldPg-IUDNg.ttf';
export const IBMPlexMono_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6sfjptAgt5VM-kVkqdyU8n1ioSClNFh8ARHNh4zg.ttf';
export const IBMPlexMono_700Bold =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6qfjptAgt5VM-kVkqdyU8n3pQP8ldPg-IUDNg.ttf';
export const IBMPlexMono_700Bold_Italic =
  'http://fonts.gstatic.com/s/ibmplexmono/v5/-F6sfjptAgt5VM-kVkqdyU8n1ioSblJFh8ARHNh4zg.ttf';
export const IBMPlexSansCondensed_100Thin =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY7KyKvBgYsMDhM.ttf';
export const IBMPlexSansCondensed_100Thin_Italic =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8hN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8M_LhakJHhOgBg.ttf';
export const IBMPlexSansCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5m6Yvrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8GPqpYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_300Light =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4C6ovrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_300Light_Italic =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8AfppYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_400Regular =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHbauwq_jhJsM.ttf';
export const IBMPlexSansCondensed_400Regular_Italic =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYasyKvBgYsMDhM.ttf';
export const IBMPlexSansCondensed_500Medium =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5a64vrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_500Medium_Italic =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8F_opYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY527Ivrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8HPvpYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_700Bold =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4S7Yvrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_700Bold_Italic =
  'http://fonts.gstatic.com/s/ibmplexsanscondensed/v6/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8BfupYMnEhq5H1w.ttf';
export const IBMPlexSans_100Thin =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX-KVElMYYaJe8bpLHnCwDKjbLeEKxIedbzDw.ttf';
export const IBMPlexSans_100Thin_Italic =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX8KVElMYYaJe8bpLHnCwDKhdTmdKZMW9PjD3N8.ttf';
export const IBMPlexSans_200ExtraLight =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX9KVElMYYaJe8bpLHnCwDKjR7_MIZmdd_qFmo.ttf';
export const IBMPlexSans_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX7KVElMYYaJe8bpLHnCwDKhdTm2Idscf3vBmpl8A.ttf';
export const IBMPlexSans_300Light =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX9KVElMYYaJe8bpLHnCwDKjXr8MIZmdd_qFmo.ttf';
export const IBMPlexSans_300Light_Italic =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRscf3vBmpl8A.ttf';
export const IBMPlexSans_400Regular =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf';
export const IBMPlexSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX-KVElMYYaJe8bpLHnCwDKhdTeEKxIedbzDw.ttf';
export const IBMPlexSans_500Medium =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIZmdd_qFmo.ttf';
export const IBMPlexSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX7KVElMYYaJe8bpLHnCwDKhdTm5IVscf3vBmpl8A.ttf';
export const IBMPlexSans_600SemiBold =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf';
export const IBMPlexSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJscf3vBmpl8A.ttf';
export const IBMPlexSans_700Bold =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX9KVElMYYaJe8bpLHnCwDKjWr7MIZmdd_qFmo.ttf';
export const IBMPlexSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/ibmplexsans/v7/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINscf3vBmpl8A.ttf';
export const IBMPlexSerif_100Thin =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizBREVNn1dOx-zrZ2X3pZvkTi182zIZj1bIkNo.ttf';
export const IBMPlexSerif_100Thin_Italic =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YTi3TNgNq55w.ttf';
export const IBMPlexSerif_200ExtraLight =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-hIzoVrBicOg.ttf';
export const IBMPlexSerif_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oyq17jjNOg_oc.ttf';
export const IBMPlexSerif_300Light =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizAREVNn1dOx-zrZ2X3pZvkTi20-RIzoVrBicOg.ttf';
export const IBMPlexSerif_300Light_Italic =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xq17jjNOg_oc.ttf';
export const IBMPlexSerif_400Regular =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizDREVNn1dOx-zrZ2X3pZvkThUY0TY7ikbI.ttf';
export const IBMPlexSerif_400Regular_Italic =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizBREVNn1dOx-zrZ2X3pZvkTiUa2zIZj1bIkNo.ttf';
export const IBMPlexSerif_500Medium =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizAREVNn1dOx-zrZ2X3pZvkTi3s-BIzoVrBicOg.ttf';
export const IBMPlexSerif_500Medium_Italic =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywq17jjNOg_oc.ttf';
export const IBMPlexSerif_600SemiBold =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizAREVNn1dOx-zrZ2X3pZvkTi3A_xIzoVrBicOg.ttf';
export const IBMPlexSerif_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3q17jjNOg_oc.ttf';
export const IBMPlexSerif_700Bold =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizAREVNn1dOx-zrZ2X3pZvkTi2k_hIzoVrBicOg.ttf';
export const IBMPlexSerif_700Bold_Italic =
  'http://fonts.gstatic.com/s/ibmplexserif/v8/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442q17jjNOg_oc.ttf';
export const Mina_400Regular = 'http://fonts.gstatic.com/s/mina/v3/-nFzOGc18vARrz9j7i3y65o.ttf';
export const Mina_700Bold = 'http://fonts.gstatic.com/s/mina/v3/-nF8OGc18vARl4NMyiXZ95OkJwA.ttf';
export const Gaegu_300Light =
  'http://fonts.gstatic.com/s/gaegu/v8/TuGSUVB6Up9NU57nifw74sdtBk0x.ttf';
export const Gaegu_400Regular = 'http://fonts.gstatic.com/s/gaegu/v8/TuGfUVB6Up9NU6ZLodgzydtk.ttf';
export const Gaegu_700Bold = 'http://fonts.gstatic.com/s/gaegu/v8/TuGSUVB6Up9NU573jvw74sdtBk0x.ttf';
export const BlackAndWhitePicture_400Regular =
  'http://fonts.gstatic.com/s/blackandwhitepicture/v8/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqI5HbcMi-yWY.ttf';
export const Stylish_400Regular =
  'http://fonts.gstatic.com/s/stylish/v8/m8JSjfhPYriQkk7-fo35dLxEdmo.ttf';
export const Sunflower_300Light =
  'http://fonts.gstatic.com/s/sunflower/v9/RWmPoKeF8fUjqIj7Vc-06MfiqYsGBGBzCw.ttf';
export const Sunflower_500Medium =
  'http://fonts.gstatic.com/s/sunflower/v9/RWmPoKeF8fUjqIj7Vc-0sMbiqYsGBGBzCw.ttf';
export const Sunflower_700Bold =
  'http://fonts.gstatic.com/s/sunflower/v9/RWmPoKeF8fUjqIj7Vc-0-MDiqYsGBGBzCw.ttf';
export const DoHyeon_400Regular =
  'http://fonts.gstatic.com/s/dohyeon/v11/TwMN-I8CRRU2zM86HFE3ZwaH__-C.ttf';
export const GothicA1_100Thin =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR74z5ZnPydRjlCCwlCCMcqYtd2vfwk.ttf';
export const GothicA1_200ExtraLight =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlCpOYKSPl6tOU9Eg.ttf';
export const GothicA1_300Light =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlCwOUKSPl6tOU9Eg.ttf';
export const GothicA1_400Regular =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR94z5ZnPydRjlCCwl6bM0uQNJmvQ.ttf';
export const GothicA1_500Medium =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlCmOQKSPl6tOU9Eg.ttf';
export const GothicA1_600SemiBold =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlCtOMKSPl6tOU9Eg.ttf';
export const GothicA1_700Bold =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlC0OIKSPl6tOU9Eg.ttf';
export const GothicA1_800ExtraBold =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlCzOEKSPl6tOU9Eg.ttf';
export const GothicA1_900Black =
  'http://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlC6OAKSPl6tOU9Eg.ttf';
export const Jua_400Regular = 'http://fonts.gstatic.com/s/jua/v8/co3KmW9ljjAjc-DZCsKgsg.ttf';
export const KirangHaerang_400Regular =
  'http://fonts.gstatic.com/s/kiranghaerang/v8/E21-_dn_gvvIjhYON1lpIU4-bcqvWPaJq4no.ttf';
export const BlackHanSans_400Regular =
  'http://fonts.gstatic.com/s/blackhansans/v8/ea8Aad44WunzF9a-dL6toA8r8nqVIXSkH-Hc.ttf';
export const CuteFont_400Regular =
  'http://fonts.gstatic.com/s/cutefont/v8/Noaw6Uny2oWPbSHMrY6vmJNVNC9hkw.ttf';
export const Dokdo_400Regular = 'http://fonts.gstatic.com/s/dokdo/v8/esDf315XNuCBLxLo4NaMlKcH.ttf';
export const GamjaFlower_400Regular =
  'http://fonts.gstatic.com/s/gamjaflower/v8/6NUR8FiKJg-Pa0rM6uN40Z4kyf9Fdty2ew.ttf';
export const Gugi_400Regular = 'http://fonts.gstatic.com/s/gugi/v8/A2BVn5dXywshVA6A9DEfgqM.ttf';
export const HiMelody_400Regular =
  'http://fonts.gstatic.com/s/himelody/v8/46ktlbP8Vnz0pJcqCTbEf29E31BBGA.ttf';
export const PoorStory_400Regular =
  'http://fonts.gstatic.com/s/poorstory/v8/jizfREFUsnUct9P6cDfd4OmnLD0Z4zM.ttf';
export const SongMyung_400Regular =
  'http://fonts.gstatic.com/s/songmyung/v8/1cX2aUDWAJH5-EIC7DIhr1GqhcitzeM.ttf';
export const YeonSung_400Regular =
  'http://fonts.gstatic.com/s/yeonsung/v8/QldMNTpbohAGtsJvUn6xSVNazqx2xg.ttf';
export const SingleDay_400Regular =
  'http://fonts.gstatic.com/s/singleday/v1/LYjHdGDjlEgoAcF95EI5jVoFUNfeQJU.ttf';
export const NanumBrushScript_400Regular =
  'http://fonts.gstatic.com/s/nanumbrushscript/v17/wXK2E2wfpokopxzthSqPbcR5_gVaxazyjqBr1lO97Q.ttf';
export const NanumGothic_400Regular =
  'http://fonts.gstatic.com/s/nanumgothic/v17/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf';
export const NanumGothic_700Bold =
  'http://fonts.gstatic.com/s/nanumgothic/v17/PN_oRfi-oW3hYwmKDpxS7F_LQv37zlEn14YEUQ.ttf';
export const NanumGothic_800ExtraBold =
  'http://fonts.gstatic.com/s/nanumgothic/v17/PN_oRfi-oW3hYwmKDpxS7F_LXv77zlEn14YEUQ.ttf';
export const NanumGothicCoding_400Regular =
  'http://fonts.gstatic.com/s/nanumgothiccoding/v14/8QIVdjzHisX_8vv59_xMxtPFW4IXROwsy6QxVs1X7tc.ttf';
export const NanumGothicCoding_700Bold =
  'http://fonts.gstatic.com/s/nanumgothiccoding/v14/8QIYdjzHisX_8vv59_xMxtPFW4IXROws8xgecsV88t5V9r4.ttf';
export const NanumMyeongjo_400Regular =
  'http://fonts.gstatic.com/s/nanummyeongjo/v15/9Btx3DZF0dXLMZlywRbVRNhxy1LreHQ8juyl.ttf';
export const NanumMyeongjo_700Bold =
  'http://fonts.gstatic.com/s/nanummyeongjo/v15/9Bty3DZF0dXLMZlywRbVRNhxy2pXV1A0pfCs5Kos.ttf';
export const NanumMyeongjo_800ExtraBold =
  'http://fonts.gstatic.com/s/nanummyeongjo/v15/9Bty3DZF0dXLMZlywRbVRNhxy2pLVFA0pfCs5Kos.ttf';
export const NanumPenScript_400Regular =
  'http://fonts.gstatic.com/s/nanumpenscript/v15/daaDSSYiLGqEal3MvdA_FOL_3FkN2z7-aMFCcTU.ttf';
export const NotoSansKR_100Thin =
  'http://fonts.gstatic.com/s/notosanskr/v12/Pby6FmXiEBPT4ITbgNA5CgmOsn7uwpYcuH8y.otf';
export const NotoSansKR_300Light =
  'http://fonts.gstatic.com/s/notosanskr/v12/Pby7FmXiEBPT4ITbgNA5CgmOelzI7rgQsWYrzw.otf';
export const NotoSansKR_400Regular =
  'http://fonts.gstatic.com/s/notosanskr/v12/PbykFmXiEBPT4ITbgNA5Cgm20HTs4JMMuA.otf';
export const NotoSansKR_500Medium =
  'http://fonts.gstatic.com/s/notosanskr/v12/Pby7FmXiEBPT4ITbgNA5CgmOIl3I7rgQsWYrzw.otf';
export const NotoSansKR_700Bold =
  'http://fonts.gstatic.com/s/notosanskr/v12/Pby7FmXiEBPT4ITbgNA5CgmOalvI7rgQsWYrzw.otf';
export const NotoSansKR_900Black =
  'http://fonts.gstatic.com/s/notosanskr/v12/Pby7FmXiEBPT4ITbgNA5CgmOUlnI7rgQsWYrzw.otf';
export const LibreCaslonDisplay_400Regular =
  'http://fonts.gstatic.com/s/librecaslondisplay/v1/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr2lRdRhtCC4d.ttf';
export const Barlow_100Thin =
  'http://fonts.gstatic.com/s/barlow/v4/7cHrv4kjgoGqM7E3b8s8yn4hnCci.ttf';
export const Barlow_100Thin_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHtv4kjgoGqM7E_CfNYwHoDmTcibrA.ttf';
export const Barlow_200ExtraLight =
  'http://fonts.gstatic.com/s/barlow/v4/7cHqv4kjgoGqM7E3w-oc4FAtlT47dw.ttf';
export const Barlow_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHsv4kjgoGqM7E_CfP04Voptzsrd6m9.ttf';
export const Barlow_300Light =
  'http://fonts.gstatic.com/s/barlow/v4/7cHqv4kjgoGqM7E3p-kc4FAtlT47dw.ttf';
export const Barlow_300Light_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHsv4kjgoGqM7E_CfOQ4loptzsrd6m9.ttf';
export const Barlow_400Regular =
  'http://fonts.gstatic.com/s/barlow/v4/7cHpv4kjgoGqM7EPC8E46HsxnA.ttf';
export const Barlow_400Regular_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHrv4kjgoGqM7E_Ccs8yn4hnCci.ttf';
export const Barlow_500Medium =
  'http://fonts.gstatic.com/s/barlow/v4/7cHqv4kjgoGqM7E3_-gc4FAtlT47dw.ttf';
export const Barlow_500Medium_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHsv4kjgoGqM7E_CfPI41optzsrd6m9.ttf';
export const Barlow_600SemiBold =
  'http://fonts.gstatic.com/s/barlow/v4/7cHqv4kjgoGqM7E30-8c4FAtlT47dw.ttf';
export const Barlow_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHsv4kjgoGqM7E_CfPk5Foptzsrd6m9.ttf';
export const Barlow_700Bold =
  'http://fonts.gstatic.com/s/barlow/v4/7cHqv4kjgoGqM7E3t-4c4FAtlT47dw.ttf';
export const Barlow_700Bold_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHsv4kjgoGqM7E_CfOA5Voptzsrd6m9.ttf';
export const Barlow_800ExtraBold =
  'http://fonts.gstatic.com/s/barlow/v4/7cHqv4kjgoGqM7E3q-0c4FAtlT47dw.ttf';
export const Barlow_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHsv4kjgoGqM7E_CfOc5loptzsrd6m9.ttf';
export const Barlow_900Black =
  'http://fonts.gstatic.com/s/barlow/v4/7cHqv4kjgoGqM7E3j-wc4FAtlT47dw.ttf';
export const Barlow_900Black_Italic =
  'http://fonts.gstatic.com/s/barlow/v4/7cHsv4kjgoGqM7E_CfO451optzsrd6m9.ttf';
export const BarlowCondensed_100Thin =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxxL3I-JCGChYJ8VI-L6OO_au7B43LT31vytKgbaw.ttf';
export const BarlowCondensed_100Thin_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxzL3I-JCGChYJ8VI-L6OO_au7B6xTru1H2lq0La6JN.ttf';
export const BarlowCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxwL3I-JCGChYJ8VI-L6OO_au7B497y_3HcuKECcrs.ttf';
export const BarlowCondensed_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrF3DWvIMHYrtUxg.ttf';
export const BarlowCondensed_300Light =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxwL3I-JCGChYJ8VI-L6OO_au7B47rx_3HcuKECcrs.ttf';
export const BarlowCondensed_300Light_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrc3PWvIMHYrtUxg.ttf';
export const BarlowCondensed_400Regular =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xbZ23n3pKg.ttf';
export const BarlowCondensed_400Regular_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxxL3I-JCGChYJ8VI-L6OO_au7B6xTT31vytKgbaw.ttf';
export const BarlowCondensed_500Medium =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxwL3I-JCGChYJ8VI-L6OO_au7B4-Lw_3HcuKECcrs.ttf';
export const BarlowCondensed_500Medium_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrK3LWvIMHYrtUxg.ttf';
export const BarlowCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxwL3I-JCGChYJ8VI-L6OO_au7B4873_3HcuKECcrs.ttf';
export const BarlowCondensed_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrB3XWvIMHYrtUxg.ttf';
export const BarlowCondensed_700Bold =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxwL3I-JCGChYJ8VI-L6OO_au7B46r2_3HcuKECcrs.ttf';
export const BarlowCondensed_700Bold_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrY3TWvIMHYrtUxg.ttf';
export const BarlowCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxwL3I-JCGChYJ8VI-L6OO_au7B47b1_3HcuKECcrs.ttf';
export const BarlowCondensed_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrf3fWvIMHYrtUxg.ttf';
export const BarlowCondensed_900Black =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxwL3I-JCGChYJ8VI-L6OO_au7B45L0_3HcuKECcrs.ttf';
export const BarlowCondensed_900Black_Italic =
  'http://fonts.gstatic.com/s/barlowcondensed/v4/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrW3bWvIMHYrtUxg.ttf';
export const BarlowSemiCondensed_100Thin =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfG4qvKk8ogoSP.ttf';
export const BarlowSemiCondensed_100Thin_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpjgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbLLIEsKh5SPZWs.ttf';
export const BarlowSemiCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRft6uPAGEki52WfA.ttf';
export const BarlowSemiCondensed_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJnAWsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_300Light =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06iPAGEki52WfA.ttf';
export const BarlowSemiCondensed_300Light_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIDAmsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_400Regular =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRnf4CrCEo4gg.ttf';
export const BarlowSemiCondensed_400Regular_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfYqvKk8ogoSP.ttf';
export const BarlowSemiCondensed_500Medium =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6mPAGEki52WfA.ttf';
export const BarlowSemiCondensed_500Medium_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJbA2sgqZiGfHK5.ttf';
export const BarlowSemiCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66PAGEki52WfA.ttf';
export const BarlowSemiCondensed_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJ3BGsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_700Bold =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-PAGEki52WfA.ttf';
export const BarlowSemiCondensed_700Bold_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbITBWsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf36yPAGEki52WfA.ttf';
export const BarlowSemiCondensed_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIPBmsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_900Black =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf-62PAGEki52WfA.ttf';
export const BarlowSemiCondensed_900Black_Italic =
  'http://fonts.gstatic.com/s/barlowsemicondensed/v5/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIrB2sgqZiGfHK5.ttf';
export const SpectralSC_200ExtraLight =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk0ALCRZonmalTgyPmRfs1qwkTXPYeVXJZB.ttf';
export const SpectralSC_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk2ALCRZonmalTgyPmRfsWg26zWN4O3WYZB_sU.ttf';
export const SpectralSC_300Light =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk0ALCRZonmalTgyPmRfs0OwUTXPYeVXJZB.ttf';
export const SpectralSC_300Light_Italic =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk2ALCRZonmalTgyPmRfsWg28jVN4O3WYZB_sU.ttf';
export const SpectralSC_400Regular =
  'http://fonts.gstatic.com/s/spectralsc/v5/KtkpALCRZonmalTgyPmRfvWi6WDfFpuc.ttf';
export const SpectralSC_400Regular_Italic =
  'http://fonts.gstatic.com/s/spectralsc/v5/KtkrALCRZonmalTgyPmRfsWg42T9E4ucRY8.ttf';
export const SpectralSC_500Medium =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk0ALCRZonmalTgyPmRfs1WwETXPYeVXJZB.ttf';
export const SpectralSC_500Medium_Italic =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk2ALCRZonmalTgyPmRfsWg25DUN4O3WYZB_sU.ttf';
export const SpectralSC_600SemiBold =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk0ALCRZonmalTgyPmRfs16x0TXPYeVXJZB.ttf';
export const SpectralSC_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk2ALCRZonmalTgyPmRfsWg27zTN4O3WYZB_sU.ttf';
export const SpectralSC_700Bold =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk0ALCRZonmalTgyPmRfs0exkTXPYeVXJZB.ttf';
export const SpectralSC_700Bold_Italic =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk2ALCRZonmalTgyPmRfsWg29jSN4O3WYZB_sU.ttf';
export const SpectralSC_800ExtraBold =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk0ALCRZonmalTgyPmRfs0CxUTXPYeVXJZB.ttf';
export const SpectralSC_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/spectralsc/v5/Ktk2ALCRZonmalTgyPmRfsWg28TRN4O3WYZB_sU.ttf';
export const VollkornSC_400Regular =
  'http://fonts.gstatic.com/s/vollkornsc/v3/j8_v6-zQ3rXpceZj9cqnVhF5NH-iSq_E.ttf';
export const VollkornSC_600SemiBold =
  'http://fonts.gstatic.com/s/vollkornsc/v3/j8_y6-zQ3rXpceZj9cqnVimhGluqYbPN5Yjn.ttf';
export const VollkornSC_700Bold =
  'http://fonts.gstatic.com/s/vollkornsc/v3/j8_y6-zQ3rXpceZj9cqnVinFG1uqYbPN5Yjn.ttf';
export const VollkornSC_900Black =
  'http://fonts.gstatic.com/s/vollkornsc/v3/j8_y6-zQ3rXpceZj9cqnVin9GVuqYbPN5Yjn.ttf';
export const LibreBarcode39Extended_400Regular =
  'http://fonts.gstatic.com/s/librebarcode39extended/v8/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgfNRCOZ1GOBw.ttf';
export const LibreBarcode39ExtendedText_400Regular =
  'http://fonts.gstatic.com/s/librebarcode39extendedtext/v8/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP68fLIiPopNNA.ttf';
export const SedgwickAve_400Regular =
  'http://fonts.gstatic.com/s/sedgwickave/v5/uK_04rKEYuguzAcSYRdWTJq8Xmg1Vcf5JA.ttf';
export const SedgwickAveDisplay_400Regular =
  'http://fonts.gstatic.com/s/sedgwickavedisplay/v5/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM2czd-YnOzUD.ttf';
export const AsapCondensed_400Regular =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxidypY1o9NHyXh3WvSbGSggdNeLYk1Mq3ap.ttf';
export const AsapCondensed_400Regular_Italic =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxifypY1o9NHyXh3WvSbGSggdOeJaElurmapvvM.ttf';
export const AsapCondensed_500Medium =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxieypY1o9NHyXh3WvSbGSggdO9_S2lEgGqgp-pO.ttf';
export const AsapCondensed_500Medium_Italic =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxiYypY1o9NHyXh3WvSbGSggdOeJUL1Him6CovpOkXA.ttf';
export const AsapCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxieypY1o9NHyXh3WvSbGSggdO9TTGlEgGqgp-pO.ttf';
export const AsapCondensed_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxiYypY1o9NHyXh3WvSbGSggdOeJUJFAim6CovpOkXA.ttf';
export const AsapCondensed_700Bold =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxieypY1o9NHyXh3WvSbGSggdO83TWlEgGqgp-pO.ttf';
export const AsapCondensed_700Bold_Italic =
  'http://fonts.gstatic.com/s/asapcondensed/v5/pxiYypY1o9NHyXh3WvSbGSggdOeJUPVBim6CovpOkXA.ttf';
export const Faustina_400Regular =
  'http://fonts.gstatic.com/s/faustina/v6/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEvGVWWe8tbEg.ttf';
export const Faustina_500Medium =
  'http://fonts.gstatic.com/s/faustina/v6/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlssIEvGVWWe8tbEg.ttf';
export const Faustina_600SemiBold =
  'http://fonts.gstatic.com/s/faustina/v6/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsXIYvGVWWe8tbEg.ttf';
export const Faustina_700Bold =
  'http://fonts.gstatic.com/s/faustina/v6/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsZYYvGVWWe8tbEg.ttf';
export const Faustina_400Regular_Italic =
  'http://fonts.gstatic.com/s/faustina/v6/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsLHWl-SWc5LEnoF.ttf';
export const Faustina_500Medium_Italic =
  'http://fonts.gstatic.com/s/faustina/v6/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsL1Wl-SWc5LEnoF.ttf';
export const Faustina_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/faustina/v6/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIZXV-SWc5LEnoF.ttf';
export const Faustina_700Bold_Italic =
  'http://fonts.gstatic.com/s/faustina/v6/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIgXV-SWc5LEnoF.ttf';
export const Manuale_400Regular =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke7wD1TB_JHHY.ttf';
export const Manuale_500Medium =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHWe7wD1TB_JHHY.ttf';
export const Manuale_600SemiBold =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeE6fLwD1TB_JHHY.ttf';
export const Manuale_700Bold =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeEDfLwD1TB_JHHY.ttf';
export const Manuale_400Regular_Italic =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOFRA3zRdIWHYr8M.ttf';
export const Manuale_500Medium_Italic =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOGZA3zRdIWHYr8M.ttf';
export const Manuale_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOIpH3zRdIWHYr8M.ttf';
export const Manuale_700Bold_Italic =
  'http://fonts.gstatic.com/s/manuale/v6/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOLNH3zRdIWHYr8M.ttf';
export const Saira_100Thin = 'http://fonts.gstatic.com/s/saira/v4/mem-Ya2wxmKQyNFETZY_VrUfTck.ttf';
export const Saira_200ExtraLight =
  'http://fonts.gstatic.com/s/saira/v4/mem9Ya2wxmKQyNHobLYVeLkWVNBt.ttf';
export const Saira_300Light =
  'http://fonts.gstatic.com/s/saira/v4/mem9Ya2wxmKQyNGMb7YVeLkWVNBt.ttf';
export const Saira_400Regular = 'http://fonts.gstatic.com/s/saira/v4/memwYa2wxmKQyOkgR5IdU6Uf.ttf';
export const Saira_500Medium =
  'http://fonts.gstatic.com/s/saira/v4/mem9Ya2wxmKQyNHUbrYVeLkWVNBt.ttf';
export const Saira_600SemiBold =
  'http://fonts.gstatic.com/s/saira/v4/mem9Ya2wxmKQyNH4abYVeLkWVNBt.ttf';
export const Saira_700Bold = 'http://fonts.gstatic.com/s/saira/v4/mem9Ya2wxmKQyNGcaLYVeLkWVNBt.ttf';
export const Saira_800ExtraBold =
  'http://fonts.gstatic.com/s/saira/v4/mem9Ya2wxmKQyNGAa7YVeLkWVNBt.ttf';
export const Saira_900Black =
  'http://fonts.gstatic.com/s/saira/v4/mem9Ya2wxmKQyNGkarYVeLkWVNBt.ttf';
export const SairaCondensed_100Thin =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRMQgErUN8XuHNEtX81i9TmEkrnwetA2omSrzS8.ttf';
export const SairaCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRLQgErUN8XuHNEtX81i9TmEkrnbcpg8Keepi2lHw.ttf';
export const SairaCondensed_300Light =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRLQgErUN8XuHNEtX81i9TmEkrnCclg8Keepi2lHw.ttf';
export const SairaCondensed_400Regular =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJROQgErUN8XuHNEtX81i9TmEkrfpeFE-IyCrw.ttf';
export const SairaCondensed_500Medium =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRLQgErUN8XuHNEtX81i9TmEkrnUchg8Keepi2lHw.ttf';
export const SairaCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRLQgErUN8XuHNEtX81i9TmEkrnfc9g8Keepi2lHw.ttf';
export const SairaCondensed_700Bold =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRLQgErUN8XuHNEtX81i9TmEkrnGc5g8Keepi2lHw.ttf';
export const SairaCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRLQgErUN8XuHNEtX81i9TmEkrnBc1g8Keepi2lHw.ttf';
export const SairaCondensed_900Black =
  'http://fonts.gstatic.com/s/sairacondensed/v5/EJRLQgErUN8XuHNEtX81i9TmEkrnIcxg8Keepi2lHw.ttf';
export const SairaExtraCondensed_100Thin =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFsOHYr-vcC7h8MklGBkrvmUG9rbpkisrTri0jx9i5ss3a3.ttf';
export const SairaExtraCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrJ2nR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_300Light =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrQ2rR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_400Regular =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTT70L11Ct8sw.ttf';
export const SairaExtraCondensed_500Medium =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrG2vR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrN2zR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_700Bold =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrU23R3ABgum-uoQ.ttf';
export const SairaExtraCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrT27R3ABgum-uoQ.ttf';
export const SairaExtraCondensed_900Black =
  'http://fonts.gstatic.com/s/sairaextracondensed/v5/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTra2_R3ABgum-uoQ.ttf';
export const SairaSemiCondensed_100Thin =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MN6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXdvaOM8rXT-8V8.ttf';
export const SairaSemiCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfDScMWg3j36Ebz.ttf';
export const SairaSemiCondensed_300Light =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXenSsMWg3j36Ebz.ttf';
export const SairaSemiCondensed_400Regular =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRU8LYuceqGT-.ttf';
export const SairaSemiCondensed_500Medium =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXf_S8MWg3j36Ebz.ttf';
export const SairaSemiCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfTTMMWg3j36Ebz.ttf';
export const SairaSemiCondensed_700Bold =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXe3TcMWg3j36Ebz.ttf';
export const SairaSemiCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXerTsMWg3j36Ebz.ttf';
export const SairaSemiCondensed_900Black =
  'http://fonts.gstatic.com/s/sairasemicondensed/v5/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXePT8MWg3j36Ebz.ttf';
export const LibreBarcode128_400Regular =
  'http://fonts.gstatic.com/s/librebarcode128/v9/cIfnMbdUsUoiW3O_hVviCwVjuLtXeJ_A_gMk0izH.ttf';
export const LibreBarcode128Text_400Regular =
  'http://fonts.gstatic.com/s/librebarcode128text/v9/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5cV3Y6M90w4k.ttf';
export const LibreBarcode39_400Regular =
  'http://fonts.gstatic.com/s/librebarcode39/v9/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_d709jy92k.ttf';
export const LibreBarcode39Text_400Regular =
  'http://fonts.gstatic.com/s/librebarcode39text/v9/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-031RRL-9rYaw.ttf';
export const Mada_200ExtraLight =
  'http://fonts.gstatic.com/s/mada/v8/7Au_p_0qnzeSdf3nCCL8zkwMIFg.ttf';
export const Mada_300Light = 'http://fonts.gstatic.com/s/mada/v8/7Au_p_0qnzeSdZnkCCL8zkwMIFg.ttf';
export const Mada_400Regular = 'http://fonts.gstatic.com/s/mada/v8/7Auwp_0qnzeSTTXMLCrX0kU.ttf';
export const Mada_500Medium = 'http://fonts.gstatic.com/s/mada/v8/7Au_p_0qnzeSdcHlCCL8zkwMIFg.ttf';
export const Mada_600SemiBold =
  'http://fonts.gstatic.com/s/mada/v8/7Au_p_0qnzeSde3iCCL8zkwMIFg.ttf';
export const Mada_700Bold = 'http://fonts.gstatic.com/s/mada/v8/7Au_p_0qnzeSdYnjCCL8zkwMIFg.ttf';
export const Mada_900Black = 'http://fonts.gstatic.com/s/mada/v8/7Au_p_0qnzeSdbHhCCL8zkwMIFg.ttf';
export const ZillaSlabHighlight_400Regular =
  'http://fonts.gstatic.com/s/zillaslabhighlight/v7/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxhtIY2DwSXlM.ttf';
export const ZillaSlabHighlight_700Bold =
  'http://fonts.gstatic.com/s/zillaslabhighlight/v7/gNMUW2BrTpK8-inLtBJgMMfbm6uNVDvRxiP0TET4YmVF0Mb6.ttf';
export const Bellefair_400Regular =
  'http://fonts.gstatic.com/s/bellefair/v5/kJExBuYY6AAuhiXUxG19__A2pOdvDA.ttf';
export const ZillaSlab_300Light =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFa5ZfeM_74wlPZtksIFYpEY2HSjWlhzbaw.ttf';
export const ZillaSlab_300Light_Italic =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFanZfeM_74wlPZtksIFaj8CVHapXnp2fazkfg.ttf';
export const ZillaSlab_400Regular =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFa6ZfeM_74wlPZtksIFWj0w_HyIRlE.ttf';
export const ZillaSlab_400Regular_Italic =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFa4ZfeM_74wlPZtksIFaj86-F6NVlFqdA.ttf';
export const ZillaSlab_500Medium =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFa5ZfeM_74wlPZtksIFYskZ2HSjWlhzbaw.ttf';
export const ZillaSlab_500Medium_Italic =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFanZfeM_74wlPZtksIFaj8CDHepXnp2fazkfg.ttf';
export const ZillaSlab_600SemiBold =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFa5ZfeM_74wlPZtksIFYuUe2HSjWlhzbaw.ttf';
export const ZillaSlab_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFanZfeM_74wlPZtksIFaj8CIHCpXnp2fazkfg.ttf';
export const ZillaSlab_700Bold =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFa5ZfeM_74wlPZtksIFYoEf2HSjWlhzbaw.ttf';
export const ZillaSlab_700Bold_Italic =
  'http://fonts.gstatic.com/s/zillaslab/v5/dFanZfeM_74wlPZtksIFaj8CRHGpXnp2fazkfg.ttf';
export const MPLUS1p_100Thin =
  'http://fonts.gstatic.com/s/mplus1p/v19/e3tleuShHdiFyPFzBRrQnDQAUW3aq-5N.ttf';
export const MPLUS1p_300Light =
  'http://fonts.gstatic.com/s/mplus1p/v19/e3tmeuShHdiFyPFzBRrQVBYge0PWovdU4w.ttf';
export const MPLUS1p_400Regular =
  'http://fonts.gstatic.com/s/mplus1p/v19/e3tjeuShHdiFyPFzBRro-D4Ec2jKqw.ttf';
export const MPLUS1p_500Medium =
  'http://fonts.gstatic.com/s/mplus1p/v19/e3tmeuShHdiFyPFzBRrQDBcge0PWovdU4w.ttf';
export const MPLUS1p_700Bold =
  'http://fonts.gstatic.com/s/mplus1p/v19/e3tmeuShHdiFyPFzBRrQRBEge0PWovdU4w.ttf';
export const MPLUS1p_800ExtraBold =
  'http://fonts.gstatic.com/s/mplus1p/v19/e3tmeuShHdiFyPFzBRrQWBIge0PWovdU4w.ttf';
export const MPLUS1p_900Black =
  'http://fonts.gstatic.com/s/mplus1p/v19/e3tmeuShHdiFyPFzBRrQfBMge0PWovdU4w.ttf';
export const Spectral_200ExtraLight =
  'http://fonts.gstatic.com/s/spectral/v6/rnCs-xNNww_2s0amA9v2s13GY_etWWIJ.ttf';
export const Spectral_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/spectral/v6/rnCu-xNNww_2s0amA9M8qrXHafOPXHIJErY.ttf';
export const Spectral_300Light =
  'http://fonts.gstatic.com/s/spectral/v6/rnCs-xNNww_2s0amA9uSsF3GY_etWWIJ.ttf';
export const Spectral_300Light_Italic =
  'http://fonts.gstatic.com/s/spectral/v6/rnCu-xNNww_2s0amA9M8qtHEafOPXHIJErY.ttf';
export const Spectral_400Regular =
  'http://fonts.gstatic.com/s/spectral/v6/rnCr-xNNww_2s0amA-M-mHnOSOuk.ttf';
export const Spectral_400Regular_Italic =
  'http://fonts.gstatic.com/s/spectral/v6/rnCt-xNNww_2s0amA9M8kn3sTfukQHs.ttf';
export const Spectral_500Medium =
  'http://fonts.gstatic.com/s/spectral/v6/rnCs-xNNww_2s0amA9vKsV3GY_etWWIJ.ttf';
export const Spectral_500Medium_Italic =
  'http://fonts.gstatic.com/s/spectral/v6/rnCu-xNNww_2s0amA9M8qonFafOPXHIJErY.ttf';
export const Spectral_600SemiBold =
  'http://fonts.gstatic.com/s/spectral/v6/rnCs-xNNww_2s0amA9vmtl3GY_etWWIJ.ttf';
export const Spectral_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/spectral/v6/rnCu-xNNww_2s0amA9M8qqXCafOPXHIJErY.ttf';
export const Spectral_700Bold =
  'http://fonts.gstatic.com/s/spectral/v6/rnCs-xNNww_2s0amA9uCt13GY_etWWIJ.ttf';
export const Spectral_700Bold_Italic =
  'http://fonts.gstatic.com/s/spectral/v6/rnCu-xNNww_2s0amA9M8qsHDafOPXHIJErY.ttf';
export const Spectral_800ExtraBold =
  'http://fonts.gstatic.com/s/spectral/v6/rnCs-xNNww_2s0amA9uetF3GY_etWWIJ.ttf';
export const Spectral_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/spectral/v6/rnCu-xNNww_2s0amA9M8qt3AafOPXHIJErY.ttf';
export const MuktaMahee_200ExtraLight =
  'http://fonts.gstatic.com/s/muktamahee/v5/XRXN3IOIi0hcP8iVU67hA9MFcBoHJndqZCsW.ttf';
export const MuktaMahee_300Light =
  'http://fonts.gstatic.com/s/muktamahee/v5/XRXN3IOIi0hcP8iVU67hA9NhcxoHJndqZCsW.ttf';
export const MuktaMahee_400Regular =
  'http://fonts.gstatic.com/s/muktamahee/v5/XRXQ3IOIi0hcP8iVU67hA-vNWz4PDWtj.ttf';
export const MuktaMahee_500Medium =
  'http://fonts.gstatic.com/s/muktamahee/v5/XRXN3IOIi0hcP8iVU67hA9M5choHJndqZCsW.ttf';
export const MuktaMahee_600SemiBold =
  'http://fonts.gstatic.com/s/muktamahee/v5/XRXN3IOIi0hcP8iVU67hA9MVdRoHJndqZCsW.ttf';
export const MuktaMahee_700Bold =
  'http://fonts.gstatic.com/s/muktamahee/v5/XRXN3IOIi0hcP8iVU67hA9NxdBoHJndqZCsW.ttf';
export const MuktaMahee_800ExtraBold =
  'http://fonts.gstatic.com/s/muktamahee/v5/XRXN3IOIi0hcP8iVU67hA9NtdxoHJndqZCsW.ttf';
export const MuktaMalar_200ExtraLight =
  'http://fonts.gstatic.com/s/muktamalar/v6/MCoKzAXyz8LOE2FpJMxZqIMwBtAB62ruoAZW.ttf';
export const MuktaMalar_300Light =
  'http://fonts.gstatic.com/s/muktamalar/v6/MCoKzAXyz8LOE2FpJMxZqINUBdAB62ruoAZW.ttf';
export const MuktaMalar_400Regular =
  'http://fonts.gstatic.com/s/muktamalar/v6/MCoXzAXyz8LOE2FpJMxZqLv4LfQJwHbn.ttf';
export const MuktaMalar_500Medium =
  'http://fonts.gstatic.com/s/muktamalar/v6/MCoKzAXyz8LOE2FpJMxZqIMMBNAB62ruoAZW.ttf';
export const MuktaMalar_600SemiBold =
  'http://fonts.gstatic.com/s/muktamalar/v6/MCoKzAXyz8LOE2FpJMxZqIMgA9AB62ruoAZW.ttf';
export const MuktaMalar_700Bold =
  'http://fonts.gstatic.com/s/muktamalar/v6/MCoKzAXyz8LOE2FpJMxZqINEAtAB62ruoAZW.ttf';
export const MuktaMalar_800ExtraBold =
  'http://fonts.gstatic.com/s/muktamalar/v6/MCoKzAXyz8LOE2FpJMxZqINYAdAB62ruoAZW.ttf';
export const EncodeSans_100Thin =
  'http://fonts.gstatic.com/s/encodesans/v4/LDI0apOFNxEwR-Bd1O9uYPvIeeLkl7Iw6yg.ttf';
export const EncodeSans_200ExtraLight =
  'http://fonts.gstatic.com/s/encodesans/v4/LDIrapOFNxEwR-Bd1O9uYPtkWMLOub458jGL.ttf';
export const EncodeSans_300Light =
  'http://fonts.gstatic.com/s/encodesans/v4/LDIrapOFNxEwR-Bd1O9uYPsAW8LOub458jGL.ttf';
export const EncodeSans_400Regular =
  'http://fonts.gstatic.com/s/encodesans/v4/LDI2apOFNxEwR-Bd1O9uYMOsc-bGkqIw.ttf';
export const EncodeSans_500Medium =
  'http://fonts.gstatic.com/s/encodesans/v4/LDIrapOFNxEwR-Bd1O9uYPtYWsLOub458jGL.ttf';
export const EncodeSans_600SemiBold =
  'http://fonts.gstatic.com/s/encodesans/v4/LDIrapOFNxEwR-Bd1O9uYPt0XcLOub458jGL.ttf';
export const EncodeSans_700Bold =
  'http://fonts.gstatic.com/s/encodesans/v4/LDIrapOFNxEwR-Bd1O9uYPsQXMLOub458jGL.ttf';
export const EncodeSans_800ExtraBold =
  'http://fonts.gstatic.com/s/encodesans/v4/LDIrapOFNxEwR-Bd1O9uYPsMX8LOub458jGL.ttf';
export const EncodeSans_900Black =
  'http://fonts.gstatic.com/s/encodesans/v4/LDIrapOFNxEwR-Bd1O9uYPsoXsLOub458jGL.ttf';
export const EncodeSansCondensed_100Thin =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_76_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-5a-JLQoFI2KR.ttf';
export const EncodeSansCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-SY6pByQJKnuIFA.ttf';
export const EncodeSansCondensed_300Light =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-LY2pByQJKnuIFA.ttf';
export const EncodeSansCondensed_400Regular =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfTGgaWNDw8VIw.ttf';
export const EncodeSansCondensed_500Medium =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-dYypByQJKnuIFA.ttf';
export const EncodeSansCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-WYupByQJKnuIFA.ttf';
export const EncodeSansCondensed_700Bold =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-PYqpByQJKnuIFA.ttf';
export const EncodeSansCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-IYmpByQJKnuIFA.ttf';
export const EncodeSansCondensed_900Black =
  'http://fonts.gstatic.com/s/encodesanscondensed/v4/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-BYipByQJKnuIFA.ttf';
export const EncodeSansExpanded_100Thin =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mx1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpJGKQNicoAbJlw.ttf';
export const EncodeSansExpanded_200ExtraLight =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLqCCNIXIwSP0XD.ttf';
export const EncodeSansExpanded_300Light =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKOCyNIXIwSP0XD.ttf';
export const EncodeSansExpanded_400Regular =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUqoiIwdAd5Ab.ttf';
export const EncodeSansExpanded_500Medium =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLWCiNIXIwSP0XD.ttf';
export const EncodeSansExpanded_600SemiBold =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpL6DSNIXIwSP0XD.ttf';
export const EncodeSansExpanded_700Bold =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKeDCNIXIwSP0XD.ttf';
export const EncodeSansExpanded_800ExtraBold =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKCDyNIXIwSP0XD.ttf';
export const EncodeSansExpanded_900Black =
  'http://fonts.gstatic.com/s/encodesansexpanded/v4/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKmDiNIXIwSP0XD.ttf';
export const EncodeSansSemiCondensed_100Thin =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT6oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1T19MFtQ9jpVUA.ttf';
export const EncodeSansSemiCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RZ1eFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_300Light =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Q91uFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_400Regular =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG2yR_sVPRsjp.ttf';
export const EncodeSansSemiCondensed_500Medium =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Rl1-FHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RJ0OFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_700Bold =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qt0eFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qx0uFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_900Black =
  'http://fonts.gstatic.com/s/encodesanssemicondensed/v4/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1QV0-FHbdTgTFmr.ttf';
export const EncodeSansSemiExpanded_100Thin =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8xOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM-41KwrlKXeOEA.ttf';
export const EncodeSansSemiExpanded_200ExtraLight =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM0IUCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_300Light =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyYXCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_400Regular =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TC4o_LyjgOXc.ttf';
export const EncodeSansSemiExpanded_500Medium =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM34WCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_600SemiBold =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM1IRCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_700Bold =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMzYQCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_800ExtraBold =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyoTCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_900Black =
  'http://fonts.gstatic.com/s/encodesanssemiexpanded/v5/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMw4SCyDLJX6XCWU.ttf';
export const Mukta_200ExtraLight =
  'http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbEOjFma-2HW7ZB_.ttf';
export const Mukta_300Light =
  'http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbFqj1ma-2HW7ZB_.ttf';
export const Mukta_400Regular = 'http://fonts.gstatic.com/s/mukta/v7/iJWKBXyXfDDVXYnGp32S0H3f.ttf';
export const Mukta_500Medium =
  'http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbEyjlma-2HW7ZB_.ttf';
export const Mukta_600SemiBold =
  'http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbEeiVma-2HW7ZB_.ttf';
export const Mukta_700Bold = 'http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbF6iFma-2HW7ZB_.ttf';
export const Mukta_800ExtraBold =
  'http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbFmi1ma-2HW7ZB_.ttf';
export const Pangolin_400Regular =
  'http://fonts.gstatic.com/s/pangolin/v5/cY9GfjGcW0FPpi-tWPfK5d3aiLBG.ttf';
export const NunitoSans_200ExtraLight =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc9yAv5qWVAgVol-.ttf';
export const NunitoSans_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe01MImSLYBIv1o4X1M8cce4GxZrU1QCU5l-06Y.ttf';
export const NunitoSans_300Light =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc8WAf5qWVAgVol-.ttf';
export const NunitoSans_300Light_Italic =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe01MImSLYBIv1o4X1M8cce4G3JoU1QCU5l-06Y.ttf';
export const NunitoSans_400Regular =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe0qMImSLYBIv1o4X1M8cfe6Kdpickwp.ttf';
export const NunitoSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe0oMImSLYBIv1o4X1M8cce4I95Ad1wpT5A.ttf';
export const NunitoSans_600SemiBold =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc9iB_5qWVAgVol-.ttf';
export const NunitoSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe01MImSLYBIv1o4X1M8cce4GwZuU1QCU5l-06Y.ttf';
export const NunitoSans_700Bold =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc8GBv5qWVAgVol-.ttf';
export const NunitoSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe01MImSLYBIv1o4X1M8cce4G2JvU1QCU5l-06Y.ttf';
export const NunitoSans_800ExtraBold =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc8aBf5qWVAgVol-.ttf';
export const NunitoSans_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe01MImSLYBIv1o4X1M8cce4G35sU1QCU5l-06Y.ttf';
export const NunitoSans_900Black =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc8-BP5qWVAgVol-.ttf';
export const NunitoSans_900Black_Italic =
  'http://fonts.gstatic.com/s/nunitosans/v5/pe01MImSLYBIv1o4X1M8cce4G1ptU1QCU5l-06Y.ttf';
export const FiraSansCondensed_100Thin =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOjEADFm8hSaQTFG18FErVhsC9x-tarWZXtqOlQfx9CjA.ttf';
export const FiraSansCondensed_100Thin_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOtEADFm8hSaQTFG18FErVhsC9x-tarUfPVzONUXRpSjJcu.ttf';
export const FiraSansCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWTnMiMN-cxZblY4.ttf';
export const FiraSansCondensed_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVYMJ0dzRehY43EA.ttf';
export const FiraSansCondensed_300Light =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWV3PiMN-cxZblY4.ttf';
export const FiraSansCondensed_300Light_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVBMF0dzRehY43EA.ttf';
export const FiraSansCondensed_400Regular =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOhEADFm8hSaQTFG18FErVhsC9x-tarYfHnrMtVbx8.ttf';
export const FiraSansCondensed_400Regular_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOjEADFm8hSaQTFG18FErVhsC9x-tarUfPtqOlQfx9CjA.ttf';
export const FiraSansCondensed_500Medium =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWQXOiMN-cxZblY4.ttf';
export const FiraSansCondensed_500Medium_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVXMB0dzRehY43EA.ttf';
export const FiraSansCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWSnJiMN-cxZblY4.ttf';
export const FiraSansCondensed_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVcMd0dzRehY43EA.ttf';
export const FiraSansCondensed_700Bold =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWU3IiMN-cxZblY4.ttf';
export const FiraSansCondensed_700Bold_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVFMZ0dzRehY43EA.ttf';
export const FiraSansCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWVHLiMN-cxZblY4.ttf';
export const FiraSansCondensed_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVCMV0dzRehY43EA.ttf';
export const FiraSansCondensed_900Black =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWXXKiMN-cxZblY4.ttf';
export const FiraSansCondensed_900Black_Italic =
  'http://fonts.gstatic.com/s/firasanscondensed/v4/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVLMR0dzRehY43EA.ttf';
export const FiraSansExtraCondensed_100Thin =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3Zyuv1WarE9ncg.ttf';
export const FiraSansExtraCondensed_100Thin_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPOcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqW21-ejkp3cn22.ttf';
export const FiraSansExtraCondensed_200ExtraLight =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3TCPn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWd36-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_300Light =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3VSMn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_300Light_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWE32-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_400Regular =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda5fiku3efvE8.ttf';
export const FiraSansExtraCondensed_400Regular_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fquv1WarE9ncg.ttf';
export const FiraSansExtraCondensed_500Medium =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3QyNn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_500Medium_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWS3y-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3SCKn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWZ3u-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_700Bold =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3USLn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_700Bold_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWA3q-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_800ExtraBold =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3ViIn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWH3m-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_900Black =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3XyJn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_900Black_Italic =
  'http://fonts.gstatic.com/s/firasansextracondensed/v4/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWO3i-pGR7e2SvJQ.ttf';
export const Arsenal_400Regular =
  'http://fonts.gstatic.com/s/arsenal/v4/wXKrE3kQtZQ4pF3D11_WAewrhXY.ttf';
export const Arsenal_400Regular_Italic =
  'http://fonts.gstatic.com/s/arsenal/v4/wXKpE3kQtZQ4pF3D513cBc4ulXYrtA.ttf';
export const Arsenal_700Bold =
  'http://fonts.gstatic.com/s/arsenal/v4/wXKuE3kQtZQ4pF3D7-P5JeQAmX8yrdk.ttf';
export const Arsenal_700Bold_Italic =
  'http://fonts.gstatic.com/s/arsenal/v4/wXKsE3kQtZQ4pF3D513kueEKnV03vdnKjw.ttf';
export const Sansita_400Regular =
  'http://fonts.gstatic.com/s/sansita/v4/QldONTRRphEb_-V7HBm7TXFf3qw.ttf';
export const Sansita_400Regular_Italic =
  'http://fonts.gstatic.com/s/sansita/v4/QldMNTRRphEb_-V7LBuxSVNazqx2xg.ttf';
export const Sansita_700Bold =
  'http://fonts.gstatic.com/s/sansita/v4/QldLNTRRphEb_-V7JKWUaXl0wqVv3_g.ttf';
export const Sansita_700Bold_Italic =
  'http://fonts.gstatic.com/s/sansita/v4/QldJNTRRphEb_-V7LBuJ9Xx-xodqz_joDQ.ttf';
export const Sansita_800ExtraBold =
  'http://fonts.gstatic.com/s/sansita/v4/QldLNTRRphEb_-V7JLmXaXl0wqVv3_g.ttf';
export const Sansita_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/sansita/v4/QldJNTRRphEb_-V7LBuJ6X9-xodqz_joDQ.ttf';
export const Sansita_900Black =
  'http://fonts.gstatic.com/s/sansita/v4/QldLNTRRphEb_-V7JJ2WaXl0wqVv3_g.ttf';
export const Sansita_900Black_Italic =
  'http://fonts.gstatic.com/s/sansita/v4/QldJNTRRphEb_-V7LBuJzX5-xodqz_joDQ.ttf';
export const Archivo_400Regular =
  'http://fonts.gstatic.com/s/archivo/v6/k3kQo8UDI-1M0wlSTd7iL0nAMaM.ttf';
export const Archivo_400Regular_Italic =
  'http://fonts.gstatic.com/s/archivo/v6/k3kSo8UDI-1M0wlSfdzoK2vFIaOV8A.ttf';
export const Archivo_500Medium =
  'http://fonts.gstatic.com/s/archivo/v6/k3kVo8UDI-1M0wlSdSrLC0HrLaqM6Q4.ttf';
export const Archivo_500Medium_Italic =
  'http://fonts.gstatic.com/s/archivo/v6/k3kXo8UDI-1M0wlSfdzQ30LhKYiJ-Q7m8w.ttf';
export const Archivo_600SemiBold =
  'http://fonts.gstatic.com/s/archivo/v6/k3kVo8UDI-1M0wlSdQbMC0HrLaqM6Q4.ttf';
export const Archivo_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/archivo/v6/k3kXo8UDI-1M0wlSfdzQ80XhKYiJ-Q7m8w.ttf';
export const Archivo_700Bold =
  'http://fonts.gstatic.com/s/archivo/v6/k3kVo8UDI-1M0wlSdWLNC0HrLaqM6Q4.ttf';
export const Archivo_700Bold_Italic =
  'http://fonts.gstatic.com/s/archivo/v6/k3kXo8UDI-1M0wlSfdzQl0ThKYiJ-Q7m8w.ttf';
export const Bahiana_400Regular =
  'http://fonts.gstatic.com/s/bahiana/v4/uU9PCBUV4YenPWJU7xPb3vyHmlI.ttf';
export const Barrio_400Regular =
  'http://fonts.gstatic.com/s/barrio/v4/wEO8EBXBk8hBIDiEdQYhWdsX1Q.ttf';
export const Overpass_100Thin =
  'http://fonts.gstatic.com/s/overpass/v4/qFdB35WCmI96Ajtm81nGU97gxhcJk1s.ttf';
export const Overpass_100Thin_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdD35WCmI96Ajtm81Gga7rqwjUMg1siNQ.ttf';
export const Overpass_200ExtraLight =
  'http://fonts.gstatic.com/s/overpass/v4/qFdA35WCmI96Ajtm81lqcv7K6BsAikI7.ttf';
export const Overpass_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdC35WCmI96Ajtm81GgaxbL4h8ij1I7LLE.ttf';
export const Overpass_300Light =
  'http://fonts.gstatic.com/s/overpass/v4/qFdA35WCmI96Ajtm81kOcf7K6BsAikI7.ttf';
export const Overpass_300Light_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdC35WCmI96Ajtm81Gga3LI4h8ij1I7LLE.ttf';
export const Overpass_400Regular =
  'http://fonts.gstatic.com/s/overpass/v4/qFdH35WCmI96Ajtm82GiWdrCwwcJ.ttf';
export const Overpass_400Regular_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdB35WCmI96Ajtm81GgU97gxhcJk1s.ttf';
export const Overpass_600SemiBold =
  'http://fonts.gstatic.com/s/overpass/v4/qFdA35WCmI96Ajtm81l6d_7K6BsAikI7.ttf';
export const Overpass_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdC35WCmI96Ajtm81GgawbO4h8ij1I7LLE.ttf';
export const Overpass_700Bold =
  'http://fonts.gstatic.com/s/overpass/v4/qFdA35WCmI96Ajtm81kedv7K6BsAikI7.ttf';
export const Overpass_700Bold_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdC35WCmI96Ajtm81Gga2LP4h8ij1I7LLE.ttf';
export const Overpass_800ExtraBold =
  'http://fonts.gstatic.com/s/overpass/v4/qFdA35WCmI96Ajtm81kCdf7K6BsAikI7.ttf';
export const Overpass_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdC35WCmI96Ajtm81Gga37M4h8ij1I7LLE.ttf';
export const Overpass_900Black =
  'http://fonts.gstatic.com/s/overpass/v4/qFdA35WCmI96Ajtm81kmdP7K6BsAikI7.ttf';
export const Overpass_900Black_Italic =
  'http://fonts.gstatic.com/s/overpass/v4/qFdC35WCmI96Ajtm81Gga1rN4h8ij1I7LLE.ttf';
export const OverpassMono_300Light =
  'http://fonts.gstatic.com/s/overpassmono/v5/_Xm3-H86tzKDdAPa-KPQZ-AC3oSWk_edB3Zf8EQ.ttf';
export const OverpassMono_400Regular =
  'http://fonts.gstatic.com/s/overpassmono/v5/_Xmq-H86tzKDdAPa-KPQZ-AC5ii-t_-2G38.ttf';
export const OverpassMono_600SemiBold =
  'http://fonts.gstatic.com/s/overpassmono/v5/_Xm3-H86tzKDdAPa-KPQZ-AC3vCQk_edB3Zf8EQ.ttf';
export const OverpassMono_700Bold =
  'http://fonts.gstatic.com/s/overpassmono/v5/_Xm3-H86tzKDdAPa-KPQZ-AC3pSRk_edB3Zf8EQ.ttf';
export const Padauk_400Regular =
  'http://fonts.gstatic.com/s/padauk/v6/RrQRboJg-id7OnbBa0_g3LlYbg.ttf';
export const Padauk_700Bold =
  'http://fonts.gstatic.com/s/padauk/v6/RrQSboJg-id7Onb512DE1JJEZ4YwGg.ttf';
export const AbhayaLibre_400Regular =
  'http://fonts.gstatic.com/s/abhayalibre/v5/e3tmeuGtX-Co5MNzeAOqinEge0PWovdU4w.ttf';
export const AbhayaLibre_500Medium =
  'http://fonts.gstatic.com/s/abhayalibre/v5/e3t5euGtX-Co5MNzeAOqinEYj2ryqtxI6oYtBA.ttf';
export const AbhayaLibre_600SemiBold =
  'http://fonts.gstatic.com/s/abhayalibre/v5/e3t5euGtX-Co5MNzeAOqinEYo23yqtxI6oYtBA.ttf';
export const AbhayaLibre_700Bold =
  'http://fonts.gstatic.com/s/abhayalibre/v5/e3t5euGtX-Co5MNzeAOqinEYx2zyqtxI6oYtBA.ttf';
export const AbhayaLibre_800ExtraBold =
  'http://fonts.gstatic.com/s/abhayalibre/v5/e3t5euGtX-Co5MNzeAOqinEY22_yqtxI6oYtBA.ttf';
export const ArefRuqaa_400Regular =
  'http://fonts.gstatic.com/s/arefruqaa/v8/WwkbxPW1E165rajQKDulEIAiVNo5xNY.ttf';
export const ArefRuqaa_700Bold =
  'http://fonts.gstatic.com/s/arefruqaa/v8/WwkYxPW1E165rajQKDulKDwNcNIS2N_7Bdk.ttf';
export const Coiny_400Regular = 'http://fonts.gstatic.com/s/coiny/v5/gyByhwU1K989PXwbElSvO5Tc.ttf';
export const Farsan_400Regular =
  'http://fonts.gstatic.com/s/farsan/v5/VEMwRoJ0vY_zsyz62q-pxDX9rQ.ttf';
export const FrankRuhlLibre_300Light =
  'http://fonts.gstatic.com/s/frankruhllibre/v5/j8_36_fAw7jrcalD7oKYNX0QfAnPUxvHxJDMhYeIHw8.ttf';
export const FrankRuhlLibre_400Regular =
  'http://fonts.gstatic.com/s/frankruhllibre/v5/j8_w6_fAw7jrcalD7oKYNX0QfAnPa7fv4JjnmY4.ttf';
export const FrankRuhlLibre_500Medium =
  'http://fonts.gstatic.com/s/frankruhllibre/v5/j8_36_fAw7jrcalD7oKYNX0QfAnPU0PGxJDMhYeIHw8.ttf';
export const FrankRuhlLibre_700Bold =
  'http://fonts.gstatic.com/s/frankruhllibre/v5/j8_36_fAw7jrcalD7oKYNX0QfAnPUwvAxJDMhYeIHw8.ttf';
export const FrankRuhlLibre_900Black =
  'http://fonts.gstatic.com/s/frankruhllibre/v5/j8_36_fAw7jrcalD7oKYNX0QfAnPUzPCxJDMhYeIHw8.ttf';
export const Galada_400Regular =
  'http://fonts.gstatic.com/s/galada/v5/H4cmBXyGmcjXlUX-8iw-4Lqggw.ttf';
export const Kavivanar_400Regular =
  'http://fonts.gstatic.com/s/kavivanar/v5/o-0IIpQgyXYSwhxP7_Jb4j5Ba_2c7A.ttf';
export const MiriamLibre_400Regular =
  'http://fonts.gstatic.com/s/miriamlibre/v6/DdTh798HsHwubBAqfkcBTL_vYJn_Teun9g.ttf';
export const MiriamLibre_700Bold =
  'http://fonts.gstatic.com/s/miriamlibre/v6/DdT-798HsHwubBAqfkcBTL_X3LbbRcC7_-Z7Hg.ttf';
export const ArimaMadurai_100Thin =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5t4IRoeKYORG0WNMgnC3seB1V3PqrGCch4Drg.ttf';
export const ArimaMadurai_200ExtraLight =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5t7IRoeKYORG0WNMgnC3seB1fHuipusfhcat2c.ttf';
export const ArimaMadurai_300Light =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5t7IRoeKYORG0WNMgnC3seB1ZXtipusfhcat2c.ttf';
export const ArimaMadurai_400Regular =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5tmIRoeKYORG0WNMgnC3seB7TnFrpOHYh4.ttf';
export const ArimaMadurai_500Medium =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5t7IRoeKYORG0WNMgnC3seB1c3sipusfhcat2c.ttf';
export const ArimaMadurai_700Bold =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5t7IRoeKYORG0WNMgnC3seB1YXqipusfhcat2c.ttf';
export const ArimaMadurai_800ExtraBold =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5t7IRoeKYORG0WNMgnC3seB1Znpipusfhcat2c.ttf';
export const ArimaMadurai_900Black =
  'http://fonts.gstatic.com/s/arimamadurai/v5/t5t7IRoeKYORG0WNMgnC3seB1b3oipusfhcat2c.ttf';
export const Athiti_200ExtraLight =
  'http://fonts.gstatic.com/s/athiti/v4/pe0sMISdLIZIv1wAxDNyAv2-C99ycg.ttf';
export const Athiti_300Light =
  'http://fonts.gstatic.com/s/athiti/v4/pe0sMISdLIZIv1wAoDByAv2-C99ycg.ttf';
export const Athiti_400Regular =
  'http://fonts.gstatic.com/s/athiti/v4/pe0vMISdLIZIv1w4DBhWCtaiAg.ttf';
export const Athiti_500Medium =
  'http://fonts.gstatic.com/s/athiti/v4/pe0sMISdLIZIv1wA-DFyAv2-C99ycg.ttf';
export const Athiti_600SemiBold =
  'http://fonts.gstatic.com/s/athiti/v4/pe0sMISdLIZIv1wA1DZyAv2-C99ycg.ttf';
export const Athiti_700Bold =
  'http://fonts.gstatic.com/s/athiti/v4/pe0sMISdLIZIv1wAsDdyAv2-C99ycg.ttf';
export const Atma_300Light = 'http://fonts.gstatic.com/s/atma/v5/uK_z4rqWc-Eoo8JzKjc9PvedRkM.ttf';
export const Atma_400Regular = 'http://fonts.gstatic.com/s/atma/v5/uK_84rqWc-Eom25bDj8WIv4.ttf';
export const Atma_500Medium = 'http://fonts.gstatic.com/s/atma/v5/uK_z4rqWc-Eoo5pyKjc9PvedRkM.ttf';
export const Atma_600SemiBold =
  'http://fonts.gstatic.com/s/atma/v5/uK_z4rqWc-Eoo7Z1Kjc9PvedRkM.ttf';
export const Atma_700Bold = 'http://fonts.gstatic.com/s/atma/v5/uK_z4rqWc-Eoo9J0Kjc9PvedRkM.ttf';
export const BioRhymeExpanded_200ExtraLight =
  'http://fonts.gstatic.com/s/biorhymeexpanded/v5/i7dVIE1zZzytGswgU577CDY9LjbffxxcblSHSdTXrb_z.ttf';
export const BioRhymeExpanded_300Light =
  'http://fonts.gstatic.com/s/biorhymeexpanded/v5/i7dVIE1zZzytGswgU577CDY9Ljbffxw4bVSHSdTXrb_z.ttf';
export const BioRhymeExpanded_400Regular =
  'http://fonts.gstatic.com/s/biorhymeexpanded/v5/i7dQIE1zZzytGswgU577CDY9LjbffySURXCPYsje.ttf';
export const BioRhymeExpanded_700Bold =
  'http://fonts.gstatic.com/s/biorhymeexpanded/v5/i7dVIE1zZzytGswgU577CDY9LjbffxwoalSHSdTXrb_z.ttf';
export const BioRhymeExpanded_800ExtraBold =
  'http://fonts.gstatic.com/s/biorhymeexpanded/v5/i7dVIE1zZzytGswgU577CDY9Ljbffxw0aVSHSdTXrb_z.ttf';
export const Bungee_400Regular =
  'http://fonts.gstatic.com/s/bungee/v5/N0bU2SZBIuF2PU_ECn50Kd_PmA.ttf';
export const BungeeHairline_400Regular =
  'http://fonts.gstatic.com/s/bungeehairline/v5/snfys0G548t04270a_ljTLUVrv-7YB2dQ5ZPqQ.ttf';
export const BungeeInline_400Regular =
  'http://fonts.gstatic.com/s/bungeeinline/v5/Gg8zN58UcgnlCweMrih332VuDGJ1-FEglsc.ttf';
export const BungeeOutline_400Regular =
  'http://fonts.gstatic.com/s/bungeeoutline/v5/_6_mEDvmVP24UvU2MyiGDslL3Qg3YhJqPXxo.ttf';
export const BungeeShade_400Regular =
  'http://fonts.gstatic.com/s/bungeeshade/v5/DtVkJxarWL0t2KdzK3oI_jks7iLSrwFUlw.ttf';
export const Cairo_200ExtraLight =
  'http://fonts.gstatic.com/s/cairo/v6/SLXLc1nY6Hkvalrub76M7dd8aGZk.ttf';
export const Cairo_300Light =
  'http://fonts.gstatic.com/s/cairo/v6/SLXLc1nY6HkvalqKbL6M7dd8aGZk.ttf';
export const Cairo_400Regular = 'http://fonts.gstatic.com/s/cairo/v6/SLXGc1nY6HkvamImRJqExst1.ttf';
export const Cairo_600SemiBold =
  'http://fonts.gstatic.com/s/cairo/v6/SLXLc1nY6Hkvalr-ar6M7dd8aGZk.ttf';
export const Cairo_700Bold = 'http://fonts.gstatic.com/s/cairo/v6/SLXLc1nY6Hkvalqaa76M7dd8aGZk.ttf';
export const Cairo_900Black =
  'http://fonts.gstatic.com/s/cairo/v6/SLXLc1nY6Hkvalqiab6M7dd8aGZk.ttf';
export const Changa_200ExtraLight =
  'http://fonts.gstatic.com/s/changa/v9/2-c79JNi2YuVOUcOarRPgnNGooxCZy2xQjDp9htf1ZM.ttf';
export const Changa_300Light =
  'http://fonts.gstatic.com/s/changa/v9/2-c79JNi2YuVOUcOarRPgnNGooxCZ_OxQjDp9htf1ZM.ttf';
export const Changa_400Regular =
  'http://fonts.gstatic.com/s/changa/v9/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xQjDp9htf1ZM.ttf';
export const Changa_500Medium =
  'http://fonts.gstatic.com/s/changa/v9/2-c79JNi2YuVOUcOarRPgnNGooxCZ5-xQjDp9htf1ZM.ttf';
export const Changa_600SemiBold =
  'http://fonts.gstatic.com/s/changa/v9/2-c79JNi2YuVOUcOarRPgnNGooxCZ3O2QjDp9htf1ZM.ttf';
export const Changa_700Bold =
  'http://fonts.gstatic.com/s/changa/v9/2-c79JNi2YuVOUcOarRPgnNGooxCZ0q2QjDp9htf1ZM.ttf';
export const Changa_800ExtraBold =
  'http://fonts.gstatic.com/s/changa/v9/2-c79JNi2YuVOUcOarRPgnNGooxCZy22QjDp9htf1ZM.ttf';
export const Chathura_100Thin =
  'http://fonts.gstatic.com/s/chathura/v5/_gP91R7-rzUuVjim42dEq0SbTvZyuDo.ttf';
export const Chathura_300Light =
  'http://fonts.gstatic.com/s/chathura/v5/_gP81R7-rzUuVjim42eMiWSxYPp7oSNy.ttf';
export const Chathura_400Regular =
  'http://fonts.gstatic.com/s/chathura/v5/_gP71R7-rzUuVjim418goUC5S-Zy.ttf';
export const Chathura_700Bold =
  'http://fonts.gstatic.com/s/chathura/v5/_gP81R7-rzUuVjim42ecjmSxYPp7oSNy.ttf';
export const Chathura_800ExtraBold =
  'http://fonts.gstatic.com/s/chathura/v5/_gP81R7-rzUuVjim42eAjWSxYPp7oSNy.ttf';
export const Cormorant_300Light =
  'http://fonts.gstatic.com/s/cormorant/v8/H4cgBXOCl9bbnla_nHIiRLmYgoyyYzFzFw.ttf';
export const Cormorant_300Light_Italic =
  'http://fonts.gstatic.com/s/cormorant/v8/H4c-BXOCl9bbnla_nHIq6qMUgIa2QTRjF8ER.ttf';
export const Cormorant_400Regular =
  'http://fonts.gstatic.com/s/cormorant/v8/H4clBXOCl9bbnla_nHIa6JG8iqeuag.ttf';
export const Cormorant_400Regular_Italic =
  'http://fonts.gstatic.com/s/cormorant/v8/H4cjBXOCl9bbnla_nHIq6pu4qKK-aihq.ttf';
export const Cormorant_500Medium =
  'http://fonts.gstatic.com/s/cormorant/v8/H4cgBXOCl9bbnla_nHIiHLiYgoyyYzFzFw.ttf';
export const Cormorant_500Medium_Italic =
  'http://fonts.gstatic.com/s/cormorant/v8/H4c-BXOCl9bbnla_nHIq6qNMgYa2QTRjF8ER.ttf';
export const Cormorant_600SemiBold =
  'http://fonts.gstatic.com/s/cormorant/v8/H4cgBXOCl9bbnla_nHIiML-YgoyyYzFzFw.ttf';
export const Cormorant_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/cormorant/v8/H4c-BXOCl9bbnla_nHIq6qNghoa2QTRjF8ER.ttf';
export const Cormorant_700Bold =
  'http://fonts.gstatic.com/s/cormorant/v8/H4cgBXOCl9bbnla_nHIiVL6YgoyyYzFzFw.ttf';
export const Cormorant_700Bold_Italic =
  'http://fonts.gstatic.com/s/cormorant/v8/H4c-BXOCl9bbnla_nHIq6qMEh4a2QTRjF8ER.ttf';
export const CormorantGaramond_300Light =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQAllvuQWJ5heb_w.ttf';
export const CormorantGaramond_300Light_Italic =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPjuw-NxBKL_y94.ttf';
export const CormorantGaramond_400Regular =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf';
export const CormorantGaramond_400Regular_Italic =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrHtPkyuF7w6C.ttf';
export const CormorantGaramond_500Medium =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQWlhvuQWJ5heb_w.ttf';
export const CormorantGaramond_500Medium_Italic =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEO7ug-NxBKL_y94.ttf';
export const CormorantGaramond_600SemiBold =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQdl9vuQWJ5heb_w.ttf';
export const CormorantGaramond_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEOXvQ-NxBKL_y94.ttf';
export const CormorantGaramond_700Bold =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5vuQWJ5heb_w.ttf';
export const CormorantGaramond_700Bold_Italic =
  'http://fonts.gstatic.com/s/cormorantgaramond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvA-NxBKL_y94.ttf';
export const CormorantInfant_300Light =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyIU44g9vKiM1sORYSiWeAsLN9951w3_DMrQqcdJrk.ttf';
export const CormorantInfant_300Light_Italic =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyKU44g9vKiM1sORYSiWeAsLN997_ItcDEhRoUYNrn_Ig.ttf';
export const CormorantInfant_400Regular =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyPU44g9vKiM1sORYSiWeAsLN993_Af2DsAXq4.ttf';
export const CormorantInfant_400Regular_Italic =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyJU44g9vKiM1sORYSiWeAsLN997_IV3BkFTq4EPw.ttf';
export const CormorantInfant_500Medium =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyIU44g9vKiM1sORYSiWeAsLN995wQ2_DMrQqcdJrk.ttf';
export const CormorantInfant_500Medium_Italic =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyKU44g9vKiM1sORYSiWeAsLN997_ItKDAhRoUYNrn_Ig.ttf';
export const CormorantInfant_600SemiBold =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyIU44g9vKiM1sORYSiWeAsLN995ygx_DMrQqcdJrk.ttf';
export const CormorantInfant_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyKU44g9vKiM1sORYSiWeAsLN997_ItBDchRoUYNrn_Ig.ttf';
export const CormorantInfant_700Bold =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyIU44g9vKiM1sORYSiWeAsLN9950ww_DMrQqcdJrk.ttf';
export const CormorantInfant_700Bold_Italic =
  'http://fonts.gstatic.com/s/cormorantinfant/v8/HhyKU44g9vKiM1sORYSiWeAsLN997_ItYDYhRoUYNrn_Ig.ttf';
export const CormorantSC_300Light =
  'http://fonts.gstatic.com/s/cormorantsc/v8/0ybmGD4kxqXBmOVLG30OGwsmABIU_R3y8DOWGA.ttf';
export const CormorantSC_400Regular =
  'http://fonts.gstatic.com/s/cormorantsc/v8/0yb5GD4kxqXBmOVLG30OGwserDow9Tbu-Q.ttf';
export const CormorantSC_500Medium =
  'http://fonts.gstatic.com/s/cormorantsc/v8/0ybmGD4kxqXBmOVLG30OGwsmWBMU_R3y8DOWGA.ttf';
export const CormorantSC_600SemiBold =
  'http://fonts.gstatic.com/s/cormorantsc/v8/0ybmGD4kxqXBmOVLG30OGwsmdBQU_R3y8DOWGA.ttf';
export const CormorantSC_700Bold =
  'http://fonts.gstatic.com/s/cormorantsc/v8/0ybmGD4kxqXBmOVLG30OGwsmEBUU_R3y8DOWGA.ttf';
export const CormorantUnicase_300Light =
  'http://fonts.gstatic.com/s/cormorantunicase/v8/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9N_tucv7Gy0DRzS.ttf';
export const CormorantUnicase_400Regular =
  'http://fonts.gstatic.com/s/cormorantunicase/v8/HI_QiZUaILtOqhqgDeXoF_n1_fTGX-vTnsMnx3C9.ttf';
export const CormorantUnicase_500Medium =
  'http://fonts.gstatic.com/s/cormorantunicase/v8/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Mnt-cv7Gy0DRzS.ttf';
export const CormorantUnicase_600SemiBold =
  'http://fonts.gstatic.com/s/cormorantunicase/v8/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9MLsOcv7Gy0DRzS.ttf';
export const CormorantUnicase_700Bold =
  'http://fonts.gstatic.com/s/cormorantunicase/v8/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Nvsecv7Gy0DRzS.ttf';
export const CormorantUpright_300Light =
  'http://fonts.gstatic.com/s/cormorantupright/v6/VuJudM3I2Y35poFONtLdafkUCHw1y1N5phDsU9X6RPzQ.ttf';
export const CormorantUpright_400Regular =
  'http://fonts.gstatic.com/s/cormorantupright/v6/VuJrdM3I2Y35poFONtLdafkUCHw1y2vVjjTkeMnz.ttf';
export const CormorantUpright_500Medium =
  'http://fonts.gstatic.com/s/cormorantupright/v6/VuJudM3I2Y35poFONtLdafkUCHw1y1MhpxDsU9X6RPzQ.ttf';
export const CormorantUpright_600SemiBold =
  'http://fonts.gstatic.com/s/cormorantupright/v6/VuJudM3I2Y35poFONtLdafkUCHw1y1MNoBDsU9X6RPzQ.ttf';
export const CormorantUpright_700Bold =
  'http://fonts.gstatic.com/s/cormorantupright/v6/VuJudM3I2Y35poFONtLdafkUCHw1y1NpoRDsU9X6RPzQ.ttf';
export const DavidLibre_400Regular =
  'http://fonts.gstatic.com/s/davidlibre/v4/snfus0W_99N64iuYSvp4W_l86p6TYS-Y.ttf';
export const DavidLibre_500Medium =
  'http://fonts.gstatic.com/s/davidlibre/v4/snfzs0W_99N64iuYSvp4W8GIw7qbSjORSo9W.ttf';
export const DavidLibre_700Bold =
  'http://fonts.gstatic.com/s/davidlibre/v4/snfzs0W_99N64iuYSvp4W8HAxbqbSjORSo9W.ttf';
export const Harmattan_400Regular =
  'http://fonts.gstatic.com/s/harmattan/v6/goksH6L2DkFvVvRp9XpTS0CjkP1Yog.ttf';
export const Heebo_100Thin = 'http://fonts.gstatic.com/s/heebo/v5/NGS0v5_NC0k9P9mVTbRhtKMByaw.ttf';
export const Heebo_300Light =
  'http://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9ldb5RLmq8I0LVF.ttf';
export const Heebo_400Regular = 'http://fonts.gstatic.com/s/heebo/v5/NGS6v5_NC0k9P-HxR7BDsbMB.ttf';
export const Heebo_500Medium =
  'http://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9kFbpRLmq8I0LVF.ttf';
export const Heebo_700Bold = 'http://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9lNaJRLmq8I0LVF.ttf';
export const Heebo_800ExtraBold =
  'http://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9lRa5RLmq8I0LVF.ttf';
export const Heebo_900Black =
  'http://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9l1apRLmq8I0LVF.ttf';
export const HindGuntur_300Light =
  'http://fonts.gstatic.com/s/hindguntur/v5/wXKyE3UZrok56nvamSuJd_yGn1czn9zaj5Ju.ttf';
export const HindGuntur_400Regular =
  'http://fonts.gstatic.com/s/hindguntur/v5/wXKvE3UZrok56nvamSuJd8Qqt3M7tMDT.ttf';
export const HindGuntur_500Medium =
  'http://fonts.gstatic.com/s/hindguntur/v5/wXKyE3UZrok56nvamSuJd_zenlczn9zaj5Ju.ttf';
export const HindGuntur_600SemiBold =
  'http://fonts.gstatic.com/s/hindguntur/v5/wXKyE3UZrok56nvamSuJd_zymVczn9zaj5Ju.ttf';
export const HindGuntur_700Bold =
  'http://fonts.gstatic.com/s/hindguntur/v5/wXKyE3UZrok56nvamSuJd_yWmFczn9zaj5Ju.ttf';
export const HindMadurai_300Light =
  'http://fonts.gstatic.com/s/hindmadurai/v5/f0Xu0e2p98ZvDXdZQIOcpqjfXaUnecsoMJ0b_g.ttf';
export const HindMadurai_400Regular =
  'http://fonts.gstatic.com/s/hindmadurai/v5/f0Xx0e2p98ZvDXdZQIOcpqjn8Y0DceA0OQ.ttf';
export const HindMadurai_500Medium =
  'http://fonts.gstatic.com/s/hindmadurai/v5/f0Xu0e2p98ZvDXdZQIOcpqjfBaQnecsoMJ0b_g.ttf';
export const HindMadurai_600SemiBold =
  'http://fonts.gstatic.com/s/hindmadurai/v5/f0Xu0e2p98ZvDXdZQIOcpqjfKaMnecsoMJ0b_g.ttf';
export const HindMadurai_700Bold =
  'http://fonts.gstatic.com/s/hindmadurai/v5/f0Xu0e2p98ZvDXdZQIOcpqjfTaInecsoMJ0b_g.ttf';
export const HindSiliguri_300Light =
  'http://fonts.gstatic.com/s/hindsiliguri/v6/ijwOs5juQtsyLLR5jN4cxBEoRDf44uEfKiGvxts.ttf';
export const HindSiliguri_400Regular =
  'http://fonts.gstatic.com/s/hindsiliguri/v6/ijwTs5juQtsyLLR5jN4cxBEofJvQxuk0Nig.ttf';
export const HindSiliguri_500Medium =
  'http://fonts.gstatic.com/s/hindsiliguri/v6/ijwOs5juQtsyLLR5jN4cxBEoRG_54uEfKiGvxts.ttf';
export const HindSiliguri_600SemiBold =
  'http://fonts.gstatic.com/s/hindsiliguri/v6/ijwOs5juQtsyLLR5jN4cxBEoREP-4uEfKiGvxts.ttf';
export const HindSiliguri_700Bold =
  'http://fonts.gstatic.com/s/hindsiliguri/v6/ijwOs5juQtsyLLR5jN4cxBEoRCf_4uEfKiGvxts.ttf';
export const HindVadodara_300Light =
  'http://fonts.gstatic.com/s/hindvadodara/v6/neIQzCKvrIcn5pbuuuriV9tTSDn3iXM0oSOL2Yw.ttf';
export const HindVadodara_400Regular =
  'http://fonts.gstatic.com/s/hindvadodara/v6/neINzCKvrIcn5pbuuuriV9tTcJXfrXsfvSo.ttf';
export const HindVadodara_500Medium =
  'http://fonts.gstatic.com/s/hindvadodara/v6/neIQzCKvrIcn5pbuuuriV9tTSGH2iXM0oSOL2Yw.ttf';
export const HindVadodara_600SemiBold =
  'http://fonts.gstatic.com/s/hindvadodara/v6/neIQzCKvrIcn5pbuuuriV9tTSE3xiXM0oSOL2Yw.ttf';
export const HindVadodara_700Bold =
  'http://fonts.gstatic.com/s/hindvadodara/v6/neIQzCKvrIcn5pbuuuriV9tTSCnwiXM0oSOL2Yw.ttf';
export const Jomhuria_400Regular =
  'http://fonts.gstatic.com/s/jomhuria/v7/Dxxp8j-TMXf-llKur2b1MOGbC3Dh.ttf';
export const Katibeh_400Regular =
  'http://fonts.gstatic.com/s/katibeh/v7/ZGjXol5MQJog4bxDaC1RVDNdGDs.ttf';
export const KumarOne_400Regular =
  'http://fonts.gstatic.com/s/kumarone/v4/bMr1mS-P958wYi6YaGeGNO6WU3oT0g.ttf';
export const KumarOneOutline_400Regular =
  'http://fonts.gstatic.com/s/kumaroneoutline/v5/Noao6VH62pyLP0fsrZ-v18wlUEcX9zDwRQu8EGKF.ttf';
export const Lalezar_400Regular =
  'http://fonts.gstatic.com/s/lalezar/v6/zrfl0HLVx-HwTP82UaDyIiL0RCg.ttf';
export const Lemonada_300Light =
  'http://fonts.gstatic.com/s/lemonada/v9/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGJOt2mfWc3Z2pTg.ttf';
export const Lemonada_400Regular =
  'http://fonts.gstatic.com/s/lemonada/v9/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeut2mfWc3Z2pTg.ttf';
export const Lemonada_500Medium =
  'http://fonts.gstatic.com/s/lemonada/v9/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGSOt2mfWc3Z2pTg.ttf';
export const Lemonada_600SemiBold =
  'http://fonts.gstatic.com/s/lemonada/v9/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGpOx2mfWc3Z2pTg.ttf';
export const Lemonada_700Bold =
  'http://fonts.gstatic.com/s/lemonada/v9/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGnex2mfWc3Z2pTg.ttf';
export const LibreFranklin_100Thin =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizBREVItHgc8qDIbSTKq4XkRi182zIZj1bIkNo.ttf';
export const LibreFranklin_100Thin_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizHREVItHgc8qDIbSTKq4XkRiUa41YTi3TNgNq55w.ttf';
export const LibreFranklin_200ExtraLight =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizAREVItHgc8qDIbSTKq4XkRi3Q-hIzoVrBicOg.ttf';
export const LibreFranklin_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizGREVItHgc8qDIbSTKq4XkRiUa4_oyq17jjNOg_oc.ttf';
export const LibreFranklin_300Light =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizAREVItHgc8qDIbSTKq4XkRi20-RIzoVrBicOg.ttf';
export const LibreFranklin_300Light_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizGREVItHgc8qDIbSTKq4XkRiUa454xq17jjNOg_oc.ttf';
export const LibreFranklin_400Regular =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizDREVItHgc8qDIbSTKq4XkRhUY0TY7ikbI.ttf';
export const LibreFranklin_400Regular_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizBREVItHgc8qDIbSTKq4XkRiUa2zIZj1bIkNo.ttf';
export const LibreFranklin_500Medium =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizAREVItHgc8qDIbSTKq4XkRi3s-BIzoVrBicOg.ttf';
export const LibreFranklin_500Medium_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizGREVItHgc8qDIbSTKq4XkRiUa48Ywq17jjNOg_oc.ttf';
export const LibreFranklin_600SemiBold =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizAREVItHgc8qDIbSTKq4XkRi3A_xIzoVrBicOg.ttf';
export const LibreFranklin_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizGREVItHgc8qDIbSTKq4XkRiUa4-o3q17jjNOg_oc.ttf';
export const LibreFranklin_700Bold =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizAREVItHgc8qDIbSTKq4XkRi2k_hIzoVrBicOg.ttf';
export const LibreFranklin_700Bold_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizGREVItHgc8qDIbSTKq4XkRiUa4442q17jjNOg_oc.ttf';
export const LibreFranklin_800ExtraBold =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizAREVItHgc8qDIbSTKq4XkRi24_RIzoVrBicOg.ttf';
export const LibreFranklin_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizGREVItHgc8qDIbSTKq4XkRiUa45I1q17jjNOg_oc.ttf';
export const LibreFranklin_900Black =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizAREVItHgc8qDIbSTKq4XkRi2c_BIzoVrBicOg.ttf';
export const LibreFranklin_900Black_Italic =
  'http://fonts.gstatic.com/s/librefranklin/v4/jizGREVItHgc8qDIbSTKq4XkRiUa47Y0q17jjNOg_oc.ttf';
export const Maitree_200ExtraLight =
  'http://fonts.gstatic.com/s/maitree/v4/MjQDmil5tffhpBrklhGNWJGovLdh6OE.ttf';
export const Maitree_300Light =
  'http://fonts.gstatic.com/s/maitree/v4/MjQDmil5tffhpBrklnWOWJGovLdh6OE.ttf';
export const Maitree_400Regular =
  'http://fonts.gstatic.com/s/maitree/v4/MjQGmil5tffhpBrkrtmmfJmDoL4.ttf';
export const Maitree_500Medium =
  'http://fonts.gstatic.com/s/maitree/v4/MjQDmil5tffhpBrkli2PWJGovLdh6OE.ttf';
export const Maitree_600SemiBold =
  'http://fonts.gstatic.com/s/maitree/v4/MjQDmil5tffhpBrklgGIWJGovLdh6OE.ttf';
export const Maitree_700Bold =
  'http://fonts.gstatic.com/s/maitree/v4/MjQDmil5tffhpBrklmWJWJGovLdh6OE.ttf';
export const Mirza_400Regular = 'http://fonts.gstatic.com/s/mirza/v7/co3ImWlikiN5EurdKMewsrvI.ttf';
export const Mirza_500Medium =
  'http://fonts.gstatic.com/s/mirza/v7/co3FmWlikiN5EtIpAeO4mafBomDi.ttf';
export const Mirza_600SemiBold =
  'http://fonts.gstatic.com/s/mirza/v7/co3FmWlikiN5EtIFBuO4mafBomDi.ttf';
export const Mirza_700Bold = 'http://fonts.gstatic.com/s/mirza/v7/co3FmWlikiN5EtJhB-O4mafBomDi.ttf';
export const Mitr_200ExtraLight =
  'http://fonts.gstatic.com/s/mitr/v5/pxiEypw5ucZF8fMZFJDUc1NECPY.ttf';
export const Mitr_300Light = 'http://fonts.gstatic.com/s/mitr/v5/pxiEypw5ucZF8ZcaFJDUc1NECPY.ttf';
export const Mitr_400Regular = 'http://fonts.gstatic.com/s/mitr/v5/pxiLypw5ucZFyTsyMJj_b1o.ttf';
export const Mitr_500Medium = 'http://fonts.gstatic.com/s/mitr/v5/pxiEypw5ucZF8c8bFJDUc1NECPY.ttf';
export const Mitr_600SemiBold =
  'http://fonts.gstatic.com/s/mitr/v5/pxiEypw5ucZF8eMcFJDUc1NECPY.ttf';
export const Mitr_700Bold = 'http://fonts.gstatic.com/s/mitr/v5/pxiEypw5ucZF8YcdFJDUc1NECPY.ttf';
export const Mogra_400Regular = 'http://fonts.gstatic.com/s/mogra/v6/f0X40eSs8c95TBo4DvLmxtnG.ttf';
export const MuktaVaani_200ExtraLight =
  'http://fonts.gstatic.com/s/muktavaani/v7/3JnkSD_-ynaxmxnEfVHPIGXNV8BD-u97MW1a.ttf';
export const MuktaVaani_300Light =
  'http://fonts.gstatic.com/s/muktavaani/v7/3JnkSD_-ynaxmxnEfVHPIGWpVMBD-u97MW1a.ttf';
export const MuktaVaani_400Regular =
  'http://fonts.gstatic.com/s/muktavaani/v7/3Jn5SD_-ynaxmxnEfVHPIF0FfORL0fNy.ttf';
export const MuktaVaani_500Medium =
  'http://fonts.gstatic.com/s/muktavaani/v7/3JnkSD_-ynaxmxnEfVHPIGXxVcBD-u97MW1a.ttf';
export const MuktaVaani_600SemiBold =
  'http://fonts.gstatic.com/s/muktavaani/v7/3JnkSD_-ynaxmxnEfVHPIGXdUsBD-u97MW1a.ttf';
export const MuktaVaani_700Bold =
  'http://fonts.gstatic.com/s/muktavaani/v7/3JnkSD_-ynaxmxnEfVHPIGW5U8BD-u97MW1a.ttf';
export const MuktaVaani_800ExtraBold =
  'http://fonts.gstatic.com/s/muktavaani/v7/3JnkSD_-ynaxmxnEfVHPIGWlUMBD-u97MW1a.ttf';
export const Pavanam_400Regular =
  'http://fonts.gstatic.com/s/pavanam/v4/BXRrvF_aiezLh0xPDOtQ9Wf0QcE.ttf';
export const Pridi_200ExtraLight =
  'http://fonts.gstatic.com/s/pridi/v5/2sDdZG5JnZLfkc1SiE0jRUG0AqUc.ttf';
export const Pridi_300Light =
  'http://fonts.gstatic.com/s/pridi/v5/2sDdZG5JnZLfkc02i00jRUG0AqUc.ttf';
export const Pridi_400Regular = 'http://fonts.gstatic.com/s/pridi/v5/2sDQZG5JnZLfkfWao2krbl29.ttf';
export const Pridi_500Medium =
  'http://fonts.gstatic.com/s/pridi/v5/2sDdZG5JnZLfkc1uik0jRUG0AqUc.ttf';
export const Pridi_600SemiBold =
  'http://fonts.gstatic.com/s/pridi/v5/2sDdZG5JnZLfkc1CjU0jRUG0AqUc.ttf';
export const Pridi_700Bold = 'http://fonts.gstatic.com/s/pridi/v5/2sDdZG5JnZLfkc0mjE0jRUG0AqUc.ttf';
export const Prompt_100Thin =
  'http://fonts.gstatic.com/s/prompt/v4/-W_9XJnvUD7dzB2CA9oYREcjeo0k.ttf';
export const Prompt_100Thin_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_7XJnvUD7dzB2KZeJ8TkMBf50kbiM.ttf';
export const Prompt_200ExtraLight =
  'http://fonts.gstatic.com/s/prompt/v4/-W_8XJnvUD7dzB2Cr_s4bmkvc5Q9dw.ttf';
export const Prompt_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_6XJnvUD7dzB2KZeLQb2MrUZEtdzow.ttf';
export const Prompt_300Light =
  'http://fonts.gstatic.com/s/prompt/v4/-W_8XJnvUD7dzB2Cy_g4bmkvc5Q9dw.ttf';
export const Prompt_300Light_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_6XJnvUD7dzB2KZeK0bGMrUZEtdzow.ttf';
export const Prompt_400Regular =
  'http://fonts.gstatic.com/s/prompt/v4/-W__XJnvUD7dzB26Z9AcZkIzeg.ttf';
export const Prompt_400Regular_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_9XJnvUD7dzB2KZdoYREcjeo0k.ttf';
export const Prompt_500Medium =
  'http://fonts.gstatic.com/s/prompt/v4/-W_8XJnvUD7dzB2Ck_k4bmkvc5Q9dw.ttf';
export const Prompt_500Medium_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_6XJnvUD7dzB2KZeLsbWMrUZEtdzow.ttf';
export const Prompt_600SemiBold =
  'http://fonts.gstatic.com/s/prompt/v4/-W_8XJnvUD7dzB2Cv_44bmkvc5Q9dw.ttf';
export const Prompt_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_6XJnvUD7dzB2KZeLAamMrUZEtdzow.ttf';
export const Prompt_700Bold =
  'http://fonts.gstatic.com/s/prompt/v4/-W_8XJnvUD7dzB2C2_84bmkvc5Q9dw.ttf';
export const Prompt_700Bold_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_6XJnvUD7dzB2KZeKka2MrUZEtdzow.ttf';
export const Prompt_800ExtraBold =
  'http://fonts.gstatic.com/s/prompt/v4/-W_8XJnvUD7dzB2Cx_w4bmkvc5Q9dw.ttf';
export const Prompt_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_6XJnvUD7dzB2KZeK4aGMrUZEtdzow.ttf';
export const Prompt_900Black =
  'http://fonts.gstatic.com/s/prompt/v4/-W_8XJnvUD7dzB2C4_04bmkvc5Q9dw.ttf';
export const Prompt_900Black_Italic =
  'http://fonts.gstatic.com/s/prompt/v4/-W_6XJnvUD7dzB2KZeKcaWMrUZEtdzow.ttf';
export const ProzaLibre_400Regular =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjGdGHgj0k1DIQRyUEyyHovftvXWYyz.ttf';
export const ProzaLibre_400Regular_Italic =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjEdGHgj0k1DIQRyUEyyEotdN_1XJyz7zc.ttf';
export const ProzaLibre_500Medium =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjbdGHgj0k1DIQRyUEyyELbV__fcpC69i6N.ttf';
export const ProzaLibre_500Medium_Italic =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjZdGHgj0k1DIQRyUEyyEotTCvceJSY8z6Np1k.ttf';
export const ProzaLibre_600SemiBold =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjbdGHgj0k1DIQRyUEyyEL3UP_fcpC69i6N.ttf';
export const ProzaLibre_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjZdGHgj0k1DIQRyUEyyEotTAfbeJSY8z6Np1k.ttf';
export const ProzaLibre_700Bold =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjbdGHgj0k1DIQRyUEyyEKTUf_fcpC69i6N.ttf';
export const ProzaLibre_700Bold_Italic =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjZdGHgj0k1DIQRyUEyyEotTGPaeJSY8z6Np1k.ttf';
export const ProzaLibre_800ExtraBold =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjbdGHgj0k1DIQRyUEyyEKPUv_fcpC69i6N.ttf';
export const ProzaLibre_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/prozalibre/v4/LYjZdGHgj0k1DIQRyUEyyEotTH_ZeJSY8z6Np1k.ttf';
export const Rakkas_400Regular =
  'http://fonts.gstatic.com/s/rakkas/v7/Qw3cZQlNHiblL3j_lttPOeMcCw.ttf';
export const Rasa_300Light = 'http://fonts.gstatic.com/s/rasa/v5/xn7gYHIn1mWmdg52sgC7S9XdZN8.ttf';
export const Rasa_400Regular = 'http://fonts.gstatic.com/s/rasa/v5/xn7vYHIn1mWmTqJelgiQV9w.ttf';
export const Rasa_500Medium = 'http://fonts.gstatic.com/s/rasa/v5/xn7gYHIn1mWmdlZ3sgC7S9XdZN8.ttf';
export const Rasa_600SemiBold =
  'http://fonts.gstatic.com/s/rasa/v5/xn7gYHIn1mWmdnpwsgC7S9XdZN8.ttf';
export const Rasa_700Bold = 'http://fonts.gstatic.com/s/rasa/v5/xn7gYHIn1mWmdh5xsgC7S9XdZN8.ttf';
export const ScopeOne_400Regular =
  'http://fonts.gstatic.com/s/scopeone/v6/WBLnrEXKYFlGHrOKmGD1W0_MJMGxiQ.ttf';
export const Shrikhand_400Regular =
  'http://fonts.gstatic.com/s/shrikhand/v5/a8IbNovtLWfR7T7bMJwbBIiQ0zhMtA.ttf';
export const SpaceMono_400Regular =
  'http://fonts.gstatic.com/s/spacemono/v5/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf';
export const SpaceMono_400Regular_Italic =
  'http://fonts.gstatic.com/s/spacemono/v5/i7dNIFZifjKcF5UAWdDRYER8QHi-EwWMbg.ttf';
export const SpaceMono_700Bold =
  'http://fonts.gstatic.com/s/spacemono/v5/i7dMIFZifjKcF5UAWdDRaPpZYFKQHwyVd3U.ttf';
export const SpaceMono_700Bold_Italic =
  'http://fonts.gstatic.com/s/spacemono/v5/i7dSIFZifjKcF5UAWdDRYERE_FeaGy6QZ3WfYg.ttf';
export const Taviraj_100Thin =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcbv8Cj3ylylTXzRIorV8N1jU2gog.ttf';
export const Taviraj_100Thin_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcdv8Cj3ylylTXzTOwTM8lxr0iwolLl.ttf';
export const Taviraj_200ExtraLight =
  'http://fonts.gstatic.com/s/taviraj/v5/ahccv8Cj3ylylTXzRCYKd-lbgUS5u0s.ttf';
export const Taviraj_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcev8Cj3ylylTXzTOwTn-hRhWa8q0v8ag.ttf';
export const Taviraj_300Light =
  'http://fonts.gstatic.com/s/taviraj/v5/ahccv8Cj3ylylTXzREIJd-lbgUS5u0s.ttf';
export const Taviraj_300Light_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcev8Cj3ylylTXzTOwT--tRhWa8q0v8ag.ttf';
export const Taviraj_400Regular =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcZv8Cj3ylylTXzfO4hU-FwnU0.ttf';
export const Taviraj_400Regular_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcbv8Cj3ylylTXzTOwrV8N1jU2gog.ttf';
export const Taviraj_500Medium =
  'http://fonts.gstatic.com/s/taviraj/v5/ahccv8Cj3ylylTXzRBoId-lbgUS5u0s.ttf';
export const Taviraj_500Medium_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcev8Cj3ylylTXzTOwTo-pRhWa8q0v8ag.ttf';
export const Taviraj_600SemiBold =
  'http://fonts.gstatic.com/s/taviraj/v5/ahccv8Cj3ylylTXzRDYPd-lbgUS5u0s.ttf';
export const Taviraj_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcev8Cj3ylylTXzTOwTj-1RhWa8q0v8ag.ttf';
export const Taviraj_700Bold =
  'http://fonts.gstatic.com/s/taviraj/v5/ahccv8Cj3ylylTXzRFIOd-lbgUS5u0s.ttf';
export const Taviraj_700Bold_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcev8Cj3ylylTXzTOwT6-xRhWa8q0v8ag.ttf';
export const Taviraj_800ExtraBold =
  'http://fonts.gstatic.com/s/taviraj/v5/ahccv8Cj3ylylTXzRE4Nd-lbgUS5u0s.ttf';
export const Taviraj_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcev8Cj3ylylTXzTOwT9-9RhWa8q0v8ag.ttf';
export const Taviraj_900Black =
  'http://fonts.gstatic.com/s/taviraj/v5/ahccv8Cj3ylylTXzRGoMd-lbgUS5u0s.ttf';
export const Taviraj_900Black_Italic =
  'http://fonts.gstatic.com/s/taviraj/v5/ahcev8Cj3ylylTXzTOwT0-5RhWa8q0v8ag.ttf';
export const Trirong_100Thin =
  'http://fonts.gstatic.com/s/trirong/v5/7r3EqXNgp8wxdOdOl-go3YRl6ujngw.ttf';
export const Trirong_100Thin_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3CqXNgp8wxdOdOn44QuY5hyO33g8IY.ttf';
export const Trirong_200ExtraLight =
  'http://fonts.gstatic.com/s/trirong/v5/7r3DqXNgp8wxdOdOl0QJ_a5L5uH-mts.ttf';
export const Trirong_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3BqXNgp8wxdOdOn44QFa9B4sP7itsB5g.ttf';
export const Trirong_300Light =
  'http://fonts.gstatic.com/s/trirong/v5/7r3DqXNgp8wxdOdOlyAK_a5L5uH-mts.ttf';
export const Trirong_300Light_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3BqXNgp8wxdOdOn44QcaxB4sP7itsB5g.ttf';
export const Trirong_400Regular =
  'http://fonts.gstatic.com/s/trirong/v5/7r3GqXNgp8wxdOdOr4wi2aZg-ug.ttf';
export const Trirong_400Regular_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3EqXNgp8wxdOdOn44o3YRl6ujngw.ttf';
export const Trirong_500Medium =
  'http://fonts.gstatic.com/s/trirong/v5/7r3DqXNgp8wxdOdOl3gL_a5L5uH-mts.ttf';
export const Trirong_500Medium_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3BqXNgp8wxdOdOn44QKa1B4sP7itsB5g.ttf';
export const Trirong_600SemiBold =
  'http://fonts.gstatic.com/s/trirong/v5/7r3DqXNgp8wxdOdOl1QM_a5L5uH-mts.ttf';
export const Trirong_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3BqXNgp8wxdOdOn44QBapB4sP7itsB5g.ttf';
export const Trirong_700Bold =
  'http://fonts.gstatic.com/s/trirong/v5/7r3DqXNgp8wxdOdOlzAN_a5L5uH-mts.ttf';
export const Trirong_700Bold_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3BqXNgp8wxdOdOn44QYatB4sP7itsB5g.ttf';
export const Trirong_800ExtraBold =
  'http://fonts.gstatic.com/s/trirong/v5/7r3DqXNgp8wxdOdOlywO_a5L5uH-mts.ttf';
export const Trirong_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3BqXNgp8wxdOdOn44QfahB4sP7itsB5g.ttf';
export const Trirong_900Black =
  'http://fonts.gstatic.com/s/trirong/v5/7r3DqXNgp8wxdOdOlwgP_a5L5uH-mts.ttf';
export const Trirong_900Black_Italic =
  'http://fonts.gstatic.com/s/trirong/v5/7r3BqXNgp8wxdOdOn44QWalB4sP7itsB5g.ttf';
export const YatraOne_400Regular =
  'http://fonts.gstatic.com/s/yatraone/v6/C8ch4copsHzj8p7NaF0xw1OBbRDvXw.ttf';
export const Yrsa_300Light = 'http://fonts.gstatic.com/s/yrsa/v5/wlpxgwnQFlxs3af93IQ73W5OcCk.ttf';
export const Yrsa_400Regular = 'http://fonts.gstatic.com/s/yrsa/v5/wlp-gwnQFlxs5QvV-IwQwWc.ttf';
export const Yrsa_500Medium = 'http://fonts.gstatic.com/s/yrsa/v5/wlpxgwnQFlxs3f_83IQ73W5OcCk.ttf';
export const Yrsa_600SemiBold =
  'http://fonts.gstatic.com/s/yrsa/v5/wlpxgwnQFlxs3dP73IQ73W5OcCk.ttf';
export const Yrsa_700Bold = 'http://fonts.gstatic.com/s/yrsa/v5/wlpxgwnQFlxs3bf63IQ73W5OcCk.ttf';
export const ElMessiri_400Regular =
  'http://fonts.gstatic.com/s/elmessiri/v6/K2F0fZBRmr9vQ1pHEey6AoqKAyLzfWo.ttf';
export const ElMessiri_500Medium =
  'http://fonts.gstatic.com/s/elmessiri/v6/K2F3fZBRmr9vQ1pHEey6On6jJyrYYWOMluQ.ttf';
export const ElMessiri_600SemiBold =
  'http://fonts.gstatic.com/s/elmessiri/v6/K2F3fZBRmr9vQ1pHEey6OlKkJyrYYWOMluQ.ttf';
export const ElMessiri_700Bold =
  'http://fonts.gstatic.com/s/elmessiri/v6/K2F3fZBRmr9vQ1pHEey6OjalJyrYYWOMluQ.ttf';
export const MeeraInimai_400Regular =
  'http://fonts.gstatic.com/s/meerainimai/v4/845fNMM5EIqOW5MPuvO3ILep_2jDVevnLQ.ttf';
export const Pattaya_400Regular =
  'http://fonts.gstatic.com/s/pattaya/v5/ea8ZadcqV_zkHY-XNdCn92ZEmVs.ttf';
export const ReemKufi_400Regular =
  'http://fonts.gstatic.com/s/reemkufi/v7/2sDcZGJLip7W2J7v7wQDb2-4C7wFZQ.ttf';
export const Chilanka_400Regular =
  'http://fonts.gstatic.com/s/chilanka/v5/WWXRlj2DZQiMJYaYRrJQI9EAZhTO.ttf';
export const Assistant_200ExtraLight =
  'http://fonts.gstatic.com/s/assistant/v4/2sDZZGJYnIjSi6H75xk7p0ScA5cZbCjItw.ttf';
export const Assistant_300Light =
  'http://fonts.gstatic.com/s/assistant/v4/2sDZZGJYnIjSi6H75xk7w0ecA5cZbCjItw.ttf';
export const Assistant_400Regular =
  'http://fonts.gstatic.com/s/assistant/v4/2sDcZGJYnIjSi6H75xkDb2-4C7wFZQ.ttf';
export const Assistant_600SemiBold =
  'http://fonts.gstatic.com/s/assistant/v4/2sDZZGJYnIjSi6H75xk7t0GcA5cZbCjItw.ttf';
export const Assistant_700Bold =
  'http://fonts.gstatic.com/s/assistant/v4/2sDZZGJYnIjSi6H75xk700CcA5cZbCjItw.ttf';
export const Assistant_800ExtraBold =
  'http://fonts.gstatic.com/s/assistant/v4/2sDZZGJYnIjSi6H75xk7z0OcA5cZbCjItw.ttf';
export const SecularOne_400Regular =
  'http://fonts.gstatic.com/s/secularone/v4/8QINdiTajsj_87rMuMdKypDlMul7LJpK.ttf';
export const SuezOne_400Regular =
  'http://fonts.gstatic.com/s/suezone/v4/taiJGmd_EZ6rqscQgNFJkIqg-I0w.ttf';
export const Amiko_400Regular = 'http://fonts.gstatic.com/s/amiko/v4/WwkQxPq1DFK04tqlc17MMZgJ.ttf';
export const Amiko_600SemiBold =
  'http://fonts.gstatic.com/s/amiko/v4/WwkdxPq1DFK04uJ9XXrEGoQAUco5.ttf';
export const Amiko_700Bold = 'http://fonts.gstatic.com/s/amiko/v4/WwkdxPq1DFK04uIZXHrEGoQAUco5.ttf';
export const BioRhyme_200ExtraLight =
  'http://fonts.gstatic.com/s/biorhyme/v4/1cX3aULHBpDMsHYW_ESOjnGAq8Sk1PoH.ttf';
export const BioRhyme_300Light =
  'http://fonts.gstatic.com/s/biorhyme/v4/1cX3aULHBpDMsHYW_ETqjXGAq8Sk1PoH.ttf';
export const BioRhyme_400Regular =
  'http://fonts.gstatic.com/s/biorhyme/v4/1cXwaULHBpDMsHYW_HxGpVWIgNit.ttf';
export const BioRhyme_700Bold =
  'http://fonts.gstatic.com/s/biorhyme/v4/1cX3aULHBpDMsHYW_ET6inGAq8Sk1PoH.ttf';
export const BioRhyme_800ExtraBold =
  'http://fonts.gstatic.com/s/biorhyme/v4/1cX3aULHBpDMsHYW_ETmiXGAq8Sk1PoH.ttf';
export const Kosugi_400Regular =
  'http://fonts.gstatic.com/s/kosugi/v6/pxiFyp4_v8FCjlI4NLr6f1pdEQ.ttf';
export const KosugiMaru_400Regular =
  'http://fonts.gstatic.com/s/kosugimaru/v6/0nksC9PgP_wGh21A2KeqGiTqivr9iBq_.ttf';
export const Baloo2_400Regular =
  'http://fonts.gstatic.com/s/baloo2/v1/wXKrE3kTposypRyd11_WAewrhXY.ttf';
export const Baloo2_500Medium =
  'http://fonts.gstatic.com/s/baloo2/v1/wXKuE3kTposypRyd76v_JeQAmX8yrdk.ttf';
export const Baloo2_600SemiBold =
  'http://fonts.gstatic.com/s/baloo2/v1/wXKuE3kTposypRyd74f4JeQAmX8yrdk.ttf';
export const Baloo2_700Bold =
  'http://fonts.gstatic.com/s/baloo2/v1/wXKuE3kTposypRyd7-P5JeQAmX8yrdk.ttf';
export const Baloo2_800ExtraBold =
  'http://fonts.gstatic.com/s/baloo2/v1/wXKuE3kTposypRyd7__6JeQAmX8yrdk.ttf';
export const BalooBhai2_400Regular =
  'http://fonts.gstatic.com/s/baloobhai2/v1/sZlDdRSL-z1VEWZ4YNA7Y5I3cdTmiH1gFQ.ttf';
export const BalooBhai2_500Medium =
  'http://fonts.gstatic.com/s/baloobhai2/v1/sZlcdRSL-z1VEWZ4YNA7Y5IPhf3CgFZ8HNV3Nw.ttf';
export const BalooBhai2_600SemiBold =
  'http://fonts.gstatic.com/s/baloobhai2/v1/sZlcdRSL-z1VEWZ4YNA7Y5IPqfrCgFZ8HNV3Nw.ttf';
export const BalooBhai2_700Bold =
  'http://fonts.gstatic.com/s/baloobhai2/v1/sZlcdRSL-z1VEWZ4YNA7Y5IPzfvCgFZ8HNV3Nw.ttf';
export const BalooBhai2_800ExtraBold =
  'http://fonts.gstatic.com/s/baloobhai2/v1/sZlcdRSL-z1VEWZ4YNA7Y5IP0fjCgFZ8HNV3Nw.ttf';
export const BalooBhaina2_400Regular =
  'http://fonts.gstatic.com/s/baloobhaina2/v1/qWczB6yyq4P9Adr3RtoX1q6yShz7mDUoupoI.ttf';
export const BalooBhaina2_500Medium =
  'http://fonts.gstatic.com/s/baloobhaina2/v1/qWcwB6yyq4P9Adr3RtoX1q6ySiQPsREgkYYBX_3F.ttf';
export const BalooBhaina2_600SemiBold =
  'http://fonts.gstatic.com/s/baloobhaina2/v1/qWcwB6yyq4P9Adr3RtoX1q6ySiQjthEgkYYBX_3F.ttf';
export const BalooBhaina2_700Bold =
  'http://fonts.gstatic.com/s/baloobhaina2/v1/qWcwB6yyq4P9Adr3RtoX1q6ySiRHtxEgkYYBX_3F.ttf';
export const BalooBhaina2_800ExtraBold =
  'http://fonts.gstatic.com/s/baloobhaina2/v1/qWcwB6yyq4P9Adr3RtoX1q6ySiRbtBEgkYYBX_3F.ttf';
export const BalooChettan2_400Regular =
  'http://fonts.gstatic.com/s/baloochettan2/v1/vm8udRbmXEva26PK-NtuX4ynWEzf4P17OpYDlg.ttf';
export const BalooChettan2_500Medium =
  'http://fonts.gstatic.com/s/baloochettan2/v1/vm8rdRbmXEva26PK-NtuX4ynWEznFNRfMr0fn5bhCA.ttf';
export const BalooChettan2_600SemiBold =
  'http://fonts.gstatic.com/s/baloochettan2/v1/vm8rdRbmXEva26PK-NtuX4ynWEznONNfMr0fn5bhCA.ttf';
export const BalooChettan2_700Bold =
  'http://fonts.gstatic.com/s/baloochettan2/v1/vm8rdRbmXEva26PK-NtuX4ynWEznXNJfMr0fn5bhCA.ttf';
export const BalooChettan2_800ExtraBold =
  'http://fonts.gstatic.com/s/baloochettan2/v1/vm8rdRbmXEva26PK-NtuX4ynWEznQNFfMr0fn5bhCA.ttf';
export const BalooDa2_400Regular =
  'http://fonts.gstatic.com/s/balooda2/v1/2-ci9J9j0IaUMQZwAJyJcu7XoZFDf2Q.ttf';
export const BalooDa2_500Medium =
  'http://fonts.gstatic.com/s/balooda2/v1/2-ch9J9j0IaUMQZwAJyJShr-hZloY23zejE.ttf';
export const BalooDa2_600SemiBold =
  'http://fonts.gstatic.com/s/balooda2/v1/2-ch9J9j0IaUMQZwAJyJSjb5hZloY23zejE.ttf';
export const BalooDa2_700Bold =
  'http://fonts.gstatic.com/s/balooda2/v1/2-ch9J9j0IaUMQZwAJyJSlL4hZloY23zejE.ttf';
export const BalooDa2_800ExtraBold =
  'http://fonts.gstatic.com/s/balooda2/v1/2-ch9J9j0IaUMQZwAJyJSk77hZloY23zejE.ttf';
export const BalooPaaji2_400Regular =
  'http://fonts.gstatic.com/s/baloopaaji2/v1/i7dMIFFzbz-QHZUdV9_UGWZuYFKQHwyVd3U.ttf';
export const BalooPaaji2_500Medium =
  'http://fonts.gstatic.com/s/baloopaaji2/v1/i7dRIFFzbz-QHZUdV9_UGWZuWKa5OwS-a3yGe9E.ttf';
export const BalooPaaji2_600SemiBold =
  'http://fonts.gstatic.com/s/baloopaaji2/v1/i7dRIFFzbz-QHZUdV9_UGWZuWIq-OwS-a3yGe9E.ttf';
export const BalooPaaji2_700Bold =
  'http://fonts.gstatic.com/s/baloopaaji2/v1/i7dRIFFzbz-QHZUdV9_UGWZuWO6_OwS-a3yGe9E.ttf';
export const BalooPaaji2_800ExtraBold =
  'http://fonts.gstatic.com/s/baloopaaji2/v1/i7dRIFFzbz-QHZUdV9_UGWZuWPK8OwS-a3yGe9E.ttf';
export const BalooTamma2_400Regular =
  'http://fonts.gstatic.com/s/balootamma2/v1/vEFX2_hCAgcR46PaajtrYlBbT0g21tqeR7c.ttf';
export const BalooTamma2_500Medium =
  'http://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd7wf8tK1W77HtMo.ttf';
export const BalooTamma2_600SemiBold =
  'http://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd5AY8tK1W77HtMo.ttf';
export const BalooTamma2_700Bold =
  'http://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd_QZ8tK1W77HtMo.ttf';
export const BalooTamma2_800ExtraBold =
  'http://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd-ga8tK1W77HtMo.ttf';
export const BalooTammudu2_400Regular =
  'http://fonts.gstatic.com/s/balootammudu2/v1/1Pt2g8TIS_SAmkLguUdFP8UaJcK-xXEW6aGXHw.ttf';
export const BalooTammudu2_500Medium =
  'http://fonts.gstatic.com/s/balootammudu2/v1/1Ptzg8TIS_SAmkLguUdFP8UaJcKGMVgy4YqLFrUnJA.ttf';
export const BalooTammudu2_600SemiBold =
  'http://fonts.gstatic.com/s/balootammudu2/v1/1Ptzg8TIS_SAmkLguUdFP8UaJcKGHV8y4YqLFrUnJA.ttf';
export const BalooTammudu2_700Bold =
  'http://fonts.gstatic.com/s/balootammudu2/v1/1Ptzg8TIS_SAmkLguUdFP8UaJcKGeV4y4YqLFrUnJA.ttf';
export const BalooTammudu2_800ExtraBold =
  'http://fonts.gstatic.com/s/balootammudu2/v1/1Ptzg8TIS_SAmkLguUdFP8UaJcKGZV0y4YqLFrUnJA.ttf';
export const BalooThambi2_400Regular =
  'http://fonts.gstatic.com/s/baloothambi2/v1/cY9cfjeOW0NHpmOQXranrbDyu4hHBJOxZQPp.ttf';
export const BalooThambi2_500Medium =
  'http://fonts.gstatic.com/s/baloothambi2/v1/cY9ffjeOW0NHpmOQXranrbDyu7CzLbe5Th_gRA7L.ttf';
export const BalooThambi2_600SemiBold =
  'http://fonts.gstatic.com/s/baloothambi2/v1/cY9ffjeOW0NHpmOQXranrbDyu7CfKre5Th_gRA7L.ttf';
export const BalooThambi2_700Bold =
  'http://fonts.gstatic.com/s/baloothambi2/v1/cY9ffjeOW0NHpmOQXranrbDyu7D7K7e5Th_gRA7L.ttf';
export const BalooThambi2_800ExtraBold =
  'http://fonts.gstatic.com/s/baloothambi2/v1/cY9ffjeOW0NHpmOQXranrbDyu7DnKLe5Th_gRA7L.ttf';
export const Kanit_100Thin = 'http://fonts.gstatic.com/s/kanit/v5/nKKX-Go6G5tXcr72GwWKcaxALFs.ttf';
export const Kanit_100Thin_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKV-Go6G5tXcraQI2GAdY5FPFtrGw.ttf';
export const Kanit_200ExtraLight =
  'http://fonts.gstatic.com/s/kanit/v5/nKKU-Go6G5tXcr5aOiWgX6BJNUJy.ttf';
export const Kanit_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKS-Go6G5tXcraQI82hVaRrMFJyAu4.ttf';
export const Kanit_300Light =
  'http://fonts.gstatic.com/s/kanit/v5/nKKU-Go6G5tXcr4-OSWgX6BJNUJy.ttf';
export const Kanit_300Light_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKS-Go6G5tXcraQI6miVaRrMFJyAu4.ttf';
export const Kanit_400Regular = 'http://fonts.gstatic.com/s/kanit/v5/nKKZ-Go6G5tXcoaSEQGodLxA.ttf';
export const Kanit_400Regular_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKX-Go6G5tXcraQGwWKcaxALFs.ttf';
export const Kanit_500Medium =
  'http://fonts.gstatic.com/s/kanit/v5/nKKU-Go6G5tXcr5mOCWgX6BJNUJy.ttf';
export const Kanit_500Medium_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKS-Go6G5tXcraQI_GjVaRrMFJyAu4.ttf';
export const Kanit_600SemiBold =
  'http://fonts.gstatic.com/s/kanit/v5/nKKU-Go6G5tXcr5KPyWgX6BJNUJy.ttf';
export const Kanit_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKS-Go6G5tXcraQI92kVaRrMFJyAu4.ttf';
export const Kanit_700Bold = 'http://fonts.gstatic.com/s/kanit/v5/nKKU-Go6G5tXcr4uPiWgX6BJNUJy.ttf';
export const Kanit_700Bold_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKS-Go6G5tXcraQI7mlVaRrMFJyAu4.ttf';
export const Kanit_800ExtraBold =
  'http://fonts.gstatic.com/s/kanit/v5/nKKU-Go6G5tXcr4yPSWgX6BJNUJy.ttf';
export const Kanit_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKS-Go6G5tXcraQI6WmVaRrMFJyAu4.ttf';
export const Kanit_900Black =
  'http://fonts.gstatic.com/s/kanit/v5/nKKU-Go6G5tXcr4WPCWgX6BJNUJy.ttf';
export const Kanit_900Black_Italic =
  'http://fonts.gstatic.com/s/kanit/v5/nKKS-Go6G5tXcraQI4GnVaRrMFJyAu4.ttf';
export const Caveat_400Regular =
  'http://fonts.gstatic.com/s/caveat/v7/Wnz6HAc5bAfYB2QLYTwZqg_MPQ.ttf';
export const Caveat_700Bold =
  'http://fonts.gstatic.com/s/caveat/v7/Wnz5HAc5bAfYB2Qz3RM9oiTQNAuxjA.ttf';
export const CaveatBrush_400Regular =
  'http://fonts.gstatic.com/s/caveatbrush/v5/EYq0maZfwr9S9-ETZc3fKXtMW7mT03pdQw.ttf';
export const Rubik_300Light =
  'http://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7Fqj1ma-2HW7ZB_.ttf';
export const Rubik_300Light_Italic =
  'http://fonts.gstatic.com/s/rubik/v9/iJWBBXyIfDnIV7nEldWY8WX06IB_18o.ttf';
export const Rubik_400Regular = 'http://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV4nGp32S0H3f.ttf';
export const Rubik_400Regular_Italic =
  'http://fonts.gstatic.com/s/rubik/v9/iJWEBXyIfDnIV7nErXmw1W3f9Ik.ttf';
export const Rubik_500Medium =
  'http://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7Eyjlma-2HW7ZB_.ttf';
export const Rubik_500Medium_Italic =
  'http://fonts.gstatic.com/s/rubik/v9/iJWBBXyIfDnIV7nElY2Z8WX06IB_18o.ttf';
export const Rubik_700Bold = 'http://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7F6iFma-2HW7ZB_.ttf';
export const Rubik_700Bold_Italic =
  'http://fonts.gstatic.com/s/rubik/v9/iJWBBXyIfDnIV7nElcWf8WX06IB_18o.ttf';
export const Rubik_900Black =
  'http://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7FCilma-2HW7ZB_.ttf';
export const Rubik_900Black_Italic =
  'http://fonts.gstatic.com/s/rubik/v9/iJWBBXyIfDnIV7nElf2d8WX06IB_18o.ttf';
export const Catamaran_100Thin =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0OIpQoyXQa2RxT7-5jhjRFSfiM7HBj.ttf';
export const Catamaran_200ExtraLight =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jKhVlY9aA5Wl6PQ.ttf';
export const Catamaran_300Light =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jThZlY9aA5Wl6PQ.ttf';
export const Catamaran_400Regular =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0IIpQoyXQa2RxT7-5b4j5Ba_2c7A.ttf';
export const Catamaran_500Medium =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jFhdlY9aA5Wl6PQ.ttf';
export const Catamaran_600SemiBold =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jOhBlY9aA5Wl6PQ.ttf';
export const Catamaran_700Bold =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jXhFlY9aA5Wl6PQ.ttf';
export const Catamaran_800ExtraBold =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jQhJlY9aA5Wl6PQ.ttf';
export const Catamaran_900Black =
  'http://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jZhNlY9aA5Wl6PQ.ttf';
export const Chonburi_400Regular =
  'http://fonts.gstatic.com/s/chonburi/v4/8AtqGs-wOpGRTBq66IWaFr3biAfZ.ttf';
export const WorkSans_100Thin =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNigDp6_cOyA.ttf';
export const WorkSans_200ExtraLight =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNigDp6_cOyA.ttf';
export const WorkSans_300Light =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNigDp6_cOyA.ttf';
export const WorkSans_400Regular =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf';
export const WorkSans_500Medium =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf';
export const WorkSans_600SemiBold =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf';
export const WorkSans_700Bold =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNigDp6_cOyA.ttf';
export const WorkSans_800ExtraBold =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNigDp6_cOyA.ttf';
export const WorkSans_900Black =
  'http://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNigDp6_cOyA.ttf';
export const WorkSans_100Thin_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3moJo43ZKyDSQQ.ttf';
export const WorkSans_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmsJo43ZKyDSQQ.ttf';
export const WorkSans_300Light_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUgGsJo43ZKyDSQQ.ttf';
export const WorkSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3msJo43ZKyDSQQ.ttf';
export const WorkSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJo43ZKyDSQQ.ttf';
export const WorkSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUAGwJo43ZKyDSQQ.ttf';
export const WorkSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUOWwJo43ZKyDSQQ.ttf';
export const WorkSans_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJo43ZKyDSQQ.ttf';
export const WorkSans_900Black_Italic =
  'http://fonts.gstatic.com/s/worksans/v7/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUd2wJo43ZKyDSQQ.ttf';
export const Itim_400Regular = 'http://fonts.gstatic.com/s/itim/v4/0nknC9ziJOYewARKkc7ZdwU.ttf';
export const Sriracha_400Regular =
  'http://fonts.gstatic.com/s/sriracha/v4/0nkrC9D4IuYBgWcI9ObYRQDioeb0.ttf';
export const Asar_400Regular = 'http://fonts.gstatic.com/s/asar/v7/sZlLdRyI6TBIXkYQDLlTW6E.ttf';
export const Kadwa_400Regular = 'http://fonts.gstatic.com/s/kadwa/v4/rnCm-x5V0g7iphTHRcc2s2XH.ttf';
export const Kadwa_700Bold = 'http://fonts.gstatic.com/s/kadwa/v4/rnCr-x5V0g7ipix7auM-mHnOSOuk.ttf';
export const Sahitya_400Regular =
  'http://fonts.gstatic.com/s/sahitya/v4/6qLAKZkOuhnuqlJAaScFPywEDnI.ttf';
export const Sahitya_700Bold =
  'http://fonts.gstatic.com/s/sahitya/v4/6qLFKZkOuhnuqlJAUZsqGyQvEnvSexI.ttf';
export const Sarala_400Regular =
  'http://fonts.gstatic.com/s/sarala/v4/uK_y4riEZv4o1w9RCh0TMv6EXw.ttf';
export const Sarala_700Bold =
  'http://fonts.gstatic.com/s/sarala/v4/uK_x4riEZv4o1w9ptjI3OtWYVkMpXA.ttf';
export const Sura_400Regular = 'http://fonts.gstatic.com/s/sura/v4/SZc23FL5PbyzFf5UWzXtjUM.ttf';
export const Sura_700Bold = 'http://fonts.gstatic.com/s/sura/v4/SZc53FL5PbyzLUJ7fz3GkUrS8DI.ttf';
export const Eczar_400Regular = 'http://fonts.gstatic.com/s/eczar/v8/BXRlvF3Pi-DLmw0iBu9y8Hf0.ttf';
export const Eczar_500Medium =
  'http://fonts.gstatic.com/s/eczar/v8/BXRovF3Pi-DLmzXWL8t622v9WNjW.ttf';
export const Eczar_600SemiBold =
  'http://fonts.gstatic.com/s/eczar/v8/BXRovF3Pi-DLmzX6KMt622v9WNjW.ttf';
export const Eczar_700Bold = 'http://fonts.gstatic.com/s/eczar/v8/BXRovF3Pi-DLmzWeKct622v9WNjW.ttf';
export const Eczar_800ExtraBold =
  'http://fonts.gstatic.com/s/eczar/v8/BXRovF3Pi-DLmzWCKst622v9WNjW.ttf';
export const Poppins_100Thin =
  'http://fonts.gstatic.com/s/poppins/v9/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf';
export const Poppins_100Thin_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf';
export const Poppins_200ExtraLight =
  'http://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf';
export const Poppins_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf';
export const Poppins_300Light =
  'http://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf';
export const Poppins_300Light_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf';
export const Poppins_400Regular =
  'http://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf';
export const Poppins_400Regular_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf';
export const Poppins_500Medium =
  'http://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf';
export const Poppins_500Medium_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf';
export const Poppins_600SemiBold =
  'http://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf';
export const Poppins_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf';
export const Poppins_700Bold =
  'http://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf';
export const Poppins_700Bold_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf';
export const Poppins_800ExtraBold =
  'http://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf';
export const Poppins_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf';
export const Poppins_900Black =
  'http://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf';
export const Poppins_900Black_Italic =
  'http://fonts.gstatic.com/s/poppins/v9/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf';
export const RhodiumLibre_400Regular =
  'http://fonts.gstatic.com/s/rhodiumlibre/v4/1q2AY5adA0tn_ukeHcQHqpx6pETLeo2gm2U.ttf';
export const Tillana_400Regular =
  'http://fonts.gstatic.com/s/tillana/v5/VuJxdNvf35P4qJ1OeKbXOIFneRo.ttf';
export const Tillana_500Medium =
  'http://fonts.gstatic.com/s/tillana/v5/VuJ0dNvf35P4qJ1OQFL-HIlMZRNcp0o.ttf';
export const Tillana_600SemiBold =
  'http://fonts.gstatic.com/s/tillana/v5/VuJ0dNvf35P4qJ1OQH75HIlMZRNcp0o.ttf';
export const Tillana_700Bold =
  'http://fonts.gstatic.com/s/tillana/v5/VuJ0dNvf35P4qJ1OQBr4HIlMZRNcp0o.ttf';
export const Tillana_800ExtraBold =
  'http://fonts.gstatic.com/s/tillana/v5/VuJ0dNvf35P4qJ1OQAb7HIlMZRNcp0o.ttf';
export const Yantramanav_100Thin =
  'http://fonts.gstatic.com/s/yantramanav/v5/flU-Rqu5zY00QEpyWJYWN5-QXeNzDB41rZg.ttf';
export const Yantramanav_300Light =
  'http://fonts.gstatic.com/s/yantramanav/v5/flUhRqu5zY00QEpyWJYWN59Yf8NZIhI8tIHh.ttf';
export const Yantramanav_400Regular =
  'http://fonts.gstatic.com/s/yantramanav/v5/flU8Rqu5zY00QEpyWJYWN6f0V-dRCQ41.ttf';
export const Yantramanav_500Medium =
  'http://fonts.gstatic.com/s/yantramanav/v5/flUhRqu5zY00QEpyWJYWN58AfsNZIhI8tIHh.ttf';
export const Yantramanav_700Bold =
  'http://fonts.gstatic.com/s/yantramanav/v5/flUhRqu5zY00QEpyWJYWN59IeMNZIhI8tIHh.ttf';
export const Yantramanav_900Black =
  'http://fonts.gstatic.com/s/yantramanav/v5/flUhRqu5zY00QEpyWJYWN59wesNZIhI8tIHh.ttf';
export const Amita_400Regular = 'http://fonts.gstatic.com/s/amita/v5/HhyaU5si9Om7PQlvAfSKEZZL.ttf';
export const Amita_700Bold = 'http://fonts.gstatic.com/s/amita/v5/HhyXU5si9Om7PTHTLtCCOopCTKkI.ttf';
export const Arya_400Regular = 'http://fonts.gstatic.com/s/arya/v5/ga6CawNG-HJd9Ub1-beqdFE.ttf';
export const Arya_700Bold = 'http://fonts.gstatic.com/s/arya/v5/ga6NawNG-HJdzfra3b-BaFg3dRE.ttf';
export const InknutAntiqua_300Light =
  'http://fonts.gstatic.com/s/inknutantiqua/v5/Y4GRYax7VC4ot_qNB4nYpBdaKU2vwrj5bBoIYJNf.ttf';
export const InknutAntiqua_400Regular =
  'http://fonts.gstatic.com/s/inknutantiqua/v5/Y4GSYax7VC4ot_qNB4nYpBdaKXUD6pzxRwYB.ttf';
export const InknutAntiqua_500Medium =
  'http://fonts.gstatic.com/s/inknutantiqua/v5/Y4GRYax7VC4ot_qNB4nYpBdaKU33w7j5bBoIYJNf.ttf';
export const InknutAntiqua_600SemiBold =
  'http://fonts.gstatic.com/s/inknutantiqua/v5/Y4GRYax7VC4ot_qNB4nYpBdaKU3bxLj5bBoIYJNf.ttf';
export const InknutAntiqua_700Bold =
  'http://fonts.gstatic.com/s/inknutantiqua/v5/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xbj5bBoIYJNf.ttf';
export const InknutAntiqua_800ExtraBold =
  'http://fonts.gstatic.com/s/inknutantiqua/v5/Y4GRYax7VC4ot_qNB4nYpBdaKU2jxrj5bBoIYJNf.ttf';
export const InknutAntiqua_900Black =
  'http://fonts.gstatic.com/s/inknutantiqua/v5/Y4GRYax7VC4ot_qNB4nYpBdaKU2Hx7j5bBoIYJNf.ttf';
export const Kurale_400Regular =
  'http://fonts.gstatic.com/s/kurale/v5/4iCs6KV9e9dXjho6eAT3v02QFg.ttf';
export const RobotoMono_100Thin =
  'http://fonts.gstatic.com/s/robotomono/v7/L0x7DF4xlVMF-BfR8bXMIjAoq3qcW7KCG1w.ttf';
export const RobotoMono_100Thin_Italic =
  'http://fonts.gstatic.com/s/robotomono/v7/L0xlDF4xlVMF-BfR8bXMIjhOkx6WX5CHC1wnFw.ttf';
export const RobotoMono_300Light =
  'http://fonts.gstatic.com/s/robotomono/v7/L0xkDF4xlVMF-BfR8bXMIjDgiVq2db6LAkU-.ttf';
export const RobotoMono_300Light_Italic =
  'http://fonts.gstatic.com/s/robotomono/v7/L0xmDF4xlVMF-BfR8bXMIjhOk9a0f7qpB1U-Drg.ttf';
export const RobotoMono_400Regular =
  'http://fonts.gstatic.com/s/robotomono/v7/L0x5DF4xlVMF-BfR8bXMIghMoX6-XqKC.ttf';
export const RobotoMono_400Regular_Italic =
  'http://fonts.gstatic.com/s/robotomono/v7/L0x7DF4xlVMF-BfR8bXMIjhOq3qcW7KCG1w.ttf';
export const RobotoMono_500Medium =
  'http://fonts.gstatic.com/s/robotomono/v7/L0xkDF4xlVMF-BfR8bXMIjC4iFq2db6LAkU-.ttf';
export const RobotoMono_500Medium_Italic =
  'http://fonts.gstatic.com/s/robotomono/v7/L0xmDF4xlVMF-BfR8bXMIjhOk461f7qpB1U-Drg.ttf';
export const RobotoMono_700Bold =
  'http://fonts.gstatic.com/s/robotomono/v7/L0xkDF4xlVMF-BfR8bXMIjDwjlq2db6LAkU-.ttf';
export const RobotoMono_700Bold_Italic =
  'http://fonts.gstatic.com/s/robotomono/v7/L0xmDF4xlVMF-BfR8bXMIjhOk8azf7qpB1U-Drg.ttf';
export const Sumana_400Regular =
  'http://fonts.gstatic.com/s/sumana/v4/4UaDrE5TqRBjGj-G8Bji76zR4w.ttf';
export const Sumana_700Bold =
  'http://fonts.gstatic.com/s/sumana/v4/4UaArE5TqRBjGj--TDfG54fN6ppsKg.ttf';
export const Biryani_200ExtraLight =
  'http://fonts.gstatic.com/s/biryani/v5/hv-TlzNxIFoO84YddYQyGTBSU-J-RxQ.ttf';
export const Biryani_300Light =
  'http://fonts.gstatic.com/s/biryani/v5/hv-TlzNxIFoO84YddeAxGTBSU-J-RxQ.ttf';
export const Biryani_400Regular =
  'http://fonts.gstatic.com/s/biryani/v5/hv-WlzNxIFoO84YdTUwZPTh5T-s.ttf';
export const Biryani_600SemiBold =
  'http://fonts.gstatic.com/s/biryani/v5/hv-TlzNxIFoO84YddZQ3GTBSU-J-RxQ.ttf';
export const Biryani_700Bold =
  'http://fonts.gstatic.com/s/biryani/v5/hv-TlzNxIFoO84YddfA2GTBSU-J-RxQ.ttf';
export const Biryani_800ExtraBold =
  'http://fonts.gstatic.com/s/biryani/v5/hv-TlzNxIFoO84Yddew1GTBSU-J-RxQ.ttf';
export const Biryani_900Black =
  'http://fonts.gstatic.com/s/biryani/v5/hv-TlzNxIFoO84Yddcg0GTBSU-J-RxQ.ttf';
export const Jaldi_400Regular = 'http://fonts.gstatic.com/s/jaldi/v6/or3sQ67z0_CI30NUZpD_B6g8.ttf';
export const Jaldi_700Bold = 'http://fonts.gstatic.com/s/jaldi/v6/or3hQ67z0_CI33voSbT3LLQ1niPn.ttf';
export const PragatiNarrow_400Regular =
  'http://fonts.gstatic.com/s/pragatinarrow/v5/vm8vdRf0T0bS1ffgsPB7WZ-mD17_ytN3M48a.ttf';
export const PragatiNarrow_700Bold =
  'http://fonts.gstatic.com/s/pragatinarrow/v5/vm8sdRf0T0bS1ffgsPB7WZ-mD2ZD5fd_GJMTlo_4.ttf';
export const Martel_200ExtraLight =
  'http://fonts.gstatic.com/s/martel/v4/PN_yRfK9oXHga0XVqekahRbX9vnDzw.ttf';
export const Martel_300Light =
  'http://fonts.gstatic.com/s/martel/v4/PN_yRfK9oXHga0XVzeoahRbX9vnDzw.ttf';
export const Martel_400Regular =
  'http://fonts.gstatic.com/s/martel/v4/PN_xRfK9oXHga0XtYcI-jT3L_w.ttf';
export const Martel_600SemiBold =
  'http://fonts.gstatic.com/s/martel/v4/PN_yRfK9oXHga0XVuewahRbX9vnDzw.ttf';
export const Martel_700Bold =
  'http://fonts.gstatic.com/s/martel/v4/PN_yRfK9oXHga0XV3e0ahRbX9vnDzw.ttf';
export const Martel_800ExtraBold =
  'http://fonts.gstatic.com/s/martel/v4/PN_yRfK9oXHga0XVwe4ahRbX9vnDzw.ttf';
export const Martel_900Black =
  'http://fonts.gstatic.com/s/martel/v4/PN_yRfK9oXHga0XV5e8ahRbX9vnDzw.ttf';
export const MartelSans_200ExtraLight =
  'http://fonts.gstatic.com/s/martelsans/v6/h0GxssGi7VdzDgKjM-4d8hAX5suHFUknqMxQ.ttf';
export const MartelSans_300Light =
  'http://fonts.gstatic.com/s/martelsans/v6/h0GxssGi7VdzDgKjM-4d8hBz5cuHFUknqMxQ.ttf';
export const MartelSans_400Regular =
  'http://fonts.gstatic.com/s/martelsans/v6/h0GsssGi7VdzDgKjM-4d8ijfze-PPlUu.ttf';
export const MartelSans_600SemiBold =
  'http://fonts.gstatic.com/s/martelsans/v6/h0GxssGi7VdzDgKjM-4d8hAH48uHFUknqMxQ.ttf';
export const MartelSans_700Bold =
  'http://fonts.gstatic.com/s/martelsans/v6/h0GxssGi7VdzDgKjM-4d8hBj4suHFUknqMxQ.ttf';
export const MartelSans_800ExtraBold =
  'http://fonts.gstatic.com/s/martelsans/v6/h0GxssGi7VdzDgKjM-4d8hB_4cuHFUknqMxQ.ttf';
export const MartelSans_900Black =
  'http://fonts.gstatic.com/s/martelsans/v6/h0GxssGi7VdzDgKjM-4d8hBb4MuHFUknqMxQ.ttf';
export const Lateef_400Regular =
  'http://fonts.gstatic.com/s/lateef/v15/hESw6XVnNCxEvkbMpheEZo_H_w.ttf';
export const Modak_400Regular = 'http://fonts.gstatic.com/s/modak/v5/EJRYQgs1XtIEsnMH8BVZ76KU.ttf';
export const NotoSansJP_100Thin =
  'http://fonts.gstatic.com/s/notosansjp/v25/-F6ofjtqLzI2JPCgQBnw7HFQoggM-FNthvIU.otf';
export const NotoSansJP_300Light =
  'http://fonts.gstatic.com/s/notosansjp/v25/-F6pfjtqLzI2JPCgQBnw7HFQaioq1H1hj-sNFQ.otf';
export const NotoSansJP_400Regular =
  'http://fonts.gstatic.com/s/notosansjp/v25/-F62fjtqLzI2JPCgQBnw7HFowAIO2lZ9hg.otf';
export const NotoSansJP_500Medium =
  'http://fonts.gstatic.com/s/notosansjp/v25/-F6pfjtqLzI2JPCgQBnw7HFQMisq1H1hj-sNFQ.otf';
export const NotoSansJP_700Bold =
  'http://fonts.gstatic.com/s/notosansjp/v25/-F6pfjtqLzI2JPCgQBnw7HFQei0q1H1hj-sNFQ.otf';
export const NotoSansJP_900Black =
  'http://fonts.gstatic.com/s/notosansjp/v25/-F6pfjtqLzI2JPCgQBnw7HFQQi8q1H1hj-sNFQ.otf';
export const Cambay_400Regular =
  'http://fonts.gstatic.com/s/cambay/v6/SLXJc1rY6H0_ZDsGbrSIz9JsaA.ttf';
export const Cambay_400Regular_Italic =
  'http://fonts.gstatic.com/s/cambay/v6/SLXLc1rY6H0_ZDs2bL6M7dd8aGZk.ttf';
export const Cambay_700Bold =
  'http://fonts.gstatic.com/s/cambay/v6/SLXKc1rY6H0_ZDs-0pusx_lwYX99kA.ttf';
export const Cambay_700Bold_Italic =
  'http://fonts.gstatic.com/s/cambay/v6/SLXMc1rY6H0_ZDs2bIYwwvN0Q3ptkDMN.ttf';
export const Dekko_400Regular = 'http://fonts.gstatic.com/s/dekko/v6/46khlb_wWjfSrttFR0vsfl1B.ttf';
export const Khula_300Light =
  'http://fonts.gstatic.com/s/khula/v5/OpNPnoEOns3V7G-ljCvUrC59XwXD.ttf';
export const Khula_400Regular = 'http://fonts.gstatic.com/s/khula/v5/OpNCnoEOns3V7FcJpA_chzJ0.ttf';
export const Khula_600SemiBold =
  'http://fonts.gstatic.com/s/khula/v5/OpNPnoEOns3V7G_RiivUrC59XwXD.ttf';
export const Khula_700Bold = 'http://fonts.gstatic.com/s/khula/v5/OpNPnoEOns3V7G-1iyvUrC59XwXD.ttf';
export const Khula_800ExtraBold =
  'http://fonts.gstatic.com/s/khula/v5/OpNPnoEOns3V7G-piCvUrC59XwXD.ttf';
export const Palanquin_100Thin =
  'http://fonts.gstatic.com/s/palanquin/v5/9XUhlJ90n1fBFg7ceXwUEltI7rWmZzTH.ttf';
export const Palanquin_200ExtraLight =
  'http://fonts.gstatic.com/s/palanquin/v5/9XUilJ90n1fBFg7ceXwUvnpoxJuqbi3ezg.ttf';
export const Palanquin_300Light =
  'http://fonts.gstatic.com/s/palanquin/v5/9XUilJ90n1fBFg7ceXwU2nloxJuqbi3ezg.ttf';
export const Palanquin_400Regular =
  'http://fonts.gstatic.com/s/palanquin/v5/9XUnlJ90n1fBFg7ceXwsdlFMzLC2Zw.ttf';
export const Palanquin_500Medium =
  'http://fonts.gstatic.com/s/palanquin/v5/9XUilJ90n1fBFg7ceXwUgnhoxJuqbi3ezg.ttf';
export const Palanquin_600SemiBold =
  'http://fonts.gstatic.com/s/palanquin/v5/9XUilJ90n1fBFg7ceXwUrn9oxJuqbi3ezg.ttf';
export const Palanquin_700Bold =
  'http://fonts.gstatic.com/s/palanquin/v5/9XUilJ90n1fBFg7ceXwUyn5oxJuqbi3ezg.ttf';
export const PalanquinDark_400Regular =
  'http://fonts.gstatic.com/s/palanquindark/v6/xn75YHgl1nqmANMB-26xC7yuF_6OTEo9VtfE.ttf';
export const PalanquinDark_500Medium =
  'http://fonts.gstatic.com/s/palanquindark/v6/xn76YHgl1nqmANMB-26xC7yuF8Z6ZW41fcvN2KT4.ttf';
export const PalanquinDark_600SemiBold =
  'http://fonts.gstatic.com/s/palanquindark/v6/xn76YHgl1nqmANMB-26xC7yuF8ZWYm41fcvN2KT4.ttf';
export const PalanquinDark_700Bold =
  'http://fonts.gstatic.com/s/palanquindark/v6/xn76YHgl1nqmANMB-26xC7yuF8YyY241fcvN2KT4.ttf';
export const Ranga_400Regular = 'http://fonts.gstatic.com/s/ranga/v5/C8ct4cYisGb28p6CLDwZwmGE.ttf';
export const Ranga_700Bold = 'http://fonts.gstatic.com/s/ranga/v5/C8cg4cYisGb28qY-AxgR6X2NZAn2.ttf';
export const LakkiReddy_400Regular =
  'http://fonts.gstatic.com/s/lakkireddy/v6/S6u5w49MUSzD9jlCPmvLZQfox9k97-xZ.ttf';
export const RaviPrakash_400Regular =
  'http://fonts.gstatic.com/s/raviprakash/v6/gokpH6fsDkVrF9Bv9X8SOAKHmNZEq6TTFw.ttf';
export const SreeKrushnadevaraya_400Regular =
  'http://fonts.gstatic.com/s/sreekrushnadevaraya/v7/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_EWb2FhQuXir.ttf';
export const Suranna_400Regular =
  'http://fonts.gstatic.com/s/suranna/v7/gokuH6ztGkFjWe58tBRZT2KmgP0.ttf';
export const Suravaram_400Regular =
  'http://fonts.gstatic.com/s/suravaram/v6/_gP61R_usiY7SCym4xIAi261Qv9roQ.ttf';
export const Timmana_400Regular =
  'http://fonts.gstatic.com/s/timmana/v4/6xKvdShfL9yK-rvpCmvbKHwJUOM.ttf';
export const Gurajada_400Regular =
  'http://fonts.gstatic.com/s/gurajada/v7/FwZY7-Qx308m-l-0Kd6A4sijpFu_.ttf';
export const Ramaraja_400Regular =
  'http://fonts.gstatic.com/s/ramaraja/v4/SlGTmQearpYAYG1CABIkqnB6aSQU.ttf';
export const Dhurjati_400Regular =
  'http://fonts.gstatic.com/s/dhurjati/v7/_6_8ED3gSeatXfFiFX3ySKQtuTA2.ttf';
export const Gidugu_400Regular =
  'http://fonts.gstatic.com/s/gidugu/v6/L0x8DFMkk1Uf6w3RvPCmRSlUig.ttf';
export const Mallanna_400Regular =
  'http://fonts.gstatic.com/s/mallanna/v7/hv-Vlzx-KEQb84YaDGwzEzRwVvJ-.ttf';
export const Mandali_400Regular =
  'http://fonts.gstatic.com/s/mandali/v8/LhWlMVbYOfASNfNUVFk1ZPdcKtA.ttf';
export const NTR_400Regular = 'http://fonts.gstatic.com/s/ntr/v7/RLpzK5Xy0ZjiGGhs5TA4bg.ttf';
export const Peddana_400Regular =
  'http://fonts.gstatic.com/s/peddana/v7/aFTU7PBhaX89UcKWhh2aBYyMcKw.ttf';
export const Ramabhadra_400Regular =
  'http://fonts.gstatic.com/s/ramabhadra/v9/EYq2maBOwqRW9P1SQ83LehNGX5uWw3o.ttf';
export const TenaliRamakrishna_400Regular =
  'http://fonts.gstatic.com/s/tenaliramakrishna/v6/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-8KJXqUFFvtA.ttf';
export const Kalam_300Light =
  'http://fonts.gstatic.com/s/kalam/v10/YA9Qr0Wd4kDdMtD6GgLLmCUItqGt.ttf';
export const Kalam_400Regular = 'http://fonts.gstatic.com/s/kalam/v10/YA9dr0Wd4kDdMuhWMibDszkB.ttf';
export const Kalam_700Bold =
  'http://fonts.gstatic.com/s/kalam/v10/YA9Qr0Wd4kDdMtDqHQLLmCUItqGt.ttf';
export const Sarpanch_400Regular =
  'http://fonts.gstatic.com/s/sarpanch/v5/hESy6Xt4NCpRuk6Pzh2ARIrX_20n.ttf';
export const Sarpanch_500Medium =
  'http://fonts.gstatic.com/s/sarpanch/v5/hES16Xt4NCpRuk6PziV0ba7f1HEuRHkM.ttf';
export const Sarpanch_600SemiBold =
  'http://fonts.gstatic.com/s/sarpanch/v5/hES16Xt4NCpRuk6PziVYaq7f1HEuRHkM.ttf';
export const Sarpanch_700Bold =
  'http://fonts.gstatic.com/s/sarpanch/v5/hES16Xt4NCpRuk6PziU8a67f1HEuRHkM.ttf';
export const Sarpanch_800ExtraBold =
  'http://fonts.gstatic.com/s/sarpanch/v5/hES16Xt4NCpRuk6PziUgaK7f1HEuRHkM.ttf';
export const Sarpanch_900Black =
  'http://fonts.gstatic.com/s/sarpanch/v5/hES16Xt4NCpRuk6PziUEaa7f1HEuRHkM.ttf';
export const Halant_300Light =
  'http://fonts.gstatic.com/s/halant/v7/u-490qaujRI2Pbsvc_pCmwZqcwdRXg.ttf';
export const Halant_400Regular =
  'http://fonts.gstatic.com/s/halant/v7/u-4-0qaujRI2PbsX39Jmky12eg.ttf';
export const Halant_500Medium =
  'http://fonts.gstatic.com/s/halant/v7/u-490qaujRI2PbsvK_tCmwZqcwdRXg.ttf';
export const Halant_600SemiBold =
  'http://fonts.gstatic.com/s/halant/v7/u-490qaujRI2PbsvB_xCmwZqcwdRXg.ttf';
export const Halant_700Bold =
  'http://fonts.gstatic.com/s/halant/v7/u-490qaujRI2PbsvY_1CmwZqcwdRXg.ttf';
export const Laila_300Light =
  'http://fonts.gstatic.com/s/laila/v6/LYjBdG_8nE8jDLzxogNAh14nVcfe.ttf';
export const Laila_400Regular = 'http://fonts.gstatic.com/s/laila/v6/LYjMdG_8nE8jDIRdiidIrEIu.ttf';
export const Laila_500Medium =
  'http://fonts.gstatic.com/s/laila/v6/LYjBdG_8nE8jDLypowNAh14nVcfe.ttf';
export const Laila_600SemiBold =
  'http://fonts.gstatic.com/s/laila/v6/LYjBdG_8nE8jDLyFpANAh14nVcfe.ttf';
export const Laila_700Bold = 'http://fonts.gstatic.com/s/laila/v6/LYjBdG_8nE8jDLzhpQNAh14nVcfe.ttf';
export const RozhaOne_400Regular =
  'http://fonts.gstatic.com/s/rozhaone/v7/AlZy_zVFtYP12Zncg2khdXf4XB0Tow.ttf';
export const Khand_300Light =
  'http://fonts.gstatic.com/s/khand/v8/TwMN-IINQlQQ0bL5cFE3ZwaH__-C.ttf';
export const Khand_400Regular = 'http://fonts.gstatic.com/s/khand/v8/TwMA-IINQlQQ0YpVWHU_TBqO.ttf';
export const Khand_500Medium =
  'http://fonts.gstatic.com/s/khand/v8/TwMN-IINQlQQ0bKhcVE3ZwaH__-C.ttf';
export const Khand_600SemiBold =
  'http://fonts.gstatic.com/s/khand/v8/TwMN-IINQlQQ0bKNdlE3ZwaH__-C.ttf';
export const Khand_700Bold = 'http://fonts.gstatic.com/s/khand/v8/TwMN-IINQlQQ0bLpd1E3ZwaH__-C.ttf';
export const VesperLibre_400Regular =
  'http://fonts.gstatic.com/s/vesperlibre/v11/bx6CNxyWnf-uxPdXDHUD_Rd4D0-N2qIWVQ.ttf';
export const VesperLibre_500Medium =
  'http://fonts.gstatic.com/s/vesperlibre/v11/bx6dNxyWnf-uxPdXDHUD_RdA-2ap0okKXKvPlw.ttf';
export const VesperLibre_700Bold =
  'http://fonts.gstatic.com/s/vesperlibre/v11/bx6dNxyWnf-uxPdXDHUD_RdAs2Cp0okKXKvPlw.ttf';
export const VesperLibre_900Black =
  'http://fonts.gstatic.com/s/vesperlibre/v11/bx6dNxyWnf-uxPdXDHUD_RdAi2Kp0okKXKvPlw.ttf';
export const Rajdhani_300Light =
  'http://fonts.gstatic.com/s/rajdhani/v9/LDI2apCSOBg7S-QT7pasEcOsc-bGkqIw.ttf';
export const Rajdhani_400Regular =
  'http://fonts.gstatic.com/s/rajdhani/v9/LDIxapCSOBg7S-QT7q4AOeekWPrP.ttf';
export const Rajdhani_500Medium =
  'http://fonts.gstatic.com/s/rajdhani/v9/LDI2apCSOBg7S-QT7pb0EMOsc-bGkqIw.ttf';
export const Rajdhani_600SemiBold =
  'http://fonts.gstatic.com/s/rajdhani/v9/LDI2apCSOBg7S-QT7pbYF8Osc-bGkqIw.ttf';
export const Rajdhani_700Bold =
  'http://fonts.gstatic.com/s/rajdhani/v9/LDI2apCSOBg7S-QT7pa8FsOsc-bGkqIw.ttf';
export const Hind_300Light = 'http://fonts.gstatic.com/s/hind/v10/5aU19_a8oxmIfMJaIRuYjDpf5Vw.ttf';
export const Hind_400Regular = 'http://fonts.gstatic.com/s/hind/v10/5aU69_a8oxmIRG5yBROzkDM.ttf';
export const Hind_500Medium = 'http://fonts.gstatic.com/s/hind/v10/5aU19_a8oxmIfJpbIRuYjDpf5Vw.ttf';
export const Hind_600SemiBold =
  'http://fonts.gstatic.com/s/hind/v10/5aU19_a8oxmIfLZcIRuYjDpf5Vw.ttf';
export const Hind_700Bold = 'http://fonts.gstatic.com/s/hind/v10/5aU19_a8oxmIfNJdIRuYjDpf5Vw.ttf';
export const Karma_300Light =
  'http://fonts.gstatic.com/s/karma/v10/va9F4kzAzMZRGLjDY8Z_uqzGQC_-.ttf';
export const Karma_400Regular = 'http://fonts.gstatic.com/s/karma/v10/va9I4kzAzMZRGIBvS-J3kbDP.ttf';
export const Karma_500Medium =
  'http://fonts.gstatic.com/s/karma/v10/va9F4kzAzMZRGLibYsZ_uqzGQC_-.ttf';
export const Karma_600SemiBold =
  'http://fonts.gstatic.com/s/karma/v10/va9F4kzAzMZRGLi3ZcZ_uqzGQC_-.ttf';
export const Karma_700Bold =
  'http://fonts.gstatic.com/s/karma/v10/va9F4kzAzMZRGLjTZMZ_uqzGQC_-.ttf';
export const Teko_300Light = 'http://fonts.gstatic.com/s/teko/v9/LYjCdG7kmE0gdQhfgCNqqVIuTN4.ttf';
export const Teko_400Regular = 'http://fonts.gstatic.com/s/teko/v9/LYjNdG7kmE0gTaR3pCtBtVs.ttf';
export const Teko_500Medium = 'http://fonts.gstatic.com/s/teko/v9/LYjCdG7kmE0gdVBegCNqqVIuTN4.ttf';
export const Teko_600SemiBold =
  'http://fonts.gstatic.com/s/teko/v9/LYjCdG7kmE0gdXxZgCNqqVIuTN4.ttf';
export const Teko_700Bold = 'http://fonts.gstatic.com/s/teko/v9/LYjCdG7kmE0gdRhYgCNqqVIuTN4.ttf';
export const FiraMono_400Regular =
  'http://fonts.gstatic.com/s/firamono/v8/N0bX2SlFPv1weGeLZDtQIfTTkdbJYA.ttf';
export const FiraMono_500Medium =
  'http://fonts.gstatic.com/s/firamono/v8/N0bS2SlFPv1weGeLZDto1d33mf3VaZBRBQ.ttf';
export const FiraMono_700Bold =
  'http://fonts.gstatic.com/s/firamono/v8/N0bS2SlFPv1weGeLZDtondv3mf3VaZBRBQ.ttf';
export const FiraSans_100Thin =
  'http://fonts.gstatic.com/s/firasans/v10/va9C4kDNxMZdWfMOD5Vn9IjOazP3dUTP.ttf';
export const FiraSans_100Thin_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9A4kDNxMZdWfMOD5VvkrCqYTfVcFTPj0s.ttf';
export const FiraSans_200ExtraLight =
  'http://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnWKnuQR37fF3Wlg.ttf';
export const FiraSans_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9f4kDNxMZdWfMOD5VvkrAGQBf_XljGllLX.ttf';
export const FiraSans_300Light =
  'http://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnPKruQR37fF3Wlg.ttf';
export const FiraSans_300Light_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9f4kDNxMZdWfMOD5VvkrBiQxf_XljGllLX.ttf';
export const FiraSans_400Regular =
  'http://fonts.gstatic.com/s/firasans/v10/va9E4kDNxMZdWfMOD5VfkILKSTbndQ.ttf';
export const FiraSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9C4kDNxMZdWfMOD5VvkojOazP3dUTP.ttf';
export const FiraSans_500Medium =
  'http://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnZKvuQR37fF3Wlg.ttf';
export const FiraSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9f4kDNxMZdWfMOD5VvkrA6Qhf_XljGllLX.ttf';
export const FiraSans_600SemiBold =
  'http://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnSKzuQR37fF3Wlg.ttf';
export const FiraSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9f4kDNxMZdWfMOD5VvkrAWRRf_XljGllLX.ttf';
export const FiraSans_700Bold =
  'http://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnLK3uQR37fF3Wlg.ttf';
export const FiraSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9f4kDNxMZdWfMOD5VvkrByRBf_XljGllLX.ttf';
export const FiraSans_800ExtraBold =
  'http://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnMK7uQR37fF3Wlg.ttf';
export const FiraSans_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9f4kDNxMZdWfMOD5VvkrBuRxf_XljGllLX.ttf';
export const FiraSans_900Black =
  'http://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnFK_uQR37fF3Wlg.ttf';
export const FiraSans_900Black_Italic =
  'http://fonts.gstatic.com/s/firasans/v10/va9f4kDNxMZdWfMOD5VvkrBKRhf_XljGllLX.ttf';
export const Slabo13px_400Regular =
  'http://fonts.gstatic.com/s/slabo13px/v7/11hEGp_azEvXZUdSBzzRcKer2wkYnvI.ttf';
export const Slabo27px_400Regular =
  'http://fonts.gstatic.com/s/slabo27px/v6/mFT0WbgBwKPR_Z4hGN2qsxgJ1EJ7i90.ttf';
export const SourceSerifPro_400Regular =
  'http://fonts.gstatic.com/s/sourceserifpro/v7/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM0oSOL2Yw.ttf';
export const SourceSerifPro_600SemiBold =
  'http://fonts.gstatic.com/s/sourceserifpro/v7/neIXzD-0qpwxpaWvjeD0X88SAOeasasahSugxYUvZrI.ttf';
export const SourceSerifPro_700Bold =
  'http://fonts.gstatic.com/s/sourceserifpro/v7/neIXzD-0qpwxpaWvjeD0X88SAOeasc8bhSugxYUvZrI.ttf';
export const RubikMonoOne_400Regular =
  'http://fonts.gstatic.com/s/rubikmonoone/v8/UqyJK8kPP3hjw6ANTdfRk9YSN-8wRqQrc_j9.ttf';
export const AlegreyaSans_100Thin =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUt9_-1phKLFgshYDvh6Vwt5TltuGdShm5bsg.ttf';
export const AlegreyaSans_100Thin_Italic =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUv9_-1phKLFgshYDvh6Vwt7V9V3G1WpGtLsgu7.ttf';
export const AlegreyaSans_300Light =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUu9_-1phKLFgshYDvh6Vwt5fFPmE18imdCqxI.ttf';
export const AlegreyaSans_300Light_Italic =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUo9_-1phKLFgshYDvh6Vwt7V9VFE92jkVHuxKiBA.ttf';
export const AlegreyaSans_400Regular =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUz9_-1phKLFgshYDvh6Vwt3V1nvEVXlm4.ttf';
export const AlegreyaSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUt9_-1phKLFgshYDvh6Vwt7V9tuGdShm5bsg.ttf';
export const AlegreyaSans_500Medium =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUu9_-1phKLFgshYDvh6Vwt5alOmE18imdCqxI.ttf';
export const AlegreyaSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUo9_-1phKLFgshYDvh6Vwt7V9VTE52jkVHuxKiBA.ttf';
export const AlegreyaSans_700Bold =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUu9_-1phKLFgshYDvh6Vwt5eFImE18imdCqxI.ttf';
export const AlegreyaSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEh2jkVHuxKiBA.ttf';
export const AlegreyaSans_800ExtraBold =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUu9_-1phKLFgshYDvh6Vwt5f1LmE18imdCqxI.ttf';
export const AlegreyaSans_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUo9_-1phKLFgshYDvh6Vwt7V9VGEt2jkVHuxKiBA.ttf';
export const AlegreyaSans_900Black =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUu9_-1phKLFgshYDvh6Vwt5dlKmE18imdCqxI.ttf';
export const AlegreyaSans_900Black_Italic =
  'http://fonts.gstatic.com/s/alegreyasans/v10/5aUo9_-1phKLFgshYDvh6Vwt7V9VPEp2jkVHuxKiBA.ttf';
export const AlegreyaSansSC_100Thin =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Dipl8g5FPYtmMg.ttf';
export const AlegreyaSansSC_100Thin_Italic =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGl4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdlgRBH452Mvds.ttf';
export const AlegreyaSansSC_300Light =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DuJH0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_300Light_Italic =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdXiZhNaB6O-51OA.ttf';
export const AlegreyaSansSC_400Regular =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Nk5v9ixALYs.ttf';
export const AlegreyaSansSC_400Regular_Italic =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Bkxl8g5FPYtmMg.ttf';
export const AlegreyaSansSC_500Medium =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_500Medium_Italic =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdBidhNaB6O-51OA.ttf';
export const AlegreyaSansSC_700Bold =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_700Bold_Italic =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdTiFhNaB6O-51OA.ttf';
export const AlegreyaSansSC_800ExtraBold =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1Du5D0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdUiJhNaB6O-51OA.ttf';
export const AlegreyaSansSC_900Black =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DspC0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_900Black_Italic =
  'http://fonts.gstatic.com/s/alegreyasanssc/v9/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxddiNhNaB6O-51OA.ttf';
export const Exo2_100Thin =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvOcPtq-rpvLpQ.ttf';
export const Exo2_200ExtraLight =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvKcPtq-rpvLpQ.ttf';
export const Exo2_300Light =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKcPtq-rpvLpQ.ttf';
export const Exo2_400Regular =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPtq-rpvLpQ.ttf';
export const Exo2_500Medium =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jjPKcPtq-rpvLpQ.ttf';
export const Exo2_600SemiBold =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jYPWcPtq-rpvLpQ.ttf';
export const Exo2_700Bold =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcPtq-rpvLpQ.ttf';
export const Exo2_800ExtraBold =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvWcPtq-rpvLpQ.ttf';
export const Exo2_900Black =
  'http://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jF_WcPtq-rpvLpQ.ttf';
export const Exo2_100Thin_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fNC6jJ7bpQBL.ttf';
export const Exo2_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0fdC6jJ7bpQBL.ttf';
export const Exo2_300Light_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drEqfdC6jJ7bpQBL.ttf';
export const Exo2_400Regular_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fdC6jJ7bpQBL.ttf';
export const Exo2_500Medium_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drFGfdC6jJ7bpQBL.ttf';
export const Exo2_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGqetC6jJ7bpQBL.ttf';
export const Exo2_700Bold_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGTetC6jJ7bpQBL.ttf';
export const Exo2_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0etC6jJ7bpQBL.ttf';
export const Exo2_900Black_Italic =
  'http://fonts.gstatic.com/s/exo2/v8/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drHdetC6jJ7bpQBL.ttf';
export const KdamThmor_400Regular =
  'http://fonts.gstatic.com/s/kdamthmor/v7/MwQzbhjs3veF6QwJVf0JkGMViblPtXs.ttf';
export const Sarabun_100Thin =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVhJx26TKEr37c9YHZJmnYI5gnOpg.ttf';
export const Sarabun_100Thin_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVnJx26TKEr37c9aBBx_nwMxAzephhN.ttf';
export const Sarabun_200ExtraLight =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YNpoulwm6gDXvwE.ttf';
export const Sarabun_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVkJx26TKEr37c9aBBxUl0s7iLSrwFUlw.ttf';
export const Sarabun_300Light =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YL5rulwm6gDXvwE.ttf';
export const Sarabun_300Light_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVkJx26TKEr37c9aBBxNl4s7iLSrwFUlw.ttf';
export const Sarabun_400Regular =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVjJx26TKEr37c9WBJDnlQN9gk.ttf';
export const Sarabun_400Regular_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVhJx26TKEr37c9aBBJmnYI5gnOpg.ttf';
export const Sarabun_500Medium =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YOZqulwm6gDXvwE.ttf';
export const Sarabun_500Medium_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVkJx26TKEr37c9aBBxbl8s7iLSrwFUlw.ttf';
export const Sarabun_600SemiBold =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YMptulwm6gDXvwE.ttf';
export const Sarabun_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVkJx26TKEr37c9aBBxQlgs7iLSrwFUlw.ttf';
export const Sarabun_700Bold =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YK5sulwm6gDXvwE.ttf';
export const Sarabun_700Bold_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVkJx26TKEr37c9aBBxJlks7iLSrwFUlw.ttf';
export const Sarabun_800ExtraBold =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YLJvulwm6gDXvwE.ttf';
export const Sarabun_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/sarabun/v7/DtVkJx26TKEr37c9aBBxOlos7iLSrwFUlw.ttf';
export const Kantumruy_300Light =
  'http://fonts.gstatic.com/s/kantumruy/v7/syk0-yJ0m7wyVb-f4FOPUtDlpn-UJ1H6Uw.ttf';
export const Kantumruy_400Regular =
  'http://fonts.gstatic.com/s/kantumruy/v7/sykx-yJ0m7wyVb-f4FO3_vjBrlSILg.ttf';
export const Kantumruy_700Bold =
  'http://fonts.gstatic.com/s/kantumruy/v7/syk0-yJ0m7wyVb-f4FOPQtflpn-UJ1H6Uw.ttf';
export const FaunaOne_400Regular =
  'http://fonts.gstatic.com/s/faunaone/v7/wlpzgwTPBVpjpCuwkuEx2UxLYClOCg.ttf';
export const LilyScriptOne_400Regular =
  'http://fonts.gstatic.com/s/lilyscriptone/v7/LhW9MV7ZMfIPdMxeBjBvFN8SXLS4gsSjQNsRMg.ttf';
export const PathwayGothicOne_400Regular =
  'http://fonts.gstatic.com/s/pathwaygothicone/v8/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-dTFxeb80flQ.ttf';
export const Alef_400Regular = 'http://fonts.gstatic.com/s/alef/v11/FeVfS0NQpLYgrjJbC5FxxbU.ttf';
export const Alef_700Bold = 'http://fonts.gstatic.com/s/alef/v11/FeVQS0NQpLYglo50L5la2bxii28.ttf';
export const RobotoSlab_100Thin =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaG5iddG-1A.ttf';
export const RobotoSlab_200ExtraLight =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaG5iddG-1A.ttf';
export const RobotoSlab_300Light =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaG5iddG-1A.ttf';
export const RobotoSlab_400Regular =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf';
export const RobotoSlab_500Medium =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaG5iddG-1A.ttf';
export const RobotoSlab_600SemiBold =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaG5iddG-1A.ttf';
export const RobotoSlab_700Bold =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaG5iddG-1A.ttf';
export const RobotoSlab_800ExtraBold =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaG5iddG-1A.ttf';
export const RobotoSlab_900Black =
  'http://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaG5iddG-1A.ttf';
export const LibreCaslonText_400Regular =
  'http://fonts.gstatic.com/s/librecaslontext/v1/DdT878IGsGw1aF1JU10PUbTvNNaDMcq_3eNrHgO1.ttf';
export const LibreCaslonText_400Regular_Italic =
  'http://fonts.gstatic.com/s/librecaslontext/v1/DdT678IGsGw1aF1JU10PUbTvNNaDMfq91-dJGxO1q9o.ttf';
export const LibreCaslonText_700Bold =
  'http://fonts.gstatic.com/s/librecaslontext/v1/DdT578IGsGw1aF1JU10PUbTvNNaDMfID8sdjNR-8ssPt.ttf';
export const Gabriela_400Regular =
  'http://fonts.gstatic.com/s/gabriela/v8/qkBWXvsO6sreR8E-b_m-zrpHmRzC.ttf';
export const MerriweatherSans_300Light =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c49IRs1JiJN1FRAMjTN5zd9vgsFH1eYBDD2BdWzIqY.ttf';
export const MerriweatherSans_300Light_Italic =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c29IRs1JiJN1FRAMjTN5zd9vgsFHXwepzB0hN0yZqYcqw.ttf';
export const MerriweatherSans_400Regular =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c99IRs1JiJN1FRAMjTN5zd9vgsFEXySDTL8wtf.ttf';
export const MerriweatherSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c79IRs1JiJN1FRAMjTN5zd9vgsFHXwQjDp9htf1ZM.ttf';
export const MerriweatherSans_700Bold =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c49IRs1JiJN1FRAMjTN5zd9vgsFH1OZxDD2BdWzIqY.ttf';
export const MerriweatherSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c29IRs1JiJN1FRAMjTN5zd9vgsFHXweozG0hN0yZqYcqw.ttf';
export const MerriweatherSans_800ExtraBold =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c49IRs1JiJN1FRAMjTN5zd9vgsFH1SZBDD2BdWzIqY.ttf';
export const MerriweatherSans_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/merriweathersans/v11/2-c29IRs1JiJN1FRAMjTN5zd9vgsFHXwepDF0hN0yZqYcqw.ttf';
export const NotoSans_400Regular =
  'http://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf';
export const NotoSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/notosans/v9/o-0OIpQlx3QUlC5A4PNr4DRFSfiM7HBj.ttf';
export const NotoSans_700Bold =
  'http://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFlY9aA5Wl6PQ.ttf';
export const NotoSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/notosans/v9/o-0TIpQlx3QUlC5A4PNr4Az5ZtyEx2xqPaif.ttf';
export const NotoSerif_400Regular =
  'http://fonts.gstatic.com/s/notoserif/v8/ga6Iaw1J5X9T9RW6j9bNTFAcaRi_bMQ.ttf';
export const NotoSerif_400Regular_Italic =
  'http://fonts.gstatic.com/s/notoserif/v8/ga6Kaw1J5X9T9RW6j9bNfFIWbTq6fMRRMw.ttf';
export const NotoSerif_700Bold =
  'http://fonts.gstatic.com/s/notoserif/v8/ga6Law1J5X9T9RW6j9bNdOwzTRCUcM1IKoY.ttf';
export const NotoSerif_700Bold_Italic =
  'http://fonts.gstatic.com/s/notoserif/v8/ga6Vaw1J5X9T9RW6j9bNfFIu0RWedO9NOoYIDg.ttf';
export const PatrickHandSC_400Regular =
  'http://fonts.gstatic.com/s/patrickhandsc/v7/0nkwC9f7MfsBiWcLtY65AWDK873ViSi6JQc7Vg.ttf';
export const Tauri_400Regular = 'http://fonts.gstatic.com/s/tauri/v8/TwMA-IISS0AM3IpVWHU_TBqO.ttf';
export const Sintony_400Regular =
  'http://fonts.gstatic.com/s/sintony/v7/XoHm2YDqR7-98cVUITQnu98ojjs.ttf';
export const Sintony_700Bold =
  'http://fonts.gstatic.com/s/sintony/v7/XoHj2YDqR7-98cVUGYgIn9cDkjLp6C8.ttf';
export const Kavoon_400Regular =
  'http://fonts.gstatic.com/s/kavoon/v8/pxiFyp4_scRYhlU4NLr6f1pdEQ.ttf';
export const Fruktur_400Regular =
  'http://fonts.gstatic.com/s/fruktur/v12/SZc53FHsOru5QYsMfz3GkUrS8DI.ttf';
export const Roboto_100Thin =
  'http://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf';
export const Roboto_100Thin_Italic =
  'http://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf';
export const Roboto_300Light =
  'http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf';
export const Roboto_300Light_Italic =
  'http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf';
export const Roboto_400Regular =
  'http://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf';
export const Roboto_400Regular_Italic =
  'http://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf';
export const Roboto_500Medium =
  'http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf';
export const Roboto_500Medium_Italic =
  'http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf';
export const Roboto_700Bold =
  'http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf';
export const Roboto_700Bold_Italic =
  'http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf';
export const Roboto_900Black =
  'http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf';
export const Roboto_900Black_Italic =
  'http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf';
export const DenkOne_400Regular =
  'http://fonts.gstatic.com/s/denkone/v7/dg4m_pzhrqcFb2IzROtHpbglShon.ttf';
export const DonegalOne_400Regular =
  'http://fonts.gstatic.com/s/donegalone/v7/m8JWjfRYea-ZnFz6fsK9FZRFRG-K3Mud.ttf';
export const Elsie_400Regular = 'http://fonts.gstatic.com/s/elsie/v9/BCanqZABrez54yYu9slAeLgX.ttf';
export const Elsie_900Black =
  'http://fonts.gstatic.com/s/elsie/v9/BCaqqZABrez54x6q2-1IU6QeXSBk.ttf';
export const ElsieSwashCaps_400Regular =
  'http://fonts.gstatic.com/s/elsieswashcaps/v8/845DNN8xGZyVX5MVo_upKf7KnjK0ferVKGWsUo8.ttf';
export const ElsieSwashCaps_900Black =
  'http://fonts.gstatic.com/s/elsieswashcaps/v8/845ENN8xGZyVX5MVo_upKf7KnjK0RW74DG2HToawrdU.ttf';
export const WendyOne_400Regular =
  'http://fonts.gstatic.com/s/wendyone/v8/2sDcZGJOipXfgfXV5wgDb2-4C7wFZQ.ttf';
export const Domine_400Regular =
  'http://fonts.gstatic.com/s/domine/v7/L0x8DFMnlVwD4h3RvPCmRSlUig.ttf';
export const Domine_700Bold =
  'http://fonts.gstatic.com/s/domine/v7/L0x_DFMnlVwD4h3pAN-CTQJIg3uuXg.ttf';
export const GrandHotel_400Regular =
  'http://fonts.gstatic.com/s/grandhotel/v7/7Au7p_IgjDKdCRWuR1azpmQNEl0O0kEx.ttf';
export const LibreBaskerville_400Regular =
  'http://fonts.gstatic.com/s/librebaskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNeYRI4CN2V.ttf';
export const LibreBaskerville_400Regular_Italic =
  'http://fonts.gstatic.com/s/librebaskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcaxYaDc2V2ro.ttf';
export const LibreBaskerville_700Bold =
  'http://fonts.gstatic.com/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYwI8Gcw6Oi.ttf';
export const Milonga_400Regular =
  'http://fonts.gstatic.com/s/milonga/v7/SZc53FHnIaK9W5kffz3GkUrS8DI.ttf';
export const Monda_400Regular = 'http://fonts.gstatic.com/s/monda/v9/TK3tWkYFABsmjvpmNBsLvPdG.ttf';
export const Monda_700Bold = 'http://fonts.gstatic.com/s/monda/v9/TK3gWkYFABsmjsLaGz8Dl-tPKo2t.ttf';
export const NewRocker_400Regular =
  'http://fonts.gstatic.com/s/newrocker/v8/MwQzbhjp3-HImzcCU_cJkGMViblPtXs.ttf';
export const FreckleFace_400Regular =
  'http://fonts.gstatic.com/s/freckleface/v8/AMOWz4SXrmKHCvXTohxY-YI0U1K2w9lb4g.ttf';
export const Hanalei_400Regular =
  'http://fonts.gstatic.com/s/hanalei/v10/E21n_dD8iufIjBRHXzgmVydREus.ttf';
export const HanaleiFill_400Regular =
  'http://fonts.gstatic.com/s/hanaleifill/v8/fC1mPYtObGbfyQznIaQzPQiMVwLBplm9aw.ttf';
export const SnowburstOne_400Regular =
  'http://fonts.gstatic.com/s/snowburstone/v7/MQpS-WezKdujBsXY3B7I-UT7eZ-UPyacPbo.ttf';
export const VampiroOne_400Regular =
  'http://fonts.gstatic.com/s/vampiroone/v10/gokqH6DoDl5yXvJytFsdLkqnsvhIor3K.ttf';
export const Margarine_400Regular =
  'http://fonts.gstatic.com/s/margarine/v8/qkBXXvoE6trLT9Y7YLye5JRLkAXbMQ.ttf';
export const PurplePurse_400Regular =
  'http://fonts.gstatic.com/s/purplepurse/v8/qWctB66gv53iAp-Vfs4My6qyeBb_ujA4ug.ttf';
export const AlmendraDisplay_400Regular =
  'http://fonts.gstatic.com/s/almendradisplay/v10/0FlPVOGWl1Sb4O3tETtADHRRlZhzXS_eTyer338.ttf';
export const CroissantOne_400Regular =
  'http://fonts.gstatic.com/s/croissantone/v7/3y9n6bU9bTPg4m8NDy3Kq24UM3pqn5cdJ-4.ttf';
export const OleoScriptSwashCaps_400Regular =
  'http://fonts.gstatic.com/s/oleoscriptswashcaps/v7/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HMXquSY0Hg90.ttf';
export const OleoScriptSwashCaps_700Bold =
  'http://fonts.gstatic.com/s/oleoscriptswashcaps/v7/Noag6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HCcaBbYUsn9T5dt0.ttf';
export const ClickerScript_400Regular =
  'http://fonts.gstatic.com/s/clickerscript/v7/raxkHiKPvt8CMH6ZWP8PdlEq72rY2zqUKafv.ttf';
export const Risque_400Regular =
  'http://fonts.gstatic.com/s/risque/v7/VdGfAZUfHosahXxoCUYVBJ-T5g.ttf';
export const Stalemate_400Regular =
  'http://fonts.gstatic.com/s/stalemate/v7/taiIGmZ_EJq97-UfkZRpuqSs8ZQpaQ.ttf';
export const BigelowRules_400Regular =
  'http://fonts.gstatic.com/s/bigelowrules/v8/RrQWboly8iR_I3KWSzeRuN0zT4cCH8WAJVk.ttf';
export const Englebert_400Regular =
  'http://fonts.gstatic.com/s/englebert/v7/xn7iYH8w2XGrC8AR4HSxT_fYdN-WZw.ttf';
export const MouseMemoirs_400Regular =
  'http://fonts.gstatic.com/s/mousememoirs/v7/t5tmIRoSNJ-PH0WNNgDYxdSb7TnFrpOHYh4.ttf';
export const Quintessential_400Regular =
  'http://fonts.gstatic.com/s/quintessential/v7/fdNn9sOGq31Yjnh3qWU14DdtjY5wS7kmAyxM.ttf';
export const RumRaisin_400Regular =
  'http://fonts.gstatic.com/s/rumraisin/v7/nwpRtKu3Ih8D5avB4h2uJ3-IywA7eMM.ttf';
export const Sacramento_400Regular =
  'http://fonts.gstatic.com/s/sacramento/v7/buEzpo6gcdjy0EiZMBUG0CoV_NxLeiw.ttf';
export const Anaheim_400Regular =
  'http://fonts.gstatic.com/s/anaheim/v7/8vII7w042Wp87g4G0UTUEE5eK_w.ttf';
export const Gafata_400Regular =
  'http://fonts.gstatic.com/s/gafata/v8/XRXV3I6Cn0VJKon4MuyAbsrVcA.ttf';
export const GildaDisplay_400Regular =
  'http://fonts.gstatic.com/s/gildadisplay/v7/t5tmIRoYMoaYG0WEOh7HwMeR7TnFrpOHYh4.ttf';
export const JotiOne_400Regular =
  'http://fonts.gstatic.com/s/jotione/v8/Z9XVDmdJQAmWm9TwaYTe4u2El6GC.ttf';
export const KeaniaOne_400Regular =
  'http://fonts.gstatic.com/s/keaniaone/v7/zOL54pXJk65E8pXardnuycRuv-hHkOs.ttf';
export const Merienda_400Regular =
  'http://fonts.gstatic.com/s/merienda/v8/gNMHW3x8Qoy5_mf8uVMCOou6_dvg.ttf';
export const Merienda_700Bold =
  'http://fonts.gstatic.com/s/merienda/v8/gNMAW3x8Qoy5_mf8uWu-Fa-y1sfpPES4.ttf';
export const PirataOne_400Regular =
  'http://fonts.gstatic.com/s/pirataone/v8/I_urMpiDvgLdLh0fAtoftiiEr5_BdZ8.ttf';
export const Rambla_400Regular =
  'http://fonts.gstatic.com/s/rambla/v7/snfrs0ip98hx6mr0I7IONthkwQ.ttf';
export const Rambla_400Regular_Italic =
  'http://fonts.gstatic.com/s/rambla/v7/snfps0ip98hx6mrEIbgKFN10wYKa.ttf';
export const Rambla_700Bold =
  'http://fonts.gstatic.com/s/rambla/v7/snfos0ip98hx6mrMn50qPvN4yJuDYQ.ttf';
export const Rambla_700Bold_Italic =
  'http://fonts.gstatic.com/s/rambla/v7/snfus0ip98hx6mrEIYC2O_l86p6TYS-Y.ttf';
export const Rufina_400Regular =
  'http://fonts.gstatic.com/s/rufina/v7/Yq6V-LyURyLy-aKyoxRktOdClg.ttf';
export const Rufina_700Bold =
  'http://fonts.gstatic.com/s/rufina/v7/Yq6W-LyURyLy-aKKHztAvMxenxE0SA.ttf';
export const Sanchez_400Regular =
  'http://fonts.gstatic.com/s/sanchez/v7/Ycm2sZJORluHnXbITm5b_BwE1l0.ttf';
export const Sanchez_400Regular_Italic =
  'http://fonts.gstatic.com/s/sanchez/v7/Ycm0sZJORluHnXbIfmxR-D4Bxl3gkw.ttf';
export const ShareTech_400Regular =
  'http://fonts.gstatic.com/s/sharetech/v9/7cHtv4Uyi5K0OeZ7bohUwHoDmTcibrA.ttf';
export const ShareTechMono_400Regular =
  'http://fonts.gstatic.com/s/sharetechmono/v9/J7aHnp1uDWRBEqV98dVQztYldFc7pAsEIc3Xew.ttf';
export const TextMeOne_400Regular =
  'http://fonts.gstatic.com/s/textmeone/v7/i7dOIFdlayuLUvgoFvHQFWZcalayGhyV.ttf';
export const FjallaOne_400Regular =
  'http://fonts.gstatic.com/s/fjallaone/v7/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf';
export const AverageSans_400Regular =
  'http://fonts.gstatic.com/s/averagesans/v8/1Ptpg8fLXP2dlAXR-HlJJNJPBdqazVoK4A.ttf';
export const CutiveMono_400Regular =
  'http://fonts.gstatic.com/s/cutivemono/v8/m8JWjfRfY7WVjVi2E-K9H5RFRG-K3Mud.ttf';
export const FasterOne_400Regular =
  'http://fonts.gstatic.com/s/fasterone/v11/H4ciBXCHmdfClFb-vWhfyLuShq63czE.ttf';
export const KiteOne_400Regular =
  'http://fonts.gstatic.com/s/kiteone/v7/70lQu7shLnA_E02vyq1b6HnGO4uA.ttf';
export const Offside_400Regular =
  'http://fonts.gstatic.com/s/offside/v7/HI_KiYMWKa9QrAykQ5HiRp-dhpQ.ttf';
export const Paprika_400Regular =
  'http://fonts.gstatic.com/s/paprika/v7/8QIJdijZitv49rDfuIgOq7jkAOw.ttf';
export const PlayfairDisplaySC_400Regular =
  'http://fonts.gstatic.com/s/playfairdisplaysc/v9/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_pb4GEcM2M4s.ttf';
export const PlayfairDisplaySC_400Regular_Italic =
  'http://fonts.gstatic.com/s/playfairdisplaysc/v9/ke87OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbwMFeEzI4sNKg.ttf';
export const PlayfairDisplaySC_700Bold =
  'http://fonts.gstatic.com/s/playfairdisplaysc/v9/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nQIpNcsdL4IUMyE.ttf';
export const PlayfairDisplaySC_700Bold_Italic =
  'http://fonts.gstatic.com/s/playfairdisplaysc/v9/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0qc4XK6ARIyH5IA.ttf';
export const PlayfairDisplaySC_900Black =
  'http://fonts.gstatic.com/s/playfairdisplaysc/v9/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nTorNcsdL4IUMyE.ttf';
export const PlayfairDisplaySC_900Black_Italic =
  'http://fonts.gstatic.com/s/playfairdisplaysc/v9/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0kcwXK6ARIyH5IA.ttf';
export const Strait_400Regular =
  'http://fonts.gstatic.com/s/strait/v7/DtViJxy6WaEr1LZzeDhtkl0U7w.ttf';
export const UnicaOne_400Regular =
  'http://fonts.gstatic.com/s/unicaone/v7/DPEuYwWHyAYGVTSmalshdtffuEY7FA.ttf';
export const CherrySwash_400Regular =
  'http://fonts.gstatic.com/s/cherryswash/v8/i7dNIFByZjaNAMxtZcnfAy58QHi-EwWMbg.ttf';
export const CherrySwash_700Bold =
  'http://fonts.gstatic.com/s/cherryswash/v8/i7dSIFByZjaNAMxtZcnfAy5E_FeaGy6QZ3WfYg.ttf';
export const Cinzel_400Regular =
  'http://fonts.gstatic.com/s/cinzel/v9/8vIJ7ww63mVu7gtL8W76HEdHMg.ttf';
export const Cinzel_700Bold =
  'http://fonts.gstatic.com/s/cinzel/v9/8vIK7ww63mVu7gtzTUHeFGxbO_zo-w.ttf';
export const Cinzel_900Black =
  'http://fonts.gstatic.com/s/cinzel/v9/8vIK7ww63mVu7gtzdUPeFGxbO_zo-w.ttf';
export const CinzelDecorative_400Regular =
  'http://fonts.gstatic.com/s/cinzeldecorative/v8/daaCSScvJGqLYhG8nNt8KPPswUAPnh7URs1LaCyC.ttf';
export const CinzelDecorative_700Bold =
  'http://fonts.gstatic.com/s/cinzeldecorative/v8/daaHSScvJGqLYhG8nNt8KPPswUAPniZoaelDQzCLlQXE.ttf';
export const CinzelDecorative_900Black =
  'http://fonts.gstatic.com/s/cinzeldecorative/v8/daaHSScvJGqLYhG8nNt8KPPswUAPniZQa-lDQzCLlQXE.ttf';
export const SeymourOne_400Regular =
  'http://fonts.gstatic.com/s/seymourone/v7/4iCp6Khla9xbjQpoWGGd0myIPYBvgpUI.ttf';
export const ChelaOne_400Regular =
  'http://fonts.gstatic.com/s/chelaone/v8/6ae-4KC7Uqgdz_JZdPIy31vWNTMwoQ.ttf';
export const JuliusSansOne_400Regular =
  'http://fonts.gstatic.com/s/juliussansone/v8/1Pt2g8TAX_SGgBGUi0tGOYEga5W-xXEW6aGXHw.ttf';
export const MontserratAlternates_100Thin =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU0xiKfVKphL03l4.ttf';
export const MontserratAlternates_100Thin_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTjWacfw6zH4dthXcyms1lPpC8I_b0juU057p-xIJxp1ml4imo.ttf';
export const MontserratAlternates_200ExtraLight =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xJIb1ALZH2mBhkw.ttf';
export const MontserratAlternates_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8dAbxD-GVxk3Nd.ttf';
export const MontserratAlternates_300Light =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xQIX1ALZH2mBhkw.ttf';
export const MontserratAlternates_300Light_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p95ArxD-GVxk3Nd.ttf';
export const MontserratAlternates_400Regular =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7K3RCJ1b0w.ttf';
export const MontserratAlternates_400Regular_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU057qfVKphL03l4.ttf';
export const MontserratAlternates_500Medium =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xGIT1ALZH2mBhkw.ttf';
export const MontserratAlternates_500Medium_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8hA7xD-GVxk3Nd.ttf';
export const MontserratAlternates_600SemiBold =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIP1ALZH2mBhkw.ttf';
export const MontserratAlternates_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8NBLxD-GVxk3Nd.ttf';
export const MontserratAlternates_700Bold =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xUIL1ALZH2mBhkw.ttf';
export const MontserratAlternates_700Bold_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9pBbxD-GVxk3Nd.ttf';
export const MontserratAlternates_800ExtraBold =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xTIH1ALZH2mBhkw.ttf';
export const MontserratAlternates_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p91BrxD-GVxk3Nd.ttf';
export const MontserratAlternates_900Black =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xaID1ALZH2mBhkw.ttf';
export const MontserratAlternates_900Black_Italic =
  'http://fonts.gstatic.com/s/montserratalternates/v11/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9RB7xD-GVxk3Nd.ttf';
export const MontserratSubrayada_400Regular =
  'http://fonts.gstatic.com/s/montserratsubrayada/v9/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQE8LYuceqGT-.ttf';
export const MontserratSubrayada_700Bold =
  'http://fonts.gstatic.com/s/montserratsubrayada/v9/U9MM6c-o9H7PgjlTHThBnNHGVUORwteQQHe3TcMWg3j36Ebz.ttf';
export const TitilliumWeb_200ExtraLight =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffAzHKIx5YrSYqWM.ttf';
export const TitilliumWeb_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPFcZTIAOhVxoMyOr9n_E7fdMbewI1zZpaduWMmxA.ttf';
export const TitilliumWeb_300Light =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffGjEKIx5YrSYqWM.ttf';
export const TitilliumWeb_300Light_Italic =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPFcZTIAOhVxoMyOr9n_E7fdMbepI5zZpaduWMmxA.ttf';
export const TitilliumWeb_400Regular =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf';
export const TitilliumWeb_400Regular_Italic =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPAcZTIAOhVxoMyOr9n_E7fdMbmCKZXbr2BsA.ttf';
export const TitilliumWeb_600SemiBold =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffBzCKIx5YrSYqWM.ttf';
export const TitilliumWeb_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPFcZTIAOhVxoMyOr9n_E7fdMbe0IhzZpaduWMmxA.ttf';
export const TitilliumWeb_700Bold =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffHjDKIx5YrSYqWM.ttf';
export const TitilliumWeb_700Bold_Italic =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPFcZTIAOhVxoMyOr9n_E7fdMbetIlzZpaduWMmxA.ttf';
export const TitilliumWeb_900Black =
  'http://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffEDBKIx5YrSYqWM.ttf';
export const ABeeZee_400Regular =
  'http://fonts.gstatic.com/s/abeezee/v13/esDR31xSG-6AGleN6tKukbcHCpE.ttf';
export const ABeeZee_400Regular_Italic =
  'http://fonts.gstatic.com/s/abeezee/v13/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf';
export const CarroisGothic_400Regular =
  'http://fonts.gstatic.com/s/carroisgothic/v10/Z9XPDmFATg-N1PLtLOOxvIHl9ZmD3i7ajcJ-.ttf';
export const CarroisGothicSC_400Regular =
  'http://fonts.gstatic.com/s/carroisgothicsc/v9/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-28nNHabY4dN.ttf';
export const FingerPaint_400Regular =
  'http://fonts.gstatic.com/s/fingerpaint/v9/0QInMXVJ-o-oRn_7dron8YWO85bS8ANesw.ttf';
export const SofadiOne_400Regular =
  'http://fonts.gstatic.com/s/sofadione/v8/JIA2UVBxdnVBuElZaMFGcDOIETkmYDU.ttf';
export const BenchNine_300Light =
  'http://fonts.gstatic.com/s/benchnine/v8/ahcev8612zF4jxrwMosT--tRhWa8q0v8ag.ttf';
export const BenchNine_400Regular =
  'http://fonts.gstatic.com/s/benchnine/v8/ahcbv8612zF4jxrwMosrV8N1jU2gog.ttf';
export const BenchNine_700Bold =
  'http://fonts.gstatic.com/s/benchnine/v8/ahcev8612zF4jxrwMosT6-xRhWa8q0v8ag.ttf';
export const Fenix_400Regular = 'http://fonts.gstatic.com/s/fenix/v7/XoHo2YL_S7-g5ostKzAFvs8o.ttf';
export const Akronim_400Regular =
  'http://fonts.gstatic.com/s/akronim/v9/fdN-9sqWtWZZlHRp-gBxkFYN-a8.ttf';
export const Combo_400Regular = 'http://fonts.gstatic.com/s/combo/v8/BXRlvF3Jh_fIhg0iBu9y8Hf0.ttf';
export const Underdog_400Regular =
  'http://fonts.gstatic.com/s/underdog/v8/CHygV-jCElj7diMroVSiU14GN2Il.ttf';
export const SourceCodePro_200ExtraLight =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_XiYsKILxRpg3hIP6sJ7fM7Pqt8srztO0rzmmkDQ.ttf';
export const SourceCodePro_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONMbtecv7Gy0DRzS.ttf';
export const SourceCodePro_300Light =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_XiYsKILxRpg3hIP6sJ7fM7PqtlsnztO0rzmmkDQ.ttf';
export const SourceCodePro_300Light_Italic =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONN_tucv7Gy0DRzS.ttf';
export const SourceCodePro_400Regular =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_SiYsKILxRpg3hIP6sJ7fM7PqVOuHXvMY3xw.ttf';
export const SourceCodePro_400Regular_Italic =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_QiYsKILxRpg3hIP6sJ7fM7PqlOOvTnsMnx3C9.ttf';
export const SourceCodePro_500Medium =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_XiYsKILxRpg3hIP6sJ7fM7PqtzsjztO0rzmmkDQ.ttf';
export const SourceCodePro_500Medium_Italic =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONMnt-cv7Gy0DRzS.ttf';
export const SourceCodePro_600SemiBold =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_XiYsKILxRpg3hIP6sJ7fM7Pqt4s_ztO0rzmmkDQ.ttf';
export const SourceCodePro_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONMLsOcv7Gy0DRzS.ttf';
export const SourceCodePro_700Bold =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_XiYsKILxRpg3hIP6sJ7fM7Pqths7ztO0rzmmkDQ.ttf';
export const SourceCodePro_700Bold_Italic =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONNvsecv7Gy0DRzS.ttf';
export const SourceCodePro_900Black =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_XiYsKILxRpg3hIP6sJ7fM7PqtvszztO0rzmmkDQ.ttf';
export const SourceCodePro_900Black_Italic =
  'http://fonts.gstatic.com/s/sourcecodepro/v11/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONNXs-cv7Gy0DRzS.ttf';
export const ArbutusSlab_400Regular =
  'http://fonts.gstatic.com/s/arbutusslab/v8/oY1Z8e7OuLXkJGbXtr5ba7ZVa68dJlaFAQ.ttf';
export const ArchivoBlack_400Regular =
  'http://fonts.gstatic.com/s/archivoblack/v9/HTxqL289NzCGg4MzN6KJ7eW6OYuP_x7yx3A.ttf';
export const ArchivoNarrow_400Regular =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tss0ApVBdCYD5Q7hcxTE1ArZ0Yb3g31S2s8p.ttf';
export const ArchivoNarrow_400Regular_Italic =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tss2ApVBdCYD5Q7hcxTE1ArZ0bb1iXlw398pJxk.ttf';
export const ArchivoNarrow_500Medium =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tss3ApVBdCYD5Q7hcxTE1ArZ0b4Dqlla8dMgPgBu.ttf';
export const ArchivoNarrow_500Medium_Italic =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tssxApVBdCYD5Q7hcxTE1ArZ0bb1sY1Z-9cCOxBu_BM.ttf';
export const ArchivoNarrow_600SemiBold =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tss3ApVBdCYD5Q7hcxTE1ArZ0b4vrVla8dMgPgBu.ttf';
export const ArchivoNarrow_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tssxApVBdCYD5Q7hcxTE1ArZ0bb1saFe-9cCOxBu_BM.ttf';
export const ArchivoNarrow_700Bold =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tss3ApVBdCYD5Q7hcxTE1ArZ0b5LrFla8dMgPgBu.ttf';
export const ArchivoNarrow_700Bold_Italic =
  'http://fonts.gstatic.com/s/archivonarrow/v11/tssxApVBdCYD5Q7hcxTE1ArZ0bb1scVf-9cCOxBu_BM.ttf';
export const Molle_400Regular_Italic =
  'http://fonts.gstatic.com/s/molle/v8/E21n_dL5hOXFhWEsXzgmVydREus.ttf';
export const OxygenMono_400Regular =
  'http://fonts.gstatic.com/s/oxygenmono/v7/h0GsssGg9FxgDgCjLeAd7ijfze-PPlUu.ttf';
export const JacquesFrancois_400Regular =
  'http://fonts.gstatic.com/s/jacquesfrancois/v7/ZXu9e04ZvKeOOHIe1TMahbcIU2cgmcPqoeRWfbs.ttf';
export const JacquesFrancoisShadow_400Regular =
  'http://fonts.gstatic.com/s/jacquesfrancoisshadow/v8/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_q8VsHuV5ULS.ttf';
export const Orienta_400Regular =
  'http://fonts.gstatic.com/s/orienta/v7/PlI9FlK4Jrl5Y9zNeyeo9HRFhcU.ttf';
export const PetitFormalScript_400Regular =
  'http://fonts.gstatic.com/s/petitformalscript/v7/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qoHnqP4gZSiE.ttf';
export const Raleway_100Thin =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptsg8zYS_SKggPNwE4ISotrDfGGxA.ttf';
export const Raleway_100Thin_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptqg8zYS_SKggPNyCgwLoFvL_SWxEMT.ttf';
export const Raleway_200ExtraLight =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwOIpaqFFAfif3Vo.ttf';
export const Raleway_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptpg8zYS_SKggPNyCgwgqBPBdqazVoK4A.ttf';
export const Raleway_300Light =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwIYqaqFFAfif3Vo.ttf';
export const Raleway_300Light_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptpg8zYS_SKggPNyCgw5qNPBdqazVoK4A.ttf';
export const Raleway_400Regular =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPN-CoCTqluHfE.ttf';
export const Raleway_400Regular_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptsg8zYS_SKggPNyCgISotrDfGGxA.ttf';
export const Raleway_500Medium =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwN4raqFFAfif3Vo.ttf';
export const Raleway_500Medium_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptpg8zYS_SKggPNyCgwvqJPBdqazVoK4A.ttf';
export const Raleway_600SemiBold =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwPIsaqFFAfif3Vo.ttf';
export const Raleway_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptpg8zYS_SKggPNyCgwkqVPBdqazVoK4A.ttf';
export const Raleway_700Bold =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtaqFFAfif3Vo.ttf';
export const Raleway_700Bold_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptpg8zYS_SKggPNyCgw9qRPBdqazVoK4A.ttf';
export const Raleway_800ExtraBold =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwIouaqFFAfif3Vo.ttf';
export const Raleway_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptpg8zYS_SKggPNyCgw6qdPBdqazVoK4A.ttf';
export const Raleway_900Black =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqFFAfif3Vo.ttf';
export const Raleway_900Black_Italic =
  'http://fonts.gstatic.com/s/raleway/v14/1Ptpg8zYS_SKggPNyCgwzqZPBdqazVoK4A.ttf';
export const RalewayDots_400Regular =
  'http://fonts.gstatic.com/s/ralewaydots/v7/6NUR8FifJg6AfQvzpshgwJ8kyf9Fdty2ew.ttf';
export const Ranchers_400Regular =
  'http://fonts.gstatic.com/s/ranchers/v7/zrfm0H3Lx-P2Xvs2AoDYDC79XTHv.ttf';
export const Warnes_400Regular =
  'http://fonts.gstatic.com/s/warnes/v9/pONn1hc0GsW6sW5OpiC2o6Lkqg.ttf';
export const Griffy_400Regular =
  'http://fonts.gstatic.com/s/griffy/v8/FwZa7-ox2FQh9kfwSNSEwM2zpA.ttf';
export const MeieScript_400Regular =
  'http://fonts.gstatic.com/s/meiescript/v7/_LOImzDK7erRjhunIspaMjxn5IXg0WDz.ttf';
export const Rye_400Regular = 'http://fonts.gstatic.com/s/rye/v7/r05XGLJT86YDFpTsXOqx4w.ttf';
export const Skranji_400Regular =
  'http://fonts.gstatic.com/s/skranji/v7/OZpDg_dtriVFNerMYzuuklTm3Ek.ttf';
export const Skranji_700Bold =
  'http://fonts.gstatic.com/s/skranji/v7/OZpGg_dtriVFNerMW4eBtlzNwED-b4g.ttf';
export const Oranienbaum_400Regular =
  'http://fonts.gstatic.com/s/oranienbaum/v8/OZpHg_txtzZKMuXLIVrx-3zn7kz3dpHc.ttf';
export const StalinistOne_400Regular =
  'http://fonts.gstatic.com/s/stalinistone/v25/MQpS-WezM9W4Dd7D3B7I-UT7eZ-UPyacPbo.ttf';
export const Galindo_400Regular =
  'http://fonts.gstatic.com/s/galindo/v7/HI_KiYMeLqVKqwyuQ5HiRp-dhpQ.ttf';
export const LifeSavers_400Regular =
  'http://fonts.gstatic.com/s/lifesavers/v10/ZXuie1UftKKabUQMgxAal_lrFgpbuNvB.ttf';
export const LifeSavers_700Bold =
  'http://fonts.gstatic.com/s/lifesavers/v10/ZXu_e1UftKKabUQMgxAal8HXOS5Tk8fIpPRW.ttf';
export const LifeSavers_800ExtraBold =
  'http://fonts.gstatic.com/s/lifesavers/v10/ZXu_e1UftKKabUQMgxAal8HLOi5Tk8fIpPRW.ttf';
export const McLaren_400Regular =
  'http://fonts.gstatic.com/s/mclaren/v7/2EbnL-ZuAXFqZFXISYYf8z2Yt_c.ttf';
export const Oregano_400Regular =
  'http://fonts.gstatic.com/s/oregano/v7/If2IXTPxciS3H4S2kZffPznO3yM.ttf';
export const Oregano_400Regular_Italic =
  'http://fonts.gstatic.com/s/oregano/v7/If2KXTPxciS3H4S2oZXVOxvLzyP_qw.ttf';
export const RacingSansOne_400Regular =
  'http://fonts.gstatic.com/s/racingsansone/v7/sykr-yRtm7EvTrXNxkv5jfKKyDCwL3rmWpIBtA.ttf';
export const Romanesco_400Regular =
  'http://fonts.gstatic.com/s/romanesco/v8/w8gYH2ozQOY7_r_J7mSn3HwLqOqSBg.ttf';
export const Nunito_200ExtraLight =
  'http://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA-sekZuHJeTsfDQ.ttf';
export const Nunito_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/nunito/v12/XRXQ3I6Li01BKofIMN5MZ-vNWz4PDWtj.ttf';
export const Nunito_300Light =
  'http://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAnsSkZuHJeTsfDQ.ttf';
export const Nunito_300Light_Italic =
  'http://fonts.gstatic.com/s/nunito/v12/XRXQ3I6Li01BKofIMN4oZOvNWz4PDWtj.ttf';
export const Nunito_400Regular =
  'http://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKof4MuyAbsrVcA.ttf';
export const Nunito_400Regular_Italic =
  'http://fonts.gstatic.com/s/nunito/v12/XRXX3I6Li01BKofIMOaETM_FcCIG.ttf';
export const Nunito_600SemiBold =
  'http://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKkZuHJeTsfDQ.ttf';
export const Nunito_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/nunito/v12/XRXQ3I6Li01BKofIMN5cYuvNWz4PDWtj.ttf';
export const Nunito_700Bold =
  'http://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOkZuHJeTsfDQ.ttf';
export const Nunito_700Bold_Italic =
  'http://fonts.gstatic.com/s/nunito/v12/XRXQ3I6Li01BKofIMN44Y-vNWz4PDWtj.ttf';
export const Nunito_800ExtraBold =
  'http://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAksCkZuHJeTsfDQ.ttf';
export const Nunito_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/nunito/v12/XRXQ3I6Li01BKofIMN4kYOvNWz4PDWtj.ttf';
export const Nunito_900Black =
  'http://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAtsGkZuHJeTsfDQ.ttf';
export const Nunito_900Black_Italic =
  'http://fonts.gstatic.com/s/nunito/v12/XRXQ3I6Li01BKofIMN4AYevNWz4PDWtj.ttf';
export const Stoke_300Light =
  'http://fonts.gstatic.com/s/stoke/v9/z7NXdRb7aTMfKNvFVgxC_pjcTeWU.ttf';
export const Stoke_400Regular = 'http://fonts.gstatic.com/s/stoke/v9/z7NadRb7aTMfKONpfihK1YTV.ttf';
export const SourceSansPro_200ExtraLight =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_AkB1v_8CGxg.ttf';
export const SourceSansPro_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokRdr3cWWxg40.ttf';
export const SourceSansPro_300Light =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zAkB1v_8CGxg.ttf';
export const SourceSansPro_300Light_Italic =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkhdr3cWWxg40.ttf';
export const SourceSansPro_400Regular =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3aP6TkmDZz9g.ttf';
export const SourceSansPro_400Regular_Italic =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7gujNj9tmf.ttf';
export const SourceSansPro_600SemiBold =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkB1v_8CGxg.ttf';
export const SourceSansPro_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr3cWWxg40.ttf';
export const SourceSansPro_700Bold =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkB1v_8CGxg.ttf';
export const SourceSansPro_700Bold_Italic =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr3cWWxg40.ttf';
export const SourceSansPro_900Black =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nAkB1v_8CGxg.ttf';
export const SourceSansPro_900Black_Italic =
  'http://fonts.gstatic.com/s/sourcesanspro/v13/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklxdr3cWWxg40.ttf';
export const Amiri_400Regular = 'http://fonts.gstatic.com/s/amiri/v13/J7aRnpd8CGxBHqUpvrIw74NL.ttf';
export const Amiri_400Regular_Italic =
  'http://fonts.gstatic.com/s/amiri/v13/J7afnpd8CGxBHpUrtLYS6pNLAjk.ttf';
export const Amiri_700Bold =
  'http://fonts.gstatic.com/s/amiri/v13/J7acnpd8CGxBHp2VkZY4xJ9CGyAa.ttf';
export const Amiri_700Bold_Italic =
  'http://fonts.gstatic.com/s/amiri/v13/J7aanpd8CGxBHpUrjAo9zptgHjAavCA.ttf';
export const CantoraOne_400Regular =
  'http://fonts.gstatic.com/s/cantoraone/v9/gyB4hws1JdgnKy56GB_JX6zdZ4vZVbgZ.ttf';
export const Scada_400Regular = 'http://fonts.gstatic.com/s/scada/v8/RLpxK5Pv5qumeWJoxzUobkvv.ttf';
export const Scada_400Regular_Italic =
  'http://fonts.gstatic.com/s/scada/v8/RLp_K5Pv5qumeVJqzTEKa1vvffg.ttf';
export const Scada_700Bold = 'http://fonts.gstatic.com/s/scada/v8/RLp8K5Pv5qumeVrU6BEgRVfmZOE5.ttf';
export const Scada_700Bold_Italic =
  'http://fonts.gstatic.com/s/scada/v8/RLp6K5Pv5qumeVJq9Y0lT1PEYfE5p6g.ttf';
export const EagleLake_400Regular =
  'http://fonts.gstatic.com/s/eaglelake/v7/ptRMTiqbbuNJDOiKj9wG5O7yKQNute8.ttf';
export const MetalMania_400Regular =
  'http://fonts.gstatic.com/s/metalmania/v9/RWmMoKWb4e8kqMfBUdPFJeXCg6UKDXlq.ttf';
export const Peralta_400Regular =
  'http://fonts.gstatic.com/s/peralta/v7/hYkJPu0-RP_9d3kRGxAhrv956B8.ttf';
export const Amarante_400Regular =
  'http://fonts.gstatic.com/s/amarante/v7/xMQXuF1KTa6EvGx9bq-3C3rAmD-b.ttf';
export const Capriola_400Regular =
  'http://fonts.gstatic.com/s/capriola/v7/wXKoE3YSppcvo1PDln_8L-AinG8y.ttf';
export const Courgette_400Regular =
  'http://fonts.gstatic.com/s/courgette/v7/wEO_EBrAnc9BLjLQAUkFUfAL3EsHiA.ttf';
export const Quando_400Regular =
  'http://fonts.gstatic.com/s/quando/v8/xMQVuFNaVa6YuW0pC6WzKX_QmA.ttf';
export const RobotoCondensed_300Light =
  'http://fonts.gstatic.com/s/robotocondensed/v18/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCMSbvtdYyQ.ttf';
export const RobotoCondensed_300Light_Italic =
  'http://fonts.gstatic.com/s/robotocondensed/v18/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEYatlYcyRi4A.ttf';
export const RobotoCondensed_400Regular =
  'http://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-59WxDCs5cvI.ttf';
export const RobotoCondensed_400Regular_Italic =
  'http://fonts.gstatic.com/s/robotocondensed/v18/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CAk8YvJEeg.ttf';
export const RobotoCondensed_700Bold =
  'http://fonts.gstatic.com/s/robotocondensed/v18/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCMSbvtdYyQ.ttf';
export const RobotoCondensed_700Bold_Italic =
  'http://fonts.gstatic.com/s/robotocondensed/v18/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYYatlYcyRi4A.ttf';
export const Fasthand_400Regular =
  'http://fonts.gstatic.com/s/fasthand/v10/0yb9GDohyKTYn_ZEESkuYkw2rQg1.ttf';
export const AutourOne_400Regular =
  'http://fonts.gstatic.com/s/autourone/v9/UqyVK80cP25l3fJgbdfbk5lWVscxdKE.ttf';
export const BubblerOne_400Regular =
  'http://fonts.gstatic.com/s/bubblerone/v8/f0Xy0eqj68ppQV9KBLmAouHH26MPePkt.ttf';
export const HeadlandOne_400Regular =
  'http://fonts.gstatic.com/s/headlandone/v7/yYLu0hHR2vKnp89Tk1TCq3Tx0PlTeZ3mJA.ttf';
export const Marcellus_400Regular =
  'http://fonts.gstatic.com/s/marcellus/v7/wEO_EBrOk8hQLDvIAF8FUfAL3EsHiA.ttf';
export const MarcellusSC_400Regular =
  'http://fonts.gstatic.com/s/marcellussc/v7/ke8iOgUHP1dg-Rmi6RWjbLEPgdydGKikhA.ttf';
export const Audiowide_400Regular =
  'http://fonts.gstatic.com/s/audiowide/v8/l7gdbjpo0cum0ckerWCtkQXPExpQBw.ttf';
export const ChauPhilomeneOne_400Regular =
  'http://fonts.gstatic.com/s/chauphilomeneone/v9/55xxezRsPtfie1vPY49qzdgSlJiHRQFsnIx7QMISdQ.ttf';
export const ChauPhilomeneOne_400Regular_Italic =
  'http://fonts.gstatic.com/s/chauphilomeneone/v9/55xzezRsPtfie1vPY49qzdgSlJiHRQFcnoZ_YscCdXQB.ttf';
export const Cuprum_400Regular =
  'http://fonts.gstatic.com/s/cuprum/v11/dg4k_pLmvrkcOkB9IeFDh701Sg.ttf';
export const Cuprum_400Regular_Italic =
  'http://fonts.gstatic.com/s/cuprum/v11/dg4m_pLmvrkcOkBNI-tHpbglShon.ttf';
export const Cuprum_700Bold =
  'http://fonts.gstatic.com/s/cuprum/v11/dg4n_pLmvrkcOkBFnc5nj5YpQwM-gg.ttf';
export const Cuprum_700Bold_Italic =
  'http://fonts.gstatic.com/s/cuprum/v11/dg4h_pLmvrkcOkBNI9P7ipwtYQYugjW4.ttf';
export const DellaRespira_400Regular =
  'http://fonts.gstatic.com/s/dellarespira/v7/RLp5K5v44KaueWI6iEJQBiGPRfkSu6EuTHo.ttf';
export const PressStart2P_400Regular =
  'http://fonts.gstatic.com/s/pressstart2p/v8/e3t4euO8T-267oIAQAu6jDQyK0nSgPJE4580.ttf';
export const RussoOne_400Regular =
  'http://fonts.gstatic.com/s/russoone/v8/Z9XUDmZRWg6M1LvRYsH-yMOInrib9Q.ttf';
export const Simonetta_400Regular =
  'http://fonts.gstatic.com/s/simonetta/v10/x3dickHVYrCU5BU15c4BfPACvy_1BA.ttf';
export const Simonetta_400Regular_Italic =
  'http://fonts.gstatic.com/s/simonetta/v10/x3dkckHVYrCU5BU15c4xfvoGnSrlBBsy.ttf';
export const Simonetta_900Black =
  'http://fonts.gstatic.com/s/simonetta/v10/x3dnckHVYrCU5BU15c45-N0mtwTpDQIrGg.ttf';
export const Simonetta_900Black_Italic =
  'http://fonts.gstatic.com/s/simonetta/v10/x3d5ckHVYrCU5BU15c4xfsKCsA7tLwc7Gn88.ttf';
export const Trocchi_400Regular =
  'http://fonts.gstatic.com/s/trocchi/v8/qWcqB6WkuIDxDZLcDrtUvMeTYD0.ttf';
export const Belleza_400Regular =
  'http://fonts.gstatic.com/s/belleza/v8/0nkoC9_pNeMfhX4BtcbyawzruP8.ttf';
export const GreatVibes_400Regular =
  'http://fonts.gstatic.com/s/greatvibes/v7/RWmMoKWR9v4ksMfaWd_JN-XCg6UKDXlq.ttf';
export const LoversQuarrel_400Regular =
  'http://fonts.gstatic.com/s/loversquarrel/v7/Yq6N-LSKXTL-5bCy8ksBzpQ_-zAsY7pO6siz.ttf';
export const OleoScript_400Regular =
  'http://fonts.gstatic.com/s/oleoscript/v8/rax5HieDvtMOe0iICsUccBhasU7Q8Cad.ttf';
export const OleoScript_700Bold =
  'http://fonts.gstatic.com/s/oleoscript/v8/raxkHieDvtMOe0iICsUccCDmnmrY2zqUKafv.ttf';
export const Oxygen_300Light =
  'http://fonts.gstatic.com/s/oxygen/v9/2sDcZG1Wl4LcnbuCJW8Db2-4C7wFZQ.ttf';
export const Oxygen_400Regular =
  'http://fonts.gstatic.com/s/oxygen/v9/2sDfZG1Wl4Lcnbu6iUcnZ0SkAg.ttf';
export const Oxygen_700Bold =
  'http://fonts.gstatic.com/s/oxygen/v9/2sDcZG1Wl4LcnbuCNWgDb2-4C7wFZQ.ttf';
export const Rosarivo_400Regular =
  'http://fonts.gstatic.com/s/rosarivo/v7/PlI-Fl2lO6N9f8HaNAeC2nhMnNy5.ttf';
export const Rosarivo_400Regular_Italic =
  'http://fonts.gstatic.com/s/rosarivo/v7/PlI4Fl2lO6N9f8HaNDeA0Hxumcy5ZX8.ttf';
export const Dosis_200ExtraLight =
  'http://fonts.gstatic.com/s/dosis/v17/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7MV3BkFTq4EPw.ttf';
export const Dosis_300Light =
  'http://fonts.gstatic.com/s/dosis/v17/HhyJU5sn9vOmLxNkIwRSjTVNWLEJabMV3BkFTq4EPw.ttf';
export const Dosis_400Regular =
  'http://fonts.gstatic.com/s/dosis/v17/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7MV3BkFTq4EPw.ttf';
export const Dosis_500Medium =
  'http://fonts.gstatic.com/s/dosis/v17/HhyJU5sn9vOmLxNkIwRSjTVNWLEJBbMV3BkFTq4EPw.ttf';
export const Dosis_600SemiBold =
  'http://fonts.gstatic.com/s/dosis/v17/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ6bQV3BkFTq4EPw.ttf';
export const Dosis_700Bold =
  'http://fonts.gstatic.com/s/dosis/v17/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ0LQV3BkFTq4EPw.ttf';
export const Dosis_800ExtraBold =
  'http://fonts.gstatic.com/s/dosis/v17/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7QV3BkFTq4EPw.ttf';
export const Quattrocento_400Regular =
  'http://fonts.gstatic.com/s/quattrocento/v11/OZpEg_xvsDZQL_LKIF7q4jPHxGL7f4jFuA.ttf';
export const Quattrocento_700Bold =
  'http://fonts.gstatic.com/s/quattrocento/v11/OZpbg_xvsDZQL_LKIF7q4jP_eE3fd6PZsXcM9w.ttf';
export const AnticDidone_400Regular =
  'http://fonts.gstatic.com/s/anticdidone/v8/RWmPoKKX6u8sp8fIWdnDKqDiqYsGBGBzCw.ttf';
export const AnticSlab_400Regular =
  'http://fonts.gstatic.com/s/anticslab/v8/bWt97fPFfRzkCa9Jlp6IWcJWXW5p5Qo.ttf';
export const Average_400Regular =
  'http://fonts.gstatic.com/s/average/v8/fC1hPYBHe23MxA7rIeJwVWytTyk.ttf';
export const AveriaGruesaLibre_400Regular =
  'http://fonts.gstatic.com/s/averiagruesalibre/v8/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZUmmJw0SLRA8.ttf';
export const AveriaLibre_300Light =
  'http://fonts.gstatic.com/s/averialibre/v8/2V0FKIcMGZEnV6xygz7eNjEarovtb07t-pQgTw.ttf';
export const AveriaLibre_300Light_Italic =
  'http://fonts.gstatic.com/s/averialibre/v8/2V0HKIcMGZEnV6xygz7eNjESAJFhbUTp2JEwT4Sk.ttf';
export const AveriaLibre_400Regular =
  'http://fonts.gstatic.com/s/averialibre/v8/2V0aKIcMGZEnV6xygz7eNjEiAqPJZ2Xx8w.ttf';
export const AveriaLibre_400Regular_Italic =
  'http://fonts.gstatic.com/s/averialibre/v8/2V0EKIcMGZEnV6xygz7eNjESAKnNRWDh8405.ttf';
export const AveriaLibre_700Bold =
  'http://fonts.gstatic.com/s/averialibre/v8/2V0FKIcMGZEnV6xygz7eNjEavoztb07t-pQgTw.ttf';
export const AveriaLibre_700Bold_Italic =
  'http://fonts.gstatic.com/s/averialibre/v8/2V0HKIcMGZEnV6xygz7eNjESAJFxakTp2JEwT4Sk.ttf';
export const AveriaSansLibre_300Light =
  'http://fonts.gstatic.com/s/averiasanslibre/v8/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd3lMKcQJZP1LmD9.ttf';
export const AveriaSansLibre_300Light_Italic =
  'http://fonts.gstatic.com/s/averiasanslibre/v8/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKisSL5fXK3D9qtg.ttf';
export const AveriaSansLibre_400Regular =
  'http://fonts.gstatic.com/s/averiasanslibre/v8/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEeVJGIMYDo_8.ttf';
export const AveriaSansLibre_400Regular_Italic =
  'http://fonts.gstatic.com/s/averiasanslibre/v8/ga6RaxZG_G5OvCf_rt7FH3B6BHLMEdVLEoc6C5_8N3k.ttf';
export const AveriaSansLibre_700Bold =
  'http://fonts.gstatic.com/s/averiasanslibre/v8/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd31N6cQJZP1LmD9.ttf';
export const AveriaSansLibre_700Bold_Italic =
  'http://fonts.gstatic.com/s/averiasanslibre/v8/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKjsVL5fXK3D9qtg.ttf';
export const AveriaSerifLibre_300Light =
  'http://fonts.gstatic.com/s/averiaseriflibre/v9/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGCSmqwacqdrKvbQ.ttf';
export const AveriaSerifLibre_300Light_Italic =
  'http://fonts.gstatic.com/s/averiaseriflibre/v9/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzMmw60uVLe_bXHq.ttf';
export const AveriaSerifLibre_400Regular =
  'http://fonts.gstatic.com/s/averiaseriflibre/v9/neIWzD2ms4wxr6GvjeD0X88SHPyX2xY-pQGOyYw2fw.ttf';
export const AveriaSerifLibre_400Regular_Italic =
  'http://fonts.gstatic.com/s/averiaseriflibre/v9/neIUzD2ms4wxr6GvjeD0X88SHPyX2xYOpwuK64kmf6u2.ttf';
export const AveriaSerifLibre_700Bold =
  'http://fonts.gstatic.com/s/averiaseriflibre/v9/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGGS6qwacqdrKvbQ.ttf';
export const AveriaSerifLibre_700Bold_Italic =
  'http://fonts.gstatic.com/s/averiaseriflibre/v9/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzM2xK0uVLe_bXHq.ttf';
export const BerkshireSwash_400Regular =
  'http://fonts.gstatic.com/s/berkshireswash/v8/ptRRTi-cavZOGqCvnNJDl5m5XmNPrcQybX4pQA.ttf';
export const Codystar_300Light =
  'http://fonts.gstatic.com/s/codystar/v7/FwZf7-Q1xVk-40qxOuYsyuyrj0e29bfC.ttf';
export const Codystar_400Regular =
  'http://fonts.gstatic.com/s/codystar/v7/FwZY7-Q1xVk-40qxOt6A4sijpFu_.ttf';
export const FredokaOne_400Regular =
  'http://fonts.gstatic.com/s/fredokaone/v7/k3kUo8kEI-tA1RRcTZGmTmHBA6aF8Bf_.ttf';
export const Gorditas_400Regular =
  'http://fonts.gstatic.com/s/gorditas/v7/ll8_K2aTVD26DsPEtQDoDa4AlxYb.ttf';
export const Gorditas_700Bold =
  'http://fonts.gstatic.com/s/gorditas/v7/ll84K2aTVD26DsPEtThUIooIvAoShA1i.ttf';
export const Graduate_400Regular =
  'http://fonts.gstatic.com/s/graduate/v7/C8cg4cs3o2n15t_2YxgR6X2NZAn2.ttf';
export const HappyMonkey_400Regular =
  'http://fonts.gstatic.com/s/happymonkey/v8/K2F2fZZcl-9SXwl5F_C4R_OABwD2bWqVjw.ttf';
export const Imprima_400Regular =
  'http://fonts.gstatic.com/s/imprima/v8/VEMxRoN7sY3yuy-7-oWHyDzktPo.ttf';
export const Italiana_400Regular =
  'http://fonts.gstatic.com/s/italiana/v8/QldNNTtLsx4E__B0XTmRY31Wx7Vv.ttf';
export const JollyLodger_400Regular =
  'http://fonts.gstatic.com/s/jollylodger/v7/BXRsvFTAh_bGkA1uQ48dlB3VWerT3ZyuqA.ttf';
export const Karla_400Regular = 'http://fonts.gstatic.com/s/karla/v13/qkBbXvYC6trAT4RSJN225aZO.ttf';
export const Karla_400Regular_Italic =
  'http://fonts.gstatic.com/s/karla/v13/qkBVXvYC6trAT7RQLtmU4LZOgAU.ttf';
export const Karla_700Bold =
  'http://fonts.gstatic.com/s/karla/v13/qkBWXvYC6trAT7zuC_m-zrpHmRzC.ttf';
export const Karla_700Bold_Italic =
  'http://fonts.gstatic.com/s/karla/v13/qkBQXvYC6trAT7RQFmW7xL5lnAzCKNg.ttf';
export const LondrinaOutline_400Regular =
  'http://fonts.gstatic.com/s/londrinaoutline/v10/C8c44dM8vmb14dfsZxhetg3pDH-SfuoxrSKMDvI.ttf';
export const LondrinaShadow_400Regular =
  'http://fonts.gstatic.com/s/londrinashadow/v9/oPWX_kB4kOQoWNJmjxLV5JuoCUlXRlaSxkrMCQ.ttf';
export const LondrinaSketch_400Regular =
  'http://fonts.gstatic.com/s/londrinasketch/v8/c4m41npxGMTnomOHtRU68eIJn8qfWWn5Pos6CA.ttf';
export const LondrinaSolid_100Thin =
  'http://fonts.gstatic.com/s/londrinasolid/v9/flUjRq6sw40kQEJxWNgkLuudGfs9KBYesZHhV64.ttf';
export const LondrinaSolid_300Light =
  'http://fonts.gstatic.com/s/londrinasolid/v9/flUiRq6sw40kQEJxWNgkLuudGfv1CjY0n53oTrcL.ttf';
export const LondrinaSolid_400Regular =
  'http://fonts.gstatic.com/s/londrinasolid/v9/flUhRq6sw40kQEJxWNgkLuudGcNZIhI8tIHh.ttf';
export const LondrinaSolid_900Black =
  'http://fonts.gstatic.com/s/londrinasolid/v9/flUiRq6sw40kQEJxWNgkLuudGfvdDzY0n53oTrcL.ttf';
export const PontanoSans_400Regular =
  'http://fonts.gstatic.com/s/pontanosans/v7/qFdD35GdgYR8EzR6oBLDHa3qwjUMg1siNQ.ttf';
export const Revalia_400Regular =
  'http://fonts.gstatic.com/s/revalia/v7/WwkexPimBE2-4ZPEeVruNIgJSNM.ttf';
export const AdventPro_100Thin =
  'http://fonts.gstatic.com/s/adventpro/v10/V8mCoQfxVT4Dvddr_yOwjVmtLZxcBtItFw.ttf';
export const AdventPro_200ExtraLight =
  'http://fonts.gstatic.com/s/adventpro/v10/V8mDoQfxVT4Dvddr_yOwjfWMDbZyCts0DqQ.ttf';
export const AdventPro_300Light =
  'http://fonts.gstatic.com/s/adventpro/v10/V8mDoQfxVT4Dvddr_yOwjZGPDbZyCts0DqQ.ttf';
export const AdventPro_400Regular =
  'http://fonts.gstatic.com/s/adventpro/v10/V8mAoQfxVT4Dvddr_yOwtT2nKb5ZFtI.ttf';
export const AdventPro_500Medium =
  'http://fonts.gstatic.com/s/adventpro/v10/V8mDoQfxVT4Dvddr_yOwjcmODbZyCts0DqQ.ttf';
export const AdventPro_600SemiBold =
  'http://fonts.gstatic.com/s/adventpro/v10/V8mDoQfxVT4Dvddr_yOwjeWJDbZyCts0DqQ.ttf';
export const AdventPro_700Bold =
  'http://fonts.gstatic.com/s/adventpro/v10/V8mDoQfxVT4Dvddr_yOwjYGIDbZyCts0DqQ.ttf';
export const CantataOne_400Regular =
  'http://fonts.gstatic.com/s/cantataone/v9/PlI5Fl60Nb5obNzNe2jslVxEt8CwfGaD.ttf';
export const Cutive_400Regular =
  'http://fonts.gstatic.com/s/cutive/v11/NaPZcZ_fHOhV3Ip7T_hDoyqlZQ.ttf';
export const Economica_400Regular =
  'http://fonts.gstatic.com/s/economica/v7/Qw3fZQZaHCLgIWa29ZBrMcgAAl1lfQ.ttf';
export const Economica_400Regular_Italic =
  'http://fonts.gstatic.com/s/economica/v7/Qw3ZZQZaHCLgIWa29ZBbM8IEIFh1fWUl.ttf';
export const Economica_700Bold =
  'http://fonts.gstatic.com/s/economica/v7/Qw3aZQZaHCLgIWa29ZBTjeckCnZ5dHw8iw.ttf';
export const Economica_700Bold_Italic =
  'http://fonts.gstatic.com/s/economica/v7/Qw3EZQZaHCLgIWa29ZBbM_q4D3x9Vnksi4M7.ttf';
export const EmilysCandy_400Regular =
  'http://fonts.gstatic.com/s/emilyscandy/v7/2EbgL-1mD1Rnb0OGKudbk0y5r9xrX84JjA.ttf';
export const MysteryQuest_400Regular =
  'http://fonts.gstatic.com/s/mysteryquest/v7/-nF6OG414u0E6k0wynSGlujRHwElD_9Qz9E.ttf';
export const Oswald_200ExtraLight =
  'http://fonts.gstatic.com/s/oswald/v31/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf';
export const Oswald_300Light =
  'http://fonts.gstatic.com/s/oswald/v31/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf';
export const Oswald_400Regular =
  'http://fonts.gstatic.com/s/oswald/v31/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf';
export const Oswald_500Medium =
  'http://fonts.gstatic.com/s/oswald/v31/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf';
export const Oswald_600SemiBold =
  'http://fonts.gstatic.com/s/oswald/v31/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf';
export const Oswald_700Bold =
  'http://fonts.gstatic.com/s/oswald/v31/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf';
export const PTMono_400Regular =
  'http://fonts.gstatic.com/s/ptmono/v7/9oRONYoBnWILk-9ArCg5MtPyAcg.ttf';
export const PoiretOne_400Regular =
  'http://fonts.gstatic.com/s/poiretone/v8/UqyVK80NJXN4zfRgbdfbk5lWVscxdKE.ttf';
export const ProstoOne_400Regular =
  'http://fonts.gstatic.com/s/prostoone/v8/OpNJno4VhNfK-RgpwWWxpipfWhXD00c.ttf';
export const SeaweedScript_400Regular =
  'http://fonts.gstatic.com/s/seaweedscript/v7/bx6cNx6Tne2pxOATYE8C_Rsoe0WJ-KcGVbLW.ttf';
export const DoppioOne_400Regular =
  'http://fonts.gstatic.com/s/doppioone/v7/Gg8wN5gSaBfyBw2MqCh-lgshKGpe5Fg.ttf';
export const GlassAntiqua_400Regular =
  'http://fonts.gstatic.com/s/glassantiqua/v7/xfu30Wr0Wn3NOQM2piC0uXOjnL_wN6fRUkY.ttf';
export const HennyPenny_400Regular =
  'http://fonts.gstatic.com/s/hennypenny/v7/wXKvE3UZookzsxz_kjGSfMQqt3M7tMDT.ttf';
export const KronaOne_400Regular =
  'http://fonts.gstatic.com/s/kronaone/v8/jAnEgHdjHcjgfIb1ZcUCMY-h3cWkWg.ttf';
export const Ledger_400Regular =
  'http://fonts.gstatic.com/s/ledger/v7/j8_q6-HK1L3if_sxm8DwHTBhHw.ttf';
export const Sevillana_400Regular =
  'http://fonts.gstatic.com/s/sevillana/v8/KFOlCnWFscmDt1Bfiy1vAx05IsDqlA.ttf';
export const ShadowsIntoLightTwo_400Regular =
  'http://fonts.gstatic.com/s/shadowsintolighttwo/v7/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmlAvNGLNnIF0.ttf';
export const Voces_400Regular = 'http://fonts.gstatic.com/s/voces/v9/-F6_fjJyLyU8d4PBBG7YpzlJ.ttf';
export const ButterflyKids_400Regular =
  'http://fonts.gstatic.com/s/butterflykids/v8/ll8lK2CWTjuqAsXDqlnIbMNs5S4arxFrAX1D.ttf';
export const PrincessSofia_400Regular =
  'http://fonts.gstatic.com/s/princesssofia/v8/qWczB6yguIb8DZ_GXZst16n7GRz7mDUoupoI.ttf';
export const QuattrocentoSans_400Regular =
  'http://fonts.gstatic.com/s/quattrocentosans/v12/va9c4lja2NVIDdIAAoMR5MfuElaRB3zOvU7eHGHJ.ttf';
export const QuattrocentoSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/quattrocentosans/v12/va9a4lja2NVIDdIAAoMR5MfuElaRB0zMt0r8GXHJkLI.ttf';
export const QuattrocentoSans_700Bold =
  'http://fonts.gstatic.com/s/quattrocentosans/v12/va9Z4lja2NVIDdIAAoMR5MfuElaRB0RykmrWN33AiasJ.ttf';
export const QuattrocentoSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/quattrocentosans/v12/va9X4lja2NVIDdIAAoMR5MfuElaRB0zMj_bTPXnijLsJV7E.ttf';
export const StintUltraExpanded_400Regular =
  'http://fonts.gstatic.com/s/stintultraexpanded/v7/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd0qoATQkWwam.ttf';
export const Allura_400Regular =
  'http://fonts.gstatic.com/s/allura/v8/9oRPNYsQpS4zjuAPjAIXPtrrGA.ttf';
export const EuphoriaScript_400Regular =
  'http://fonts.gstatic.com/s/euphoriascript/v8/mFTpWb0X2bLb_cx6To2B8GpKoD5ak_ZT1D8x7Q.ttf';
export const Ewert_400Regular = 'http://fonts.gstatic.com/s/ewert/v7/va9I4kzO2tFODYBvS-J3kbDP.ttf';
export const Exo_100Thin =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM2CwNsOl4p5Is.ttf';
export const Exo_200ExtraLight =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4tM3CwNsOl4p5Is.ttf';
export const Exo_300Light =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4g03CwNsOl4p5Is.ttf';
export const Exo_400Regular =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3CwNsOl4p5Is.ttf';
export const Exo_500Medium =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4mE3CwNsOl4p5Is.ttf';
export const Exo_600SemiBold =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4o0wCwNsOl4p5Is.ttf';
export const Exo_700Bold =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwCwNsOl4p5Is.ttf';
export const Exo_800ExtraBold =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4tMwCwNsOl4p5Is.ttf';
export const Exo_900Black =
  'http://fonts.gstatic.com/s/exo/v10/4UaZrEtFpBI4f1ZSIK9d4LjJ4vowCwNsOl4p5Is.ttf';
export const Exo_100Thin_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t040FmPnws9Iu-uA.ttf';
export const Exo_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t0Y0BmPnws9Iu-uA.ttf';
export const Exo_300Light_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t0vUBmPnws9Iu-uA.ttf';
export const Exo_400Regular_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t040BmPnws9Iu-uA.ttf';
export const Exo_500Medium_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t00UBmPnws9Iu-uA.ttf';
export const Exo_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t0PUdmPnws9Iu-uA.ttf';
export const Exo_700Bold_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t0BEdmPnws9Iu-uA.ttf';
export const Exo_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t0Y0dmPnws9Iu-uA.ttf';
export const Exo_900Black_Italic =
  'http://fonts.gstatic.com/s/exo/v10/4UafrEtFpBISdmSt-MY2ehbO95t0SkdmPnws9Iu-uA.ttf';
export const Felipa_400Regular =
  'http://fonts.gstatic.com/s/felipa/v7/FwZa7-owz1Eu4F_wSNSEwM2zpA.ttf';
export const Norican_400Regular =
  'http://fonts.gstatic.com/s/norican/v8/MwQ2bhXp1eSBqjkPGJJRtGs-lbA.ttf';
export const NoticiaText_400Regular =
  'http://fonts.gstatic.com/s/noticiatext/v9/VuJ2dNDF2Yv9qppOePKYRP1GYTFZt0rNpQ.ttf';
export const NoticiaText_400Regular_Italic =
  'http://fonts.gstatic.com/s/noticiatext/v9/VuJodNDF2Yv9qppOePKYRP12YztdlU_dpSjt.ttf';
export const NoticiaText_700Bold =
  'http://fonts.gstatic.com/s/noticiatext/v9/VuJpdNDF2Yv9qppOePKYRP1-3R59v2HRrDH0eA.ttf';
export const NoticiaText_700Bold_Italic =
  'http://fonts.gstatic.com/s/noticiatext/v9/VuJrdNDF2Yv9qppOePKYRP12YwPhumvVjjTkeMnz.ttf';
export const Share_400Regular = 'http://fonts.gstatic.com/s/share/v10/i7dEIFliZjKNF5VNHLq2cV5d.ttf';
export const Share_400Regular_Italic =
  'http://fonts.gstatic.com/s/share/v10/i7dKIFliZjKNF6VPFr6UdE5dWFM.ttf';
export const Share_700Bold =
  'http://fonts.gstatic.com/s/share/v10/i7dJIFliZjKNF63xM56-WkJUQUq7.ttf';
export const Share_700Bold_Italic =
  'http://fonts.gstatic.com/s/share/v10/i7dPIFliZjKNF6VPLgK7UEZ2RFq7AwU.ttf';
export const Asap_400Regular = 'http://fonts.gstatic.com/s/asap/v11/KFOoCniXp96a-zwU4UROGzY.ttf';
export const Asap_400Regular_Italic =
  'http://fonts.gstatic.com/s/asap/v11/KFOmCniXp96ayz4e5WZLCzYlKw.ttf';
export const Asap_500Medium = 'http://fonts.gstatic.com/s/asap/v11/KFOnCniXp96aw8g9xUxlBz88MsA.ttf';
export const Asap_500Medium_Italic =
  'http://fonts.gstatic.com/s/asap/v11/KFOlCniXp96ayz4mEU9vAx05IsDqlA.ttf';
export const Asap_600SemiBold =
  'http://fonts.gstatic.com/s/asap/v11/KFOnCniXp96aw-Q6xUxlBz88MsA.ttf';
export const Asap_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/asap/v11/KFOlCniXp96ayz4mPUhvAx05IsDqlA.ttf';
export const Asap_700Bold = 'http://fonts.gstatic.com/s/asap/v11/KFOnCniXp96aw4A7xUxlBz88MsA.ttf';
export const Asap_700Bold_Italic =
  'http://fonts.gstatic.com/s/asap/v11/KFOlCniXp96ayz4mWUlvAx05IsDqlA.ttf';
export const Condiment_400Regular =
  'http://fonts.gstatic.com/s/condiment/v7/pONk1hggFNmwvXALyH6Sq4n4o1vyCQ.ttf';
export const Diplomata_400Regular =
  'http://fonts.gstatic.com/s/diplomata/v11/Cn-0JtiMXwhNwp-wKxyfYGxYrdM9Sg.ttf';
export const DiplomataSC_400Regular =
  'http://fonts.gstatic.com/s/diplomatasc/v8/buExpoi3ecvs3kidKgBJo2kf-P5Oaiw4cw.ttf';
export const Glegoo_400Regular =
  'http://fonts.gstatic.com/s/glegoo/v9/_Xmt-HQyrTKWaw2Ji6mZAI91xw.ttf';
export const Glegoo_700Bold =
  'http://fonts.gstatic.com/s/glegoo/v9/_Xmu-HQyrTKWaw2xN4a9CKRpzimMsg.ttf';
export const Iceberg_400Regular =
  'http://fonts.gstatic.com/s/iceberg/v7/8QIJdijAiM7o-qnZuIgOq7jkAOw.ttf';
export const KaushanScript_400Regular =
  'http://fonts.gstatic.com/s/kaushanscript/v8/vm8vdRfvXFLG3OLnsO15WYS5DF7_ytN3M48a.ttf';
export const KottaOne_400Regular =
  'http://fonts.gstatic.com/s/kottaone/v7/S6u_w41LXzPc_jlfNWqPHA3s5dwt7w.ttf';
export const Parisienne_400Regular =
  'http://fonts.gstatic.com/s/parisienne/v7/E21i_d3kivvAkxhLEVZpcy96DuKuavM.ttf';
export const RopaSans_400Regular =
  'http://fonts.gstatic.com/s/ropasans/v9/EYqxmaNOzLlWtsZSScyKWjloU5KP2g.ttf';
export const RopaSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/ropasans/v9/EYq3maNOzLlWtsZSScy6WDNscZef2mNE.ttf';
export const Shojumaru_400Regular =
  'http://fonts.gstatic.com/s/shojumaru/v7/rax_HiWfutkLLnaKCtlMBBJek0vA8A.ttf';
export const Amethysta_400Regular =
  'http://fonts.gstatic.com/s/amethysta/v8/rP2Fp2K15kgb_F3ibfWIGDWCBl0O8Q.ttf';
export const EmblemaOne_400Regular =
  'http://fonts.gstatic.com/s/emblemaone/v8/nKKT-GQ0F5dSY8vzG0rOEIRBHl57G_f_.ttf';
export const EricaOne_400Regular =
  'http://fonts.gstatic.com/s/ericaone/v10/WBLnrEXccV9VGrOKmGD1W0_MJMGxiQ.ttf';
export const GermaniaOne_400Regular =
  'http://fonts.gstatic.com/s/germaniaone/v7/Fh4yPjrqIyv2ucM2qzBjeS3ezAJONau6ew.ttf';
export const Gudea_400Regular = 'http://fonts.gstatic.com/s/gudea/v9/neIFzCqgsI0mp-CP9IGON7Ez.ttf';
export const Gudea_400Regular_Italic =
  'http://fonts.gstatic.com/s/gudea/v9/neILzCqgsI0mp9CN_oWsMqEzSJQ.ttf';
export const Gudea_700Bold = 'http://fonts.gstatic.com/s/gudea/v9/neIIzCqgsI0mp9gz26WGHK06UY30.ttf';
export const Homenaje_400Regular =
  'http://fonts.gstatic.com/s/homenaje/v9/FwZY7-Q-xVAi_l-6Ld6A4sijpFu_.ttf';
export const Junge_400Regular = 'http://fonts.gstatic.com/s/junge/v7/gokgH670Gl1lUqAdvhB7SnKm.ttf';
export const Lustria_400Regular =
  'http://fonts.gstatic.com/s/lustria/v7/9oRONYodvDEyjuhOrCg5MtPyAcg.ttf';
export const Macondo_400Regular =
  'http://fonts.gstatic.com/s/macondo/v8/RrQQboN9-iB1IXmOS2XO0LBBd4Y.ttf';
export const MacondoSwashCaps_400Regular =
  'http://fonts.gstatic.com/s/macondoswashcaps/v7/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6Oekoa_mm5bA.ttf';
export const Montaga_400Regular =
  'http://fonts.gstatic.com/s/montaga/v7/H4cnBX2Ml8rCkEO_0gYQ7LO5mqc.ttf';
export const PortLligatSans_400Regular =
  'http://fonts.gstatic.com/s/portlligatsans/v8/kmKmZrYrGBbdN1aV7Vokow6Lw4s4l7N0Tx4xEcQ.ttf';
export const PortLligatSlab_400Regular =
  'http://fonts.gstatic.com/s/portlligatslab/v8/LDIpaoiQNgArA8kR7ulhZ8P_NYOss7ob9yGLmfI.ttf';
export const Scheherazade_400Regular =
  'http://fonts.gstatic.com/s/scheherazade/v17/YA9Ur0yF4ETZN60keViq1kQgt5OohvbJ9A.ttf';
export const Scheherazade_700Bold =
  'http://fonts.gstatic.com/s/scheherazade/v17/YA9Lr0yF4ETZN60keViq1kQYC7yMjt3V_dB0Yw.ttf';
export const SirinStencil_400Regular =
  'http://fonts.gstatic.com/s/sirinstencil/v8/mem4YaWwznmLx-lzGfN7MdRydchGBq6al6o.ttf';
export const SonsieOne_400Regular =
  'http://fonts.gstatic.com/s/sonsieone/v8/PbymFmP_EAnPqbKaoc18YVu80lbp8JM.ttf';
export const Telex_400Regular = 'http://fonts.gstatic.com/s/telex/v8/ieVw2Y1fKWmIO9fTB1piKFIf.ttf';
export const Trochut_400Regular =
  'http://fonts.gstatic.com/s/trochut/v7/CHyjV-fDDlP9bDIw5nSIfVIPLns.ttf';
export const Trochut_400Regular_Italic =
  'http://fonts.gstatic.com/s/trochut/v7/CHyhV-fDDlP9bDIw1naCeXAKPns8jw.ttf';
export const Trochut_700Bold =
  'http://fonts.gstatic.com/s/trochut/v7/CHymV-fDDlP9bDIw3sinWVokMnIllmA.ttf';
export const Esteban_400Regular =
  'http://fonts.gstatic.com/s/esteban/v8/r05bGLZE-bdGdN-GdOuD5jokU8E.ttf';
export const Inika_400Regular = 'http://fonts.gstatic.com/s/inika/v8/rnCm-x5X3QP-phTHRcc2s2XH.ttf';
export const Inika_700Bold = 'http://fonts.gstatic.com/s/inika/v8/rnCr-x5X3QP-pix7auM-mHnOSOuk.ttf';
export const LilitaOne_400Regular =
  'http://fonts.gstatic.com/s/lilitaone/v7/i7dPIFZ9Zz-WBtRtedDbUEZ2RFq7AwU.ttf';
export const Lusitana_400Regular =
  'http://fonts.gstatic.com/s/lusitana/v7/CSR84z9ShvucWzsMKxhaRuMiSct_.ttf';
export const Lusitana_700Bold =
  'http://fonts.gstatic.com/s/lusitana/v7/CSR74z9ShvucWzsMKyDmaccqYtd2vfwk.ttf';
export const Magra_400Regular = 'http://fonts.gstatic.com/s/magra/v8/uK_94ruaZus72k5xIDMfO-ed.ttf';
export const Magra_700Bold = 'http://fonts.gstatic.com/s/magra/v8/uK_w4ruaZus72nbNDxcXEPuUX1ow.ttf';
export const MrBedfort_400Regular =
  'http://fonts.gstatic.com/s/mrbedfort/v8/MQpR-WCtNZSWAdTMwBicliq0XZe_Iy8.ttf';
export const MrsSaintDelafield_400Regular =
  'http://fonts.gstatic.com/s/mrssaintdelafield/v7/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62cK4tLsubB2w.ttf';
export const RougeScript_400Regular =
  'http://fonts.gstatic.com/s/rougescript/v8/LYjFdGbiklMoCIQOw1Ep3S4PVPXbUJWq9g.ttf';
export const Ruda_400Regular =
  'http://fonts.gstatic.com/s/ruda/v12/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsi_-2KiSGg-H.ttf';
export const Ruda_500Medium =
  'http://fonts.gstatic.com/s/ruda/v12/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJ3si_-2KiSGg-H.ttf';
export const Ruda_600SemiBold =
  'http://fonts.gstatic.com/s/ruda/v12/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKbtS_-2KiSGg-H.ttf';
export const Ruda_700Bold =
  'http://fonts.gstatic.com/s/ruda/v12/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKitS_-2KiSGg-H.ttf';
export const Ruda_800ExtraBold =
  'http://fonts.gstatic.com/s/ruda/v12/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLFtS_-2KiSGg-H.ttf';
export const Ruda_900Black =
  'http://fonts.gstatic.com/s/ruda/v12/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLstS_-2KiSGg-H.ttf';
export const Ruluko_400Regular =
  'http://fonts.gstatic.com/s/ruluko/v7/xMQVuFNZVaODtm0pC6WzKX_QmA.ttf';
export const TitanOne_400Regular =
  'http://fonts.gstatic.com/s/titanone/v7/mFTzWbsGxbbS_J5cQcjykzIn2Etikg.ttf';
export const Wellfleet_400Regular =
  'http://fonts.gstatic.com/s/wellfleet/v7/nuF7D_LfQJb3VYgX6eyT42aLDhO2HA.ttf';
export const ChelseaMarket_400Regular =
  'http://fonts.gstatic.com/s/chelseamarket/v7/BCawqZsHqfr89WNP_IApC8tzKBhlLA4uKkWk.ttf';
export const JimNightshade_400Regular =
  'http://fonts.gstatic.com/s/jimnightshade/v7/PlIkFlu9Pb08Q8HLM1PxmB0g-OS4V3qKaMxD.ttf';
export const Acme_400Regular = 'http://fonts.gstatic.com/s/acme/v9/RrQfboBx-C5_bx3Lb23lzLk.ttf';
export const Alegreya_400Regular =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaBrEBBsBhlBjvfkRLmzanB44N1.ttf';
export const Alegreya_400Regular_Italic =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaHrEBBsBhlBjvfkSLkx63j5pN1MwI.ttf';
export const Alegreya_500Medium =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaGrEBBsBhlBjvfkSoS5I3JyJ98KhtH.ttf';
export const Alegreya_500Medium_Italic =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaErEBBsBhlBjvfkSLk_1nKwpteLwtHJlc.ttf';
export const Alegreya_700Bold =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaGrEBBsBhlBjvfkSpa4o3JyJ98KhtH.ttf';
export const Alegreya_700Bold_Italic =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaErEBBsBhlBjvfkSLk_xHMwpteLwtHJlc.ttf';
export const Alegreya_800ExtraBold =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaGrEBBsBhlBjvfkSpG4Y3JyJ98KhtH.ttf';
export const Alegreya_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaErEBBsBhlBjvfkSLk_w3PwpteLwtHJlc.ttf';
export const Alegreya_900Black =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaGrEBBsBhlBjvfkSpi4I3JyJ98KhtH.ttf';
export const Alegreya_900Black_Italic =
  'http://fonts.gstatic.com/s/alegreya/v13/4UaErEBBsBhlBjvfkSLk_ynOwpteLwtHJlc.ttf';
export const AlegreyaSC_400Regular =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiOGmRtCJ62-O0HhNEa-a6o05E5abe_.ttf';
export const AlegreyaSC_400Regular_Italic =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiMGmRtCJ62-O0HhNEa-Z6q2ZUbbKe_DGs.ttf';
export const AlegreyaSC_500Medium =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiTGmRtCJ62-O0HhNEa-ZZc-rUxQqu2FXKD.ttf';
export const AlegreyaSC_500Medium_Italic =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiRGmRtCJ62-O0HhNEa-Z6q4WEySK-UEGKDBz4.ttf';
export const AlegreyaSC_700Bold =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiTGmRtCJ62-O0HhNEa-ZYU_LUxQqu2FXKD.ttf';
export const AlegreyaSC_700Bold_Italic =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiRGmRtCJ62-O0HhNEa-Z6q4Sk0SK-UEGKDBz4.ttf';
export const AlegreyaSC_800ExtraBold =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiTGmRtCJ62-O0HhNEa-ZYI_7UxQqu2FXKD.ttf';
export const AlegreyaSC_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiRGmRtCJ62-O0HhNEa-Z6q4TU3SK-UEGKDBz4.ttf';
export const AlegreyaSC_900Black =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiTGmRtCJ62-O0HhNEa-ZYs_rUxQqu2FXKD.ttf';
export const AlegreyaSC_900Black_Italic =
  'http://fonts.gstatic.com/s/alegreyasc/v11/taiRGmRtCJ62-O0HhNEa-Z6q4RE2SK-UEGKDBz4.ttf';
export const AlexBrush_400Regular =
  'http://fonts.gstatic.com/s/alexbrush/v11/SZc83FzrJKuqFbwMKk6EtUL57DtOmCc.ttf';
export const AlfaSlabOne_400Regular =
  'http://fonts.gstatic.com/s/alfaslabone/v9/6NUQ8FmMKwSEKjnm5-4v-4Jh6dVretWvYmE.ttf';
export const Almendra_400Regular =
  'http://fonts.gstatic.com/s/almendra/v12/H4ckBXKAlMnTn0CskyY6wr-wg763.ttf';
export const Almendra_400Regular_Italic =
  'http://fonts.gstatic.com/s/almendra/v12/H4ciBXKAlMnTn0CskxY4yLuShq63czE.ttf';
export const Almendra_700Bold =
  'http://fonts.gstatic.com/s/almendra/v12/H4cjBXKAlMnTn0Cskx6G7Zu4qKK-aihq.ttf';
export const Almendra_700Bold_Italic =
  'http://fonts.gstatic.com/s/almendra/v12/H4chBXKAlMnTn0CskxY48Ae9oqacbzhqDtg.ttf';
export const AlmendraSC_400Regular =
  'http://fonts.gstatic.com/s/almendrasc/v10/Iure6Yx284eebowr7hbyTZZJprVA4XQ0.ttf';
export const Arizonia_400Regular =
  'http://fonts.gstatic.com/s/arizonia/v10/neIIzCemt4A5qa7mv6WGHK06UY30.ttf';
export const Armata_400Regular =
  'http://fonts.gstatic.com/s/armata/v11/gokvH63_HV5jQ-E9lD53Q2u_mQ.ttf';
export const Asul_400Regular = 'http://fonts.gstatic.com/s/asul/v9/VuJ-dNjKxYr46fMFXK78JIg.ttf';
export const Asul_700Bold = 'http://fonts.gstatic.com/s/asul/v9/VuJxdNjKxYr40U8qeKbXOIFneRo.ttf';
export const Belgrano_400Regular =
  'http://fonts.gstatic.com/s/belgrano/v10/55xvey5tM9rwKWrJZcMFirl08KDJ.ttf';
export const Bitter_400Regular =
  'http://fonts.gstatic.com/s/bitter/v15/rax8HiqOu8IVPmnLeIZoDDlCmg.ttf';
export const Bitter_400Regular_Italic =
  'http://fonts.gstatic.com/s/bitter/v15/rax-HiqOu8IVPmn7eoxsLjxSmlLZ.ttf';
export const Bitter_700Bold =
  'http://fonts.gstatic.com/s/bitter/v15/rax_HiqOu8IVPmnzxKlMBBJek0vA8A.ttf';
export const Boogaloo_400Regular =
  'http://fonts.gstatic.com/s/boogaloo/v11/kmK-Zq45GAvOdnaW6x1F_SrQo_1K.ttf';
export const BreeSerif_400Regular =
  'http://fonts.gstatic.com/s/breeserif/v9/4UaHrEJCrhhnVA3DgluAx63j5pN1MwI.ttf';
export const Buenard_400Regular =
  'http://fonts.gstatic.com/s/buenard/v11/OD5DuM6Cyma8FnnsPzf9qGi9HL4.ttf';
export const Buenard_700Bold =
  'http://fonts.gstatic.com/s/buenard/v11/OD5GuM6Cyma8FnnsB4vSjGCWALepwss.ttf';
export const Butcherman_400Regular =
  'http://fonts.gstatic.com/s/butcherman/v11/2EbiL-thF0loflXUBOdb1zWzq_5uT84.ttf';
export const CaesarDressing_400Regular =
  'http://fonts.gstatic.com/s/caesardressing/v8/yYLx0hLa3vawqtwdswbotmK4vrR3cbb6LZttyg.ttf';
export const Cambo_400Regular = 'http://fonts.gstatic.com/s/cambo/v8/IFSqHeNEk8FJk416ok7xkPm8.ttf';
export const Creepster_400Regular =
  'http://fonts.gstatic.com/s/creepster/v8/AlZy_zVUqJz4yMrniH4hdXf4XB0Tow.ttf';
export const CreteRound_400Regular =
  'http://fonts.gstatic.com/s/creteround/v8/55xoey1sJNPjPiv1ZZZrxJ1827zAKnxN.ttf';
export const CreteRound_400Regular_Italic =
  'http://fonts.gstatic.com/s/creteround/v8/55xqey1sJNPjPiv1ZZZrxK1-0bjiL2xNhKc.ttf';
export const DuruSans_400Regular =
  'http://fonts.gstatic.com/s/durusans/v13/xn7iYH8xwmSyTvEV_HOxT_fYdN-WZw.ttf';
export const Dynalight_400Regular =
  'http://fonts.gstatic.com/s/dynalight/v8/1Ptsg8LOU_aOmQvTsF4ISotrDfGGxA.ttf';
export const Eater_400Regular = 'http://fonts.gstatic.com/s/eater/v8/mtG04_FCK7bOvpu2u3FwsXsR.ttf';
export const Flamenco_300Light =
  'http://fonts.gstatic.com/s/flamenco/v10/neIPzCehqYguo67ssZ0qNIkyepH9qGsf.ttf';
export const Flamenco_400Regular =
  'http://fonts.gstatic.com/s/flamenco/v10/neIIzCehqYguo67ssaWGHK06UY30.ttf';
export const Flavors_400Regular =
  'http://fonts.gstatic.com/s/flavors/v9/FBV2dDrhxqmveJTpbkzlNqkG9UY.ttf';
export const FrederickatheGreat_400Regular =
  'http://fonts.gstatic.com/s/frederickathegreat/v9/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV-9Skz7Ylch2L.ttf';
export const Frijole_400Regular =
  'http://fonts.gstatic.com/s/frijole/v8/uU9PCBUR8oakM2BQ7xPb3vyHmlI.ttf';
export const FugazOne_400Regular =
  'http://fonts.gstatic.com/s/fugazone/v9/rax_HiWKp9EAITukFslMBBJek0vA8A.ttf';
export const Habibi_400Regular =
  'http://fonts.gstatic.com/s/habibi/v8/CSR-4zFWkuqcTTNCShJeZOYySQ.ttf';
export const Inder_400Regular = 'http://fonts.gstatic.com/s/inder/v8/w8gUH2YoQe8_4vq6pw-P3U4O.ttf';
export const Italianno_400Regular =
  'http://fonts.gstatic.com/s/italianno/v9/dg4n_p3sv6gCJkwzT6Rnj5YpQwM-gg.ttf';
export const MedulaOne_400Regular =
  'http://fonts.gstatic.com/s/medulaone/v9/YA9Wr0qb5kjJM6l2V0yukiEqs7GtlvY.ttf';
export const Miniver_400Regular =
  'http://fonts.gstatic.com/s/miniver/v8/eLGcP-PxIg-5H0vC770Cy8r8fWA.ttf';
export const Nosifer_400Regular =
  'http://fonts.gstatic.com/s/nosifer/v8/ZGjXol5JTp0g5bxZaC1RVDNdGDs.ttf';
export const Oldenburg_400Regular =
  'http://fonts.gstatic.com/s/oldenburg/v7/fC1jPY5JYWzbywv7c4V6UU6oXyndrw.ttf';
export const Overlock_400Regular =
  'http://fonts.gstatic.com/s/overlock/v9/Z9XVDmdMWRiN1_T9Z4Te4u2El6GC.ttf';
export const Overlock_400Regular_Italic =
  'http://fonts.gstatic.com/s/overlock/v9/Z9XTDmdMWRiN1_T9Z7Tc6OmmkrGC7Cs.ttf';
export const Overlock_700Bold =
  'http://fonts.gstatic.com/s/overlock/v9/Z9XSDmdMWRiN1_T9Z7xizcmMvL2L9TLT.ttf';
export const Overlock_700Bold_Italic =
  'http://fonts.gstatic.com/s/overlock/v9/Z9XQDmdMWRiN1_T9Z7Tc0FWJtrmp8CLTlNs.ttf';
export const Overlock_900Black =
  'http://fonts.gstatic.com/s/overlock/v9/Z9XSDmdMWRiN1_T9Z7xaz8mMvL2L9TLT.ttf';
export const Overlock_900Black_Italic =
  'http://fonts.gstatic.com/s/overlock/v9/Z9XQDmdMWRiN1_T9Z7Tc0G2Ltrmp8CLTlNs.ttf';
export const OverlockSC_400Regular =
  'http://fonts.gstatic.com/s/overlocksc/v8/1cX3aUHKGZrstGAY8nwVzHGAq8Sk1PoH.ttf';
export const PatuaOne_400Regular =
  'http://fonts.gstatic.com/s/patuaone/v10/ZXuke1cDvLCKLDcimxBI5PNvNA9LuA.ttf';
export const Playball_400Regular =
  'http://fonts.gstatic.com/s/playball/v9/TK3gWksYAxQ7jbsKcj8Dl-tPKo2t.ttf';
export const Quantico_400Regular =
  'http://fonts.gstatic.com/s/quantico/v9/rax-HiSdp9cPL3KIF4xsLjxSmlLZ.ttf';
export const Quantico_400Regular_Italic =
  'http://fonts.gstatic.com/s/quantico/v9/rax4HiSdp9cPL3KIF7xuJDhwn0LZ6T8.ttf';
export const Quantico_700Bold =
  'http://fonts.gstatic.com/s/quantico/v9/rax5HiSdp9cPL3KIF7TQARhasU7Q8Cad.ttf';
export const Quantico_700Bold_Italic =
  'http://fonts.gstatic.com/s/quantico/v9/rax7HiSdp9cPL3KIF7xuHIRfu0ry9TadML4.ttf';
export const Qwigley_400Regular =
  'http://fonts.gstatic.com/s/qwigley/v9/1cXzaU3UGJb5tGoCuVxsi1mBmcE.ttf';
export const RugeBoogie_400Regular =
  'http://fonts.gstatic.com/s/rugeboogie/v10/JIA3UVFwbHRF_GIWSMhKNROiPzUveSxy.ttf';
export const Ruthie_400Regular =
  'http://fonts.gstatic.com/s/ruthie/v10/gokvH63sGkdqXuU9lD53Q2u_mQ.ttf';
export const Sail_400Regular = 'http://fonts.gstatic.com/s/sail/v10/DPEjYwiBxwYJFBTDADYAbvw.ttf';
export const Sarina_400Regular =
  'http://fonts.gstatic.com/s/sarina/v8/-F6wfjF3ITQwasLhLkDUriBQxw.ttf';
export const Sofia_400Regular = 'http://fonts.gstatic.com/s/sofia/v8/8QIHdirahM3j_vu-sowsrqjk.ttf';
export const TradeWinds_400Regular =
  'http://fonts.gstatic.com/s/tradewinds/v8/AYCPpXPpYNIIT7h8-QenM3Jq7PKP5Z_G.ttf';
export const Trykker_400Regular =
  'http://fonts.gstatic.com/s/trykker/v8/KtktALyWZJXudUPzhNnoOd2j22U.ttf';
export const UncialAntiqua_400Regular =
  'http://fonts.gstatic.com/s/uncialantiqua/v7/N0bM2S5WOex4OUbESzoESK-i-PfRS5VBBSSF.ttf';
export const Yesteryear_400Regular =
  'http://fonts.gstatic.com/s/yesteryear/v8/dg4g_p78rroaKl8kRKo1r7wHTwonmyw.ttf';
export const Basic_400Regular = 'http://fonts.gstatic.com/s/basic/v9/xfu_0WLxV2_XKQN34lDVyR7D.ttf';
export const SquadaOne_400Regular =
  'http://fonts.gstatic.com/s/squadaone/v8/BCasqZ8XsOrx4mcOk6MtWaA8WDBkHgs.ttf';
export const BadScript_400Regular =
  'http://fonts.gstatic.com/s/badscript/v8/6NUT8F6PJgbFWQn47_x7lOwuzd1AZtw.ttf';
export const Balthazar_400Regular =
  'http://fonts.gstatic.com/s/balthazar/v9/d6lKkaajS8Gm4CVQjFEvyRTo39l8hw.ttf';
export const BilboSwashCaps_400Regular =
  'http://fonts.gstatic.com/s/bilboswashcaps/v12/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdqAPopiRfKp8.ttf';
export const Chango_400Regular =
  'http://fonts.gstatic.com/s/chango/v8/2V0cKI0OB5U7WaJyz324TFUaAw.ttf';
export const Enriqueta_400Regular =
  'http://fonts.gstatic.com/s/enriqueta/v9/goksH6L7AUFrRvV44HVTS0CjkP1Yog.ttf';
export const Enriqueta_500Medium =
  'http://fonts.gstatic.com/s/enriqueta/v9/gokpH6L7AUFrRvV44HVrv2mHmNZEq6TTFw.ttf';
export const Enriqueta_600SemiBold =
  'http://fonts.gstatic.com/s/enriqueta/v9/gokpH6L7AUFrRvV44HVrk26HmNZEq6TTFw.ttf';
export const Enriqueta_700Bold =
  'http://fonts.gstatic.com/s/enriqueta/v9/gokpH6L7AUFrRvV44HVr92-HmNZEq6TTFw.ttf';
export const Handlee_400Regular =
  'http://fonts.gstatic.com/s/handlee/v8/-F6xfjBsISg9aMakDmr6oilJ3ik.ttf';
export const MarkoOne_400Regular =
  'http://fonts.gstatic.com/s/markoone/v9/9Btq3DFG0cnVM5lw1haaKpUfrHPzUw.ttf';
export const Montserrat_100Thin =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QphziTn89dtpQ.ttf';
export const Montserrat_100Thin_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqi7j0dJ9pTOi.ttf';
export const Montserrat_200ExtraLight =
  'http://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA7g7J_950vCo.ttf';
export const Montserrat_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_D-_xxrCq7qg.ttf';
export const Montserrat_300Light =
  'http://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD7g7J_950vCo.ttf';
export const Montserrat_300Light_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzD-_xxrCq7qg.ttf';
export const Montserrat_400Regular =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm45xW5rygbi49c.ttf';
export const Montserrat_400Regular_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxhziTn89dtpQ.ttf';
export const Montserrat_500Medium =
  'http://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC7g7J_950vCo.ttf';
export const Montserrat_500Medium_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3D-_xxrCq7qg.ttf';
export const Montserrat_600SemiBold =
  'http://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF7g7J_950vCo.ttf';
export const Montserrat_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrD-_xxrCq7qg.ttf';
export const Montserrat_700Bold =
  'http://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE7g7J_950vCo.ttf';
export const Montserrat_700Bold_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvD-_xxrCq7qg.ttf';
export const Montserrat_800ExtraBold =
  'http://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H7g7J_950vCo.ttf';
export const Montserrat_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjD-_xxrCq7qg.ttf';
export const Montserrat_900Black =
  'http://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG7g7J_950vCo.ttf';
export const Montserrat_900Black_Italic =
  'http://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnD-_xxrCq7qg.ttf';
export const PassionOne_400Regular =
  'http://fonts.gstatic.com/s/passionone/v10/PbynFmL8HhTPqbjUzux3JHuW_Frg6YoV.ttf';
export const PassionOne_700Bold =
  'http://fonts.gstatic.com/s/passionone/v10/Pby6FmL8HhTPqbjUzux3JEMq037owpYcuH8y.ttf';
export const PassionOne_900Black =
  'http://fonts.gstatic.com/s/passionone/v10/Pby6FmL8HhTPqbjUzux3JEMS0X7owpYcuH8y.ttf';
export const Plaster_400Regular =
  'http://fonts.gstatic.com/s/plaster/v11/DdTm79QatW80eRh4Ei5JOtLOeLI.ttf';
export const Radley_400Regular =
  'http://fonts.gstatic.com/s/radley/v14/LYjDdGzinEIjCN19oAlEpVs3VQ.ttf';
export const Radley_400Regular_Italic =
  'http://fonts.gstatic.com/s/radley/v14/LYjBdGzinEIjCN1NogNAh14nVcfe.ttf';
export const SpicyRice_400Regular =
  'http://fonts.gstatic.com/s/spicyrice/v8/uK_24rSEd-Uqwk4jY1RyGv-2WkowRcc.ttf';
export const Arbutus_400Regular =
  'http://fonts.gstatic.com/s/arbutus/v9/NaPYcZ7dG_5J3poob9JtryO8fMU.ttf';
export const Baumans_400Regular =
  'http://fonts.gstatic.com/s/baumans/v9/-W_-XJj9QyTd3QfpR_oyaksqY5Q.ttf';
export const Bilbo_400Regular = 'http://fonts.gstatic.com/s/bilbo/v9/o-0EIpgpwWwZ210hpIRz4wxE.ttf';
export const Bonbon_400Regular =
  'http://fonts.gstatic.com/s/bonbon/v11/0FlVVPeVlFec4ee_cDEAbQY5-A.ttf';
export const CevicheOne_400Regular =
  'http://fonts.gstatic.com/s/cevicheone/v10/gyB4hws1IcA6JzR-GB_JX6zdZ4vZVbgZ.ttf';
export const Chivo_300Light =
  'http://fonts.gstatic.com/s/chivo/v11/va9F4kzIxd1KFrjDY8Z_uqzGQC_-.ttf';
export const Chivo_300Light_Italic =
  'http://fonts.gstatic.com/s/chivo/v11/va9D4kzIxd1KFrBteUp9sKjkRT_-bF0.ttf';
export const Chivo_400Regular = 'http://fonts.gstatic.com/s/chivo/v11/va9I4kzIxd1KFoBvS-J3kbDP.ttf';
export const Chivo_400Regular_Italic =
  'http://fonts.gstatic.com/s/chivo/v11/va9G4kzIxd1KFrBtQeZVlKDPWTY.ttf';
export const Chivo_700Bold =
  'http://fonts.gstatic.com/s/chivo/v11/va9F4kzIxd1KFrjTZMZ_uqzGQC_-.ttf';
export const Chivo_700Bold_Italic =
  'http://fonts.gstatic.com/s/chivo/v11/va9D4kzIxd1KFrBteVp6sKjkRT_-bF0.ttf';
export const Chivo_900Black =
  'http://fonts.gstatic.com/s/chivo/v11/va9F4kzIxd1KFrjrZsZ_uqzGQC_-.ttf';
export const Chivo_900Black_Italic =
  'http://fonts.gstatic.com/s/chivo/v11/va9D4kzIxd1KFrBteWJ4sKjkRT_-bF0.ttf';
export const Electrolize_400Regular =
  'http://fonts.gstatic.com/s/electrolize/v8/cIf5Ma1dtE0zSiGSiED7AUEGso5tQafB.ttf';
export const Engagement_400Regular =
  'http://fonts.gstatic.com/s/engagement/v9/x3dlckLDZbqa7RUs9MFVXNossybsHQI.ttf';
export const Fascinate_400Regular =
  'http://fonts.gstatic.com/s/fascinate/v8/z7NWdRrufC8XJK0IIEli1LbQRPyNrw.ttf';
export const FascinateInline_400Regular =
  'http://fonts.gstatic.com/s/fascinateinline/v9/jVyR7mzzB3zc-jp6QCAu60poNqIy1g3CfRXxWZQ.ttf';
export const Fresca_400Regular =
  'http://fonts.gstatic.com/s/fresca/v8/6ae94K--SKgCzbM2Gr0W13DKPA.ttf';
export const Galdeano_400Regular =
  'http://fonts.gstatic.com/s/galdeano/v9/uU9MCBoQ4YOqOW1boDPx8PCOg0uX.ttf';
export const Marmelad_400Regular =
  'http://fonts.gstatic.com/s/marmelad/v9/Qw3eZQdSHj_jK2e-8tFLG-YMC0R8.ttf';
export const Metamorphous_400Regular =
  'http://fonts.gstatic.com/s/metamorphous/v10/Wnz8HA03aAXcC39ZEX5y1330PCCthTsmaQ.ttf';
export const OriginalSurfer_400Regular =
  'http://fonts.gstatic.com/s/originalsurfer/v8/RWmQoKGZ9vIirYntXJ3_MbekzNMiDEtvAlaMKw.ttf';
export const StintUltraCondensed_400Regular =
  'http://fonts.gstatic.com/s/stintultracondensed/v8/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2A-qhUO2cNvdg.ttf';
export const Viga_400Regular = 'http://fonts.gstatic.com/s/viga/v8/xMQbuFFdSaiX_QIjD4e2OX8.ttf';
export const Unkempt_400Regular =
  'http://fonts.gstatic.com/s/unkempt/v11/2EbnL-Z2DFZue0DSSYYf8z2Yt_c.ttf';
export const Unkempt_700Bold =
  'http://fonts.gstatic.com/s/unkempt/v11/2EbiL-Z2DFZue0DScTow1zWzq_5uT84.ttf';
export const AguafinaScript_400Regular =
  'http://fonts.gstatic.com/s/aguafinascript/v8/If2QXTv_ZzSxGIO30LemWEOmt1bHqs4pgicOrg.ttf';
export const Aladin_400Regular =
  'http://fonts.gstatic.com/s/aladin/v8/ZgNSjPJFPrvJV5f16Sf4pGT2Ng.ttf';
export const CabinCondensed_400Regular =
  'http://fonts.gstatic.com/s/cabincondensed/v13/nwpMtK6mNhBK2err_hqkYhHRqmwaYOjZ5HZl8Q.ttf';
export const CabinCondensed_500Medium =
  'http://fonts.gstatic.com/s/cabincondensed/v13/nwpJtK6mNhBK2err_hqkYhHRqmwilMH97F15-K1oqQ.ttf';
export const CabinCondensed_600SemiBold =
  'http://fonts.gstatic.com/s/cabincondensed/v13/nwpJtK6mNhBK2err_hqkYhHRqmwiuMb97F15-K1oqQ.ttf';
export const CabinCondensed_700Bold =
  'http://fonts.gstatic.com/s/cabincondensed/v13/nwpJtK6mNhBK2err_hqkYhHRqmwi3Mf97F15-K1oqQ.ttf';
export const Cagliostro_400Regular =
  'http://fonts.gstatic.com/s/cagliostro/v8/ZgNWjP5HM73BV5amnX-TjGXEM4COoE4.ttf';
export const ChangaOne_400Regular =
  'http://fonts.gstatic.com/s/changaone/v12/xfu00W3wXn3QLUJXhzq46AbouLfbK64.ttf';
export const ChangaOne_400Regular_Italic =
  'http://fonts.gstatic.com/s/changaone/v12/xfu20W3wXn3QLUJXhzq42ATivJXeO67ISw.ttf';
export const Chicle_400Regular =
  'http://fonts.gstatic.com/s/chicle/v8/lJwG-pw9i2dqU-BDyWKuobYSxw.ttf';
export const DrSugiyama_400Regular =
  'http://fonts.gstatic.com/s/drsugiyama/v9/HTxoL2k4N3O9n5I1boGI7abRM4-t-g7y.ttf';
export const HerrVonMuellerhoff_400Regular =
  'http://fonts.gstatic.com/s/herrvonmuellerhoff/v9/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft3c6_qJY3QPQ.ttf';
export const Lemon_400Regular = 'http://fonts.gstatic.com/s/lemon/v8/HI_EiYEVKqRMq0jBSZXAQ4-d.ttf';
export const MissFajardose_400Regular =
  'http://fonts.gstatic.com/s/missfajardose/v9/E21-_dn5gvrawDdPFVl-N0Ajb8qvWPaJq4no.ttf';
export const MonsieurLaDoulaise_400Regular =
  'http://fonts.gstatic.com/s/monsieurladoulaise/v8/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5ewkEU4HTy.ttf';
export const MrDafoe_400Regular =
  'http://fonts.gstatic.com/s/mrdafoe/v8/lJwE-pIzkS5NXuMMrGiqg7MCxz_C.ttf';
export const MrDeHaviland_400Regular =
  'http://fonts.gstatic.com/s/mrdehaviland/v8/OpNVnooIhJj96FdB73296ksbOj3C4ULVNTlB.ttf';
export const MrsSheppards_400Regular =
  'http://fonts.gstatic.com/s/mrssheppards/v8/PN_2Rfm9snC0XUGoEZhb91ig3vjxynMix4Y.ttf';
export const Piedra_400Regular =
  'http://fonts.gstatic.com/s/piedra/v8/ke8kOg8aN0Bn7hTunEyHN_M3gA.ttf';
export const Unlock_400Regular =
  'http://fonts.gstatic.com/s/unlock/v9/7Au-p_8ykD-cDl7GKAjSwkUVOQ.ttf';
export const BubblegumSans_400Regular =
  'http://fonts.gstatic.com/s/bubblegumsans/v8/AYCSpXb_Z9EORv1M5QTjEzMEtdaHzoPPb7R4.ttf';
export const ConcertOne_400Regular =
  'http://fonts.gstatic.com/s/concertone/v10/VEM1Ro9xs5PjtzCu-srDqRTlhv-CuVAQ.ttf';
export const Iceland_400Regular =
  'http://fonts.gstatic.com/s/iceland/v8/rax9HiuFsdMNOnWPWKxGADBbg0s.ttf';
export const Knewave_400Regular =
  'http://fonts.gstatic.com/s/knewave/v8/sykz-yx0lLcxQaSItSq9-trEvlQ.ttf';
export const Niconne_400Regular =
  'http://fonts.gstatic.com/s/niconne/v9/w8gaH2QvRug1_rTfrQut2F4OuOo.ttf';
export const Ribeye_400Regular =
  'http://fonts.gstatic.com/s/ribeye/v8/L0x8DFMxk1MP9R3RvPCmRSlUig.ttf';
export const RibeyeMarrow_400Regular =
  'http://fonts.gstatic.com/s/ribeyemarrow/v9/GFDsWApshnqMRO2JdtRZ2d0vEAwTVWgKdtw.ttf';
export const Righteous_400Regular =
  'http://fonts.gstatic.com/s/righteous/v8/1cXxaUPXBpj2rGoU7C9mj3uEicG01A.ttf';
export const Signika_300Light =
  'http://fonts.gstatic.com/s/signika/v10/vEFU2_JTCgwQ5ejvE_oEI3BDa0AdytM.ttf';
export const Signika_400Regular =
  'http://fonts.gstatic.com/s/signika/v10/vEFR2_JTCgwQ5ejvK1YsB3hod0k.ttf';
export const Signika_600SemiBold =
  'http://fonts.gstatic.com/s/signika/v10/vEFU2_JTCgwQ5ejvE44CI3BDa0AdytM.ttf';
export const Signika_700Bold =
  'http://fonts.gstatic.com/s/signika/v10/vEFU2_JTCgwQ5ejvE-oDI3BDa0AdytM.ttf';
export const SignikaNegative_300Light =
  'http://fonts.gstatic.com/s/signikanegative/v10/E217_cfngu7HiRpPX3ZpNE4kY5zKal6DipHD6z_iXAs.ttf';
export const SignikaNegative_400Regular =
  'http://fonts.gstatic.com/s/signikanegative/v10/E218_cfngu7HiRpPX3ZpNE4kY5zKUvKrrpno9zY.ttf';
export const SignikaNegative_600SemiBold =
  'http://fonts.gstatic.com/s/signikanegative/v10/E217_cfngu7HiRpPX3ZpNE4kY5zKaiqFipHD6z_iXAs.ttf';
export const SignikaNegative_700Bold =
  'http://fonts.gstatic.com/s/signikanegative/v10/E217_cfngu7HiRpPX3ZpNE4kY5zKak6EipHD6z_iXAs.ttf';
export const Spirax_400Regular =
  'http://fonts.gstatic.com/s/spirax/v8/buE3poKgYNLy0F3cXktt-Csn-Q.ttf';
export const Devonshire_400Regular =
  'http://fonts.gstatic.com/s/devonshire/v8/46kqlbDwWirWr4gtBD2BX0Vq01lYAZM.ttf';
export const Fondamento_400Regular =
  'http://fonts.gstatic.com/s/fondamento/v10/4UaHrEJGsxNmFTPDnkaJx63j5pN1MwI.ttf';
export const Fondamento_400Regular_Italic =
  'http://fonts.gstatic.com/s/fondamento/v10/4UaFrEJGsxNmFTPDnkaJ96_p4rFwIwJePw.ttf';
export const PlayfairDisplay_400Regular =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQZNLo_U2r.ttf';
export const PlayfairDisplay_500Medium =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQZNLo_U2r.ttf';
export const PlayfairDisplay_600SemiBold =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQZNLo_U2r.ttf';
export const PlayfairDisplay_700Bold =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiukDQZNLo_U2r.ttf';
export const PlayfairDisplay_800ExtraBold =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFukDQZNLo_U2r.ttf';
export const PlayfairDisplay_900Black =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsukDQZNLo_U2r.ttf';
export const PlayfairDisplay_400Regular_Italic =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTbtbK-F2rA0s.ttf';
export const PlayfairDisplay_500Medium_Italic =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTbtbK-F2rA0s.ttf';
export const PlayfairDisplay_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUbtbK-F2rA0s.ttf';
export const PlayfairDisplay_700Bold_Italic =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UbtbK-F2rA0s.ttf';
export const PlayfairDisplay_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUbtbK-F2rA0s.ttf';
export const PlayfairDisplay_900Black_Italic =
  'http://fonts.gstatic.com/s/playfairdisplay/v20/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUbtbK-F2rA0s.ttf';
export const Convergence_400Regular =
  'http://fonts.gstatic.com/s/convergence/v8/rax5HiePvdgXPmmMHcIPYRhasU7Q8Cad.ttf';
export const Nokora_400Regular =
  'http://fonts.gstatic.com/s/nokora/v13/hYkIPuwgTubzaWxQOzoPovZg8Q.ttf';
export const Nokora_700Bold =
  'http://fonts.gstatic.com/s/nokora/v13/hYkLPuwgTubzaWxohxUrqt18-B9Uuw.ttf';
export const Arapey_400Regular =
  'http://fonts.gstatic.com/s/arapey/v8/-W__XJn-UDDA2RC6Z9AcZkIzeg.ttf';
export const Arapey_400Regular_Italic =
  'http://fonts.gstatic.com/s/arapey/v8/-W_9XJn-UDDA2RCKZdoYREcjeo0k.ttf';
export const FjordOne_400Regular =
  'http://fonts.gstatic.com/s/fjordone/v8/zOL-4pbEnKBY_9S1jNKr6e5As-FeiQ.ttf';
export const Lancelot_400Regular =
  'http://fonts.gstatic.com/s/lancelot/v9/J7acnppxBGtQEulG4JY4xJ9CGyAa.ttf';
export const Mate_400Regular = 'http://fonts.gstatic.com/s/mate/v8/m8JdjftRd7WZ2z28WoXSaLU.ttf';
export const Mate_400Regular_Italic =
  'http://fonts.gstatic.com/s/mate/v8/m8JTjftRd7WZ6z-2XqfXeLVdbw.ttf';
export const MateSC_400Regular =
  'http://fonts.gstatic.com/s/matesc/v8/-nF8OGQ1-uoVr2wKyiXZ95OkJwA.ttf';
export const Petrona_400Regular =
  'http://fonts.gstatic.com/s/petrona/v8/mtG64_NXL7bZo9XXsXVStGsRwCU.ttf';
export const Poly_400Regular = 'http://fonts.gstatic.com/s/poly/v10/MQpb-W6wKNitRLCAq2Lpris.ttf';
export const Poly_400Regular_Italic =
  'http://fonts.gstatic.com/s/poly/v10/MQpV-W6wKNitdLKKr0DsviuGWA.ttf';
export const RammettoOne_400Regular =
  'http://fonts.gstatic.com/s/rammettoone/v8/LhWiMV3HOfMbMetJG3lQDpp9Mvuciu-_SQ.ttf';
export const SupermercadoOne_400Regular =
  'http://fonts.gstatic.com/s/supermercadoone/v9/OpNXnpQWg8jc_xps_Gi14kVVEXOn60b3MClBRTs.ttf';
export const AtomicAge_400Regular =
  'http://fonts.gstatic.com/s/atomicage/v12/f0Xz0eug6sdmRFkYZZGL58Ht9a8GYeA.ttf';
export const ContrailOne_400Regular =
  'http://fonts.gstatic.com/s/contrailone/v9/eLGbP-j_JA-kG0_Zo51noafdZUvt_c092w.ttf';
export const JockeyOne_400Regular =
  'http://fonts.gstatic.com/s/jockeyone/v9/HTxpL2g2KjCFj4x8WI6ArIb7HYOk4xc.ttf';
export const LindenHill_400Regular =
  'http://fonts.gstatic.com/s/lindenhill/v9/-F61fjxoKSg9Yc3hZgO8ygFI7CwC009k.ttf';
export const LindenHill_400Regular_Italic =
  'http://fonts.gstatic.com/s/lindenhill/v9/-F63fjxoKSg9Yc3hZgO8yjFK5igg1l9kn-s.ttf';
export const Quicksand_300Light =
  'http://fonts.gstatic.com/s/quicksand/v20/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo18G0wx40QDw.ttf';
export const Quicksand_400Regular =
  'http://fonts.gstatic.com/s/quicksand/v20/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18G0wx40QDw.ttf';
export const Quicksand_500Medium =
  'http://fonts.gstatic.com/s/quicksand/v20/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18G0wx40QDw.ttf';
export const Quicksand_600SemiBold =
  'http://fonts.gstatic.com/s/quicksand/v20/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv18G0wx40QDw.ttf';
export const Quicksand_700Bold =
  'http://fonts.gstatic.com/s/quicksand/v20/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18G0wx40QDw.ttf';
export const AmaticSC_400Regular =
  'http://fonts.gstatic.com/s/amaticsc/v13/TUZyzwprpvBS1izr_vO0De6ecZQf1A.ttf';
export const AmaticSC_700Bold =
  'http://fonts.gstatic.com/s/amaticsc/v13/TUZ3zwprpvBS1izr_vOMscG6eb8D3WTy-A.ttf';
export const Cookie_400Regular =
  'http://fonts.gstatic.com/s/cookie/v11/syky-y18lb0tSbfNlQCT9tPdpw.ttf';
export const DeliusUnicase_400Regular =
  'http://fonts.gstatic.com/s/deliusunicase/v13/845BNMEwEIOVT8BmgfSzIr_6mmLHd-73LXWs.ttf';
export const DeliusUnicase_700Bold =
  'http://fonts.gstatic.com/s/deliusunicase/v13/845CNMEwEIOVT8BmgfSzIr_6mlp7WMr_BmmlS5aw.ttf';
export const MarckScript_400Regular =
  'http://fonts.gstatic.com/s/marckscript/v10/nwpTtK2oNgBA3Or78gapdwuCzyI-aMPF7Q.ttf';
export const PinyonScript_400Regular =
  'http://fonts.gstatic.com/s/pinyonscript/v10/6xKpdSJbL9-e9LuoeQiDRQR8aOLQO4bhiDY.ttf';
export const Rancho_400Regular =
  'http://fonts.gstatic.com/s/rancho/v10/46kulbzmXjLaqZRlbWXgd0RY1g.ttf';
export const Salsa_400Regular = 'http://fonts.gstatic.com/s/salsa/v9/gNMKW3FiRpKj-imY8ncKEZez.ttf';
export const Sancreek_400Regular =
  'http://fonts.gstatic.com/s/sancreek/v10/pxiHypAnsdxUm159X7D-XV9NEe-K.ttf';
export const Satisfy_400Regular =
  'http://fonts.gstatic.com/s/satisfy/v10/rP2Hp2yn6lkG50LoOZSCHBeHFl0.ttf';
export const VastShadow_400Regular =
  'http://fonts.gstatic.com/s/vastshadow/v9/pe0qMImKOZ1V62ZwbVY9dfe6Kdpickwp.ttf';
export const Andada_400Regular =
  'http://fonts.gstatic.com/s/andada/v11/uK_y4riWaego3w9RCh0TMv6EXw.ttf';
export const Federant_400Regular =
  'http://fonts.gstatic.com/s/federant/v12/2sDdZGNfip_eirT0_U0jRUG0AqUc.ttf';
export const GochiHand_400Regular =
  'http://fonts.gstatic.com/s/gochihand/v10/hES06XlsOjtJsgCkx1PkTo71-n0nXWA.ttf';
export const UbuntuCondensed_400Regular =
  'http://fonts.gstatic.com/s/ubuntucondensed/v10/u-4k0rCzjgs5J7oXnJcM_0kACGMtf-fVqvHoJXw.ttf';
export const UbuntuMono_400Regular =
  'http://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbBc9AMX6lJBP.ttf';
export const UbuntuMono_400Regular_Italic =
  'http://fonts.gstatic.com/s/ubuntumono/v9/KFOhCneDtsqEr0keqCMhbCc_CsHYkYBPY3o.ttf';
export const UbuntuMono_700Bold =
  'http://fonts.gstatic.com/s/ubuntumono/v9/KFO-CneDtsqEr0keqCMhbC-BL-Hyv4xGemO1.ttf';
export const UbuntuMono_700Bold_Italic =
  'http://fonts.gstatic.com/s/ubuntumono/v9/KFO8CneDtsqEr0keqCMhbCc_Mn33tYhkf3O1GVg.ttf';
export const AlikeAngular_400Regular =
  'http://fonts.gstatic.com/s/alikeangular/v10/3qTrojWunjGQtEBlIcwMbSoI3kM6bB7FKjE.ttf';
export const PollerOne_400Regular =
  'http://fonts.gstatic.com/s/pollerone/v9/ahccv82n0TN3gia5E4Bud-lbgUS5u0s.ttf';
export const Spinnaker_400Regular =
  'http://fonts.gstatic.com/s/spinnaker/v11/w8gYH2oyX-I0_rvR6Hmn3HwLqOqSBg.ttf';
export const Adamina_400Regular =
  'http://fonts.gstatic.com/s/adamina/v13/j8_r6-DH1bjoc-dwu-reETl4Bno.ttf';
export const Cardo_400Regular = 'http://fonts.gstatic.com/s/cardo/v11/wlp_gwjKBV1pqiv_1oAZ2H5O.ttf';
export const Cardo_400Regular_Italic =
  'http://fonts.gstatic.com/s/cardo/v11/wlpxgwjKBV1pqhv93IQ73W5OcCk.ttf';
export const Cardo_700Bold =
  'http://fonts.gstatic.com/s/cardo/v11/wlpygwjKBV1pqhND-aQR82JHaTBX.ttf';
export const Julee_400Regular = 'http://fonts.gstatic.com/s/julee/v9/TuGfUVB3RpZPQ6ZLodgzydtk.ttf';
export const MeriendaOne_400Regular =
  'http://fonts.gstatic.com/s/meriendaone/v10/H4cgBXaMndbflEq6kyZ1ht6YgoyyYzFzFw.ttf';
export const Prata_400Regular = 'http://fonts.gstatic.com/s/prata/v11/6xKhdSpbNNCT-vWIAG_5LWwJ.ttf';
export const Rosario_300Light =
  'http://fonts.gstatic.com/s/rosario/v17/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM69GCWczd-YnOzUD.ttf';
export const Rosario_400Regular =
  'http://fonts.gstatic.com/s/rosario/v17/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCWczd-YnOzUD.ttf';
export const Rosario_500Medium =
  'http://fonts.gstatic.com/s/rosario/v17/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68qCWczd-YnOzUD.ttf';
export const Rosario_600SemiBold =
  'http://fonts.gstatic.com/s/rosario/v17/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6_GDmczd-YnOzUD.ttf';
export const Rosario_700Bold =
  'http://fonts.gstatic.com/s/rosario/v17/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6__Dmczd-YnOzUD.ttf';
export const Rosario_300Light_Italic =
  'http://fonts.gstatic.com/s/rosario/v17/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQStFwfeIFPiUDn08.ttf';
export const Rosario_400Regular_Italic =
  'http://fonts.gstatic.com/s/rosario/v17/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSo9wfeIFPiUDn08.ttf';
export const Rosario_500Medium_Italic =
  'http://fonts.gstatic.com/s/rosario/v17/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSr1wfeIFPiUDn08.ttf';
export const Rosario_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/rosario/v17/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSlF3feIFPiUDn08.ttf';
export const Rosario_700Bold_Italic =
  'http://fonts.gstatic.com/s/rosario/v17/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSmh3feIFPiUDn08.ttf';
export const SortsMillGoudy_400Regular =
  'http://fonts.gstatic.com/s/sortsmillgoudy/v9/Qw3GZR9MED_6PSuS_50nEaVrfzgEXH0OjpM75PE.ttf';
export const SortsMillGoudy_400Regular_Italic =
  'http://fonts.gstatic.com/s/sortsmillgoudy/v9/Qw3AZR9MED_6PSuS_50nEaVrfzgEbH8EirE-9PGLfQ.ttf';
export const AbrilFatface_400Regular =
  'http://fonts.gstatic.com/s/abrilfatface/v11/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf';
export const Antic_400Regular = 'http://fonts.gstatic.com/s/antic/v11/TuGfUVB8XY5DRaZLodgzydtk.ttf';
export const Dorsa_400Regular = 'http://fonts.gstatic.com/s/dorsa/v10/yYLn0hjd0OGwqo493XCFxAnQ.ttf';
export const FanwoodText_400Regular =
  'http://fonts.gstatic.com/s/fanwoodtext/v9/3XFtErwl05Ad_vSCF6Fq7xXGRdbY1P1Sbg.ttf';
export const FanwoodText_400Regular_Italic =
  'http://fonts.gstatic.com/s/fanwoodtext/v9/3XFzErwl05Ad_vSCF6Fq7xX2R9zc9vhCblye.ttf';
export const PasseroOne_400Regular =
  'http://fonts.gstatic.com/s/passeroone/v11/JTUTjIko8DOq5FeaeEAjgE5B5Arr-s50.ttf';
export const Philosopher_400Regular =
  'http://fonts.gstatic.com/s/philosopher/v12/vEFV2_5QCwIS4_Dhez5jcVBpRUwU08qe.ttf';
export const Philosopher_400Regular_Italic =
  'http://fonts.gstatic.com/s/philosopher/v12/vEFX2_5QCwIS4_Dhez5jcWBrT0g21tqeR7c.ttf';
export const Philosopher_700Bold =
  'http://fonts.gstatic.com/s/philosopher/v12/vEFI2_5QCwIS4_Dhez5jcWjVamgc-NaXXq7H.ttf';
export const Philosopher_700Bold_Italic =
  'http://fonts.gstatic.com/s/philosopher/v12/vEFK2_5QCwIS4_Dhez5jcWBrd_QZ8tK1W77HtMo.ttf';
export const Prociono_400Regular =
  'http://fonts.gstatic.com/s/prociono/v9/r05YGLlR-KxAf9GGO8upyDYtStiJ.ttf';
export const Alike_400Regular = 'http://fonts.gstatic.com/s/alike/v12/HI_EiYEYI6BIoEjBSZXAQ4-d.ttf';
export const Monoton_400Regular =
  'http://fonts.gstatic.com/s/monoton/v9/5h1aiZUrOngCibe4fkbBQ2S7FU8.ttf';
export const Aldrich_400Regular =
  'http://fonts.gstatic.com/s/aldrich/v10/MCoTzAn-1s3IGyJMZaAS3pP5H_E.ttf';
export const DaysOne_400Regular =
  'http://fonts.gstatic.com/s/daysone/v9/mem9YaCnxnKRiYZOCLYVeLkWVNBt.ttf';
export const GentiumBookBasic_400Regular =
  'http://fonts.gstatic.com/s/gentiumbookbasic/v10/pe0zMJCbPYBVokB1LHA9bbyaQb8ZGjcIV7t7w6bE2A.ttf';
export const GentiumBookBasic_400Regular_Italic =
  'http://fonts.gstatic.com/s/gentiumbookbasic/v10/pe0xMJCbPYBVokB1LHA9bbyaQb8ZGjc4VbF_4aPU2Ec9.ttf';
export const GentiumBookBasic_700Bold =
  'http://fonts.gstatic.com/s/gentiumbookbasic/v10/pe0wMJCbPYBVokB1LHA9bbyaQb8ZGjcw65Rfy43Y0V4kvg.ttf';
export const GentiumBookBasic_700Bold_Italic =
  'http://fonts.gstatic.com/s/gentiumbookbasic/v10/pe0-MJCbPYBVokB1LHA9bbyaQb8ZGjc4VYnDzofc81s0voO3.ttf';
export const Montez_400Regular =
  'http://fonts.gstatic.com/s/montez/v10/845ZNMk5GoGIX8lm1LDeSd-R_g.ttf';
export const Numans_400Regular =
  'http://fonts.gstatic.com/s/numans/v9/SlGRmQmGupYAfH8IYRggiHVqaQ.ttf';
export const ShortStack_400Regular =
  'http://fonts.gstatic.com/s/shortstack/v9/bMrzmS2X6p0jZC6EcmPFX-SScX8D0nq6.ttf';
export const Vidaloka_400Regular =
  'http://fonts.gstatic.com/s/vidaloka/v12/7cHrv4c3ipenMKlEass8yn4hnCci.ttf';
export const Volkhov_400Regular =
  'http://fonts.gstatic.com/s/volkhov/v11/SlGQmQieoJcKemNeQTIOhHxzcD0.ttf';
export const Volkhov_400Regular_Italic =
  'http://fonts.gstatic.com/s/volkhov/v11/SlGSmQieoJcKemNecTAEgF52YD0NYw.ttf';
export const Volkhov_700Bold =
  'http://fonts.gstatic.com/s/volkhov/v11/SlGVmQieoJcKemNeeY4hoHRYbDQUego.ttf';
export const Volkhov_700Bold_Italic =
  'http://fonts.gstatic.com/s/volkhov/v11/SlGXmQieoJcKemNecTA8PHFSaBYRagrQrA.ttf';
export const Voltaire_400Regular =
  'http://fonts.gstatic.com/s/voltaire/v9/1Pttg8PcRfSblAvGvQooYKVnBOif.ttf';
export const Alice_400Regular = 'http://fonts.gstatic.com/s/alice/v11/OpNCnoEEmtHa6FcJpA_chzJ0.ttf';
export const Andika_400Regular =
  'http://fonts.gstatic.com/s/andika/v11/mem_Ya6iyW-LwqgAbbwRWrwGVA.ttf';
export const Comfortaa_300Light =
  'http://fonts.gstatic.com/s/comfortaa/v28/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf';
export const Comfortaa_400Regular =
  'http://fonts.gstatic.com/s/comfortaa/v28/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf';
export const Comfortaa_500Medium =
  'http://fonts.gstatic.com/s/comfortaa/v28/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf';
export const Comfortaa_600SemiBold =
  'http://fonts.gstatic.com/s/comfortaa/v28/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf';
export const Comfortaa_700Bold =
  'http://fonts.gstatic.com/s/comfortaa/v28/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf';
export const Coustard_400Regular =
  'http://fonts.gstatic.com/s/coustard/v10/3XFpErgg3YsZ5fqUU9UPvWXuROTd.ttf';
export const Coustard_900Black =
  'http://fonts.gstatic.com/s/coustard/v10/3XFuErgg3YsZ5fqUU-2LkEHmb_jU3eRL.ttf';
export const Geostar_400Regular =
  'http://fonts.gstatic.com/s/geostar/v10/sykz-yx4n701VLOftSq9-trEvlQ.ttf';
export const GeostarFill_400Regular =
  'http://fonts.gstatic.com/s/geostarfill/v10/AMOWz4SWuWiXFfjEohxQ9os0U1K2w9lb4g.ttf';
export const Questrial_400Regular =
  'http://fonts.gstatic.com/s/questrial/v9/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf';
export const Abel_400Regular = 'http://fonts.gstatic.com/s/abel/v10/MwQ5bhbm2POE6VhLPJp6qGI.ttf';
export const Actor_400Regular = 'http://fonts.gstatic.com/s/actor/v9/wEOzEBbCkc5cO3ekXygtUMIO.ttf';
export const DeliusSwashCaps_400Regular =
  'http://fonts.gstatic.com/s/deliusswashcaps/v11/oY1E8fPLr7v4JWCExZpWebxVKORpXXedKmeBvEYs.ttf';
export const Marvel_400Regular =
  'http://fonts.gstatic.com/s/marvel/v9/nwpVtKeoNgBV0qaIkV7ED366zg.ttf';
export const Marvel_400Regular_Italic =
  'http://fonts.gstatic.com/s/marvel/v9/nwpXtKeoNgBV0qa4k1TALXuqzhA7.ttf';
export const Marvel_700Bold =
  'http://fonts.gstatic.com/s/marvel/v9/nwpWtKeoNgBV0qawLXHgB1WmxwkiYQ.ttf';
export const Marvel_700Bold_Italic =
  'http://fonts.gstatic.com/s/marvel/v9/nwpQtKeoNgBV0qa4k2x8Al-i5QwyYdrc.ttf';
export const Rationale_400Regular =
  'http://fonts.gstatic.com/s/rationale/v11/9XUnlJ92n0_JFxHIfHcsdlFMzLC2Zw.ttf';
export const Rochester_400Regular =
  'http://fonts.gstatic.com/s/rochester/v10/6ae-4KCqVa4Zy6Fif-Uy31vWNTMwoQ.ttf';
export const Smokum_400Regular =
  'http://fonts.gstatic.com/s/smokum/v10/TK3iWkUbAhopmrdGHjUHte5fKg.ttf';
export const TulpenOne_400Regular =
  'http://fonts.gstatic.com/s/tulpenone/v9/dFa6ZfeC474skLgesc0CWj0w_HyIRlE.ttf';
export const Aubrey_400Regular =
  'http://fonts.gstatic.com/s/aubrey/v12/q5uGsou7NPBw-p7vugNsCxVEgA.ttf';
export const BlackOpsOne_400Regular =
  'http://fonts.gstatic.com/s/blackopsone/v11/qWcsB6-ypo7xBdr6Xshe96H3WDzRtjkho4M.ttf';
export const Carme_400Regular = 'http://fonts.gstatic.com/s/carme/v10/ptRHTiWdbvZIDOjGxLNrxfbZ.ttf';
export const Delius_400Regular =
  'http://fonts.gstatic.com/s/delius/v9/PN_xRfK0pW_9e1rtYcI-jT3L_w.ttf';
export const Federo_400Regular =
  'http://fonts.gstatic.com/s/federo/v11/iJWFBX-cbD_ETsbmjVOe2WTG7Q.ttf';
export const GloriaHallelujah_400Regular =
  'http://fonts.gstatic.com/s/gloriahallelujah/v11/LYjYdHv3kUk9BMV96EIswT9DIbW-MLSy3TKEvkCF.ttf';
export const KellySlab_400Regular =
  'http://fonts.gstatic.com/s/kellyslab/v10/-W_7XJX0Rz3cxUnJC5t6TkMBf50kbiM.ttf';
export const Rokkitt_100Thin =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rydpDLE76HvN6n.ttf';
export const Rokkitt_200ExtraLight =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pyd5DLE76HvN6n.ttf';
export const Rokkitt_300Light =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1qsd5DLE76HvN6n.ttf';
export const Rokkitt_400Regular =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd5DLE76HvN6n.ttf';
export const Rokkitt_500Medium =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rAd5DLE76HvN6n.ttf';
export const Rokkitt_600SemiBold =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oscJDLE76HvN6n.ttf';
export const Rokkitt_700Bold =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oVcJDLE76HvN6n.ttf';
export const Rokkitt_800ExtraBold =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pycJDLE76HvN6n.ttf';
export const Rokkitt_900Black =
  'http://fonts.gstatic.com/s/rokkitt/v18/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pbcJDLE76HvN6n.ttf';
export const Tienne_400Regular =
  'http://fonts.gstatic.com/s/tienne/v12/AYCKpX7pe9YCRP0LkEPHSFNyxw.ttf';
export const Tienne_700Bold =
  'http://fonts.gstatic.com/s/tienne/v12/AYCJpX7pe9YCRP0zLGzjQHhuzvef5Q.ttf';
export const Tienne_900Black =
  'http://fonts.gstatic.com/s/tienne/v12/AYCJpX7pe9YCRP0zFG7jQHhuzvef5Q.ttf';
export const GentiumBasic_400Regular =
  'http://fonts.gstatic.com/s/gentiumbasic/v11/Wnz9HAw9aB_JD2VGQVR80We3HAqDiTI_cIM.ttf';
export const GentiumBasic_400Regular_Italic =
  'http://fonts.gstatic.com/s/gentiumbasic/v11/WnzjHAw9aB_JD2VGQVR80We3LAiJjRA6YIORZQ.ttf';
export const GentiumBasic_700Bold =
  'http://fonts.gstatic.com/s/gentiumbasic/v11/WnzgHAw9aB_JD2VGQVR80We3JLasrToUbIqIfBU.ttf';
export const GentiumBasic_700Bold_Italic =
  'http://fonts.gstatic.com/s/gentiumbasic/v11/WnzmHAw9aB_JD2VGQVR80We3LAixMT8eaKiNbBVWkw.ttf';
export const LeckerliOne_400Regular =
  'http://fonts.gstatic.com/s/leckerlione/v10/V8mCoQH8VCsNttEnxnGQ-1itLZxcBtItFw.ttf';
export const Ovo_400Regular = 'http://fonts.gstatic.com/s/ovo/v11/yYLl0h7Wyfzjy4Q5_3WVxA.ttf';
export const Pompiere_400Regular =
  'http://fonts.gstatic.com/s/pompiere/v9/VEMyRoxis5Dwuyeov6Wt5jDtreOL.ttf';
export const Snippet_400Regular =
  'http://fonts.gstatic.com/s/snippet/v9/bWt47f7XfQH9Gupu2v_Afcp9QWc.ttf';
export const Unna_400Regular = 'http://fonts.gstatic.com/s/unna/v13/AYCEpXzofN0NCpgBlGHCWFM.ttf';
export const Unna_400Regular_Italic =
  'http://fonts.gstatic.com/s/unna/v13/AYCKpXzofN0NOpoLkEPHSFNyxw.ttf';
export const Unna_700Bold = 'http://fonts.gstatic.com/s/unna/v13/AYCLpXzofN0NMiQusGnpRFpr3vc.ttf';
export const Unna_700Bold_Italic =
  'http://fonts.gstatic.com/s/unna/v13/AYCJpXzofN0NOpozLGzjQHhuzvef5Q.ttf';
export const Yellowtail_400Regular =
  'http://fonts.gstatic.com/s/yellowtail/v10/OZpGg_pnoDtINPfRIlLotlzNwED-b4g.ttf';
export const BowlbyOne_400Regular =
  'http://fonts.gstatic.com/s/bowlbyone/v11/taiPGmVuC4y96PFeqp8smo6C_Z0wcK4.ttf';
export const GiveYouGlory_400Regular =
  'http://fonts.gstatic.com/s/giveyouglory/v9/8QIQdiHOgt3vv4LR7ahjw9-XYc1zB4ZD6rwa.ttf';
export const IstokWeb_400Regular =
  'http://fonts.gstatic.com/s/istokweb/v14/3qTvojGmgSyUukBzKslZAWF-9kIIaQ.ttf';
export const IstokWeb_400Regular_Italic =
  'http://fonts.gstatic.com/s/istokweb/v14/3qTpojGmgSyUukBzKslpA2t61EcYaQ7F.ttf';
export const IstokWeb_700Bold =
  'http://fonts.gstatic.com/s/istokweb/v14/3qTqojGmgSyUukBzKslhvU5a_mkUYBfcMw.ttf';
export const IstokWeb_700Bold_Italic =
  'http://fonts.gstatic.com/s/istokweb/v14/3qT0ojGmgSyUukBzKslpA1PG-2MQQhLMMygN.ttf';
export const ModernAntiqua_400Regular =
  'http://fonts.gstatic.com/s/modernantiqua/v9/NGStv5TIAUg6Iq_RLNo_2dp1sI1Ea2u0c3Gi.ttf';
export const VarelaRound_400Regular =
  'http://fonts.gstatic.com/s/varelaround/v12/w8gdH283Tvk__Lua32TysjIvoMGOD9gxZw.ttf';
export const YesevaOne_400Regular =
  'http://fonts.gstatic.com/s/yesevaone/v14/OpNJno4ck8vc-xYpwWWxpipfWhXD00c.ttf';
export const BowlbyOneSC_400Regular =
  'http://fonts.gstatic.com/s/bowlbyonesc/v11/DtVlJxerQqQm37tzN3wMug9Pzgj8owhNjuE.ttf';
export const Forum_400Regular = 'http://fonts.gstatic.com/s/forum/v10/6aey4Ky-Vb8Ew_IWMJMa3mnT.ttf';
export const Lora_400Regular =
  'http://fonts.gstatic.com/s/lora/v15/0QI6MX1D_JOuGQbT0gvTJPa787weuyJGmKxemMeZ.ttf';
export const Lora_500Medium =
  'http://fonts.gstatic.com/s/lora/v15/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJGmKxemMeZ.ttf';
export const Lora_600SemiBold =
  'http://fonts.gstatic.com/s/lora/v15/0QI6MX1D_JOuGQbT0gvTJPa787zAvCJGmKxemMeZ.ttf';
export const Lora_700Bold =
  'http://fonts.gstatic.com/s/lora/v15/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJGmKxemMeZ.ttf';
export const Lora_400Regular_Italic =
  'http://fonts.gstatic.com/s/lora/v15/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-MoFkqh8ndeZzZ0.ttf';
export const Lora_500Medium_Italic =
  'http://fonts.gstatic.com/s/lora/v15/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-PgFkqh8ndeZzZ0.ttf';
export const Lora_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/lora/v15/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-BQCkqh8ndeZzZ0.ttf';
export const Lora_700Bold_Italic =
  'http://fonts.gstatic.com/s/lora/v15/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-C0Ckqh8ndeZzZ0.ttf';
export const LoveYaLikeASister_400Regular =
  'http://fonts.gstatic.com/s/loveyalikeasister/v10/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-Be78nZcsGGycA.ttf';
export const LovedbytheKing_400Regular =
  'http://fonts.gstatic.com/s/lovedbytheking/v9/Gw6gwdP76VDVJNXerebZxUMeRXUF2PiNlXFu2R64.ttf';
export const PatrickHand_400Regular =
  'http://fonts.gstatic.com/s/patrickhand/v13/LDI1apSQOAYtSuYWp8ZhfYeMWcjKm7sp8g.ttf';
export const StardosStencil_400Regular =
  'http://fonts.gstatic.com/s/stardosstencil/v10/X7n94bcuGPC8hrvEOHXOgaKCc2TR71R3tiSx0g.ttf';
export const StardosStencil_700Bold =
  'http://fonts.gstatic.com/s/stardosstencil/v10/X7n44bcuGPC8hrvEOHXOgaKCc2TpU3tTvg-t29HSHw.ttf';
export const Asset_400Regular = 'http://fonts.gstatic.com/s/asset/v10/SLXGc1na-mM4cWImRJqExst1.ttf';
export const GoblinOne_400Regular =
  'http://fonts.gstatic.com/s/goblinone/v9/CSR64z1ZnOqZRjRCBVY_TOcATNt_pOU.ttf';
export const GravitasOne_400Regular =
  'http://fonts.gstatic.com/s/gravitasone/v9/5h1diZ4hJ3cblKy3LWakKQmaDWRNr3DzbQ.ttf';
export const HammersmithOne_400Regular =
  'http://fonts.gstatic.com/s/hammersmithone/v10/qWcyB624q4L_C4jGQ9IK0O_dFlnbshsks4MRXw.ttf';
export const Varela_400Regular =
  'http://fonts.gstatic.com/s/varela/v10/DPEtYwqExx0AWHXJBBQFfvzDsQ.ttf';
export const LobsterTwo_400Regular =
  'http://fonts.gstatic.com/s/lobstertwo/v12/BngMUXZGTXPUvIoyV6yN59fK7KSJ4ACD.ttf';
export const LobsterTwo_400Regular_Italic =
  'http://fonts.gstatic.com/s/lobstertwo/v12/BngOUXZGTXPUvIoyV6yN5-fI5qCr5RCDY_k.ttf';
export const LobsterTwo_700Bold =
  'http://fonts.gstatic.com/s/lobstertwo/v12/BngRUXZGTXPUvIoyV6yN5-92w4CByxyKeuDp.ttf';
export const LobsterTwo_700Bold_Italic =
  'http://fonts.gstatic.com/s/lobstertwo/v12/BngTUXZGTXPUvIoyV6yN5-fI3hyEwRiof_DpXMY.ttf';
export const NixieOne_400Regular =
  'http://fonts.gstatic.com/s/nixieone/v10/lW-8wjkKLXjg5y2o2uUoUOFzpS-yLw.ttf';
export const Redressed_400Regular =
  'http://fonts.gstatic.com/s/redressed/v10/x3dickHUbrmJ7wMy9MsBfPACvy_1BA.ttf';
export const CedarvilleCursive_400Regular =
  'http://fonts.gstatic.com/s/cedarvillecursive/v11/yYL00g_a2veiudhUmxjo5VKkoqA-B_neJbBxw8BeTg.ttf';
export const Kameron_400Regular =
  'http://fonts.gstatic.com/s/kameron/v10/vm82dR7vXErQxuznsL4wL-XIYH8.ttf';
export const Kameron_700Bold =
  'http://fonts.gstatic.com/s/kameron/v10/vm8zdR7vXErQxuzniAIfC-3jfHb--NY.ttf';
export const LaBelleAurore_400Regular =
  'http://fonts.gstatic.com/s/labelleaurore/v10/RrQIbot8-mNYKnGNDkWlocovHeIIG-eFNVmULg.ttf';
export const ShadowsIntoLight_400Regular =
  'http://fonts.gstatic.com/s/shadowsintolight/v9/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcsr4xzSMYA.ttf';
export const Zeyada_400Regular =
  'http://fonts.gstatic.com/s/zeyada/v9/11hAGpPTxVPUbgZDNGatWKaZ3g.ttf';
export const Artifika_400Regular =
  'http://fonts.gstatic.com/s/artifika/v10/VEMyRoxzronptCuxu6Wt5jDtreOL.ttf';
export const Limelight_400Regular =
  'http://fonts.gstatic.com/s/limelight/v10/XLYkIZL7aopJVbZJHDuYPeNGrnY2TA.ttf';
export const MavenPro_400Regular =
  'http://fonts.gstatic.com/s/mavenpro/v20/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX25nCpozp5GvU.ttf';
export const MavenPro_500Medium =
  'http://fonts.gstatic.com/s/mavenpro/v20/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Rf25nCpozp5GvU.ttf';
export const MavenPro_600SemiBold =
  'http://fonts.gstatic.com/s/mavenpro/v20/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8fvx5nCpozp5GvU.ttf';
export const MavenPro_700Bold =
  'http://fonts.gstatic.com/s/mavenpro/v20/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8cLx5nCpozp5GvU.ttf';
export const MavenPro_800ExtraBold =
  'http://fonts.gstatic.com/s/mavenpro/v20/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8aXx5nCpozp5GvU.ttf';
export const MavenPro_900Black =
  'http://fonts.gstatic.com/s/mavenpro/v20/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Yzx5nCpozp5GvU.ttf';
export const Muli_200ExtraLight =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFlOkHkw2-m9x2iC.ttf';
export const Muli_300Light =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFmQkHkw2-m9x2iC.ttf';
export const Muli_400Regular =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFnOkHkw2-m9x2iC.ttf';
export const Muli_500Medium =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFn8kHkw2-m9x2iC.ttf';
export const Muli_600SemiBold =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFkQl3kw2-m9x2iC.ttf';
export const Muli_700Bold =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFkpl3kw2-m9x2iC.ttf';
export const Muli_800ExtraBold =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFlOl3kw2-m9x2iC.ttf';
export const Muli_900Black =
  'http://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFlnl3kw2-m9x2iC.ttf';
export const Muli_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG0xFz0e2fwniCvzM.ttf';
export const Muli_300Light_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG089z0e2fwniCvzM.ttf';
export const Muli_400Regular_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG05Fz0e2fwniCvzM.ttf';
export const Muli_500Medium_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG06Nz0e2fwniCvzM.ttf';
export const Muli_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG00900e2fwniCvzM.ttf';
export const Muli_700Bold_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG03Z00e2fwniCvzM.ttf';
export const Muli_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG0xF00e2fwniCvzM.ttf';
export const Muli_900Black_Italic =
  'http://fonts.gstatic.com/s/muli/v20/7Aujp_0qiz-afTfcIyoiGtm2P0wG0zh00e2fwniCvzM.ttf';
export const TenorSans_400Regular =
  'http://fonts.gstatic.com/s/tenorsans/v11/bx6ANxqUneKx06UkIXISr3JyC22IyqI.ttf';
export const Brawler_400Regular =
  'http://fonts.gstatic.com/s/brawler/v10/xn7gYHE3xXewAscGsgC7S9XdZN8.ttf';
export const Caudex_400Regular =
  'http://fonts.gstatic.com/s/caudex/v9/esDQ311QOP6BJUrIyviAnb4eEw.ttf';
export const Caudex_400Regular_Italic =
  'http://fonts.gstatic.com/s/caudex/v9/esDS311QOP6BJUr4yPKEv7sOE4in.ttf';
export const Caudex_700Bold =
  'http://fonts.gstatic.com/s/caudex/v9/esDT311QOP6BJUrwdteklZUCGpG-GQ.ttf';
export const Caudex_700Bold_Italic =
  'http://fonts.gstatic.com/s/caudex/v9/esDV311QOP6BJUr4yMo4kJ8GOJSuGdLB.ttf';
export const DancingScript_400Regular =
  'http://fonts.gstatic.com/s/dancingscript/v14/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTeB9ptDqpw.ttf';
export const DancingScript_500Medium =
  'http://fonts.gstatic.com/s/dancingscript/v14/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BAyoHTeB9ptDqpw.ttf';
export const DancingScript_600SemiBold =
  'http://fonts.gstatic.com/s/dancingscript/v14/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B7y0HTeB9ptDqpw.ttf';
export const DancingScript_700Bold =
  'http://fonts.gstatic.com/s/dancingscript/v14/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B1i0HTeB9ptDqpw.ttf';
export const Jura_300Light =
  'http://fonts.gstatic.com/s/jura/v14/z7NOdRfiaC4Vd8hhoPzfb5vBTP0D7auhTfmrH_rt.ttf';
export const Jura_400Regular =
  'http://fonts.gstatic.com/s/jura/v14/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7auhTfmrH_rt.ttf';
export const Jura_500Medium =
  'http://fonts.gstatic.com/s/jura/v14/z7NOdRfiaC4Vd8hhoPzfb5vBTP1v7auhTfmrH_rt.ttf';
export const Jura_600SemiBold =
  'http://fonts.gstatic.com/s/jura/v14/z7NOdRfiaC4Vd8hhoPzfb5vBTP2D6quhTfmrH_rt.ttf';
export const Jura_700Bold =
  'http://fonts.gstatic.com/s/jura/v14/z7NOdRfiaC4Vd8hhoPzfb5vBTP266quhTfmrH_rt.ttf';
export const Podkova_400Regular =
  'http://fonts.gstatic.com/s/podkova/v16/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzcU4EoporSHH.ttf';
export const Podkova_500Medium =
  'http://fonts.gstatic.com/s/podkova/v16/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWt3zcU4EoporSHH.ttf';
export const Podkova_600SemiBold =
  'http://fonts.gstatic.com/s/podkova/v16/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWubysU4EoporSHH.ttf';
export const Podkova_700Bold =
  'http://fonts.gstatic.com/s/podkova/v16/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWuiysU4EoporSHH.ttf';
export const Podkova_800ExtraBold =
  'http://fonts.gstatic.com/s/podkova/v16/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWvFysU4EoporSHH.ttf';
export const RuslanDisplay_400Regular =
  'http://fonts.gstatic.com/s/ruslandisplay/v10/Gw6jwczl81XcIZuckK_e3UpfdzxrldyFvm1n.ttf';
export const WireOne_400Regular =
  'http://fonts.gstatic.com/s/wireone/v10/qFdH35Wah5htUhV75WGiWdrCwwcJ.ttf';
export const Mako_400Regular = 'http://fonts.gstatic.com/s/mako/v11/H4coBX6Mmc_Z0ST09g478Lo.ttf';
export const Merriweather_300Light =
  'http://fonts.gstatic.com/s/merriweather/v21/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf';
export const Merriweather_300Light_Italic =
  'http://fonts.gstatic.com/s/merriweather/v21/u-4l0qyriQwlOrhSvowK_l5-eR7lXcf_hP3hPGWH.ttf';
export const Merriweather_400Regular =
  'http://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf';
export const Merriweather_400Regular_Italic =
  'http://fonts.gstatic.com/s/merriweather/v21/u-4m0qyriQwlOrhSvowK_l5-eSZJdeP3r-Ho.ttf';
export const Merriweather_700Bold =
  'http://fonts.gstatic.com/s/merriweather/v21/u-4n0qyriQwlOrhSvowK_l52xwNpX837pvjxPA.ttf';
export const Merriweather_700Bold_Italic =
  'http://fonts.gstatic.com/s/merriweather/v21/u-4l0qyriQwlOrhSvowK_l5-eR71Wsf_hP3hPGWH.ttf';
export const Merriweather_900Black =
  'http://fonts.gstatic.com/s/merriweather/v21/u-4n0qyriQwlOrhSvowK_l52_wFpX837pvjxPA.ttf';
export const Merriweather_900Black_Italic =
  'http://fonts.gstatic.com/s/merriweather/v21/u-4l0qyriQwlOrhSvowK_l5-eR7NWMf_hP3hPGWH.ttf';
export const Metrophobic_400Regular =
  'http://fonts.gstatic.com/s/metrophobic/v13/sJoA3LZUhMSAPV_u0qwiAT-J737FPEEL.ttf';
export const NothingYouCouldDo_400Regular =
  'http://fonts.gstatic.com/s/nothingyoucoulddo/v9/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb0OJl1ol2Ymo.ttf';
export const Shanti_400Regular =
  'http://fonts.gstatic.com/s/shanti/v11/t5thIREMM4uSDgzgU0ezpKfwzA.ttf';
export const OpenSansCondensed_300Light =
  'http://fonts.gstatic.com/s/opensanscondensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhPuLGRpWRyAs.ttf';
export const OpenSansCondensed_300Light_Italic =
  'http://fonts.gstatic.com/s/opensanscondensed/v14/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suDMQreU2AsJSg.ttf';
export const OpenSansCondensed_700Bold =
  'http://fonts.gstatic.com/s/opensanscondensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmPuLGRpWRyAs.ttf';
export const Ultra_400Regular = 'http://fonts.gstatic.com/s/ultra/v12/zOLy4prXmrtY-tT6yLOD6NxF.ttf';
export const Amaranth_400Regular =
  'http://fonts.gstatic.com/s/amaranth/v10/KtkuALODe433f0j1zPnCF9GqwnzW.ttf';
export const Amaranth_400Regular_Italic =
  'http://fonts.gstatic.com/s/amaranth/v10/KtkoALODe433f0j1zMnAHdWIx2zWD4I.ttf';
export const Amaranth_700Bold =
  'http://fonts.gstatic.com/s/amaranth/v10/KtkpALODe433f0j1zMF-OPWi6WDfFpuc.ttf';
export const Amaranth_700Bold_Italic =
  'http://fonts.gstatic.com/s/amaranth/v10/KtkrALODe433f0j1zMnAJWmn42T9E4ucRY8.ttf';
export const BigshotOne_400Regular =
  'http://fonts.gstatic.com/s/bigshotone/v10/u-470qukhRkkO6BD_7cM_gxuUQJBXv_-.ttf';
export const CarterOne_400Regular =
  'http://fonts.gstatic.com/s/carterone/v11/q5uCsoe5IOB2-pXv9UcNIxR2hYxREMs.ttf';
export const DidactGothic_400Regular =
  'http://fonts.gstatic.com/s/didactgothic/v13/ahcfv8qz1zt6hCC5G4F_P4ASpUySp0LlcyQ.ttf';
export const ExpletusSans_400Regular =
  'http://fonts.gstatic.com/s/expletussans/v13/RLp5K5v5_bqufTYdnhFzDj2dRfkSu6EuTHo.ttf';
export const ExpletusSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/expletussans/v13/RLpnK5v5_bqufTYdnhFzDj2ddfsYv4MrXHrRDA.ttf';
export const ExpletusSans_500Medium =
  'http://fonts.gstatic.com/s/expletussans/v13/RLpkK5v5_bqufTYdnhFzDj2dfQ07n6kFUHPIFaU.ttf';
export const ExpletusSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/expletussans/v13/RLpiK5v5_bqufTYdnhFzDj2ddfsgS6oPVFHNBaVImA.ttf';
export const ExpletusSans_600SemiBold =
  'http://fonts.gstatic.com/s/expletussans/v13/RLpkK5v5_bqufTYdnhFzDj2dfSE8n6kFUHPIFaU.ttf';
export const ExpletusSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/expletussans/v13/RLpiK5v5_bqufTYdnhFzDj2ddfsgZ60PVFHNBaVImA.ttf';
export const ExpletusSans_700Bold =
  'http://fonts.gstatic.com/s/expletussans/v13/RLpkK5v5_bqufTYdnhFzDj2dfUU9n6kFUHPIFaU.ttf';
export const ExpletusSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/expletussans/v13/RLpiK5v5_bqufTYdnhFzDj2ddfsgA6wPVFHNBaVImA.ttf';
export const FrancoisOne_400Regular =
  'http://fonts.gstatic.com/s/francoisone/v14/_Xmr-H4zszafZw3A-KPSZutNxgKQu_avAg.ttf';
export const HoltwoodOneSC_400Regular =
  'http://fonts.gstatic.com/s/holtwoodonesc/v10/yYLx0hLR0P-3vMFSk1TCq3Txg5B3cbb6LZttyg.ttf';
export const Judson_400Regular =
  'http://fonts.gstatic.com/s/judson/v12/FeVRS0Fbvbc14VxRD7N01bV7kg.ttf';
export const Judson_400Regular_Italic =
  'http://fonts.gstatic.com/s/judson/v12/FeVTS0Fbvbc14VxhDblw97BrknZf.ttf';
export const Judson_700Bold =
  'http://fonts.gstatic.com/s/judson/v12/FeVSS0Fbvbc14Vxps5xQ3Z5nm29Gww.ttf';
export const Megrim_400Regular =
  'http://fonts.gstatic.com/s/megrim/v10/46kulbz5WjvLqJZlbWXgd0RY1g.ttf';
export const Monofett_400Regular =
  'http://fonts.gstatic.com/s/monofett/v9/mFTyWbofw6zc9NtnW43SuRwr0VJ7.ttf';
export const PaytoneOne_400Regular =
  'http://fonts.gstatic.com/s/paytoneone/v12/0nksC9P7MfYHj2oFtYm2CiTqivr9iBq_.ttf';
export const Play_400Regular = 'http://fonts.gstatic.com/s/play/v11/6aez4K2oVqwIjtI8Hp8Tx3A.ttf';
export const Play_700Bold = 'http://fonts.gstatic.com/s/play/v11/6ae84K2oVqwItm4TOpc423nTJTM.ttf';
export const SigmarOne_400Regular =
  'http://fonts.gstatic.com/s/sigmarone/v10/co3DmWZ8kjZuErj9Ta3dk6Pjp3Di8U0.ttf';
export const Aclonica_400Regular =
  'http://fonts.gstatic.com/s/aclonica/v10/K2FyfZJVlfNNSEBXGb7TCI6oBjLz.ttf';
export const Damion_400Regular =
  'http://fonts.gstatic.com/s/damion/v9/hv-XlzJ3KEUe_YZUbWY3MTFgVg.ttf';
export const NewsCycle_400Regular =
  'http://fonts.gstatic.com/s/newscycle/v16/CSR64z1Qlv-GDxkbKVQ_TOcATNt_pOU.ttf';
export const NewsCycle_700Bold =
  'http://fonts.gstatic.com/s/newscycle/v16/CSR54z1Qlv-GDxkbKVQ_dFsvaNNUuOwkC2s.ttf';
export const OvertheRainbow_400Regular =
  'http://fonts.gstatic.com/s/overtherainbow/v10/11haGoXG1k_HKhMLUWz7Mc7vvW5upvOm9NA2XG0.ttf';
export const SwankyandMooMoo_400Regular =
  'http://fonts.gstatic.com/s/swankyandmoomoo/v9/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kUtbPkR64SYQ.ttf';
export const Syncopate_400Regular =
  'http://fonts.gstatic.com/s/syncopate/v11/pe0sMIuPIYBCpEV5eFdyAv2-C99ycg.ttf';
export const Syncopate_700Bold =
  'http://fonts.gstatic.com/s/syncopate/v11/pe0pMIuPIYBCpEV5eFdKvtKaA_Rue1UwVg.ttf';
export const Wallpoet_400Regular =
  'http://fonts.gstatic.com/s/wallpoet/v11/f0X10em2_8RnXVVdUNbu7cXP8L8G.ttf';
export const Siemreap_400Regular =
  'http://fonts.gstatic.com/s/siemreap/v12/Gg82N5oFbgLvHAfNl2YbnA8DLXpe.ttf';
export const Smythe_400Regular =
  'http://fonts.gstatic.com/s/smythe/v10/MwQ3bhT01--coT1BOLh_uGInjA.ttf';
export const SpecialElite_400Regular =
  'http://fonts.gstatic.com/s/specialelite/v10/XLYgIZbkc4JPUL5CVArUVL0nhncESXFtUsM.ttf';
export const TheGirlNextDoor_400Regular =
  'http://fonts.gstatic.com/s/thegirlnextdoor/v10/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCYIV7t7w6bE2A.ttf';
export const AnnieUseYourTelescope_400Regular =
  'http://fonts.gstatic.com/s/annieuseyourtelescope/v10/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGjlDfB3UUVZA.ttf';
export const DawningofaNewDay_400Regular =
  'http://fonts.gstatic.com/s/dawningofanewday/v10/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAz8bhWJGNoBE.ttf';
export const NovaSquare_400Regular =
  'http://fonts.gstatic.com/s/novasquare/v12/RrQUbo9-9DV7b06QHgSWsZhARYMgGtWA.ttf';
export const SueEllenFrancisco_400Regular =
  'http://fonts.gstatic.com/s/sueellenfrancisco/v10/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9ropF2lqk9H4.ttf';
export const WaitingfortheSunrise_400Regular =
  'http://fonts.gstatic.com/s/waitingforthesunrise/v10/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsYZQ9h_ZYk5J.ttf';
export const Miltonian_400Regular =
  'http://fonts.gstatic.com/s/miltonian/v13/zOL-4pbPn6Ne9JqTg9mr6e5As-FeiQ.ttf';
export const MiltonianTattoo_400Regular =
  'http://fonts.gstatic.com/s/miltoniantattoo/v15/EvOUzBRL0o0kCxF-lcMCQxlpVsA_FwP8MDBku-s.ttf';
export const Michroma_400Regular =
  'http://fonts.gstatic.com/s/michroma/v10/PN_zRfy9qWD8fEagAMg6rzjb_-Da.ttf';
export const Cabin_400Regular = 'http://fonts.gstatic.com/s/cabin/v14/u-4x0qWljRw-Pe839fxqmjRv.ttf';
export const Cabin_400Regular_Italic =
  'http://fonts.gstatic.com/s/cabin/v14/u-4_0qWljRw-Pd81__hInyRvYwc.ttf';
export const Cabin_500Medium =
  'http://fonts.gstatic.com/s/cabin/v14/u-480qWljRw-PdfD3NhisShmeh5I.ttf';
export const Cabin_500Medium_Italic =
  'http://fonts.gstatic.com/s/cabin/v14/u-460qWljRw-Pd81xwxhuyxEfw5IR-Y.ttf';
export const Cabin_600SemiBold =
  'http://fonts.gstatic.com/s/cabin/v14/u-480qWljRw-Pdfv29hisShmeh5I.ttf';
export const Cabin_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/cabin/v14/u-460qWljRw-Pd81xyBmuyxEfw5IR-Y.ttf';
export const Cabin_700Bold =
  'http://fonts.gstatic.com/s/cabin/v14/u-480qWljRw-PdeL2thisShmeh5I.ttf';
export const Cabin_700Bold_Italic =
  'http://fonts.gstatic.com/s/cabin/v14/u-460qWljRw-Pd81x0RnuyxEfw5IR-Y.ttf';
export const EBGaramond_400Regular =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RUA4V-e6yHgQ.ttf';
export const EBGaramond_500Medium =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-2fRUA4V-e6yHgQ.ttf';
export const EBGaramond_600SemiBold =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-NfNUA4V-e6yHgQ.ttf';
export const EBGaramond_700Bold =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-DPNUA4V-e6yHgQ.ttf';
export const EBGaramond_800ExtraBold =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-a_NUA4V-e6yHgQ.ttf';
export const EBGaramond_400Regular_Italic =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7e8QI96WamXgXFI.ttf';
export const EBGaramond_500Medium_Italic =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7eOQI96WamXgXFI.ttf';
export const EBGaramond_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7diR496WamXgXFI.ttf';
export const EBGaramond_700Bold_Italic =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7dbR496WamXgXFI.ttf';
export const EBGaramond_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/ebgaramond/v13/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7c8R496WamXgXFI.ttf';
export const NovaCut_400Regular =
  'http://fonts.gstatic.com/s/novacut/v11/KFOkCnSYu8mL-39LkWxPKTM1K9nz.ttf';
export const NovaFlat_400Regular =
  'http://fonts.gstatic.com/s/novaflat/v11/QdVUSTc-JgqpytEbVebEuStkm20oJA.ttf';
export const NovaMono_400Regular =
  'http://fonts.gstatic.com/s/novamono/v10/Cn-0JtiGWQ5Ajb--MRKfYGxYrdM9Sg.ttf';
export const NovaOval_400Regular =
  'http://fonts.gstatic.com/s/novaoval/v11/jAnEgHdmANHvPenMaswCMY-h3cWkWg.ttf';
export const NovaRound_400Regular =
  'http://fonts.gstatic.com/s/novaround/v11/flU9Rqquw5UhEnlwTJYTYYfeeetYEBc.ttf';
export const NovaScript_400Regular =
  'http://fonts.gstatic.com/s/novascript/v12/7Au7p_IpkSWSTWaFWkumvmQNEl0O0kEx.ttf';
export const NovaSlim_400Regular =
  'http://fonts.gstatic.com/s/novaslim/v11/Z9XUDmZNQAuem8jyZcn-yMOInrib9Q.ttf';
export const CabinSketch_400Regular =
  'http://fonts.gstatic.com/s/cabinsketch/v13/QGYpz_kZZAGCONcK2A4bGOjMn9JM6fnuKg.ttf';
export const CabinSketch_700Bold =
  'http://fonts.gstatic.com/s/cabinsketch/v13/QGY2z_kZZAGCONcK2A4bGOj0I_1o4dLyI4CMFw.ttf';
export const IrishGrover_400Regular =
  'http://fonts.gstatic.com/s/irishgrover/v10/buExpoi6YtLz2QW7LA4flVgf-P5Oaiw4cw.ttf';
export const ArchitectsDaughter_400Regular =
  'http://fonts.gstatic.com/s/architectsdaughter/v10/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvfY5q4szgE-Q.ttf';
export const Candal_400Regular =
  'http://fonts.gstatic.com/s/candal/v9/XoHn2YH6T7-t_8cNAR4Jt9Yxlw.ttf';
export const IndieFlower_400Regular =
  'http://fonts.gstatic.com/s/indieflower/v11/m8JVjfNVeKWVnh3QMuKkFcZlbkGG1dKEDw.ttf';
export const LeagueScript_400Regular =
  'http://fonts.gstatic.com/s/leaguescript/v11/CSR54zpSlumSWj9CGVsoBZdeaNNUuOwkC2s.ttf';
export const Pacifico_400Regular =
  'http://fonts.gstatic.com/s/pacifico/v16/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf';
export const Angkor_400Regular =
  'http://fonts.gstatic.com/s/angkor/v12/H4cmBXyAlsPdnlb-8iw-4Lqggw.ttf';
export const Battambang_400Regular =
  'http://fonts.gstatic.com/s/battambang/v13/uk-mEGe7raEw-HjkzZabDnWj4yxx7o8.ttf';
export const Battambang_700Bold =
  'http://fonts.gstatic.com/s/battambang/v13/uk-lEGe7raEw-HjkzZabNsmMxyRa8oZK9I0.ttf';
export const Bayon_400Regular = 'http://fonts.gstatic.com/s/bayon/v13/9XUrlJNmn0LPFl-pOhYEd2NJ.ttf';
export const Bokor_400Regular = 'http://fonts.gstatic.com/s/bokor/v12/m8JcjfpeeaqTiR2WdInbcaxE.ttf';
export const Chenla_400Regular =
  'http://fonts.gstatic.com/s/chenla/v12/SZc43FDpIKu8WZ9eXxfonUPL6Q.ttf';
export const Content_400Regular =
  'http://fonts.gstatic.com/s/content/v12/zrfl0HLayePhU_AwUaDyIiL0RCg.ttf';
export const Content_700Bold =
  'http://fonts.gstatic.com/s/content/v12/zrfg0HLayePhU_AwaRzdBirfWCHvkAI.ttf';
export const Dangrek_400Regular =
  'http://fonts.gstatic.com/s/dangrek/v11/LYjCdG30nEgoH8E2gCNqqVIuTN4.ttf';
export const Freehand_400Regular =
  'http://fonts.gstatic.com/s/freehand/v11/cIf-Ma5eqk01VjKTgAmBTmUOmZJk.ttf';
export const Khmer_400Regular = 'http://fonts.gstatic.com/s/khmer/v12/MjQImit_vPPwpF-BpN2EeYmD.ttf';
export const Koulen_400Regular =
  'http://fonts.gstatic.com/s/koulen/v13/AMOQz46as3KIBPeWgnA9kuYMUg.ttf';
export const MedievalSharp_400Regular =
  'http://fonts.gstatic.com/s/medievalsharp/v12/EvOJzAlL3oU5AQl2mP5KdgptAq96MwvXLDk.ttf';
export const Metal_400Regular = 'http://fonts.gstatic.com/s/metal/v12/lW-wwjUJIXTo7i3nnoQAUdN2.ttf';
export const Moul_400Regular = 'http://fonts.gstatic.com/s/moul/v11/nuF2D__FSo_3E-RYiJCy-00.ttf';
export const Moulpali_400Regular =
  'http://fonts.gstatic.com/s/moulpali/v12/H4ckBXKMl9HagUWymyY6wr-wg763.ttf';
export const OdorMeanChey_400Regular =
  'http://fonts.gstatic.com/s/odormeanchey/v11/raxkHiKDttkTe1aOGcJMR1A_4mrY2zqUKafv.ttf';
export const Preahvihear_400Regular =
  'http://fonts.gstatic.com/s/preahvihear/v11/6NUS8F-dNQeEYhzj7uluxswE49FJf8Wv.ttf';
export const Suwannaphum_400Regular =
  'http://fonts.gstatic.com/s/suwannaphum/v13/jAnCgHV7GtDvc8jbe8hXXIWl_8C0Wg2V.ttf';
export const Taprom_400Regular =
  'http://fonts.gstatic.com/s/taprom/v11/UcCn3F82JHycULbFQyk3-0kvHg.ttf';
export const VT323_400Regular = 'http://fonts.gstatic.com/s/vt323/v11/pxiKyp0ihIEF2hsYHpT2dkNE.ttf';
export const Anton_400Regular = 'http://fonts.gstatic.com/s/anton/v11/1Ptgg87LROyAm0K08i4gS7lu.ttf';
export const Bevan_400Regular = 'http://fonts.gstatic.com/s/bevan/v11/4iCj6KZ0a9NXjF8aUir7tlSJ.ttf';
export const Astloch_400Regular =
  'http://fonts.gstatic.com/s/astloch/v11/TuGRUVJ8QI5GSeUjq9wRzMtkH1Q.ttf';
export const Astloch_700Bold =
  'http://fonts.gstatic.com/s/astloch/v11/TuGUUVJ8QI5GSeUjk2A-6MNPA10xLMQ.ttf';
export const SixCaps_400Regular =
  'http://fonts.gstatic.com/s/sixcaps/v10/6ae_4KGrU7VR7bNmabcS9XXaPCop.ttf';
export const Bangers_400Regular =
  'http://fonts.gstatic.com/s/bangers/v12/FeVQS0BTqb0h60ACL5la2bxii28.ttf';
export const CodaCaption_800ExtraBold =
  'http://fonts.gstatic.com/s/codacaption/v13/ieVm2YRII2GMY7SyXSoDRiQGqcx6x_-fACIgaw.ttf';
export const GoudyBookletter1911_400Regular =
  'http://fonts.gstatic.com/s/goudybookletter1911/v9/sykt-z54laciWfKv-kX8krex0jDiD2HbY6I5tRbXZ4IXAA.ttf';
export const Neuton_200ExtraLight =
  'http://fonts.gstatic.com/s/neuton/v12/UMBQrPtMoH62xUZKAKkfegD5Drog6Q.ttf';
export const Neuton_300Light =
  'http://fonts.gstatic.com/s/neuton/v12/UMBQrPtMoH62xUZKZKofegD5Drog6Q.ttf';
export const Neuton_400Regular =
  'http://fonts.gstatic.com/s/neuton/v12/UMBTrPtMoH62xUZyyII7civlBw.ttf';
export const Neuton_400Regular_Italic =
  'http://fonts.gstatic.com/s/neuton/v12/UMBRrPtMoH62xUZCyog_UC71B6M5.ttf';
export const Neuton_700Bold =
  'http://fonts.gstatic.com/s/neuton/v12/UMBQrPtMoH62xUZKdK0fegD5Drog6Q.ttf';
export const Neuton_800ExtraBold =
  'http://fonts.gstatic.com/s/neuton/v12/UMBQrPtMoH62xUZKaK4fegD5Drog6Q.ttf';
export const PTSerif_400Regular =
  'http://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvDFRxL6ddjb-.ttf';
export const PTSerif_400Regular_Italic =
  'http://fonts.gstatic.com/s/ptserif/v11/EJRTQgYoZZY2vCFuvAFTzrq_cyb-vco.ttf';
export const PTSerif_700Bold =
  'http://fonts.gstatic.com/s/ptserif/v11/EJRSQgYoZZY2vCFuvAnt65qVXSr3pNNB.ttf';
export const PTSerif_700Bold_Italic =
  'http://fonts.gstatic.com/s/ptserif/v11/EJRQQgYoZZY2vCFuvAFT9gaQVy7VocNB6Iw.ttf';
export const PTSerifCaption_400Regular =
  'http://fonts.gstatic.com/s/ptserifcaption/v11/ieVl2ZhbGCW-JoW6S34pSDpqYKU059WxDCs5cvI.ttf';
export const PTSerifCaption_400Regular_Italic =
  'http://fonts.gstatic.com/s/ptserifcaption/v11/ieVj2ZhbGCW-JoW6S34pSDpqYKU019e7CAk8YvJEeg.ttf';
export const Kreon_300Light =
  'http://fonts.gstatic.com/s/kreon/v22/t5t9IRIUKY-TFF_LW5lnMR3v2DnvPNimejUfp2dWNg.ttf';
export const Kreon_400Regular =
  'http://fonts.gstatic.com/s/kreon/v22/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtimejUfp2dWNg.ttf';
export const Kreon_500Medium =
  'http://fonts.gstatic.com/s/kreon/v22/t5t9IRIUKY-TFF_LW5lnMR3v2DnvUNimejUfp2dWNg.ttf';
export const Kreon_600SemiBold =
  'http://fonts.gstatic.com/s/kreon/v22/t5t9IRIUKY-TFF_LW5lnMR3v2DnvvN-mejUfp2dWNg.ttf';
export const Kreon_700Bold =
  'http://fonts.gstatic.com/s/kreon/v22/t5t9IRIUKY-TFF_LW5lnMR3v2Dnvhd-mejUfp2dWNg.ttf';
export const Meddon_400Regular =
  'http://fonts.gstatic.com/s/meddon/v12/kmK8ZqA2EgDNeHTZhBdB3y_Aow.ttf';
export const OpenSans_300Light =
  'http://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8-VeJoCqeDjg.ttf';
export const OpenSans_300Light_Italic =
  'http://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKWyV-hsKKKTjrPW.ttf';
export const OpenSans_400Regular =
  'http://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf';
export const OpenSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/opensans/v17/mem6YaGs126MiZpBA-UFUJ0ef8xkA76a.ttf';
export const OpenSans_600SemiBold =
  'http://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirk-VeJoCqeDjg.ttf';
export const OpenSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKXGUehsKKKTjrPW.ttf';
export const OpenSans_700Bold =
  'http://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rg-VeJoCqeDjg.ttf';
export const OpenSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKWiUOhsKKKTjrPW.ttf';
export const OpenSans_800ExtraBold =
  'http://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN8rs-VeJoCqeDjg.ttf';
export const OpenSans_800ExtraBold_Italic =
  'http://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKW-U-hsKKKTjrPW.ttf';
export const CrimsonText_400Regular =
  'http://fonts.gstatic.com/s/crimsontext/v10/wlp2gwHKFkZgtmSR3NB0oRJvaAJSA_JN3Q.ttf';
export const CrimsonText_400Regular_Italic =
  'http://fonts.gstatic.com/s/crimsontext/v10/wlpogwHKFkZgtmSR3NB0oRJfaghWIfdd3ahG.ttf';
export const CrimsonText_600SemiBold =
  'http://fonts.gstatic.com/s/crimsontext/v10/wlppgwHKFkZgtmSR3NB0oRJXsCx2C9lR1LFffg.ttf';
export const CrimsonText_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/crimsontext/v10/wlprgwHKFkZgtmSR3NB0oRJfajCOD9NV9rRPfrKu.ttf';
export const CrimsonText_700Bold =
  'http://fonts.gstatic.com/s/crimsontext/v10/wlppgwHKFkZgtmSR3NB0oRJX1C12C9lR1LFffg.ttf';
export const CrimsonText_700Bold_Italic =
  'http://fonts.gstatic.com/s/crimsontext/v10/wlprgwHKFkZgtmSR3NB0oRJfajDqDtNV9rRPfrKu.ttf';
export const Calligraffitti_400Regular =
  'http://fonts.gstatic.com/s/calligraffitti/v11/46k2lbT3XjDVqJw3DCmCFjE0vnFZM5ZBpYN-.ttf';
export const CherryCreamSoda_400Regular =
  'http://fonts.gstatic.com/s/cherrycreamsoda/v10/UMBIrOxBrW6w2FFyi9paG0fdVdRciTd6Cd47DJ7G.ttf';
export const Chewy_400Regular = 'http://fonts.gstatic.com/s/chewy/v11/uK_94ruUb-k-wk5xIDMfO-ed.ttf';
export const ComingSoon_400Regular =
  'http://fonts.gstatic.com/s/comingsoon/v11/qWcuB6mzpYL7AJ2VfdQR1u-SUjjzsykh.ttf';
export const CraftyGirls_400Regular =
  'http://fonts.gstatic.com/s/craftygirls/v9/va9B4kXI39VaDdlPJo8N_NvuQR37fF3Wlg.ttf';
export const Crushed_400Regular =
  'http://fonts.gstatic.com/s/crushed/v10/U9Mc6dym6WXImTlFT1kfuIqyLzA.ttf';
export const FontdinerSwanky_400Regular =
  'http://fonts.gstatic.com/s/fontdinerswanky/v10/ijwOs4XgRNsiaI5-hcVb4hQgMvCD4uEfKiGvxts.ttf';
export const HomemadeApple_400Regular =
  'http://fonts.gstatic.com/s/homemadeapple/v10/Qw3EZQFXECDrI2q789EKQZJob3x9Vnksi4M7.ttf';
export const Kranky_400Regular =
  'http://fonts.gstatic.com/s/kranky/v10/hESw6XVgJzlPsFnMpheEZo_H_w.ttf';
export const LuckiestGuy_400Regular =
  'http://fonts.gstatic.com/s/luckiestguy/v10/_gP_1RrxsjcxVyin9l9n_j2RStR3qDpraA.ttf';
export const PermanentMarker_400Regular =
  'http://fonts.gstatic.com/s/permanentmarker/v9/Fh4uPib9Iyv2ucM6pGQMWimMp004HaqIfrT5nlk.ttf';
export const RockSalt_400Regular =
  'http://fonts.gstatic.com/s/rocksalt/v10/MwQ0bhv11fWD6QsAVOZbsEk7hbBWrA.ttf';
export const Schoolbell_400Regular =
  'http://fonts.gstatic.com/s/schoolbell/v10/92zQtBZWOrcgoe-fgnJIVxIQ6mRqfiQ.ttf';
export const Slackey_400Regular =
  'http://fonts.gstatic.com/s/slackey/v10/N0bV2SdQO-5yM0-dKlRaJdbWgdY.ttf';
export const Sunshiney_400Regular =
  'http://fonts.gstatic.com/s/sunshiney/v10/LDIwapGTLBwsS-wT4vcgE8moUePWkg.ttf';
export const WalterTurncoat_400Regular =
  'http://fonts.gstatic.com/s/walterturncoat/v10/snfys0Gs98ln43n0d-14ULoToe67YB2dQ5ZPqQ.ttf';
export const Buda_300Light = 'http://fonts.gstatic.com/s/buda/v10/GFDqWAN8mnyIJSSrG7UBr7pZKA0.ttf';
export const Corben_400Regular =
  'http://fonts.gstatic.com/s/corben/v13/LYjDdGzzklQtCMp9oAlEpVs3VQ.ttf';
export const Corben_700Bold =
  'http://fonts.gstatic.com/s/corben/v13/LYjAdGzzklQtCMpFHCZgrXArXN7HWQ.ttf';
export const Gruppo_400Regular =
  'http://fonts.gstatic.com/s/gruppo/v10/WwkfxPmzE06v_ZWFWXDAOIEQUQ.ttf';
export const JustAnotherHand_400Regular =
  'http://fonts.gstatic.com/s/justanotherhand/v11/845CNN4-AJyIGvIou-6yJKyptyOpOcr_BmmlS5aw.ttf';
export const Kristi_400Regular =
  'http://fonts.gstatic.com/s/kristi/v11/uK_y4ricdeU6zwdRCh0TMv6EXw.ttf';
export const Lekton_400Regular =
  'http://fonts.gstatic.com/s/lekton/v10/SZc43FDmLaWmWpBeXxfonUPL6Q.ttf';
export const Lekton_400Regular_Italic =
  'http://fonts.gstatic.com/s/lekton/v10/SZc63FDmLaWmWpBuXR3sv0bb6StO.ttf';
export const Lekton_700Bold =
  'http://fonts.gstatic.com/s/lekton/v10/SZc73FDmLaWmWpBm4zjMlWjX4DJXgQ.ttf';
export const MaidenOrange_400Regular =
  'http://fonts.gstatic.com/s/maidenorange/v10/kJE1BuIX7AUmhi2V4m08kb1XjOZdCZS8FY8.ttf';
export const Allan_400Regular = 'http://fonts.gstatic.com/s/allan/v12/ea8XadU7WuTxEtb2P9SF8nZE.ttf';
export const Allan_700Bold =
  'http://fonts.gstatic.com/s/allan/v12/ea8aadU7WuTxEu5KEPCN2WpNgEKU.ttf';
export const AnonymousPro_400Regular =
  'http://fonts.gstatic.com/s/anonymouspro/v13/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf';
export const AnonymousPro_400Regular_Italic =
  'http://fonts.gstatic.com/s/anonymouspro/v13/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf';
export const AnonymousPro_700Bold =
  'http://fonts.gstatic.com/s/anonymouspro/v13/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf';
export const AnonymousPro_700Bold_Italic =
  'http://fonts.gstatic.com/s/anonymouspro/v13/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf';
export const Copse_400Regular = 'http://fonts.gstatic.com/s/copse/v9/11hPGpDKz1rGb0djHkihUb-A.ttf';
export const Kenia_400Regular = 'http://fonts.gstatic.com/s/kenia/v11/jizURE5PuHQH9qCONUGswfGM.ttf';
export const Lato_100Thin = 'http://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf';
export const Lato_100Thin_Italic =
  'http://fonts.gstatic.com/s/lato/v16/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf';
export const Lato_300Light = 'http://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf';
export const Lato_300Light_Italic =
  'http://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf';
export const Lato_400Regular = 'http://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHvxk6XweuBCY.ttf';
export const Lato_400Regular_Italic =
  'http://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf';
export const Lato_700Bold = 'http://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf';
export const Lato_700Bold_Italic =
  'http://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf';
export const Lato_900Black = 'http://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf';
export const Lato_900Black_Italic =
  'http://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf';
export const Orbitron_400Regular =
  'http://fonts.gstatic.com/s/orbitron/v15/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6xpmIyXjU1pg.ttf';
export const Orbitron_500Medium =
  'http://fonts.gstatic.com/s/orbitron/v15/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyKS6xpmIyXjU1pg.ttf';
export const Orbitron_600SemiBold =
  'http://fonts.gstatic.com/s/orbitron/v15/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyxSmxpmIyXjU1pg.ttf';
export const Orbitron_700Bold =
  'http://fonts.gstatic.com/s/orbitron/v15/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1ny_CmxpmIyXjU1pg.ttf';
export const Orbitron_800ExtraBold =
  'http://fonts.gstatic.com/s/orbitron/v15/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nymymxpmIyXjU1pg.ttf';
export const Orbitron_900Black =
  'http://fonts.gstatic.com/s/orbitron/v15/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimxpmIyXjU1pg.ttf';
export const Sniglet_400Regular =
  'http://fonts.gstatic.com/s/sniglet/v11/cIf9MaFLtkE3UjaJxCmrYGkHgIs.ttf';
export const Sniglet_800ExtraBold =
  'http://fonts.gstatic.com/s/sniglet/v11/cIf4MaFLtkE3UjaJ_ImHRGEsnIJkWL4.ttf';
export const Ubuntu_300Light =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCv6KVjbNBYlgoC1CzTt2aMH4V_gg.ttf';
export const Ubuntu_300Light_Italic =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCp6KVjbNBYlgoKejZftWyIPYBvgpUI.ttf';
export const Ubuntu_400Regular =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf';
export const Ubuntu_400Regular_Italic =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCu6KVjbNBYlgoKeg7znUiAFpxm.ttf';
export const Ubuntu_500Medium =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCv6KVjbNBYlgoCjC3Tt2aMH4V_gg.ttf';
export const Ubuntu_500Medium_Italic =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCp6KVjbNBYlgoKejYHtGyIPYBvgpUI.ttf';
export const Ubuntu_700Bold =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCv6KVjbNBYlgoCxCvTt2aMH4V_gg.ttf';
export const Ubuntu_700Bold_Italic =
  'http://fonts.gstatic.com/s/ubuntu/v14/4iCp6KVjbNBYlgoKejZPsmyIPYBvgpUI.ttf';
export const Vibur_400Regular = 'http://fonts.gstatic.com/s/vibur/v10/DPEiYwmEzw0QRjTpLjoJd-Xa.ttf';
export const MountainsofChristmas_400Regular =
  'http://fonts.gstatic.com/s/mountainsofchristmas/v12/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7ePNamMPNpJpc.ttf';
export const MountainsofChristmas_700Bold =
  'http://fonts.gstatic.com/s/mountainsofchristmas/v12/3y9z6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eBGqJFPtCOp6IaEA.ttf';
export const Coda_400Regular = 'http://fonts.gstatic.com/s/coda/v15/SLXHc1jY5nQ8JUIMapaN39I.ttf';
export const Coda_800ExtraBold =
  'http://fonts.gstatic.com/s/coda/v15/SLXIc1jY5nQ8HeIgTp6mw9t1cX8.ttf';
export const CoveredByYourGrace_400Regular =
  'http://fonts.gstatic.com/s/coveredbyyourgrace/v9/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOS0FgItq6bFIg.ttf';
export const JustMeAgainDownHere_400Regular =
  'http://fonts.gstatic.com/s/justmeagaindownhere/v11/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAuwHvqDwc_fg.ttf';
export const UnifrakturCook_700Bold =
  'http://fonts.gstatic.com/s/unifrakturcook/v11/IurA6Yli8YOdcoky-0PTTdkm56n05Uw13ILXs-h6.ttf';
export const Allerta_400Regular =
  'http://fonts.gstatic.com/s/allerta/v10/TwMO-IAHRlkbx940UnEdSQqO5uY.ttf';
export const AllertaStencil_400Regular =
  'http://fonts.gstatic.com/s/allertastencil/v10/HTx0L209KT-LmIE9N7OR6eiycOeF-zz313DuvQ.ttf';
export const Bentham_400Regular =
  'http://fonts.gstatic.com/s/bentham/v10/VdGeAZQPEpYfmHglKWw7CJaK_y4.ttf';
export const Geo_400Regular = 'http://fonts.gstatic.com/s/geo/v11/CSRz4zRZlufVL3BmQjlCbQ.ttf';
export const Geo_400Regular_Italic =
  'http://fonts.gstatic.com/s/geo/v11/CSRx4zRZluflLXpiYDxSbf8r.ttf';
export const Puritan_400Regular =
  'http://fonts.gstatic.com/s/puritan/v11/845YNMgkAJ2VTtIo9JrwRdaI50M.ttf';
export const Puritan_400Regular_Italic =
  'http://fonts.gstatic.com/s/puritan/v11/845aNMgkAJ2VTtIoxJj6QfSN90PfXA.ttf';
export const Puritan_700Bold =
  'http://fonts.gstatic.com/s/puritan/v11/845dNMgkAJ2VTtIozCbfYd6j-0rGRes.ttf';
export const Puritan_700Bold_Italic =
  'http://fonts.gstatic.com/s/puritan/v11/845fNMgkAJ2VTtIoxJjC_dup_2jDVevnLQ.ttf';
export const UnifrakturMaguntia_400Regular =
  'http://fonts.gstatic.com/s/unifrakturmaguntia/v10/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVun2xNNgNa1A.ttf';
export const Arimo_400Regular = 'http://fonts.gstatic.com/s/arimo/v13/P5sMzZCDf9_T_20eziBMjI-u.ttf';
export const Arimo_400Regular_Italic =
  'http://fonts.gstatic.com/s/arimo/v13/P5sCzZCDf9_T_10cxCRuiZ-uydg.ttf';
export const Arimo_700Bold =
  'http://fonts.gstatic.com/s/arimo/v13/P5sBzZCDf9_T_1Wi4QREp5On0ME2.ttf';
export const Arimo_700Bold_Italic =
  'http://fonts.gstatic.com/s/arimo/v13/P5sHzZCDf9_T_10c_JhBrZeF1dE2PY4.ttf';
export const Cousine_400Regular =
  'http://fonts.gstatic.com/s/cousine/v14/d6lIkaiiRdih4SpPzSMlzTbtz9k.ttf';
export const Cousine_400Regular_Italic =
  'http://fonts.gstatic.com/s/cousine/v14/d6lKkaiiRdih4SpP_SEvyRTo39l8hw.ttf';
export const Cousine_700Bold =
  'http://fonts.gstatic.com/s/cousine/v14/d6lNkaiiRdih4SpP9Z8K6T7G09BlnmQ.ttf';
export const Cousine_700Bold_Italic =
  'http://fonts.gstatic.com/s/cousine/v14/d6lPkaiiRdih4SpP_SEXdTvM1_JgjmRpOA.ttf';
export const Tinos_400Regular = 'http://fonts.gstatic.com/s/tinos/v13/buE4poGnedXvwgX8dGVh8TI-.ttf';
export const Tinos_400Regular_Italic =
  'http://fonts.gstatic.com/s/tinos/v13/buE2poGnedXvwjX-fmFD9CI-4NU.ttf';
export const Tinos_700Bold =
  'http://fonts.gstatic.com/s/tinos/v13/buE1poGnedXvwj1AW0Fp2i43-cxL.ttf';
export const Tinos_700Bold_Italic =
  'http://fonts.gstatic.com/s/tinos/v13/buEzpoGnedXvwjX-Rt1s0CoV_NxLeiw.ttf';
export const Arvo_400Regular = 'http://fonts.gstatic.com/s/arvo/v13/tDbD2oWUg0MKmSAa7Lzr7vs.ttf';
export const Arvo_400Regular_Italic =
  'http://fonts.gstatic.com/s/arvo/v13/tDbN2oWUg0MKqSIQ6J7u_vvijQ.ttf';
export const Arvo_700Bold = 'http://fonts.gstatic.com/s/arvo/v13/tDbM2oWUg0MKoZw1yLTA8vL7lAE.ttf';
export const Arvo_700Bold_Italic =
  'http://fonts.gstatic.com/s/arvo/v13/tDbO2oWUg0MKqSIoVLHK9tD-hAHkGg.ttf';
export const JosefinSans_100Thin =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjRXMFrLgTsQV0.ttf';
export const JosefinSans_200ExtraLight =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_LjQXMFrLgTsQV0.ttf';
export const JosefinSans_300Light =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQXMFrLgTsQV0.ttf';
export const JosefinSans_400Regular =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf';
export const JosefinSans_500Medium =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQXMFrLgTsQV0.ttf';
export const JosefinSans_600SemiBold =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ObXXMFrLgTsQV0.ttf';
export const JosefinSans_700Bold =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_N_XXMFrLgTsQV0.ttf';
export const JosefinSans_100Thin_Italic =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtINhKibpUV3MEQ.ttf';
export const JosefinSans_200ExtraLight_Italic =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTNIJhKibpUV3MEQ.ttf';
export const JosefinSans_300Light_Italic =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCT6oJhKibpUV3MEQ.ttf';
export const JosefinSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtIJhKibpUV3MEQ.ttf';
export const JosefinSans_500Medium_Italic =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCThoJhKibpUV3MEQ.ttf';
export const JosefinSans_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTaoVhKibpUV3MEQ.ttf';
export const JosefinSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/josefinsans/v15/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTU4VhKibpUV3MEQ.ttf';
export const JosefinSlab_100Thin =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-nwjwOK3Ps5GSJlNNkMalvyQ6qBM7oPxMX.ttf';
export const JosefinSlab_100Thin_Italic =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-lwjwOK3Ps5GSJlNNkMalnrzbODsrKOgMX95A.ttf';
export const JosefinSlab_300Light =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-mwjwOK3Ps5GSJlNNkMalvASyKLuDkNgoO7g.ttf';
export const JosefinSlab_300Light_Italic =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-kwjwOK3Ps5GSJlNNkMalnrzYGLOrgFA8e7onu.ttf';
export const JosefinSlab_400Regular =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-5wjwOK3Ps5GSJlNNkMalXrQSuJsv4Pw.ttf';
export const JosefinSlab_400Regular_Italic =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-nwjwOK3Ps5GSJlNNkMalnrw6qBM7oPxMX.ttf';
export const JosefinSlab_600SemiBold =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-mwjwOK3Ps5GSJlNNkMalvdSqKLuDkNgoO7g.ttf';
export const JosefinSlab_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-kwjwOK3Ps5GSJlNNkMalnrzZyKurgFA8e7onu.ttf';
export const JosefinSlab_700Bold =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-mwjwOK3Ps5GSJlNNkMalvESuKLuDkNgoO7g.ttf';
export const JosefinSlab_700Bold_Italic =
  'http://fonts.gstatic.com/s/josefinslab/v10/lW-kwjwOK3Ps5GSJlNNkMalnrzYWK-rgFA8e7onu.ttf';
export const GFSDidot_400Regular =
  'http://fonts.gstatic.com/s/gfsdidot/v9/Jqzh5TybZ9vZMWFssvwiF-fGFSCGAA.ttf';
export const GFSNeohellenic_400Regular =
  'http://fonts.gstatic.com/s/gfsneohellenic/v12/8QIRdiDOrfiq0b7R8O1Iw9WLcY5TLahP46UDUw.ttf';
export const GFSNeohellenic_400Regular_Italic =
  'http://fonts.gstatic.com/s/gfsneohellenic/v12/8QITdiDOrfiq0b7R8O1Iw9WLcY5jL6JLwaATU91X.ttf';
export const GFSNeohellenic_700Bold =
  'http://fonts.gstatic.com/s/gfsneohellenic/v12/8QIUdiDOrfiq0b7R8O1Iw9WLcY5rkYdr644fWsRO9w.ttf';
export const GFSNeohellenic_700Bold_Italic =
  'http://fonts.gstatic.com/s/gfsneohellenic/v12/8QIWdiDOrfiq0b7R8O1Iw9WLcY5jL5r37oQbeMFe985V.ttf';
export const Hanuman_400Regular =
  'http://fonts.gstatic.com/s/hanuman/v13/VuJxdNvD15HhpJJBeKbXOIFneRo.ttf';
export const Hanuman_700Bold =
  'http://fonts.gstatic.com/s/hanuman/v13/VuJ0dNvD15HhpJJBQBr4HIlMZRNcp0o.ttf';
export const Neucha_400Regular =
  'http://fonts.gstatic.com/s/neucha/v11/q5uGsou0JOdh94bvugNsCxVEgA.ttf';
export const PTSans_400Regular =
  'http://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79P0WOxOGMMDQ.ttf';
export const PTSans_400Regular_Italic =
  'http://fonts.gstatic.com/s/ptsans/v11/jizYRExUiTo99u79D0eEwMOJIDQA-g.ttf';
export const PTSans_700Bold =
  'http://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf';
export const PTSans_700Bold_Italic =
  'http://fonts.gstatic.com/s/ptsans/v11/jizdRExUiTo99u79D0e8fOytKB8c8zMrig.ttf';
export const PTSansCaption_400Regular =
  'http://fonts.gstatic.com/s/ptsanscaption/v12/0FlMVP6Hrxmt7-fsUFhlFXNIlpcqfQXwQy6yxg.ttf';
export const PTSansCaption_700Bold =
  'http://fonts.gstatic.com/s/ptsanscaption/v12/0FlJVP6Hrxmt7-fsUFhlFXNIlpcSwSrUSwWuz38Tgg.ttf';
export const PTSansNarrow_400Regular =
  'http://fonts.gstatic.com/s/ptsansnarrow/v11/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf';
export const PTSansNarrow_700Bold =
  'http://fonts.gstatic.com/s/ptsansnarrow/v11/BngSUXNadjH0qYEzV7ab-oWlsbg95DiCUfzgRd-3.ttf';
export const Vollkorn_400Regular =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb9GDoxxrvAnPhYGykuYkw2rQg1.ttf';
export const Vollkorn_400Regular_Italic =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb7GDoxxrvAnPhYGxksaEgUqBg15TY.ttf';
export const Vollkorn_600SemiBold =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb6GDoxxrvAnPhYGxH2TGg-hhQ8_C_3.ttf';
export const Vollkorn_600SemiBold_Italic =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb4GDoxxrvAnPhYGxksUJA6jBAe-T_34DM.ttf';
export const Vollkorn_700Bold =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb6GDoxxrvAnPhYGxGSTWg-hhQ8_C_3.ttf';
export const Vollkorn_700Bold_Italic =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb4GDoxxrvAnPhYGxksUPQ7jBAe-T_34DM.ttf';
export const Vollkorn_900Black =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb6GDoxxrvAnPhYGxGqT2g-hhQ8_C_3.ttf';
export const Vollkorn_900Black_Italic =
  'http://fonts.gstatic.com/s/vollkorn/v10/0yb4GDoxxrvAnPhYGxksUMw5jBAe-T_34DM.ttf';
export const Tangerine_400Regular =
  'http://fonts.gstatic.com/s/tangerine/v11/IurY6Y5j_oScZZow4VOBDpxNhLBQ4Q.ttf';
export const Tangerine_700Bold =
  'http://fonts.gstatic.com/s/tangerine/v11/Iurd6Y5j_oScZZow4VO5srNpjJtM6G0t9w.ttf';
export const OldStandardTT_400Regular =
  'http://fonts.gstatic.com/s/oldstandardtt/v12/MwQubh3o1vLImiwAVvYawgcf2eVurVC5RHdCZg.ttf';
export const OldStandardTT_400Regular_Italic =
  'http://fonts.gstatic.com/s/oldstandardtt/v12/MwQsbh3o1vLImiwAVvYawgcf2eVer1q9ZnJSZtQG.ttf';
export const OldStandardTT_700Bold =
  'http://fonts.gstatic.com/s/oldstandardtt/v12/MwQrbh3o1vLImiwAVvYawgcf2eVWEX-dTFxeb80flQ.ttf';
export const IMFellDWPica_400Regular =
  'http://fonts.gstatic.com/s/imfelldwpica/v9/2sDGZGRQotv9nbn2qSl0TxXVYNw9ZAPUvi88MQ.ttf';
export const IMFellDWPica_400Regular_Italic =
  'http://fonts.gstatic.com/s/imfelldwpica/v9/2sDEZGRQotv9nbn2qSl0TxXVYNwNZgnQnCosMXm0.ttf';
export const IMFellDWPicaSC_400Regular =
  'http://fonts.gstatic.com/s/imfelldwpicasc/v9/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTW-BiKEUiBGA.ttf';
export const IMFellDoublePica_400Regular =
  'http://fonts.gstatic.com/s/imfelldoublepica/v9/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rT0S1UL5Ayp0.ttf';
export const IMFellDoublePica_400Regular_Italic =
  'http://fonts.gstatic.com/s/imfelldoublepica/v9/3XF0EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0a_VJxF2p2G8g.ttf';
export const IMFellDoublePicaSC_400Regular =
  'http://fonts.gstatic.com/s/imfelldoublepicasc/v9/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ4Y0Eeru2dGg.ttf';
export const IMFellEnglish_400Regular =
  'http://fonts.gstatic.com/s/imfellenglish/v9/Ktk1ALSLW8zDe0rthJysWrnLsAz3F6mZVY9Y5w.ttf';
export const IMFellEnglish_400Regular_Italic =
  'http://fonts.gstatic.com/s/imfellenglish/v9/Ktk3ALSLW8zDe0rthJysWrnLsAzHFaOdd4pI59zg.ttf';
export const IMFellEnglishSC_400Regular =
  'http://fonts.gstatic.com/s/imfellenglishsc/v9/a8IENpD3CDX-4zrWfr1VY879qFF05pZLO4gOg0shzA.ttf';
export const IMFellFrenchCanon_400Regular =
  'http://fonts.gstatic.com/s/imfellfrenchcanon/v9/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6coVPt1ozoPz.ttf';
export const IMFellFrenchCanon_400Regular_Italic =
  'http://fonts.gstatic.com/s/imfellfrenchcanon/v9/-F6gfiNtDWYfYc-tDiyiw08rrghJszkK6foXNNlKy5PzzrU.ttf';
export const IMFellFrenchCanonSC_400Regular =
  'http://fonts.gstatic.com/s/imfellfrenchcanonsc/v9/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H3c0UBCVIVqH.ttf';
export const IMFellGreatPrimer_400Regular =
  'http://fonts.gstatic.com/s/imfellgreatprimer/v9/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJorXvDo3SQY1.ttf';
export const IMFellGreatPrimer_400Regular_Italic =
  'http://fonts.gstatic.com/s/imfellgreatprimer/v9/bx6UNwSJtayYxOkbYFsT6hMsLzX7u85rJrrVtj4VTBY1N6U.ttf';
export const IMFellGreatPrimerSC_400Regular =
  'http://fonts.gstatic.com/s/imfellgreatprimersc/v9/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTLf9BXz0s8FG.ttf';
export const Lobster_400Regular =
  'http://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9_oWsMqEzSJQ.ttf';
export const YanoneKaffeesatz_200ExtraLight =
  'http://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftodtWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_300Light =
  'http://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoqNWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_400Regular =
  'http://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_500Medium =
  'http://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoxNWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_600SemiBold =
  'http://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoKNKpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_700Bold =
  'http://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoEdKpcGuLCnXkVA.ttf';
export const Cantarell_400Regular =
  'http://fonts.gstatic.com/s/cantarell/v9/B50NF7ZDq37KMUvlO01Ji6hqHK-CLA.ttf';
export const Cantarell_400Regular_Italic =
  'http://fonts.gstatic.com/s/cantarell/v9/B50LF7ZDq37KMUvlO015iaJuPqqSLJYf.ttf';
export const Cantarell_700Bold =
  'http://fonts.gstatic.com/s/cantarell/v9/B50IF7ZDq37KMUvlO01xN4dOFISeJY8GgQ.ttf';
export const Cantarell_700Bold_Italic =
  'http://fonts.gstatic.com/s/cantarell/v9/B50WF7ZDq37KMUvlO015iZrSEY6aB4oWgWHB.ttf';
export const Nobile_400Regular =
  'http://fonts.gstatic.com/s/nobile/v11/m8JTjflSeaOVl1i2XqfXeLVdbw.ttf';
export const Nobile_400Regular_Italic =
  'http://fonts.gstatic.com/s/nobile/v11/m8JRjflSeaOVl1iGXK3TWrBNb3OD.ttf';
export const Nobile_500Medium =
  'http://fonts.gstatic.com/s/nobile/v11/m8JQjflSeaOVl1iOqo7zcJ5BZmqa3A.ttf';
export const Nobile_500Medium_Italic =
  'http://fonts.gstatic.com/s/nobile/v11/m8JWjflSeaOVl1iGXJUnc5RFRG-K3Mud.ttf';
export const Nobile_700Bold =
  'http://fonts.gstatic.com/s/nobile/v11/m8JQjflSeaOVl1iO4ojzcJ5BZmqa3A.ttf';
export const Nobile_700Bold_Italic =
  'http://fonts.gstatic.com/s/nobile/v11/m8JWjflSeaOVl1iGXJVvdZRFRG-K3Mud.ttf';
export const ReenieBeanie_400Regular =
  'http://fonts.gstatic.com/s/reeniebeanie/v10/z7NSdR76eDkaJKZJFkkjuvWxbP2_qoOgf_w.ttf';
export const Molengo_400Regular =
  'http://fonts.gstatic.com/s/molengo/v10/I_uuMpWeuBzZNBtQbbRQkiCvs5Y.ttf';
export const Inconsolata_200ExtraLight =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU8aRr8lleY2co.ttf';
export const Inconsolata_300Light =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp9s8aRr8lleY2co.ttf';
export const Inconsolata_400Regular =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8aRr8lleY2co.ttf';
export const Inconsolata_500Medium =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp7c8aRr8lleY2co.ttf';
export const Inconsolata_600SemiBold =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp1s7aRr8lleY2co.ttf';
export const Inconsolata_700Bold =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp2I7aRr8lleY2co.ttf';
export const Inconsolata_800ExtraBold =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU7aRr8lleY2co.ttf';
export const Inconsolata_900Black =
  'http://fonts.gstatic.com/s/inconsolata/v19/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lppyw7aRr8lleY2co.ttf';
